// LoginFrame.js
import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useStateContext } from "../../contexts/ContextProvider";
import { InputWithLabelAndError as Input } from "components/ui/InputWithLabelAndError";
import { useNavigate, Link } from "react-router-dom";

import { ReactComponent as Signature } from "icons/signature.svg";
import Checkbox from "components/ui/checkboxLabelError"; // Ensure the correct import path
import { Icon } from "@iconify/react";
import { jwtDecode } from "jwt-decode";
import loadUserProfile from "./loadUserProfile";

import axios from "axios";
import { widgetData } from "data/chartData/frontLayout";
import { frontLayouts } from "data/frontLayouts";
import { Button } from "components/ui/button";

const LoginFrame = () => {
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const {
    handleLogin,

    setAuthAccessToken,

    setAuthAccessTokenExpiration,

    setAuthUserId,
  } = useAuth();

  const [responseMessage, setResponseMessage] = useState("");

  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false); // State for spinner visibility

  const {
    setUserProfile,
    userProfile,
    setActiveComponent,
    setIsLoadingUserPermissions,
  } = useStateContext();

  const validate = () => {
    let isValid = true;
    let errors = {};

    if (!identifier) {
      isValid = false;
      errors.identifier = "El email o teléfono es necesario";
    } else if (
      !(
        /\S+@\S+\.\S+/.test(identifier) ||
        (/^\+?[0-9]+$/.test(identifier) && identifier.length >= 8)
      )
    ) {
      isValid = false;
      errors.identifier = "El email o teléfono no es válido";
    }

    if (!password) {
      isValid = false;
      errors.password = "Se necesita contraseña.";
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    setErrors("");
    e.preventDefault();

    const isEmail = /\S+@\S+\.\S+/.test(identifier);
    let email = "";
    let phoneNumber = "";

    if (isEmail) {
      email = identifier;
    } else {
      phoneNumber = identifier;
    }

    if (validate()) {
      setLoading(true);
      try {
        const response = await fetch(
          "https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/auth/login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, phoneNumber, password }),
          }
        );
        const data = await response.json();
        const responseBody = JSON.parse(data.body);
        console.log("LoginFrame response: ",response);

        if (data.statusCode >= 200 && data.statusCode < 300) {
          setErrors({});
          setResponseMessage("");

          const { jwt } = data.body;

          const jwtParsed = responseBody.jwt;

          if (!jwtParsed) {
            console.log("JWT token missing in response");
          }

          const decodedToken = jwtDecode(jwtParsed);
          //Extract data
          const accessToken = decodedToken.accessToken;
          const userId = decodedToken.id;
          const expiration = decodedToken.expiration;
          const first_login = decodedToken.first_login;

          setAuthAccessToken(accessToken);
          setAuthAccessTokenExpiration(expiration);
          setAuthUserId(userId);

          sessionStorage.setItem("accessToken", accessToken);
          sessionStorage.setItem("accessTokenExpiry", expiration);
          sessionStorage.setItem("userId", userId);

          setSuccessMessage("Sesión iniciada correctamente");

          setTimeout(() => {
            handleLogin();
            loadUserProfile(
              setUserProfile,
              userId,
              userProfile,
              setIsLoadingUserPermissions
            );

            setActiveComponent("dashboard");
          }, 1000);
        } else {
          if (response.status === 403) {
            setResponseMessage(
              "No has activado tu cuenta. Revisa tu cuenta de correo electrónico y busca el email con el enlace de activación."
            );
          } else {
            let errorMessage = "Error durante registro";
            switch (data.statusCode) {
              case 400:
                errorMessage = responseBody.message;
                break;
              case 401:
                errorMessage = responseBody.message;
                break;
              case 500:
                errorMessage = responseBody.message;
                break;
              default:
                errorMessage = `Error inesperado: ${response.statusText}`;
            }
            setResponseMessage(errorMessage);
          }
        }
      } catch (error) {
        console.error("Request error:", error);
        setResponseMessage("Error conectando con el servidor");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleGuestAccess = async () => {
    handleLogin();

    const response = await axios.post(
      "https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/users/profile?action=loginGuest",
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const parsedData = JSON.parse(response.data.body);

    sessionStorage.setItem(
      "frontendPermissions",
      JSON.stringify(parsedData.permissions)
    );

    console.log(
      "loadUserProfile Permissions stored in session storage:",
      sessionStorage.getItem("frontendPermissions")
    );

    setUserProfile({
      isUserAGuest: true,
      profilePicture: "",
      parcela: "",
      poligono: "",
      catastro: "",
      userSettings: {
        darkMode: "system",
        fontSize: "16px",
        sidebarPosition: "left",
        layoutMode: "simple",
        dashboardLayout: frontLayouts["simple"],
        widgets: widgetData,
        storedWidgets: [],
      },
      contactDetails: {
        firstName: "",
        lastName: "",
        workRole: "",
        bio: "",
        phone: "",
        contactMail: "",
        allowWhatsapp: true,

        address: {
          city: "",
          state: "",
          country: "",
          postalCode: "",
          street: "",
        },
      },
    });
    console.log("userProfile in LoginFrame", userProfile);
    setActiveComponent("dashboard");
    return;
  };

  return (
    <div className="max-w-md w-full flex flex-col gap-y-4 md:gap-y-8">
      <div>
        <h2 className=" font-medium leading-7 dark:text-white">Login</h2>
        <span className="text-sm dark:text-white">Haz Login en tu cuenta</span>
      </div>
      {/* Login with certificate */}
      {/* <button className="bg-gray-200 dark:bg-gray-900 dark:focus:bg-gray-800 text-gray-950 dark:text-gray-100 dark:hover:bg-gray-800 py-2 px-4 rounded-md hover:bg-gray-300 focus:outline-none focus:bg-gray-400">
        <Signature className="h-5 w-5 inline-block mr-3" />
        Login with digital Certificate
      </button>
      <div className="flex w-full justify-around dark:text-gray-200  items-center gap-x-2">
        <span className="h-0.5 px-2 w-full flex-1 bg-gray-600 dark:bg-gray-200"></span>
        <span className="text-sm w-fit text-center">or login with Email</span>
        <span className="h-0.5 px-2 w-full flex-1 bg-gray-600 dark:bg-gray-200"></span>
      </div> */}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <Input
            variant="default"
            name="identifier"
            type="text"
            label="Email o Teléfono"
            placeholder="Escribe tu email o teléfono"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
            error={errors.identifier}
          />
        </div>
        {/* <div className="mb-4">
          <Input
            variant="default"
            name="email"
            type="email"
            label="Email"
            placeholder="Escribe tu email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            //onChange={(e) => setEmail(e.target.value)}
            error={errors.email}
          />
        </div> */}
        <div className="mb-4 relative">
          <Input
            variant="default"
            type="text"
            name="password"
            label="Contraseña"
            placeholder="••••••••"
            hasRevealPassword={true}
            onChange={(e) => setPassword(e.target.value)}
            error={errors.password}
          />
        </div>
        <div className="flex text-sm justify-between my-2 font-semibold">
          {/**
           * <Checkbox
            name="rememberMe"
            label="Recuerdeme"
            checked={formValues.rememberMe}
            onChange={handleCheckboxChange}
          />
           */}
          <Link
            className="inline-block  hover:underline text-center text-blue-400 dark:text-blue-500 hover:text-blue-600"
            //to="/forgotPassword"
            onClick={() => setActiveComponent("forgotPassword")}
          >
            Olvidé mi contraseña
          </Link>
        </div>
        <Button
          type="submit"
          className="mt-2 w-full bg-blue-500 dark:bg-blue-500 text-white dark:text-white py-2 px-4 rounded-md hover:bg-blue-600 dark:hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
        >
          {loading ? (
            <Icon icon="svg-spinners:180-ring" className="text-white" />
          ) : (
            "Iniciar Sesión"
          )}
        </Button>
      </form>
      <Link
        className="inline-block  hover:underline text-center text-blue-400 hover:text-blue-600"
        onClick={() => handleGuestAccess()}
      >
        Acceder como invitado
      </Link>
      <div className="flex  text-sm dark:text-white justify-center gap-x-4 my-2 font-semibold">
        <span className="">¿No tienes una cuenta?</span>
        <Link
          className="inline-block hover:underline text-center text-blue-400 hover:text-blue-600"
          onClick={() => setActiveComponent("register")}
        >
          Regístrate
        </Link>
      </div>
      <div className="h-4 flex flex-col justify-center items-center">
        {successMessage ? (
          <p className="text-sm text-green-500">{successMessage}</p>
        ) : responseMessage ? (
          <p className="text-sm text-red-500">{responseMessage}</p>
        ) : errors.success ? (
          <p className="text-sm text-green-500">{errors.success}</p>
        ) : null}
      </div>
    </div>
  );
};

export default LoginFrame;
