import React, { useState, useEffect } from "react";
import { Button } from "components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";

import { ReactComponent as ClockIcon } from "icons/clock.svg";
import { ReactComponent as CloseIcon } from "icons/close.svg";
import { Icon } from "@iconify/react";
import axios from "axios";
import {
  addMinutes,
  formatISO,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
  isSameDay,
  max,
  min,
  format,
  parse,
} from "date-fns"; // Ensure you import necessary functions
import { toZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { toast } from "sonner";
import { useEventHandlers } from "contexts/EventHandlers";
import { useStateContext } from "contexts/ContextProvider";

const QueueDurationInput = ({
  isAddingDuration,
  setIsAddingDuration,
  preferences,
  handleSelectChange,
  selectedDuration,
  durationOptions,
  calculateArrivalTime,
  selectedDate,
  currentUserEvent,
  onEventExists,
  onSubmit,
  isUpdating,
  // currentUserParcela,
  // currentUser,
}) => {
  //console.log("QueueDurationInput selectedDuration:",selectedDuration);
  //console.log("QueueDurationInput selectedDate:",selectedDate);
  /* console.log("QueueDurationInput durationOptions:",durationOptions);
  console.log("QueueDurationInput preferences:",preferences);
  console.log("QueueDurationInput calculateArrivalTime:",calculateArrivalTime); */
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   console.log("QueueDurationInput currentUserEvent:", currentUserEvent);
  // }, [currentUserEvent]);

  const { events, setEvents, handleUpdateEvent, fetchEventsFromDB } =
    useEventHandlers();
  const [queueEvents, setQueueEvents] = useState([]);
  const timeZone = "Europe/Madrid";
  const { userData } = useStateContext();

  const colorPalette = {
    event: "#0073b7",
    notification: "#ff851b",
    queue: "#00a65a",
  };

  const fetchQueueEvents = async () => {
    try {
      const response = await axios.get(
        "https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/events/getEvents?type=queue"
      );
      //console.log("QueueDurationInput fetchQueueEvents response:",response);
      const responseBody = JSON.parse(response.data.body);
      console.log("QueueDurationInput fetchQueueEvents responseBody:",responseBody);
      setQueueEvents(responseBody);
    } catch (error) {
      console.error("Error fetching queue events:", error);
      toast.error("Error fetching queue events.");
    }
  };

  useEffect(() => {
    fetchQueueEvents();
  }, []);
  //console.log("QueueDurationInput queueEvents:",queueEvents);

  const handleSubmit = async () => {

    setLoading(true);
    const user_id = JSON.parse(sessionStorage.getItem("userId"));
    const userProfile = JSON.parse(localStorage.getItem("userProfile"));
    const selectedStartDate =
      typeof selectedDate === "string"
        ? new Date(selectedDate.replace(" ", "T"))
        : new Date(selectedDate);

    // Set the time explicitly to 06:00:00 in the correct timezone
    //Change from (selectedStartDate, 0), 0),4); to (selectedStartDate, 0), 0),6); to fix the two hours difference with the db
    //In the db start and end times are substracted by 2
    const startDateTime = setHours(setMinutes(setSeconds(selectedStartDate, 0), 0),6); 
    /* console.log("QueueDurationInput selectedStartDate:", selectedStartDate);
    console.log("QueueDurationInput startDateTime:", startDateTime); */

    // Check if user already has an event for the selected date
    /* console.log("QueueDurationInput Checking events for user ID:", user_id);
    console.log("QueueDurationInput Selected Start Date:", format(selectedStartDate, "yyyy-MM-dd HH:mm:ss"));
    );

    //Determine the start time based on existing events
    let lastEventEnd = setHours(setMinutes(selectedStartDate, 0), 6); // Default to 6:00 AM

    if (eventsOnSelectedDate.length > 0) {
      // Get the latest end time from the events on the selected date
      const eventEndDates = eventsOnSelectedDate.map(event => parseISO(event.end));
      lastEventEnd = max(eventEndDates);
    }

    //Ensure the start time is not before 6:00 AM and not after 1:30 PM
    const minStartTime = setHours(setMinutes(selectedStartDate, 0), 6); // 6:00 AM
    const maxStartTime = setHours(setMinutes(selectedStartDate, 30), 13); // 1:30 PM
    const minEndTime = setHours(setMinutes(selectedStartDate, 30), 6); // 6:30 AM
    const maxEndTime = setHours(setMinutes(selectedStartDate, 0), 14); // 2:00 PM
    
    const start = min(max(lastEventEnd, minStartTime), maxStartTime);
    const end = addMinutes(start, preferences.duration);
  
    // Ensure the end time is not before 6:30 AM and not after 2:00 PM
    if (end < minEndTime || end > maxEndTime) {
      toast.error('El tiempo de riego debe ser entre las 6:00 y las 14:00.');
      return;
    } */    

    //Find the last event on the selected date
    //const lastEvent = eventsOnSelectedDate.length > 0 ? max(eventsOnSelectedDate.map(event => parseISO(event.end))) : null;
    const eventsOnSelectedDate = events.filter((event) => {
      const eventDate = typeof event.start === "string" 
        ? new Date(event.start.replace(" ", "T")) 
        : new Date(event.start);
      return isSameDay(eventDate, selectedStartDate) && event.type === "queue";
    });

    //Check if the user has already created an event on the selected date
    const userHasEvent = eventsOnSelectedDate.some(
      (event) => event.user_id === user_id
    );

    if (userHasEvent && !isUpdating) {
      toast.error("Ya has creado un turno de riego para este día. No puedes crear otro.");
      setLoading(false);
      return;
    }

    const lastEvent = eventsOnSelectedDate.length > 0
      ? max(
          eventsOnSelectedDate.map((event) => 
            typeof event.end === "string" 
              ? new Date(event.end.replace(" ", "T")) 
              : new Date(event.end)
            )
          )
      : null;

    /**
     * const lastEvent = eventsOnSelectedDate.length > 0
        ? new Date(
          max(eventsOnSelectedDate.map((event) =>
            typeof event.end === "string" ? new Date(event.end.replace(" ", "T")) : new Date(event.end)
          )).getTime() - 2 * 60 * 60 * 1000
        )
        : null;
     */

    console.log("QueueDurationInput eventsOnSelectedDate: ", eventsOnSelectedDate, "lastEvent: ", lastEvent);

    const start = isUpdating
      ? (typeof currentUserEvent.start === "string" ? new Date(currentUserEvent.start.replace(" ", "T")) : new Date(currentUserEvent.start))
      : (lastEvent ? lastEvent : startDateTime);
    const end = isUpdating
      ? (typeof currentUserEvent.end === "string" ? new Date(currentUserEvent.end.replace(" ", "T")) : new Date(currentUserEvent.end))
      : addMinutes(start, preferences.duration);

    console.log("QueueDurationInput start: ",start);

    const data = {
      eventId: isUpdating ? currentUserEvent.event_id : "",
      userId: user_id, // Using userId from localStorage
      //parcela: currentUserParcela, // Using parcela passed from QueueList
      date: selectedDate, // Using selectedDate passed from QueueList
      duration: preferences.duration, //Using duration from preferences
      //start: isUpdate ? currentUser.start : format(start, "yyyy-MM-dd HH:mm:ss"), //TODO: Modify it for updating
      start: format(start, "yyyy-MM-dd HH:mm:ss"), //TODO: Modify it for updating
      end: format(end, "yyyy-MM-dd HH:mm:ss"),
      type: "queue",
      title: "Turno de Riego",
      assigned:
        userProfile.contactDetails.firstName +
        " " +
        userProfile.contactDetails.lastName,
      comments: "",
      queryType: "queue",
      //action: isUpdate ? 'update' : 'create', //TODO: Modify it for updating
      subsequentEvents: [],
    };
    console.log("QueueDurationInput data:", data);

    // Prepare data for the subsequent events
    /*   let currentStartTime = end;
    for (const event of eventsOnSelectedDate) {
      if (event.event_id !== currentUser.event_id) {
        const newEnd = addMinutes(currentStartTime, event.duration);
        data.subsequentEvents.push({
          eventId: event.event_id,
          start: format(currentStartTime, "yyyy-MM-dd HH:mm:ss"),
          end: format(newEnd, "yyyy-MM-dd HH:mm:ss"),
          duration: event.duration
        });
        currentStartTime = newEnd;
      }
    } */

    try {
      //Original code to create an event of type queue
      /* const response = await axios.post('https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/events/createEvent', 
        data,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ); */
      const endpoint = isUpdating
        ? "https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/events/updateEvent"
        : "https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/events/createEvent";

      const response = isUpdating
        ? await axios.put(endpoint, data, {
            headers: { "Content-Type": "application/json" },
          })
        : await axios.post(endpoint, data, {
            headers: { "Content-Type": "application/json" },
          });

      /* console.log(
        "QueueDurationInput handleAddTask createEvent response: ",
        response
      ); */
      const responseBody = response.data;
      const parsedBody = JSON.parse(responseBody.body);
      //console.log('QueueDurationInput responseBody:', responseBody);

      if (responseBody.statusCode >= 200 && responseBody.statusCode < 300) {
        //console.log("QueueDurationInput Data saved successfully:", parsedBody);
        toast.success(parsedBody.message);

        //Fetch updated events
        const updatedEvents = await fetchEventsFromDB();
        //console.log("QueueDurationInput updatedEvents:", updatedEvents);
        setEvents(updatedEvents);
        /* const mergedEvents = updatedEvents.map(event => {
          const start = toZonedTime(parseISO(event.start)); // Convert event start to local timezone
          let end = toZonedTime(parseISO(event.end)); // Convert event end to local timezone

          if (!event.end && event.duration) {
            end = addMinutes(start, event.duration);
          }

          return {
            ...event,
            title: event.title || userData.find(user => user.id === event.user_id)?.fullname,
            user_id: event.user_id,
            userName: userData.find(user => user.id === event.user_id)?.username || "",
            userRole: userData.find(user => user.id === event.user_id)?.role || "",
            start: start,
            duration: event.duration || null,
            end: end,
            urgency: event.urgency || null,
            assigned: event.assigned || null,
            comments: event.comments || null,
            type: event.type || 'event',
            backgroundColor: colorPalette[event.type] || colorPalette.event,
          };
        });
        setEvents(mergedEvents); */

        onSubmit(); // Handle the submit logic in QueueList
      } else {
        let errorMessage = "Error durante creación del evento";
        switch (responseBody.statusCode) {
          case 400:
            errorMessage = parsedBody.message;
            break;
          case 401:
            errorMessage = parsedBody.message;
            break;
          case 500:
            errorMessage = parsedBody.message;
            break;
          default:
            errorMessage = `Error inesperado: ${response.statusText}`;
        }
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
    setLoading(false);
    setIsAddingDuration(false);
  };
  /* useEffect(() => {
    console.log("QueueDurationInput events: ", events);
  }, [events]); */
  /* useEffect(() => {
    console.log("QueueDurationInput selectedDuration: ", selectedDuration);
  }, [selectedDuration]); */
  // useEffect(() => {
  //   console.log("QueueDurationInput selectedDuration: ", selectedDuration);
  // }, [handleSelectChange]);
  return (
    <div className={`${isAddingDuration ? "scroll-gro w-upwards " : "scroll-grow-upwards" }  grow gap-y-1 text-gray-700 gap-x-1 flex bg-gray-900 dark:bg-gray-100 flex-col border-gray-200 rounded-md px-3 py-2.5`}>
      <div className="flex justify-between items-center flex-row">
        <div className="mb-2 flex gap-x-2 justify-center text-center text-white dark:text-gray-950 pt-2">
          <ClockIcon className="w-6 h-6" />
          Cuanto tiempo vas a regar?
        </div>
        <Button
          size="icon"
          className="shrink-0 size-8"
          variant="secondary"
          onClick={() => setIsAddingDuration(false)}>
          <CloseIcon className="w-5 h-5" />
        </Button>
      </div>
      {/* <div className="text-gray-100 dark:text-gray-800 pb-1 gap-x-1 flex items-center justify-between ">
        <span>Arribo approximado:</span>
        <span>{calculateArrivalTime().time}</span>
      </div> */}

      <Select
        value={selectedDuration}
        onValueChange={handleSelectChange("duration")}
        className="w-full mb-4">
        <SelectTrigger className="bg-white dark:hover:bg-gray-200">
          <SelectValue placeholder="Selecciona una duracion">
            {durationOptions.find((option) => option.value === selectedDuration)
              ?.label || "Seleccione una duración"}
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          {durationOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Button
        size="default"
        className="w-full mt-2"
        variant="secondary"
        onClick={handleSubmit} // Call the submit handler
      >
        {loading ? (
          <Icon icon="svg-spinners:180-ring" className="dark:text-white" />
        ) : (
          "Guardar"
        )}
      </Button>
    </div>
  );
};

export default QueueDurationInput;
