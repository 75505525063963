import React, { useState, useCallback } from "react";
import { ReactComponent as AlertIcon } from "icons/alert-triangle.svg";
import { ReactComponent as TrashIcon } from "icons/trash.svg";
import { NavLink } from "react-router-dom";
import { Button } from "components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { ReactComponent as InboxIcon } from "icons/inbox.svg";
import { formatTimeToRelative } from "helpers/formatTimeToRelative";
import { useStateContext } from "contexts/ContextProvider";
const WindowNotification = ({
  notifications,
  clearNotifications,
  removeNotification,
}) => {
  const [hoveredId, setHoveredId] = useState(null);
  const [hoveredContent, setHoveredContent] = useState(null);
  const { handleSetLayout } = useStateContext();
  const getIconByUrgency = (urgency) => {
    switch (urgency) {
      case "crítico":
        return <AlertIcon className="size-7" />;
      default:
        return <InboxIcon className="size-7" />;
    }
  };

  const goToSelectedNotification = (notification) => {
    console.log("Go to notification", notification);
    // TODO: Implement the logic to go to the selected notification
    handleSetLayout("Events");
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          id="notifications"
          className="relative size-8 md:size-10 p-1 sm:p-1 md:p-2 rounded-full after:content-[''] after:transition-colors after:-inset-1 after:rounded-full after:z-0 after:hover:bg-gray-100 dark:after:hover:bg-gray-800 after:absolute hover:text-green-500 transition-colors items-center justify-center flex"
          variant="primary"
        >
          <InboxIcon className="size-full dark:text-gray-50 z-10" />
          {notifications.length > 0 && (
            <span className="absolute z-10 -top-1 sm:top-1 -right-1 sm:right-1 w-4 h-4 bg-red-500 text-xs text-gray-50 dark:text-gray-900 rounded-full flex items-center justify-center">
              {notifications.length}
            </span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-fit m-2">
        <div className="flex flex-col overflow-y-auto transition-all duration-500">
          <div className="flex w-full flex-col divide-y dark:divide-gray-600 ">
            {notifications.length === 0 && (
              <div className="p-3 md:p-6 justify-center flex text-gray-700 dark:text-gray-200 uppercase text-sm">
                <span className="text-center">
                  No hay nuevas notificaciones
                </span>
              </div>
            )}
            {notifications
              .sort((a, b) => new Date(b.date) - new Date(a.date))
              .slice(0, 4)
              .map((notification) => (
                <div
                  className="flex w-full hover:bg-gray-50 dark:hover:bg-gray-950 transition-colors px-4 py-3 md:px-6 md:py-4 flex-row items-center space-x-2"
                  key={notification.id}
                >
                  <div className="flex justify-center w-10 items-center">
                    {getIconByUrgency(notification.urgency)}
                  </div>
                  <div
                    onMouseEnter={() => setHoveredContent(notification.id)}
                    onMouseLeave={() => setHoveredContent(null)}
                    className="flex relative flex-col w-[calc(100%_-_7.5rem)]"
                  >
                    <h6 className="text-base font-semibold">
                      {notification.message}
                    </h6>
                    <p className="text-sm text-gray-400 dark:text-gray-300 truncate">
                      {notification.status} - {notification.assigned}
                    </p>
                    {hoveredContent === notification.id && (
                      <div
                        className="z-10 top-0 absolute h-full flex items-center justify-end w-40 bg-gradient-to-l from-gray-50 dark:from-gray-950 to-transparent right-0"
                        aria-label={`View notification ${notification.message}`}
                      >
                        <Button
                          className="h-fit  p-1 px-2 uppercase text-xs font-semibold  rounded-md w-fit absolute right-0"
                          onClick={() =>
                            goToSelectedNotification(notification.id)
                          }
                        >
                          ver
                        </Button>
                      </div>
                    )}
                  </div>
                  <div
                    onMouseEnter={() => setHoveredId(notification.id)}
                    onMouseLeave={() => setHoveredId(null)}
                    className="relative flex items-center justify-end w-20 h-10 text-right text-xs text-gray-700 dark:text-gray-400"
                  >
                    <span>{formatTimeToRelative(notification.date)}</span>
                    {/* {hoveredId === notification.id && (
                      <div
                        className="z-10 top-0 absolute h-full flex items-center justify-end w-20 bg-gradient-to-l from-gray-50 dark:from-gray-950 to-transparent right-0"
                        aria-label={`Remove notification ${notification.message}`}
                      >
                        <button
                          onClick={() => removeNotification(notification.id)}
                        >
                          <TrashIcon className="size-5 ml-auto hover:text-red-600 dark:hover:text-red-700" />
                        </button>
                      </div>
                    )} */}
                  </div>
                </div>
              ))}
          </div>
          <div className="flex justify-between p-4 md:p-6 md:py-4">
            {/* <button
              onClick={clearNotifications}
              disabled={notifications.length === 0}
              className="font-bold uppercase text-sm text-red-500 disabled:text-gray-200 dark:disabled:text-gray-700 hover:text-red-700"
            >
              Borrar todas
            </button> */}
            <button 
            
            className="hover:text-green-500 text-gray-500 dark:text-gray-300 font-bold uppercase text-sm"
            onClick={() =>
                        handleSetLayout(
                          'Events')
                      }>

            Ver todas
            </button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default WindowNotification;
