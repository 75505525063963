"use client";

import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";

import { cn } from "lib/utils";
import { Button } from "components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandList,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";

export function Combobox({ sections, onSelectionChange }) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");
  
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between dark:text-white"
        >
          {value
            ? sections.find((section) => section.value === value)?.label
            : "Selecciona un téma"}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0 pointer-events-auto">
        <Command>
          <CommandList>
          <CommandInput  placeholder="Search section..." />
            <CommandEmpty>No section found.</CommandEmpty>
            <CommandGroup>
              {sections.map((section) => (
                <CommandItem
                  key={section.value}
                  value={section.value}
                  onSelect={(currentValue) => {
                    const newValue = currentValue === value ? "" : currentValue;
                    setValue(newValue)
                    setOpen(false)
                    onSelectionChange(newValue);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === section.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {section.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
