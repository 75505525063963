import { ReactComponent as WaitingIcon } from "icons/status-icons/waiting.svg";
import { ReactComponent as FreezeIcon } from "icons/status-icons/snooze.svg";
import { ReactComponent as CheckmarkIcon } from "icons/status-icons/complete.svg";
import { ReactComponent as BatteryIcon } from "icons/status-icons/working.svg";


export const statusIcons = [
  { keywords: ["waiting", "esperando", "en espera", "esperando respuesta", "pendiente", "esperando confirmación"], icon: WaitingIcon },
  { keywords: ["snoozed", "suspendida", "en pausa", "en pausa por tiempo", "pausado", "pausada", "pausado temporalmente"], icon: FreezeIcon },
  { keywords: ["completed", "completada", "finalizado", "finalizada", "finalizada con éxito", "completado con éxito", "ejecutado con éxito", "finalizada satisfactoriamente", "ejecutada satisfactoriamente"], icon: CheckmarkIcon },
  { keywords: ["working", "in progress", "trabajando", "en progreso", "ejecutando", "en ejecución", "ejecutando operación", "ejecutando tarea"], icon: BatteryIcon },
];

