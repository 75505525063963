import * as React from "react";
import * as SliderPrimitive from "@radix-ui/react-slider";

// Utility for applying multiple class names
const cn = (...classes) => classes.filter(Boolean).join(' ');

const DoubleRangeSlider = React.forwardRef(
  ({ min, max, step, defaultValue, onValueChange, limits, className, ...props }, ref) => {
    const [value, setValue] = React.useState(defaultValue.sort((a, b) => a - b));

    // Update internal state when defaultValue changes
    React.useEffect(() => {
      setValue(defaultValue.sort((a, b) => a - b));
    }, [defaultValue]);

    const handleChange = (newValue) => {
      setValue(newValue);
      onValueChange(newValue);
    };

    const leftPercent = ((value[0] - min) / (max - min)) * 100;
    const rightPercent = ((value[1] - min) / (max - min)) * 100;

    const trackStyle = {
      background: `linear-gradient(to right, 
        #4CAF50 0%, #4CAF50 ${leftPercent}%, 
        #FFEB3B ${leftPercent}%, #FFEB3B ${rightPercent}%, 
        #F44336 ${rightPercent}%, #F44336 100%)`
    };

    return (
      <SliderPrimitive.Root
        min={min}
        max={max}
        step={step}
        value={value}
        onValueChange={handleChange}
        ref={ref}
        className={cn("relative flex w-full touch-none select-none items-center", className)}
        {...props}
      >
        <SliderPrimitive.Track style={limits && trackStyle} className="relative h-1.5 w-full grow overflow-hidden rounded-full bg-gray-900/20 dark:bg-gray-50/40">
          <SliderPrimitive.Range className={`${limits && "bg-yellow-500 dark:bg-yellow-500"} absolute h-full bg-gray-900 dark:bg-gray-50`} />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb className="block h-4 w-4 rounded-full border border-gray-200 border-gray-900/50 bg-white shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-800 dark:border-gray-50/50 dark:bg-gray-300 dark:focus-visible:ring-gray-300" />
        <SliderPrimitive.Thumb className="block h-4 w-4 rounded-full border border-gray-200 border-gray-900/50 bg-white shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-800 dark:border-gray-50/50 dark:bg-gray-300 dark:focus-visible:ring-gray-300" />
      </SliderPrimitive.Root>
    );
  }
);

DoubleRangeSlider.displayName = 'DoubleRangeSlider';

export { DoubleRangeSlider };
