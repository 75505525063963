import React from "react";
import { InputWithLabelAndError as Input } from "components/ui/InputWithLabelAndError";

const SignupInputs = ({
  name,
  setName,
  email,
  setEmail,
  confirmEmail,
  setConfirmEmail,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  errors,
}) => {
  return (
    <>
      <Input
        type="text"
        label="Nombre de usuario"
        placeholder="javisanchez"
        value={name}
        onChange={(e) => setName(e.target.value)}
        error={errors.name}
      />
      <div className="hidden sm:block"></div>
      <div className="md:col-span-2 h-px w-full mr-auto bg-gray-300 dark:bg-gray-700" />
      <Input
        type="email"
        label="Correo electrónico"
        placeholder="javisanchez@mail.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={errors.email}
      />
      <Input
        type="email"
        name="confirmEmail"
        label="Confirma tu correo electrónico"
        placeholder="javisanchez@mail.com"
        value={confirmEmail}
        onChange={(e) => setConfirmEmail(e.target.value)}
        error={errors.confirmEmail}
      />
      <div className="relative">
        <Input
          type="password"
          name="password"
          label="Contraseña"
          placeholder="••••••••"
          value={password}
          hasRevealPassword={true}
          onChange={(e) => setPassword(e.target.value)}
          error={errors.password}
        />
      </div>
      <div className="relative">
        <Input
          type="password"
          name="confirmPassword"
          label="Confirme Contraseña"
          placeholder="••••••••"
          hasRevealPassword={true}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          error={errors.confirmPassword}
        />
      </div>
    </>
  );
};

export default SignupInputs;
