import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { cn } from "lib/utils";
import { cva } from "class-variance-authority";
import { ReactComponent as SearchIcon } from "icons/search.svg";
const inputVariants = cva(
  "inline-flex items-center justify-center rounded-md text-base md:text-sm transition-colors focus-visible:outline-none disabled:cursor-not-allowed file:border-0 file:bg-transparent file:text-sm file:font-medium disabled:opacity-50 ",
  {
    variants: {
      variant: {
        default:
          "bg-gray-100 dark:bg-gray-900 dark:focus-visible:bg-gray-800 focus-visible:bg-gray-200 placeholder:text-gray-500 dark:placeholder:text-gray-400 ",
          // "border-gray-200 bg-transparent shadow-sm text-gray-900 shadow-sm hover:bg-gray-100/80 dark:bg-gray-800 dark:text-gray-50 dark:hover:bg-gray-800/80",
          
          outline:
          // "border bg-transparent border-gray-300 hover:border-gray-400 focus:border-gray-600 dark:border-gray-800 dark:hover:border-gray-700",
          "border border-gray-200 bg-white shadow-sm placeholder:text-gray-500  focus-visible:ring-1 focus-visible:ring-gray-950 dark:border-gray-800 dark:placeholder:text-gray-400 dark:focus-visible:ring-gray-300",
          secondary:
          "bg-gray-100 dark:bg-gray-900 dark:focus:bg-gray-800 focus:bg-gray-200 text-gray-900 dark:text-gray-100 ",
      },
      size: {
        default: "h-9 px-3 py-2",
        sm: "h-8 px-3 py-2",
        lg: "h-10 px-4 md:text-base",
      },
      hasFloatingLabel: {
        true: "bg-transparent pt-3 md:pt-4 pb-1.5 md:pb-2.5",
        false: "",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      hasFloatingLabel: "false",
    },
  }
);


const InputWithLabelAndError = React.forwardRef(({ 
  type = "text",
  label,
  name,
  hasInlineLabel = false,
  placeholder = "",
  hasFloatingLabel = false,
  value,
  onChange,
  size = "default",
  variant = "default",
  error,
  className,
  containerClassName,
  hasRevealPassword = false,
  ...props
}, ref) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (onChange) {
      onChange(e); // Propagate the change event up if an onChange handler is provided
    }
  };

  return (
    <div className={containerClassName}>
      <div className={`relative w-full flex ${hasInlineLabel ? 'flex-row items-center gap-x-3' : 'flex-col'}`}>
        {!hasFloatingLabel && label && (
          <label
            htmlFor={name}
            className="text-base  mb-1  font-bold dark:text-gray-100 text-gray-900"
          >
            {label}
          </label>
        )}
        <input
          value={inputValue}
          onChange={handleChange}
          type={hasRevealPassword ? (isPasswordVisible ? 'text' : 'password') : type}
          id={name}
          name={name}
          ref={ref}
          className={cn(inputVariants({ variant, size, className, hasFloatingLabel }),
          type === "search" && "pl-8"
        )}
          placeholder={hasFloatingLabel ? " " : placeholder}
          {...props}
        />
        {hasFloatingLabel && label && (
          <label
            htmlFor={name}
            className="absolute text-sm text-gray-500 duration-300 translate-x-px transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-gray-50 dark:bg-gray-950 px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4"
          >
            {label}
          </label>
        )}
        {type === "search" && (
           <SearchIcon className="pointer-events-none absolute size-5 left-2 top-2 text-gray-300 dark:text-gray-600" />
          )  
        }
        {hasRevealPassword && (
          <button
            type="button"
            className={cn("absolute size-4 right-2",
               size === "default" && "bottom-2.5",
               size === "sm" && "bottom-2",
               size === "lg" && "bottom-3",
              )}
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          >
            <Icon icon={isPasswordVisible ? 'bi:eye-slash-fill' : 'bi:eye-fill'} 
              className="text-gray-400 dark:text-gray-400 dark:hover:text-gray-300 hover:text-gray-500 size-full" />
          </button>
        )}
      </div>
      {error && (
        <p className="mt-2 text-xs text-red-600">
          {error}
        </p>
      )}
    </div>
  );
});
InputWithLabelAndError.displayName = "InputWithLabelAndError";

export { InputWithLabelAndError, inputVariants };
