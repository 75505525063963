import React from "react";
import { Button } from "components/ui/button";
import {
  Command,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandSeparator
} from "components/ui/command";
import { Label } from "components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { Icon } from "@iconify/react";


const LegendButton = ({ options, selected, onChange }) => {
  const [open, setOpen] = React.useState(false);

  const handleSelect = (option) => {
    const isSelected = selected.includes(option.value);
    const newSelected = isSelected
      ? selected.filter((item) => item !== option.value)
      : [...selected, option.value];
    onChange(newSelected);
  };

  // const handleUnselect = (value) => {
  //   onChange(selected.filter((item) => item !== value));
  // };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className="w-fit grow-0 group/legendButton justify-between pl-2 pr-1 h-9"
          onClick={() => setOpen(!open)}
        >
          <div className="flex  -space-x-1.5">
            {selected.map((value) => {
              const option = options.find((opt) => opt.value === value);
              return (
                <div key={value} className="flex  items-center">
                  <div
                    className="size-4 rounded-full duration-75 transition-colors group-hover/legendButton:border-gray-100 dark:group-hover/legendButton:border-gray-800 border-2 border-white dark:border-gray-950"
                    style={{ backgroundColor: option.color }}
                  />

                </div>
              );
            })}
          </div>
          <CaretSortIcon className="h-4 w-4 ml-1 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-fit">
        
        <Command>
          <CommandList>
            <CommandEmpty>No item found.</CommandEmpty>
              <Label className="block px-2 py-2">

              Leyenda
              </Label>
            <CommandSeparator />
            <CommandGroup className="max-h-64 overflow-auto">
              {options.map((option) => (
                <CommandItem key={option.value} onSelect={() => handleSelect(option)}>
                  <Icon
                    icon="material-symbols:check"
                    className={`mr-2 h-4 w-4 ${selected.includes(option.value) ? "opacity-100" : "opacity-0"}`}
                  />
                  <div
                    className="w-3 h-3 rounded-full mr-2"
                    style={{ backgroundColor: option.color }}
                  />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default LegendButton;
