import React, { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { useNavigate, Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useAuth } from "contexts/AuthContext";
import { useBrand } from 'contexts/BrandContext';


const VerifyEmailMsg = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { setActiveComponent } = useStateContext();
  const { handleLogout } = useAuth(); //Get handleLogout from AuthContext
  const { brand } = useBrand();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      setActiveComponent("login");
    }, 12000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex w-full flex-col justify-center  gap-y-4 items-center">
      <div className="flex flex-row gap-y-3 items-center">
      <img
          src={brand.logo}
          alt={brand.brandName}
          className="pointer-events-none object-contain h-12"
        />
      </div>
      <div className="max-w-md w-full flex flex-col gap-y-4 md:gap-y-8">
        <div>
          <h2 className=" font-medium leading-7 dark:text-white">
            Activa tu cuenta
          </h2>
          <span className="text-sm dark:text-gray-200">
            Te hemos enviado un email con un enlace para activar tu cuenta.{" "}
            <br />A continuación se te redireccionará a la pantalla de login...{" "}
          </span>
        </div>

        <div className="h-4 flex justify-center">
          {loading && (
            <Icon
              icon="svg-spinners:180-ring"
              className="text-black dark:text-white"
            />
          )}
        </div>
        <div className="flex flex-col font-semibold sm:flex-row justify-between items-center gap-2">
          <span className="text-sm text-center dark:text-white ">
            ¿No te redirecciona a la pantalla de login?
            <Link
              className="inline-block text-center ml-1 text-[#1D91E0] hover:text-blue-600"
              to="/login"
              onClick={() => setActiveComponent("login")}
            >
              Login
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailMsg;
