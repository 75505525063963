import React, { useEffect, useMemo, useState, useRef } from "react";
import { Button } from "components/ui/button";
import { ButtonGroup } from "@mui/material";
import {
  addMinutes,
  formatISO,
  format,
  parseISO,
  isSameDay,
  addDays,
  startOfDay,
  endOfDay,
  isWithinInterval,
  subWeeks,
} from "date-fns";
import { ReactComponent as ClockIcon } from "icons/clock.svg";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useEventHandlers } from "contexts/EventHandlers";
import QueueList from "components/widgets/queue/QueueList";
import { ScrollArea } from "components/ui/scroll-area";
import MobileWateringManager from "./MobilerWateringManagerUpdated";
import { Icon } from "@iconify/react";
import { toDate, formatInTimeZone } from 'date-fns-tz';

const timeZone = 'Europe/Madrid';
const durationOptions = [
  { value: 0, label: "No quiero regar" },
  { value: 30, label: "30 minutos" },
  { value: 60, label: "60 minutos" },
  { value: 90, label: "90 minutos" },
  { value: 120, label: "2 horas" },
  { value: 150, label: "2:30 horas" },
  { value: 180, label: "3 horas" },
];

const getWeekDates = (start, events, user_id) => {
  const visibleDates = [];

  for (let i = 0; i < 7; i++) {
    const date = startOfDay(addDays(start, i));

    const formattedDate = formatISO(date, { representation: "date" });

    //Original code
    const queueForDate = events.filter((event) => {
      const eventStart =
        typeof event.start === "string"
          ? parseISO(event.start)
          : new Date(event.start);
      return event.type === "queue" && isSameDay(eventStart, date);
    });
    //TEST Modification to try to show the date correct
    /* const queueForDate = events.filter((event) => {
      const eventStart =
        typeof event.start === "string"
          ? toDate(event.start, { timeZone })
          : new Date(event.start);
      return event.type === "queue" && isSameDay(eventStart, date);
    }); */
    //Origin of the change of time after the event has been modified?
    /* const queueForDate = events.filter((event) => {
      const eventStart = typeof event.start === 'string' ? new Date(event.start) : new Date(event.start);
      console.log("QueueWidget eventStart:",eventStart );

      const eventStart = typeof event.start === 'string' ? new Date(event.start) : event.start;
      return event.type === "queue" && isSameDay(eventStart, date);
    }); */
    // console.log("QueueWidget queueForDate:",queueForDate );

  
    const isUserInQueue = queueForDate.some(
      (event) => event.user_id === user_id // currentUserParcela
    );

    visibleDates.push({
      date: date,
      hasQueue: queueForDate.length > 0,
      isUserInQueue,
    });
    // console.log("QueueWidget visibleDates:",visibleDates );
  }

  return visibleDates;
};

const DateButton = ({ day, selectedDate, setSelectedDate }) => {
  const today = new Date();
  const isToday = day.date.toDateString() === today.toDateString();

  const dateButton = (
    <Button
      id={today.toDateString() === day.date.toDateString() ? "today" : ""}
      className={`w-full capitalize border 
        ${isToday ? "bg-gray-700 hover:bg-gray-800 text-white" : ""}
        ${selectedDate === day.date ? "bg-gray-500" : ""}
        ${day.hasQueue ? "border-green-300 hover:bg-green-300 dark:border-green-700 dark:hover:bg-green-700" : ""}
        ${day.isUserInQueue ? "bg-green-500 hover:bg-green-400 dark:bg-green-500" : ""}
      `}
      variant={
        isToday
          ? "secondary"
          : selectedDate === day.date
          ? "default"
          : "outline"
      }
      key={day.date}
      onClick={() => setSelectedDate(day.date)}>
      {new Intl.DateTimeFormat("es-ES", {
        weekday: "long",
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      })
        .format(new Date(day.date))
        .replace(/\//g, ".")}
    </Button>
  );
  
  if (selectedDate) {
    if (isToday) {
      return (
        <div className="w-full flex flex-row gap-x-2" key={day.date}>
          <Button
            className="p-2"
            variant="outline"
            onClick={() => setSelectedDate(null)}>
            <ArrowLeftIcon className="w-5 h-5" />
          </Button>
          <div className="w-full bg-gray-50 dark:bg-gray-800 capitalize font-semibold border border-gray-100 dark:border-gray-700 rounded-md px-2.5 flex items-center ">
            {new Intl.DateTimeFormat("es-ES", {
              weekday: "long",
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
            })
              .format(new Date(selectedDate))
              .replace(/\//g, ".")}
          </div>
        </div>
      );
    }
    return null;
  }
  return dateButton;
};

const QueueWidget = () => {
  const { events, handleUpdateEvent } = useEventHandlers();
  const [preferences, setPreferences] = useState({ duration: "" });
  const [selectedDate, setSelectedDate] = useState(null);
  const [visibleDates, setVisibleDates] = useState([]);
  const [previousDates, setPreviousDates] = useState([]);
  const [usersInOrder, setUsersInOrder] = useState([]);
  const [isAddingDuration, setIsAddingDuration] = useState(false);
  const [currentUserParcela, setCurrentUserParcela] = useState(1026);
  const scrollContainerRef = useRef(null);
  const prevScrollTopRef = useRef(0); // To keep track of the previous scroll position
  const [loadingMoreDates, setLoadingMoreDates] = useState(false);
 
  // useEffect(() => {
  //   console.log("QueueWidget events: ", events);
  // }, []);
  const user_id = JSON.parse(sessionStorage.getItem("userId"));

  // const handleScrollToNextDate = () => {
  //   if (scrollContainerRef && visibleDates.length > 0) {
  //     const nextDate = visibleDates.find((day) => day.isUserInQueue);
  //     if (nextDate) {
  //       setSelectedDate(nextDate.date);
  //       scrollContainerRef.current.scrollTo({
  //         top: 0,
  //         left:
  //           (scrollContainerRef.current.scrollWidth *
  //             visibleDates.indexOf(nextDate)) /
  //           visibleDates.length,
  //         behavior: "smooth",
  //       });
  //     }
  //   }
  // };

  useMemo(() => {
    const currentUser = JSON.parse(localStorage.getItem("userProfile"));
    if (currentUser !== null && currentUser.parcela !== null) {
      setCurrentUserParcela(currentUser.parcela);
    }
  }, []);

  //Original code 
  /* useEffect(() => {
    const today = new Date();
    const visibleDatesArray = getWeekDates(today, events, currentUserParcela);
    setVisibleDates(visibleDatesArray);

    const nextWeek = addDays(today, 7);
    const filteredData = events
      .filter((item) => item.type === "queue")
      .filter((item) => {
        const itemStart =
          typeof item.start === "string"
            ? parseISO(item.start)
            : new Date(item.start);
        return isWithinInterval(itemStart, {
          start: startOfDay(today),
          end: endOfDay(nextWeek),
        });
      })
      .map((item) => {
        const itemStart =
          typeof item.start === "string"
            ? parseISO(item.start)
            : new Date(item.start);
        return {
          date: formatISO(itemStart, { representation: "date" }),
          time: format(itemStart, "HH:mm"),
          ...item,
        };
      });
    setUsersInOrder(filteredData);
  }, [events, currentUserParcela]); */
  //console.log("QueueWidget events:",events );
  //Origin of the change of time after the event has been modified?
  useEffect(() => {
    const today = new Date();
    const visibleDatesArray = getWeekDates(today, events, user_id);
    setVisibleDates(visibleDatesArray);
  
    // Combine previousDates and visibleDates
    const combinedDates = [...previousDates, ...visibleDatesArray];
  
    // Determine the date range based on combined dates
    const earliestDate = combinedDates.length ? combinedDates[0].date : startOfDay(today);
    const latestDate = combinedDates.length ? combinedDates[combinedDates.length - 1].date : endOfDay(addDays(today, 7));
  
    // Filter events that fall within the combined date range
    const filteredData = events
      .filter((item) => item.type === "queue")
      .filter((item) => {
        const itemStart = new Date(item.start);
        return isWithinInterval(itemStart, {
          start: startOfDay(earliestDate),
          end: endOfDay(latestDate),
        });
      })
      .map((item) => {
        const itemStart = new Date(item.start);
        return {
          date: format(itemStart, "yyyy-MM-dd"),
          time: format(itemStart, "HH:mm"),
          ...item,
          start: itemStart,
        };
      })
      .sort((a, b) => a.start - b.start); // Sort by start date
  
    // console.log("QueueWidget filteredData:", filteredData);
    setUsersInOrder(filteredData);
  }, [events, currentUserParcela, selectedDate, previousDates, visibleDates]);
  


  const loadPreviousDates = () => {
    setLoadingMoreDates(true);
    const earliestDate = previousDates.length
      ? previousDates[0].date
      : visibleDates[0].date;
    const newPreviousDates = getWeekDates(
      subWeeks(earliestDate, 1),
      events,
      user_id // currentUserParcela
    );
    setTimeout(() => {
      setPreviousDates((prevDates) => [...newPreviousDates, ...prevDates]);
      setLoadingMoreDates(false);
    }, 300);
  };

  //If duration changes....
  const handleSelectChange = (name) => (value) => {
    console.log("running handleSelectChange");
    console.log("name", name);
    console.log("value", value)
    if (name === "duration") {
      console.log("name inside", name);
      setPreferences((prev) => ({
        ...prev,
        duration: value,
      }));
      console.log(preferences)
      updateQueueTimes(parseInt(value));
    }
  };

  //Update times for all the users in the queue
  const updateQueueTimes = (newDuration) => {
    const newQueue = usersInOrder.map((user, idx) => {
      if (user.parcela === currentUserParcela) {
        const previousTime =
          idx === 0
            ? parseISO(user.start)
            : parseISO(usersInOrder[idx - 1].start);
        const newTime = addMinutes(
          previousTime,
          idx === 0 ? newDuration : usersInOrder[idx - 1].duration
        );
        const updatedUser = {
          ...user,
          start: formatISO(newTime),
          time: format(newTime, "HH:mm"),
          duration: newDuration,
        };
        handleUpdateEvent(updatedUser); // Update the event in the context
        return updatedUser;
      }
      return user;
    });
    setUsersInOrder(newQueue);
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleScroll = () => {
      if (scrollContainer) {
        const currentScrollTop = scrollContainer.scrollTop;
        // Detect scroll direction
        const isScrollingDown = currentScrollTop > prevScrollTopRef.current;

        // Log scroll direction
        // console.log("Current Scroll Top:", currentScrollTop);
        // console.log("Scrolling Down:", isScrollingDown);

        // Check if scrolled to the top
        if (currentScrollTop === 0) {
          loadPreviousDates();
        }

        // Update previous scroll position
        prevScrollTopRef.current = currentScrollTop;
      }
    };

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [visibleDates, previousDates]); // Depend on `visibleDates` or other state variables if needed

  const calculateArrivalTime = () => {
    if (!usersInOrder.length) {
      return { date: "", time: "" };
    }
    const targetUser = usersInOrder.find(user => user.user_id === user_id);
    
    if (!targetUser) {
      return { date: "", time: "" };
    }
    // console.log("QueueWidget usersInOrder:", usersInOrder);
    // console.log("QueueWidget targetUser:", targetUser);

    let arrivalTime;

    try {
      arrivalTime = targetUser.start;
      // console.log("QueueWidget arrivalTime:", arrivalTime);
    } catch (error) {
      console.error("Invalid start date in usersInOrder:", targetUser.start);
      return { date: "", time: "" };
    }
  
    if (isNaN(arrivalTime.getTime())) {
      console.error("Invalid start date in usersInOrder:", targetUser.start);
      return { date: "", time: "" };
    }
  
    // Calculate the arrival time based on other users' duration
    // usersInOrder.forEach((user) => {
    //   if (user.user_id !== currentUserParcela) {
    //     const userDuration = user.duration || 0;
    //     if (isNaN(userDuration)) {
    //       console.error("Invalid duration for user:", user);
    //     } else {
    //       arrivalTime = addMinutes(arrivalTime, userDuration);
    //     }
    //   }
    // });
  
    return {
      date: format(arrivalTime, "dd.MM.yy"),
      time: format(arrivalTime, "HH:mm"),
    };
  };

  return (
    <div className="w-full relative flex flex-col gap-y-2 p-2 h-full ">
      <h4 className="text-center flex items-center gap-x-2 mb-2">
        <ClockIcon className="w-6 h-6 mr-3" />
        Horas de riego
      </h4>
      <div className="border flex flex-col md:flex-row items-start md:items-center bg-gray-800 dark:border-gray-500 rounded-md p-2 text-white">
        <div className="flex flex-col grow">
          <span>Tu proximo turno:</span>
          <div className="text-2xl mt-auto w-full">
            <span className="text-sm"> Approx. </span>
            <span className="font.bold">{calculateArrivalTime().date}</span>
            <span className="font-bold ml-2">
              {calculateArrivalTime().time}
            </span>
          </div>
        </div>
        {/* <Button
          className="h-full w-full md:w-fit"
          onClick={handleScrollToNextDate}
          variant="secondary">
          Ir a fecha
        </Button> */}
      </div>

      {/* <div  className="overflow-x-auto h-full flex w-full"> */}
      <ScrollArea
        ref={scrollContainerRef}
        className="h-full flex-grow"
        orientation="vertical">
        <ButtonGroup className="w-full flex flex-col gap-y-2">
          {!selectedDate && (
            <Button onClick={loadPreviousDates} variant="ghost">
            
            {loadingMoreDates ? 
            <Icon icon="svg-spinners:180-ring" className="size-4" />
            : 
            previousDates.length === 0
              ? "Cargar fechas anteriores"
              : "Cargar más..."}
          </Button>
          )}
          {previousDates.map((day) => (
            <DateButton
              currentUserParcela={currentUserParcela}
              key={day.date}
              day={day}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          ))}
          {visibleDates.map((day) => (
            <DateButton
              currentUserParcela={currentUserParcela}
              key={day.date}
              day={day}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          ))}
        </ButtonGroup>
        {/* </div> */}

        {selectedDate && (
          <QueueList
            user_id={user_id}
            selectedDate={selectedDate}
            usersInOrder={usersInOrder}
            currentUserParcela={currentUserParcela}
            isAddingDuration={isAddingDuration}
            setIsAddingDuration={setIsAddingDuration}
            preferences={preferences}
            setPreferences={setPreferences}
            handleSelectChange={handleSelectChange}
            durationOptions={durationOptions}
            calculateArrivalTime={calculateArrivalTime}
          />
        )}
      </ScrollArea>
        <div className="absolute -bottom-1 pointer-events-none hover:opacity-0 transition-opacity  -inset-x-1 w-[calc(100%_+_0.5rem)] bg-gradient-to-t from-white dark:from-gray-950 h-20 ">

        </div>
      <MobileWateringManager 
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      isAddingDuration={isAddingDuration}
      setIsAddingDuration={setIsAddingDuration}
      user_id={user_id}
      />
    </div>
  );
};

export default QueueWidget;