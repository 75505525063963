import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useStateContext } from "../../contexts/ContextProvider";
import { useBrand } from 'contexts/BrandContext';

const VerifyEmail = () => {
  const [message, setMessage] = useState("Verificando tu email...");
  const [messageRed, setMessageRed] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { setActiveComponent } = useStateContext();
  const { brand } = useBrand();

  const token = new URLSearchParams(location.search).get("verToken");

  useEffect(() => {
    const verifyEmail = async () => {
      setLoading(true);
      try {
        console.log("VerifyEmail: Inside");

        const response = await fetch(
          `https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/auth/verifyEmail?token=${token}`,
          {
            method: "GET",
          }
        );
        const data = await response.json();
        console.log("VerifyEmail data: ", data);

        if (data.statusCode >= 200 && data.statusCode < 300) {
          console.log("VerifyEmail response is ok");
          setMessage("Cuenta activada con éxito.");
          setMessageRed(
            "A continuación se te redireccionará a la pantalla de login...  "
          );
          setTimeout(() => {
            setLoading(false);

            window.location.href = "/";
          }, 12000);
        } else {
          const parsedBody = JSON.parse(data.body);
          let errorMessage = "Error durante registro";
          switch (data.statusCode) {
            case 400:
              errorMessage = parsedBody.message;
              break;
            case 500:
              errorMessage = parsedBody.message;
              break;
            default:
              errorMessage = `Error inesperado: ${response.statusText}`;
          }

          setErrors(errorMessage);
          //
          /*setErrors(
            data.message ||
              "La verificación ha fallado. Por favor, intenta de nuevo."
          );*/
        }
      } catch (error) {
        setMessage(
          "Ocurrió un error durante la verificación. Por favor, intenta de nuevo."
        );
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      console.log("VerifyEmail token exists");
      verifyEmail();
    } else {
      setMessage("No se encontró el token de verificación.");
    }
  }, [token]); //navigate

  return (
    <div className="flex w-full flex-col justify-center  gap-y-4 items-center">
      <div className="flex flex-row gap-y-3 items-center">
      <img
          src={brand.logo}
          alt={brand.brandName}
          className="pointer-events-none object-contain h-12"
        />
      </div>
      <div className="max-w-md w-full flex flex-col gap-y-4 md:gap-y-8">
        <div>
          <h2 className=" font-medium leading-7 dark:text-white">
            Verificando tu cuenta...
          </h2>
        </div>
        <div className="flex flex-col gap-y-4">
          {message && <p className="text-dark-blue-950 w-fit">{message}</p>}
          {messageRed && (
            <p className="text-dark-blue-950 w-fit">{messageRed}</p>
          )}
          {errors.success && (
            <p className="text-sm text-green-500">{errors.success}</p>
          )}
          <div className="h-4 flex justify-center">
            {loading && (
              <Icon
                icon="svg-spinners:180-ring"
                className="text-dark-blue-700"
              />
            )}
          </div>
          <div className="flex flex-col font-semibold sm:flex-row justify-between items-center gap-2">
            <span className="text-sm text-center dark:text-white">
              ¿No te redirecciona a la pantalla de inicio?
              <Link
                className="inline-block text-center ml-1 text-[#1D91E0] hover:text-blue-600"
                to="/login"
                onClick={() => (window.location.href = "/")}
              >
                {" "}
                {/**setActiveComponent('login') */}
                Inicio de sesión
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
