import React, { useState, useEffect, useMemo } from "react";
import { ResponsiveDataTable } from "components/widgets/widgetTypes/tables/ResponsiveDataTable";
import { useStateContext } from "contexts/ContextProvider";
import { useEventHandlers } from "contexts/EventHandlers";
import { ToggleGroup, ToggleGroupItem } from "components/ui/toggle-group";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Button } from "components/ui/button";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { InputWithLabelAndError as Input } from "components/ui/InputWithLabelAndError";
import CustomPopover from 'components/widgets/widgetTypes/calendar/CustomPopover';
import { eventOptions } from 'data/translationArrays';

const EventsTable = () => {
  const {
    selectedEvent,
    setSelectedEvent,
    selectedEventId,
    setSelectedEventId,

  } = useStateContext();
  const { 
    events,
    fetchEventsFromDB,
    isPopoverOpen,
    setIsPopoverOpen,
    currentEvent,
    setCurrentEvent,
    setIsEditingEvent,
    handleUpdateEvent,
    handleEventClick
   } = useEventHandlers();
  const [selectedToggleOptions, setSelectedToggleOptions] = useState(
    eventOptions.map((option) => option.label)
  ); // Track selected options
  const [searchTerm, setSearchTerm] = useState("");

  const [fetchedData, setFetchedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  // const [selectedProfileId, setSelectedEventId] = useState(null);

  useEffect(() => {
    console.log("EventsTable: selectedEventId: ", selectedEventId);
  }, [selectedEventId]);

  useMemo(() => {
    if (selectedEventId) {
      const event = events.find((event) => event.event_id === parseInt(selectedEventId));
      console.log("EventsTable: event: ", event);
      console.log("events: ", events);
      // handleEventClick();
      if (event) {
        setCurrentEvent(event);
      }
    }
  }, [selectedEventId, events]);

  const handleClosePopover = () => {
    setIsPopoverOpen(false);
    setCurrentEvent(null);
  };

  const handleSave = (updatedTask) => {
    console.log('updatedTask:', updatedTask);
    handleUpdateEvent(updatedTask);
    
    handleClosePopover();
  };

  const columnsConfig = useMemo(
    () => [
      {
        id: "id",
        type: "sortable",
        accessorKey: "id",
        header: "Id",
      },
      {
        id: "message",
        type: "sortable",
        accessorKey: "message",
        header: "Mensaje",
        format: "indicator",
      },
      {
        id: "urgency",
        type: "sortable",
        accessorKey: "urgency",
        header: "Urgencia",
        format: "urgency",
      },
      {
        id: "type",
        type: "sortable",
        accessorKey: "type",
        header: "Típo",
        format: "type",
      },
      // {
      //   id: "location",
      //   type: "sortable",
      //   accessorKey: "location",
      //   header: "Ubicación",
      // },
      {
        id: "assigned",
        type: "sortable",
        accessorKey: "assigned",
        header: "Asignado",
      },
      {
        id: "start",
        type: "sortable",
        accessorKey: "start",
        header: "Inicio",
        format: "time",
      },
      {
        id: "end",
        type: "sortable",
        accessorKey: "end",
        header: "Fin",
        format: "time",
      },
      {
        id: "actions",
        type: "actionsEvents",
        header: "Acciones",
        accessorKey: "actions",
        pin: "right",

      },
    ],
    []
  );

  const handleRowSelectionChange = (selectedRowId) => {
    // console.log("handleRowSelectionChange")

    console.log("selectedRowId in handleRowSelectionChange", selectedRowId);
    if (!selectedRowId) return;
    setIsPopoverOpen(true);
    
    // setSelectedEvent(selectedUser);
    setSelectedEventId(selectedRowId);
  };

  const editEvent = async (selectedId) => {
    if (!selectedId) return;
    setSelectedEventId(selectedId);
    setIsPopoverOpen(true);
    
  };

  const transformData = (events) => {
    return events.map((event) => ({
      id: event.event_id,
      message: event.title,
      location: event.parcela || event.location, // Check both possible property names
      start: event.start,
      end: event.end,
      urgency: event.urgency ? parseInt(event.urgency, 10) : null,
      assigned: event.assigned,
      type:
        eventOptions.find((option) => option.value === event.type)?.label ||
        event.type,
    }));
  };

  const filterData = (data, selectedOptions, searchTerm) => {
    return data.filter((event) => {
      const matchesType = selectedOptions.includes(event.type);
      const matchesSearch = searchTerm
        ? Object.values(event).some((value) =>
            String(value).toLowerCase().includes(searchTerm.toLowerCase())
          )
        : true;
      return matchesType && matchesSearch;
    });
  };

  useEffect(() => {
    const fetchAndSetData = async () => {
      const eventsFromDB = await fetchEventsFromDB();
      /* console.log("Fetched Events from DB:", eventsFromDB); */
      console.log("EventsTable: fetchAndSetData: ", eventsFromDB);
      const transformedData = transformData(eventsFromDB);
      /* console.log("Transformed Data:", transformedData); */
      console.log("EventsTable: transformData: ", transformedData);

      setFetchedData(transformedData);
      setFilteredData(
        filterData(transformedData, selectedToggleOptions, searchTerm)
      );
    };

    fetchAndSetData();
  }, []);

  useEffect(() => {
    const newFilteredData = filterData(
      fetchedData,
      selectedToggleOptions,
      searchTerm
    );
    setFilteredData(newFilteredData);
  }, [selectedToggleOptions, fetchedData, searchTerm]);

  // const handleRowSelectionChange = (selectedRowId) => {
  //   const currentEvent = filteredData.find(
  //     (event) => event.id === selectedRowId
  //   );
  //   setSelectedEvent(currentEvent);
  // };

  useEffect(() => {
    const currentEvent = filteredData.find(
      (event) => event.id === selectedEventId
    );
    setSelectedEvent(currentEvent);
  }, [selectedEventId, filteredData]);

  const handleToggleChange = (selectedValues) => {
    setSelectedToggleOptions((prevOptions) => {
      if (prevOptions.includes(selectedValues)) {
        return prevOptions.filter((option) => option !== selectedValues);
      } else {
        return [...prevOptions, selectedValues];
      }
    });
  };

  useEffect(() => {
    console.log("selectedToggleOptions: ", selectedToggleOptions);
  }
  , [selectedToggleOptions]);

  return (
    <div className="@container/events w-full h-full flex flex-col gap-2">
      <div className="flex-row items-center gap-y-2 gap-x-4 flex justify-between px-px pt-px w-full">
        <Input
          variant="outline"
          type="search"
          placeholder="Filtrar por Mensaje, tipo, asignado, etc..."
          onChange={(e) => setSearchTerm(e.target.value)}
          // containerClassName="w-full @2xl/events:shrink @2xl/events:max-w-md"
          containerClassName="w-full max-w-sm focus-within:absolute @xl/events:focus-within:relative focus-within:top-px @xl/events:focus-within:top-0 focus-within:left-px @xl/events:focus-within:left-0 focus-within:z-10 focus-within:w-[calc(100%_-_2px)] focus-within:max-w-full @xl/events:focus-within:max-w-sm"
          className="w-8 focus:w-full px-1 @xl/events:pr-2 @xl/events:w-full"

        />
            <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="ml-auto dark:text-white">
          Típos de eventos <ChevronDownIcon className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {eventOptions.map((option) => (
          <DropdownMenuCheckboxItem
            key={option.label}
            className="capitalize"
            checked={selectedToggleOptions.includes(option.label)}
            onCheckedChange={() => handleToggleChange(option.label)}
          >
            {option.label}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
      </div>
      <div id="eventsfull" className="h-[calc(100%_-_3rem)] relative dashboard-widget p-1 md:p-2">
        <ResponsiveDataTable
          data={filteredData}
          columnsConfig={columnsConfig}
          setSelectedRow={setSelectedEventId}
          editEvent={editEvent}
          // deleteEvent={deleteEvent}
          showHeaders={false}
          enablePagination={false}
          showLoadingAnimation={selectedToggleOptions.length > 0 ? true : false}
          isEmptyMessage={
            selectedToggleOptions.length > 0
            ? "Cargando eventos..."
            : "De-Seleccione un filtro"
          }
          // onRowSelectionChange={handleRowSelectionChange} // Pass the row selection handler
          // enableRowSelection={true} // Enable row selection
          // singleRowSelection={true}
        />
        <div className="absolute top-4 right-1/2 -translate-x-1/2">
        <CustomPopover
          eventOptions={eventOptions}
          isOpen={isPopoverOpen}
          onClose={handleClosePopover}
          event={currentEvent}
          setIsEditingEvent={setIsEditingEvent}
          containerId="eventsfull"
          onSave={handleSave}
        />
      </div>
      </div>
    </div>
  );
};

export default EventsTable;
