export const formatTimeToRelative = (time) => {
  const currentTime = new Date();
  const notificationTime = new Date(time);

  if (
    notificationTime.getDate() === currentTime.getDate() &&
    notificationTime.getMonth() === currentTime.getMonth() &&
    notificationTime.getFullYear() === currentTime.getFullYear()
  ) {
    // Today
    return notificationTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  } else if (
    notificationTime.getDate() === currentTime.getDate() - 1 &&
    notificationTime.getMonth() === currentTime.getMonth() &&
    notificationTime.getFullYear() === currentTime.getFullYear()
  ) {
    return "Ayer";
  } else if (
    notificationTime.getMonth() === currentTime.getMonth() - 1 &&
    notificationTime.getFullYear() === currentTime.getFullYear()
  ) {
    // X months ago
    const diffMonths = currentTime.getMonth() - notificationTime.getMonth();
    if (diffMonths === 1) {
      return "1 mes"
    } else {

      return `${diffMonths} meses`;
    }
  } else {
    // X days ago
    const diffTime = Math.abs(currentTime - notificationTime);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return `${diffDays} días`;
  }
};