import React from "react";
import { Label} from "components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";
import { object } from "zod";

const DataSelectorSelect = ({ availableOptions, selectedOption, onValueChange, label, exists }) => {
  
  // console.log("availableOptions", label, availableOptions);
  // console.log("display", label, exists);
  // console.log("type of availableOptions", typeof availableOptions);
  // console.log("selectedOption", label, selectedOption);

  if (Array.isArray(selectedOption) && selectedOption.length <= 1) {
    selectedOption = selectedOption[0];
    // console.log("selectedOption transformed", label, selectedOption);
  }

  // if (typeof availableOptions === "object") {
  //   availableOptions = Object.keys(availableOptions);
  // }

  if (!(availableOptions.length > 0 && (exists || exists === "") )) {
    // console.log("No options available for", label);
    // console.log("exists", exists);
    return null;
  }

  return (
    <>
      <Label className="pt-[8px]">{label}</Label>
      <Select
        value={selectedOption}
        onValueChange={onValueChange}>
        <SelectTrigger className="h-8 capitalize">
          <SelectValue  placeholder={`Selecciona una ${label.slice(0, -1)}`}>
            {typeof selectedOption === 'string' ? (availableOptions && availableOptions.find(param => param.value === selectedOption))?.label : selectedOption?.label}
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
        {availableOptions.map((option) => {
            const value = option.value || option;
            const display = option.label || option;
            return (
              <SelectItem className="capitalize" key={value} value={value}>
                {display}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
    </>
  );
};

export default DataSelectorSelect;
