import React, { useState } from "react";
import { Label } from "components/ui/label";
import { Switch } from "components/ui/switch";
import { useStateContext } from "contexts/ContextProvider";
import { Slider } from "components/ui/slider";
const NotificationsSettings = () => {
  const { userProfile, setUserProfile } = useStateContext();
  const storedUserProfile = JSON.parse(localStorage.getItem('userProfile'));

  const [preferences, setPreferences] = useState({
    enableNotifications: userProfile.userSettings.enableNotifications,
    notificationChannels: {
      emailNotifications: userProfile.userSettings.notificationChannels.email || false,
      pushNotifications: userProfile.userSettings.notificationChannels.push || false,
      whatsappNotifications:
        userProfile.userSettings.notificationChannels.whatsapp || false,
    },
    notificationTypes: {
      wateringNotifications: userProfile.userSettings.notificationTypes.water,
      maintenanceNotifications:
        userProfile.userSettings.notificationTypes.maintenance,
      incidentNotifications:
        userProfile.userSettings.notificationTypes.incidents,
      urgencyTreshold: userProfile.userSettings.urgencyTreshold || 0,
    },
  });

  const [sliderValue, setSliderValue] = useState(
    preferences.notificationTypes.urgencyTreshold
  ); // Local state for slider

  const sliderConfig = [
    { value: 1, label: "Muy baja" },
    { value: 2, label: "Baja" },
    { value: 3, label: "Media" },
    { value: 4, label: "Alta" },
    { value: 5, label: "Muy alta" },
  ];


  const calculateVisualPosition = (value, min, max) => {
    // Normalize value between 0 and 1
    const normalized = (value - min) / (max - min);
    // Apply a power function to spread out values more visually
    // Adjust the exponent to control the spread
    return normalized * 100;
    // return Math.pow(normalized, 0.5) * 100; // Squaring the normalized value
  };
  const handleSliderChange = (visualValue) => {
    const match = sliderConfig.find((item) => item.value === visualValue);
    if (match) {
      setSliderValue(visualValue);
      setPreferences((prevState) => ({
        ...prevState,
        apiCallFrequency: match.actualValue,
      }));
    }
  };
  const handleChange = (value, name) => {
    // Adjusting for direct value input from the Switch component
    if (name in preferences.notificationChannels) {
      setPreferences((prevState) => ({
        ...prevState,
        notificationChannels: {
          ...prevState.notificationChannels,
          [name]: value,
        },
      }));
    } else if (name in preferences.notificationTypes) {
      setPreferences((prevState) => ({
        ...prevState,
        notificationTypes: {
          ...prevState.notificationTypes,
          [name]: value,
        },
      }));
    }

    {
      setPreferences((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <form className="grid grid-cols-[minmax(min-content,_16ch)_1fr] gap-x-4 gap-y-4 md:gap-y-4 items-center">
      <h2 className="col-span-2">Notificaciones</h2>
      <h3 className="col-span-2">Gestión de Preferencias</h3>
      <Label htmlFor="enableNotifications">Permitir Notificaciones</Label>

      <Switch
        className="ml-auto"
        id="enableNotifications"
        name="enableNotifications"
        checked={preferences.enableNotifications}
        onCheckedChange={(value) => handleChange(value, "enableNotifications")}
      />
      <h3 className="col-span-2">Tipos de Notificaciones</h3>
      <Label htmlFor="wateringNotifications">Riego</Label>
      <Switch
        className="ml-auto"
        id="wateringNotifications"
        name="wateringNotifications"
        checked={preferences.notificationTypes.wateringNotifications}
        onCheckedChange={(value) =>
          handleChange(value, "wateringNotifications")
        }
      />
        {storedUserProfile && (storedUserProfile.role === 'admin' || storedUserProfile.role === 'tecnico') && (
        <>
      <Label htmlFor="maintenanceNotifications">Mantenimiento</Label>
      <Switch
        className="ml-auto"
        id="maintenanceNotifications"
        name="maintenanceNotifications"
        checked={preferences.notificationTypes.maintenanceNotifications}
        onCheckedChange={(value) =>
          handleChange(value, "maintenanceNotifications")
        }
      />
        </>
      )}
              {storedUserProfile && (storedUserProfile.role === 'admin' || storedUserProfile.role === 'tecnico') && (
        <>
      <Label htmlFor="incidentNotifications">Incidentes</Label>
      <Switch
        className="ml-auto"
        id="incidentNotifications"
        name="incidentNotifications"
        checked={preferences.notificationTypes.incidentNotifications}
        onCheckedChange={(value) =>
          handleChange(value, "incidentNotifications")
        }
        />
        </>
      )}
                    {storedUserProfile && (storedUserProfile.role === 'admin' || storedUserProfile.role === 'tecnico') && (
        <>
      <Label htmlFor="urgencyTreshold">Umbral de Urgencia</Label>
      <div className="relative col-span-2 sm:col-span-1 mb-3 h-auto pt-2 pl-4 pr-4">

      <Slider
        id="urgencyTreshold"
        name="urgencyTreshold"
        value={[sliderValue]}
        onValueChange={(values) => handleSliderChange(values[0])}
        min={sliderConfig[0].value}
        max={sliderConfig[sliderConfig.length - 1].value}
        // onValueChange={(value) => handleChange(value, 'urgencyTreshold')}
        values={sliderConfig}
      />
              <div className="ml-[7px] w-[calc(100%_-_16px)] h-2 relative mt-3">

      {sliderConfig.map((item) => (
        <div
          key={item.value}
          style={{
            left: `${calculateVisualPosition(
              item.value,
              sliderConfig[0].value,
              sliderConfig[sliderConfig.length - 1].value
            )}%`,
          }}
          className="absolute w-px flex flex-col justify-center items-center">
              <span className="bg-gray-400 w-px h-2 mb-1"></span>
              <span className="text-xs text-nowrap text-gray-700 dark:text-gray-200">{item.label}</span>
            </div>
          ))}
        </div>
      </div>
</>)}
      <h3 className="col-span-2">Canales</h3>
      <Label htmlFor="emailNotifications">Email</Label>

      <Switch
        className="ml-auto"
        id="emailNotifications"
        name="emailNotifications"
        checked={preferences.notificationChannels.emailNotifications}
        onCheckedChange={(value) => handleChange(value, "emailNotifications")}
      />
      <Label htmlFor="pushNotifications">PWA/Push</Label>
      <Switch
        className="ml-auto"
        id="pushNotifications"
        name="pushNotifications"
        checked={preferences.notificationChannels.pushNotifications}
        onCheckedChange={(value) => handleChange(value, "pushNotifications")}
      />
      <Label htmlFor="whatsappNotifications">WhatsApp</Label>
      <Switch
        className="ml-auto"
        id="whatsappNotifications"
        name="whatsappNotifications"
        checked={preferences.notificationChannels.whatsappNotifications}
        onCheckedChange={(value) =>
          handleChange(value, "whatsappNotifications")
        }
      />

      {/* Additional logic for quiet hours time range and notification digests if needed */}
      {/* No need for a submit button if changes are auto-saved or handled on change */}
    </form>
  );
};

export default NotificationsSettings;
