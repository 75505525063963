import * as React from "react";
import * as SliderPrimitive from "@radix-ui/react-slider";

// Utility for applying multiple class names
const cn = (...classes) => classes.filter(Boolean).join(' ');

const RangeSliderSteps = React.forwardRef(
  ({ min, max, step, defaultValue, onValueChange, className, ...props }, ref) => {
    
    

    const handleChange = (newValue) => {
      // setValue(newValue);
      // onValueChange(newValue);
    };

    return (
      <SliderPrimitive.Root
        min={min}
        max={max}
        step={step}
        // value={value}
        onValueChange={handleChange}
        ref={ref}
        className={cn("relative flex w-full touch-none select-none items-center", className)}
        {...props}
      >
        <SliderPrimitive.Track className="relative h-1.5 w-full grow overflow-hidden rounded-full bg-gray-900/20 dark:bg-gray-50/40">
          <SliderPrimitive.Range className="absolute h-full bg-gray-900 dark:bg-gray-50" />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb className="block h-4 w-4 rounded-full border border-gray-200 border-gray-900/50 bg-white shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-800 dark:border-gray-50/50 dark:bg-gray-300 dark:focus-visible:ring-gray-300" />
      </SliderPrimitive.Root>
    );
  }
);

RangeSliderSteps.displayName = 'RangeSliderSteps';

export { RangeSliderSteps };
