import React, { useEffect, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import { useStateContext } from "contexts/ContextProvider";

const ProtectedRoute = ({ children, activeComponent }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const { setActiveComponent } = useStateContext();
  //const permissions = JSON.parse(sessionStorage.getItem('frontendPermissions')); //Change
  //console.log("ProtectedRoute activeComponent: ", activeComponent);
  //List of components that don't require authentication
  //const publicComponents = ['forgotPasswordReset'];
  //List of components that don't require authentication
  const temporaryIsAuthenticated = false;

  //const publicComponents = useMemo(() => ['login', 'forgotPassword', 'forgotPasswordReset', 'register', 'verifyEmail', 'verifyEmailMsg'], []);

  /*useEffect(() => {
        if (!isAuthenticated && !publicComponents.includes(activeComponent)) {
            console.log("ProtectedRoute - Redirecting to login because user is not authenticated.");
            setActiveComponent('login');
        } else {
            console.log(`ProtectedRoute - Setting active component to ${activeComponent}.`);
            setActiveComponent(activeComponent);
        }
    }, [isAuthenticated, activeComponent, setActiveComponent]);*/

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated && !temporaryIsAuthenticated) {
    console.log(
      "ProtectedRoute - Redirecting to login because user is not authenticated."
    );
    //return <Navigate to="/login" replace={true} />;
    return setActiveComponent("login");
  }

  //Allow access if the component is public or the user is authenticated

  return children;
};

export default ProtectedRoute;
