import React from "react";
import { useStateContext } from "contexts/ContextProvider";

import { Button } from "components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { Icon } from "@iconify/react";

import {
  availableWidgets,
} from "data/chartData/frontLayout";

const AddWidgetGallery = () => {
  const { handleAddWidget } = useStateContext(); // Use the handleAddWidget function from the context

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          className="text-gray-500 pl-0.5 pr-2 dark:text-gray-200 h-7 items-center flex hover:text-green-600 dark:hover:text-green-300"
          variant="ghost">
          <Icon
            className="size-6 p-1 text-current"
            icon="fluent:add-12-filled"
          />
          <span className="text-xs hidden md:inline-flex">Añadir</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-fit m-2">
        <div className="p-2 flex flex-col  space-y-2 ">
          {availableWidgets.map((widget) => (
            <button
              className="w-full flex flex-row space-x-2 p-2 bg-gray-50 dark:bg-gray-1000 hover:bg-gray-100 dark:hover:bg-gray-950 transition-colors rounded-md"
              key={widget.type}
              onClick={() => handleAddWidget(widget.type)}>
              <div className="w-12 p-2 my-auto h-full flex items-center justify-center">
                {widget.icon}
              </div>
              {/* <img className="h-20 w-20" src={widget.icon}></img> */}
              <div className="flex items-start space-y-1 flex-col">
                <span className="block font-bold">{widget.title}</span>
                <span className="block text-sm ">{widget.description}</span>
              </div>
            </button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default AddWidgetGallery;
