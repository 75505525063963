// CAN BE DELETED
import React, { useEffect, useState } from "react";

import { usePageTitle } from "contexts/PageTitleContext";
import { Button } from "components/ui/button";
import UserProfile from "components/settings/UserProfile";
import ExtendedProfile from "components/settings/ExtendedProfile";
import { useStateContext } from "contexts/ContextProvider";
import { ReactComponent as ArrowLeftIcon } from "icons/arrow/left.svg";
// import { useHandleSetLayout } from "contexts/functions/layoutFunctions";
const Profile = () => {
  const { setPageTitle } = usePageTitle();
  useEffect(() => {
    setPageTitle("Profile");
  }, [setPageTitle]);
  const [isViewingExtendedProfile, setIsViewingExtendedProfile] =
    useState(false);

  const { userProfile, updateUser, handleSetLayout } = useStateContext();
  // const handleSetLayout = useHandleSetLayout(); // Use the custom hook

  useEffect(() => {
  console.log("Profile -> userProfile", userProfile);
  }, [userProfile]);

  return (
    <div
      key="profile"
      id="profile"
      className="relative grid gap-4 grid-cols-1 md:grid-cols-[minmax(auto,_20rem),_minmax(0,_1fr)] h-full rounded-lg overflow-clip bg-white md:bg-transparent">
      {isViewingExtendedProfile && (
        <Button
          className="absolute top-2 left-2 "
          onClick={() => setIsViewingExtendedProfile(false)}>
          <ArrowLeftIcon className="size-5 mr-2" />
          Atrás
        </Button>
      )}
      {/* <div className="flex gap-4 relative h-full rounded-lg overflow-clip flex-col md:flex-row"> */}
      <div
        className={`w-full col-span-full md:col-span-1 md:h-fit transition-transform row-span-full h-full gap-4 flex flex-col  rounded-lg shadow-md md:shadow-none md:max-w-xs md:bl ock ${
          isViewingExtendedProfile ? "-translate-x-full md:translate-x-0" : ""
        }`}>
        <UserProfile userProfile={userProfile} />
        <div className="flex flex-col gap-2">

        <Button className="pl-2.5" onClick={() => handleSetLayout("Settings")}>
          <ArrowLeftIcon className="size-6 p-0.5 mr-1 " />
          <span className="inline-flex">Volver a Ajustes</span>
        </Button>
        <Button
          className="md:hidden "
          onClick={() => setIsViewingExtendedProfile(true)}>
          {/* <ArrowLeftIcon className="size-5 mr-2" /> */}
          Ver perfil
        </Button>
            </div>
      </div>
      <div
        className={`w-full h-full pt-12 md:pt-4 col-span-full md:col-span-1 transition-transform row-span-full custom-scrollbar overflow-y-scroll md:dark:text-white md:bg-white md:dark:bg-gray-950 rounded-lg shadow-lg ${
          isViewingExtendedProfile ? "" : "translate-x-full md:translate-x-0"
        }`}>
        <ExtendedProfile
          userProfile={userProfile}
          setUserProfile={updateUser}
          isEditable={true}
        />
      </div>
      {/* </div> */}
    </div>
  );
};

export default Profile;
