import { useState } from "react";

import { ReactComponent as LineChartIcon } from "icons/graphs/linechart.svg";
import { ReactComponent as LineGraphIcon } from "icons/graphs/barchart.svg";
import { ReactComponent as InfoIcon } from "icons/graphs/info.svg";
import { ReactComponent as CalendarIcon } from "icons/calendar.svg";
import { ReactComponent as MapIcon } from "icons/map.svg";
import { ReactComponent as InboxIcon } from "icons/inbox.svg";
import { Icon } from "@iconify/react";

/* const unifiedData = getDynamicData(); 
console.log("frontLayout unifiedData: ",unifiedData); */

//export const widgetData = [
export const widgetData = [

  { id: "initialSortedItems1", type: "selector", options: {} },
  { id: "initialSortedItems2", type: "selector", options: {} },
  { id: "initialSortedItems3", type: "selector", options: {} },
  { id: "initialQueue", type: "queue", options: {} },
  {
    id: "initialDetails",
    type: "details",
    // assuming unifiedData is imported
    options: {
      city: "Valencia",
      location: "Marjals i Extremals",
      valueType: ["pressure", "temperature", "flow"],
      sensors: ["29"],
      // valueType: ["pressure", "temperature"],
    }
  },
  {
    id: "initialLineChart", type: "lineChart",
    options: {
      categories: {
        pressure: { minValue: 0, maxValue: 50, dangerValue: 30, criticalValue: 40 },
        temperature: { minValue: 10, maxValue: 30, dangerValue: 20, criticalValue: 25 },
        flow: { minValue: 0, maxValue: 30, dangerValue: 20, criticalValue: 25 },
      },
      city: "Valencia",
      location: "Marjals i Extremals",
      selectedTimeframe: "last3Hours",
      // startDate: "2024-07-15T12:02:40Z",
      // endDate: "2024-07-16T12:02:50Z",
      valueType: ["temperature"],
      sensors: ["29", "63", "142", "261"],
      showLimits: true,
    }
  },
  {
    id: "initialTable", type: "table",
    options: {
      city: "Valencia",
      location: "Marjals i Extremals",
      startDate: "2024-07-10T12:02:40Z",
      endDate: "2024-07-19T12:02:50Z",
      columns: ["message", "location", "updated", "urgency", "assigned", "status"], // Specifying which columns to show by 
    },
    columnsConfig: [
      // {
      //   id: "id",
      //   type: "sortable",
      //   accessorKey: "id",
      //   header: "Id",
      // },
      {
        id: "message",
        type: "sortable",
        accessorKey: "message",
        header: "Mensaje",
        format: "indicator",
      },
      {
        id: "urgency",
        type: "sortable",
        accessorKey: "urgency",
        header: "Urgencia",
        format: "urgency",
      },
      {
        id: "type",
        type: "sortable",
        accessorKey: "type",
        header: "Típo",
        format: "type",
      },
      // {
      //   id: "location",
      //   type: "sortable",
      //   accessorKey: "location",
      //   header: "Ubicación",
      // },
      {
        id: "assigned",
        type: "sortable",
        accessorKey: "assigned",
        header: "Asignado",
      },
      {
        id: "updated",
        type: "sortable",
        accessorKey: "updated",
        header: "Actualizado",
        format: "time",
      },
      // {
      //   id: "end",
      //   type: "sortable",
      //   accessorKey: "end",
      //   header: "Fin",
      //   format: "time",
      // },
    ],
  },
  {
    id: "mapGis", type: "map",
    options: {
      city: "Valencia",
      location: "Marjals i Extremals",
    }
  },
  {
    id: "initialCalendar", type: "calendar",
    options: {
      city: "Valencia",
      location: "Marjals i Extremals",
      eventTypes: ["notifications", "events"],
    }
  },
  {
    id: "initialBarChart",
    type: "detailBarChart",

    options: {
      city: "Valencia",
      location: "Marjals i Extremals",
      valueType: ["temperature"],
      categories: {
        pressure: { minValue: 0, maxValue: 50, dangerValue: 30, criticalValue: 40 },
        temperature: { minValue: 0, maxValue: 30, dangerValue: 20, criticalValue: 25 },
        flow: { minValue: 0, maxValue: 30, dangerValue: 20, criticalValue: 25 },
      },
      sensors: ["29", "63"],
    }
  },
  {
    id: "initialGaugeChart", type: "gaugeChart",
    options: {
      city: "Valencia",
      location: "Marjals i Extremals",
      sensors: ["29"],
      categories: {
        pressure: { minValue: 0, maxValue: 100 },
        temperature: { minValue: 0, maxValue: 50 },
        flow: { minValue: 0, maxValue: 30 }
      }
    }
  },
  {
    id: "analytics-lineChart", type: "analyticsLineChart",
  },
  {
    id: "analytics-data-table", type: "interactiveTable",

  },
  {
    id: "analytics-data-table-2", type: "comparativeTable",
  },
  {
    id: "community", type: "communityUsers",
  },
  {
    id: "eventsCalendar", type: "fullCalendar",
  },
  {
    id: "parcels", type: "parcels",
  },
  {
    id: "eventsTable", type: "filteredDataTable",
  },
  { id: "settings", type: "settings" },
  { id: "profile", type: "profile" },
];

export const availableWidgets = [
  {
    type: "lineChart",
    icon: <LineChartIcon className="size-full" />,
    title: "Gráfica de línea",
    description: "Una gráfica de línea",
    longDescription: "Una gráfica de línea que muestra los últimos valores de uno o de varios sensores. En las opciones puedes seleccionar la ubicación, los sensores y el tipo de valor que quieres mostrar. Tambien ofrece la posibilidad de seleccionar un rango de fechas.",
    defaultSize: { w: 3, h: 3, minW: 2, minH: 2 },
    defaultOptions: {
      city: "",
      location: "",
      startDate: "",
      endDate: "",
      valueType: ["flow"],
      sensors: [""],
      categories: {
        pressure: { minValue: 0, maxValue: 50, dangerValue: 30, criticalValue: 40 },
        temperature: { minValue: 0, maxValue: 30, dangerValue: 20, criticalValue: 25 },
        flow: { minValue: 0, maxValue: 30, dangerValue: 20, criticalValue: 25 },
      },
      showLimits: true,
    }
  },
  {
    type: "detailBarChart",
    icon: <LineGraphIcon className="size-full rotate-90" />,
    title: "Gráfica de barras",
    description: "Un gráfico de barras",
    longDescription: "Un gráfico de barras que muestra los últimos valores de varios sensores. En las opciones puedes seleccionar la ubicación, los sensores y el tipo de valor que quieres mostrar.",
    defaultSize: { w: 4, h: 3, minW: 2, minH: 2 },
    defaultOptions: {
      city: "",
      location: "",
      sensors: [""],
      startDate: "",
      endDate: "",
      valueType: ["flow"],
      categories: {
        pressure: { minValue: 0, maxValue: 50, dangerValue: 30, criticalValue: 40 },
        temperature: { minValue: 0, maxValue: 30, dangerValue: 20, criticalValue: 25 },
        flow: { minValue: 0, maxValue: 30, dangerValue: 20, criticalValue: 25 },
      },
    }
  },

  {
    type: "details",
    icon: <InfoIcon className="size-full" />,
    title: "Detalles",
    description: "Mostrar detalles de un sensor específico",
    longDescription: "Muestra todas los valores de medicion de un sensor específico junto con los cambios procentuales con los valores anteriores. En las opciones puedes seleccionar la ubicación y el sensor que quieres mostrar.",
    defaultSize: { w: 4, h: 1, minW: 4, minH: 1 },
    defaultOptions: {
      city: "",
      location: "",
      sensors: [""],
      valueType: ["flow", "pressure", "temperature"],
      // categories: {
      //   pressure: { minValue: 0, criticalValue: 40 },
      //   temperature: { minValue: 0, criticalValue: 25 },
      //   flow: { minValue: 0, criticalValue: 25 },
      // },
    }
  },
  {
    type: "gaugeChart",
    icon: <Icon icon="line-md:gauge" className="size-full" />,
    title: "Gráfico de Gauge",
    description: "Un gráfico de gauge",
    longDescription: "Muestra todas los valores de medicion de un sensor específico de una forma visual, mostrandolo dentro de los rangos para observar si los valores estan dentro de los valores normales. En las opciones puedes seleccionar la ubicación y el sensor que quieres mostrar.",
    defaultSize: { w: 4, h: 3, minW: 2, minH: 2 },
    defaultOptions: {
      city: "",
      location: "",
      sensors: [""],
      categories: {
        pressure: { minValue: 0, maxValue: 100 },
        temperature: { minValue: 0, maxValue: 50 },
        flow: { minValue: 0, maxValue: 30 }
      }
    }
  },
  {
    type: "table",
    icon: <InboxIcon className="size-full" />,
    title: "Notificaciones",
    description: "Una tabla para ver las notificaciones",
    longDescription: "Una tabla que muestra las últimas notificaciones de la ubicación seleccionada. En las opciones puedes seleccionar la ubicación, el típo de evento y el rango de fechas que quieres mostrar.",
    defaultSize: { w: 4, h: 3, minW: 2, minH: 2 },
    defaultOptions: {
      city: "",
      location: "",
      startDate: "",
      endDate: "",
      columns: ["message", "location", "updated", "urgency", "assigned", "status"], // Specifying which columns to show by 
    }
  },
  {
    type: "map",
    icon: <MapIcon className="size-full" />,
    title: "Mapa",
    description: "Un mapa del área con puntos de datos",
    longDescription: "Un mapa que muestra la ubicación de los sensores de forma visual. En las opciones puedes seleccionar la ubicación y el tipo de sensor que quieres mostrar.",
    defaultSize: { w: 4, h: 3, minW: 2, minH: 2 },
    defaultOptions: {
      city: "",
      location: "",
      // centerCoordinates: [0, 0],
    }
  },
  {
    type: "calendar",
    icon: <CalendarIcon className="size-full" />,
    title: "Calendario",
    description: "Mostrar eventos en un cuadro de fecha",
    longDescription: "Un calendario que muestra los eventos de la ubicación y en un rango de tiempo seleccionada. En las opciones puedes seleccionar la ubicación y el tipo de evento que quieres mostrar. Al hacer click en un evento se mostrará la información detallada.",
    defaultSize: { w: 2, h: 2, minW: 2, minH: 2 },
    defaultOptions: {
      city: "",
      location: "",
    }
  },
  {
    type: "queue",
    icon: <Icon icon="heroicons:queue-list-16-solid" className="size-full" />,
    title: "Cola",
    description: "Para gestionar las horas de riego",
    longDescription: "Una cola que muestra las horas de riego programadas y permite añadir, ajustar o eliminar horas de riego.",
    defaultSize: { w: 4, h: 3, minW: 2, minH: 2 },
    defaultOptions: {
      city: "",
      location: "",
      // centerCoordinates: [0, 0],
    }
  },

  // Add other widgets as needed
];


export function useWidgets(initialWidgets) {
  const [widgets, setWidgets] = useState(initialWidgets);
  return [widgets, setWidgets];
}