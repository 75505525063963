import React from "react";
import { useState, useEffect, useMemo } from "react";
import { usePageTitle } from "contexts/PageTitleContext";
import { subDays } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { Icon } from "@iconify/react";
import notificationsData from "data/notificationsData.js";
import { filterAndTransformWidgetData } from "helpers/filterAndTransformWidgetData";
import { useStateContext } from "contexts/ContextProvider";
import { hasPermission } from "components/authentication/hasPermission";
import WindowNotification from "./WindowNotification";
import WindowUserCard from "./WindowUserCard";
import { Button } from "components/ui/button";

const FixedButton = ({ toggleMobileSidebar }) => (
  <Button
    variant="outline"
    onClick={toggleMobileSidebar}
    size="icon"
    className="relative md:hidden p-1.5 te xt-gray-50 "
  >
    <Icon className="text-current size-full" icon="ri:menu-fill" />
  </Button>
);

const NavBar = ({ toggleMobileSidebar }) => {
  const { pageTitle } = usePageTitle();
  const [transformedData, setTransformedData] = useState([]);
  const { unifiedData, isLoadingUserPermissions, setIsLoadingUserPermissions } =
    useStateContext();
  const [data, setData] = useState(unifiedData);
  const [notifications, setNotifications] = useState(notificationsData);

  const requiredOptions = ["city", "location", "columns"];
  const options = {
    city: "Valencia",
    location: "Marjals i Extremals",
    startDate: "2024-05-08T12:02:40Z",
    endDate: "2024-05-09T12:02:50Z",
    columns: [
      "message",
      "location",
      "updated",
      "urgency",
      "assigned",
      "status",
    ],
  };

  const clearNotifications = () => {
    setNotifications([]);
  };

  const removeNotification = (id) => {
    setNotifications(
      notifications.filter((notification) => notification.id !== id)
    );
  };

  function transformData(data, options) {
    const {
      city,
      location,
      startDate: startDateOpt,
      endDate: endDateOpt,
    } = options;

    const endDate = endDateOpt || new Date();
    const startDate = startDateOpt || subDays(new Date(endDate), 30);

    const filteredDevices = data.filter(
      (device) =>
        device.location.city === city &&
        device.location.specificLocation === location
    );

    const combinedData = filteredDevices.flatMap((device) =>
      device.notifications.map((notification) => ({
        id: uuidv4(),
        urgency: notification.urgency || "normal",
        message: notification.message,
        status: notification.status || "Pending",
        assigned: notification.assigned || "Unknown",
        date: notification.date,
      }))
    );

    return combinedData;
  }

  useMemo(() => {
    const initialTransformedData = filterAndTransformWidgetData(
      { data, options },
      requiredOptions,
      transformData
    );
    setTransformedData(initialTransformedData || []);
  }, []);

  //TEST try to fix maximum Maximum update depth exceeded
  /* useEffect(() => {
    const initialTransformedData = filterAndTransformWidgetData(
      { data, options },
      requiredOptions,
      transformData
    );
    setTransformedData(initialTransformedData || []);
  }, [data, options, requiredOptions]); */

  const [canViewNotifications, setCanViewNotifications] = useState(false);
  useEffect(() => {
    const checkPermission = async () => {
      const permission = await hasPermission(
        "notificationData.view",
        isLoadingUserPermissions,
        setIsLoadingUserPermissions
      );
      setCanViewNotifications(permission);
    };
    checkPermission();
  }, [isLoadingUserPermissions, setIsLoadingUserPermissions]);

  return (
    <nav className="h-14 z-30 bg-gray-50 dark:bg-gray-1000  sticky top-0 flex justify-between space-x-2 items-center px-2 md:pr-4">
      <div className="flex flex-row items-center space-x-2">
        <FixedButton toggleMobileSidebar={toggleMobileSidebar} />
        <h1
          id="page-title"
          className="text-lg h-10 inline-flex items-center md:text-2xl leading-5"
        >
          {pageTitle}
        </h1>
      </div>
      <div className="flex space-x-2 shrink-0 md:space-x-4 items-center ">
        {canViewNotifications && (
          <>
            <WindowNotification
              notifications={transformedData}
              clearNotifications={clearNotifications}
              removeNotification={removeNotification}
              setNotifications={setNotifications}
            />
            <span className="w-px h-6 bg-gray-300 dark:bg-gray-700"></span>
          </>
        )}
        <WindowUserCard />
      </div>
    </nav>
  );
};

export default NavBar;
