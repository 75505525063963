// chartFunctions.js
import generateTimeframeBounds from "./generateTimeframeBounds";

export const handleSetToNow = (selectedTimeframe, lastSelectedDate, updateOptions, setLastSelectedDate) => {
  const now = new Date();
  const { start, end } = generateTimeframeBounds(selectedTimeframe, now || lastSelectedDate);
  if (start && end) {
    updateOptions({ startDate: start, endDate: end });
  }
};

export const handlePrev = (selectedTimeframe, lastSelectedDate, updateOptions, setLastSelectedDate) => {
  const { start, end } = generateTimeframeBounds(selectedTimeframe, lastSelectedDate);
  if (start && end) {
    const newStartDate = new Date(start.getTime() - (end - start));
    const newEndDate = new Date(start.getTime());
    setLastSelectedDate(newStartDate);
    updateOptions({ startDate: newStartDate, endDate: newEndDate });
  }
};

export const handleNext = (selectedTimeframe, lastSelectedDate, updateOptions, setLastSelectedDate) => {
  const now = new Date();
  const { start, end } = generateTimeframeBounds(selectedTimeframe, lastSelectedDate.getTime() > now.getTime() ? now : lastSelectedDate);
  if (start && end) {
    const newStartDate = new Date(end.getTime());
    const newEndDate = new Date(end.getTime() + (end - start));
    setLastSelectedDate(newEndDate);
    updateOptions({ startDate: newStartDate, endDate: newEndDate });
  }
};
