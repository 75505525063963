import React, { useState, useEffect } from "react";
import { usePageTitle } from "contexts/PageTitleContext";
import ForgotPasswordResetFrame from "components/authentication/ForgotPasswordResetFrame";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { InputWithLabelAndError as Input } from "components/ui/InputWithLabelAndError";
import Logo from "logo-large.svg";
import { useBrand } from 'contexts/BrandContext';

const ForgotPasswordReset = () => {
  const { setPageTitle } = usePageTitle();
  useEffect(() => {
    setPageTitle("Reset Password");
  }, [setPageTitle]);
  const { brand } = useBrand();

  const [formData, setFormData] = useState({
    email: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // To track loading state

  //Extract the token from URL query parameters
  //const token = new URLSearchParams(location.search).get("token");

  // NO TOKEN REROUTING
  // useEffect(() => {
  //     if (!token) {
  //         setMessage('Token de restablecimiento no proporcionado o inválido.');
  //         setTimeout(() => navigate('/login'), 3000);
  //     }
  // }, [navigate, token]);

  /* const validate = () => {
    let isValid = true;
    let newErrors = {};

    if (!formData.email) {
      isValid = false;
      newErrors.email = "El email es necesario";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      isValid = false;
      newErrors.email = "El email no es válido";
    }

    if (!formData.newPassword) {
      isValid = false;
      newErrors.newPassword = "Se necesita una nueva contraseña.";
    }

    if (formData.newPassword !== formData.confirmPassword) {
      isValid = false;
      newErrors.confirmPassword = "Las contraseñas no coinciden.";
    }

    setErrors(newErrors);
    return isValid;
  }; */

  /* const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  }; */

  /* const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsLoading(true); //Indicate loading state
      try {
        const response = await fetch("/api/passwordReset", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: formData.email,
            resetToken: token,
            newPassword: formData.newPassword,
          }),
        });

        if (response.ok) {
          setMessage("Nueva contraseña creada con éxito.");
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          const data = await response.json();
          setMessage(
            data.message || "Ocurrió un error al restablecer la contraseña."
          );
        }
      } catch (error) {
        console.error("Request error:", error);
        setMessage("Error conectando con el servidor");
      } finally {
        setIsLoading(false); // Reset loading state
      }
    }
  }; */

  return (
    <div className="flex  w-full flex-col justify-center  gap-y-4 items-center">
        <img
        src={brand.logo}
        alt={brand.brandName}
          className="pointer-events-none object-contain h-12"
        />
      <ForgotPasswordResetFrame />
    </div>
  );
};

export default ForgotPasswordReset;
