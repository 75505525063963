import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import SignupInputs from "components/authentication/SignupInputs";
import SetupRole from "components/authentication/SetupRole";
import { useStateContext } from "../../contexts/ContextProvider";
import { Button } from "components/ui/button";

const SignUpFrame = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("");
  const [errors, setErrors] = useState({});
  const [responseMessage, setResponseMessage] = useState("");
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { setActiveComponent } = useStateContext();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [registrationStep, setRegistrationStep] = useState(0);

  const validate = () => {
    let isValid = true;
    let errors = {};

    if (!name) {
      isValid = false;
      errors.name = "El nombre es necesario";
    }

    if (!email) {
      isValid = false;
      errors.email = "El email es necesario";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      isValid = false;
      errors.email = "El email no es válido";
    }

    if (email.toLowerCase() !== confirmEmail.toLowerCase()) {
      isValid = false;
      errors.confirmEmail = "Los emails no coinciden.";
    }

    if (!password) {
      isValid = false;
      errors.password = "La contraseña es necesaria";
    }

    if (!confirmPassword) {
      isValid = false;
      errors.confirmPassword = "La confirmación de contraseña es necesaria";
    } else if (password !== confirmPassword) {
      isValid = false;
      errors.confirmPassword = "Las contraseñas no coinciden.";
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      setErrors({});

      try {
        const response = await fetch(
          "https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/auth/register",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name,
              email,
              password,
              role,
            }),
          }
        );

        const data = await response.json();

        if (data.statusCode >= 200 && data.statusCode < 300) {
          setSuccessMessage("Se ha registrado de forma correcta");
          setTimeout(() => {
            setActiveComponent("verifyEmailMsg");
          }, 1000);
        } else {
          const parsedBody = JSON.parse(data.body);
          let errorMessage = "Error durante registro";
          switch (data.statusCode) {
            case 400:
              errorMessage = parsedBody.message;
              setRegistrationStep(0);
              break;
            case 500:
              errorMessage = parsedBody.message;
              break;
            default:
              errorMessage = `Error inesperado: ${response.statusText}`;
          }
          setResponseMessage(errorMessage);
        }
      } catch (error) {
        setResponseMessage("Error conectando al error");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleNextStep = () => {
    if (validate()) {
      setRegistrationStep(1);
    }
  };

  return (
    <div className="max-w-md md:max-w-2xl  mx-auto w-full flex flex-col gap-y-4 md:gap-y-8">
      <div>
        <h2 className="font-medium leading-7 dark:text-white">Registro</h2>
        <span className="text-sm dark:text-gray-200">Crea tu cuenta</span>
      </div>
      <form
        className="grid grid-cols-1  md:grid-cols-2 gap-2 md:gap-4"
        onSubmit={handleSubmit}
      >
        {registrationStep === 0 && (
          <SignupInputs
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            confirmEmail={confirmEmail}
            setConfirmEmail={setConfirmEmail}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            errors={errors}
          />
        )}
        {registrationStep === 1 && <SetupRole role={role} setRole={setRole} />}
        <div className="flex md:col-span-2 justify-center gap-4 w-full mt-4">
          {registrationStep === 1 && (
            <>
              <Button
                type="button"
                onClick={() => setRegistrationStep(registrationStep - 1)}
                className="flex group justify-center text-white rounded-md p-2 w-36 bg-gray-500 hover:bg-gray-600"
              >
                <Icon icon="bi:arrow-left" className="mr-2 size-5" />
                Volver
              </Button>
              <Button
                type="submit"
                disabled={role === ""}
                className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600 dark:bg-green-500"
              >
                {loading ? (
                  <Icon icon="svg-spinners:180-ring" className="text-white" />
                ) : (
                  "Registarse"
                )}
              </Button>
            </>
          )}
          {registrationStep === 0 && (
            <Button
              type="button"
              onClick={handleNextStep}
              className="flex group justify-center text-white rounded-md p-2 w-36 bg-[#1D91E0] hover:bg-blue-600"
            >
              Continuar
              <Icon icon="bi:arrow-right" className="ml-2 size-5" />
            </Button>
          )}
        </div>
      </form>

      <div className="flex flex-col md:flex-row text-sm dark:text-white justify-between gap-x-4 my-2 font-semibold">
        <div className="gap-x-4 flex justify-center">
          <span>¿Ya tienes cuenta?</span>
          <Link
            className="inline-block hover:underline text-center text-[#1D91E0] hover:text-blue-600"
            to="/login"
            onClick={() => setActiveComponent("login")}
          >
            Inicio de sesión
          </Link>
        </div>
        <Link
          className="block w-full md:w-fit text-center text-[#1D91E0] hover:text-blue-600 text-sm"
          to="/forgotPassword"
          onClick={() => setActiveComponent("forgotPassword")}
        >
          Olvidé mi contraseña
        </Link>
      </div>
      <div className="h-4 flex flex-col justify-center items-center">
        {successMessage ? (
          <p className="text-sm text-green-500">{successMessage}</p>
        ) : responseMessage ? (
          <p className="text-sm text-red-500">{responseMessage}</p>
        ) : errors.success ? (
          <p className="text-sm text-green-500">{errors.success}</p>
        ) : null}
      </div>
    </div>
  );
};

export default SignUpFrame;
