import React, { createContext, useState, useContext, useEffect } from 'react';
//Create the context
const AuthContext = createContext();

//AuthProvider component
const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [ authAccessToken, setAuthAccessToken] = useState(null); //Stores the access token created during login
  const [ authAccessTokenExpiration, setAuthAccessTokenExpiration] = useState(null); //Stores expiration date of the access token
  const [ authUserId, setAuthUserId] = useState(null); //Stores userId
  const [isLoading, setIsLoading] = useState(true); //To check if the access token exists and its valid. If not, logout 

  //console.log("AuthContext Before setting states - isAuthenticated:", isAuthenticated, "isLoading:", isLoading); //DEBUG

  useEffect(() => {
    const checkToken = () => {      
      try {
        const token = sessionStorage.getItem("accessToken");
        const expiration = sessionStorage.getItem("accessTokenExpiry");
        const currentTime = new Date().getTime();
        const expirationTime = new Date(expiration).getTime(); //Convert to milliseconds

        //console.log("AuthContext Checking token:", token, expiration);
        //console.log("Navigating to login");
        //console.log("AuthContext Current Time:", currentTime, "Expiration Time:", expirationTime);

        if (token && expiration && currentTime < expirationTime) {
          setIsAuthenticated(true);
          setAuthAccessToken(token);
        } else {
          setIsAuthenticated(false);
          handleLogout();
        }
      } catch (error) {
        console.error('Error checking token:', error);
        handleLogout(); //Optional: Log out on error
      } finally {
        setIsLoading(false); //Ensure loading is set to false regardless of error
      }
    };

    checkToken(); //Check immediately on load
    const interval = setInterval(checkToken, 10 * 60 * 1000); //Check every 10 minutes

    return () => clearInterval(interval); //Cleanup on unmount
  }, []);

  const handleLogin = () => {
    //Retrieve tokens from DB and store them locally
    //Update: done in login form. 
    setIsAuthenticated(true);
  };

  const handleLogout = async () => {
    
    try {
      //Remove tokens from local or session storage
      sessionStorage.removeItem("accessToken");
      sessionStorage.removeItem("accessTokenExpiry");
      sessionStorage.removeItem("userId");
      //Remove user profile data
      localStorage.removeItem("userProfile");
      localStorage.removeItem("contactDetails");
      localStorage.removeItem("userSettings");
      sessionStorage.removeItem('frontendPermissions');
      //Remove access Token from context
      setAuthAccessToken(null);
      //
      setIsAuthenticated(false);      

      /*const response = await fetch('/api/handleUserProfile?action=logout', { //Not implemented yet
          method: 'GET'
      });
      
      if (response.ok) {
          //Handle successful logout on client-side
          console.log('Logged out successfully');
      } else {
          throw new Error('Failed to log out');
      }*/
      //console.log('AuthContext Logged out successfully');

    } catch (error) {
        console.error('Error logging out:', error);
    }
  };

  /*useEffect(() => {
    console.log("AuthContext isAuthenticated changed:", isAuthenticated);
  }, [isAuthenticated]);*/

  //Handle refresh token

  return (
    <AuthContext.Provider value={{ isAuthenticated, handleLogin, handleLogout, authAccessToken, setAuthAccessToken, authAccessTokenExpiration, setAuthAccessTokenExpiration, authUserId, setAuthUserId }}>
      {children}
    </AuthContext.Provider>
  );
};

// Export AuthContext and AuthProvider
//export default AuthContext;
export { AuthProvider };
export const useAuth = () => useContext(AuthContext);
