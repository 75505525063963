import { useCallback, useRef } from "react";
import { debounce } from "lodash";
import { frontLayouts } from "data/frontLayouts";
import { links } from "data/navLinks";

export const useHandleSetLayout = (userProfile, setUserProfile, setActiveLinkName, setIsEditingDashboardLayout, setPageTitle) => {
  const isUpdating = useRef(false);

  console.log("Initial userProfile.userSettings.layoutMode:", userProfile.userSettings.layoutMode);

  const handleSetLayout = useCallback(
    debounce((linkName, specifiedLayoutName = null) => {
      console.log("layoutFunctions handleSetLayout called with:", { linkName, specifiedLayoutName });
      const matchingLink = links.find((link) => link.name === linkName);
      let layoutMode = userProfile.userSettings.layoutMode || "simple";
      console.log("Current layoutMode:", layoutMode);
      if (matchingLink) {
        let layoutName;
        if (linkName === "Home") {
          layoutName = layoutMode;
          console.log("layoutFunctions handleSetLayout Home layoutName:", layoutName);
        } else if (specifiedLayoutName) {
          layoutName = specifiedLayoutName;
        } else {
          layoutName = Array.isArray(matchingLink.layoutName)
            ? matchingLink.layoutName[0]
            : matchingLink.layoutName;
        }
        console.log("layoutFunctions handleSetLayout:", { linkName, layoutName });
        const layout = frontLayouts[layoutName];
        if (layout && !isUpdating.current) {
          isUpdating.current = true;
          setUserProfile((prevProfile) => {
            const newProfile = {
              ...prevProfile,
              userSettings: {
                ...prevProfile.userSettings,
                dashboardLayout: layout,
              },
            };
            console.log("Updated userProfile:", newProfile);
            return newProfile;
          });

          setPageTitle(matchingLink.menuNameSpa);
          const gridItems = document.getElementsByClassName("react-grid-item");
          for (let i = 0; i < gridItems.length; i++) {
            const gridItem = gridItems[i];
            gridItem.style.transition = "none";
            setTimeout(() => {
              gridItem.style.transition = "all 200ms ease";
            }, 0);
          }
          setIsEditingDashboardLayout(false);
          setActiveLinkName(layoutName);

          sessionStorage.setItem("activeLinkName", JSON.stringify(layoutName));

          // console.log("Layout updated to:", layout);
          isUpdating.current = false;
        } else {
          console.error("Layout not found or already updating:", layoutName);
        }
      } else {
        console.log("No matching link found");
      }
    }, 300), // Debounce duration in milliseconds
    [setActiveLinkName, setIsEditingDashboardLayout, setUserProfile, setPageTitle, userProfile.userSettings.layoutMode]
  );

  return handleSetLayout;
};
