import React, { useState, useMemo } from "react";
import DataSelectorSelect from "../DataSelectors/DataSelectorSelect"; // Adjust path as necessary
import { selectorsWithLabels } from "data/translationArrays";
import { Button } from "components/ui/button";

const AddMissingOptions = ({
  data,
  requiredOptions = [],
  options = {},
  onOptionUpdate,
  toggleDataSelector,
}) => {
  const cities = useMemo(() => {
    const citySet = new Set(data.map((item) => item.location.city));
    return Array.from(citySet);
  }, [data]);

  // console.log("requiredOptions", requiredOptions);
  // console.log("options", options);
  const missingOptions = requiredOptions.filter((option) => !options[option]);

  // console.log("missingOptions", missingOptions);
  const [selectedValues, setSelectedValues] = useState({});

  const handleValueChange = (option) => (value) => {
    setSelectedValues((prev) => ({ ...prev, [option]: value }));
    onOptionUpdate(option, value);
  };

  if (missingOptions.length > 0) {
    return (

      <div className="h-full relative w-full flex flex-col justify-center items-center p-4">
        <div>
          <h4 className="text-center mb-4">
            Para mostrar datos selecciona estos campos requeridos:
          </h4>
          <div className="flex flex-col justify-center items-center gap-2">

          {missingOptions.map((option) => {
            const labelInfo = selectorsWithLabels.find(
              (item) => item.value === option
            );
            return (
              <>
                <span>{labelInfo ? labelInfo.label : option}</span>
                {/* <DataSelectorSelect
                key={option}
                label={labelInfo ? labelInfo.label : option}
                availableOptions={cities} // This should be dynamically determined based on the option
                selectedOption={selectedValues[option]}
                onValueChange={handleValueChange(option)}
                exists={true}
              /> */}
              </>
            );
          })}
          <Button onClick={toggleDataSelector}>Ajustar</Button>
          </div>
        </div>
    </div>
)
  }
  return null;
};

export default AddMissingOptions;
