import React, { useState, useEffect, useMemo, useRef } from "react";
import { availableWidgets } from "data/chartData/frontLayout";
import { useStateContext } from "contexts/ContextProvider";
import {
  columnsWithLabels,
  parametersWithLabels,
  timeFrames,
} from "data/translationArrays";
import { ReactComponent as CloseIcon } from "icons/close.svg";

import { Label } from "components/ui/label";
import { Checkbox } from "components/ui/checkbox";
import { Button } from "components/ui/button";
import { formatISO } from "date-fns";
// import { extractDataFromObjects } from "helpers/extractDataFromObjects";
import { DateTimeRangePickerForm } from "components/ui/time-picker/date-time-range-picker-form";
import { DoubleRangeSlider } from "components/ui/double-range-slider";

import DataSelectorSelect from "./DataSelectorSelect";
import DataSelectorMultiSelect from "./DataSelectorMultiSelect";


const DataSelector = ({
  widgetId,
  type,
  toggleDataSelector = () => {},
  onSave = () => {},
}) => {
  // Load the initial options from widgetID
  const { userProfile, unifiedData } = useStateContext();
  const widget = userProfile.userSettings.widgets.find(
    (w) => w.id === widgetId
  );
  const widgetName =
    availableWidgets.find((widget) => widget.type === type) || "";
  const initialOptions = widget ? widget.options : {};

  // const data = widget.data;
  const data = unifiedData;
  console.log("widget.data", widgetName.title, widget.data);
  console.log("widget.options", widgetName.title, widget.options);

  const extractSensors = (data, city, location) => {
    const sensorSet = new Set();
    data.forEach((item) => {
      if (
        item.location.city === city &&
        item.location.specificLocation === location
      ) {
        sensorSet.add({
          value: item.deviceId,
          label: item.title + " - " + item.deviceId,
        }); // Assuming 'title' is the property you want for the label
      }
    });
    return Array.from(sensorSet);
  };

  const initialSensors = initialOptions.sensors
    ? initialOptions.sensors
        .map((key) => {
          const foundSensors = extractSensors(
            data,
            initialOptions.city,
            initialOptions.location
          );
          return foundSensors.find((sensor) => sensor.value === key);
        })
        .filter((sensor) => sensor !== undefined) // Ensure no undefined values are included
    : [];

  const initialColumns = initialOptions.columns
    ? initialOptions.columns.map((colKey) =>
        columnsWithLabels.find((col) => col.value === colKey)
      )
    : [];

  const initialvalueType = initialOptions.valueType
    ? initialOptions.valueType
        .map((value) =>
          parametersWithLabels.find((param) => param.value === value)
        )
        .filter((param) => param) // Ensure there's no undefined entries if key is not found
        .map((param) => ({ value: param.value, label: param.label }))
    : [];

  const [selectedOptions, setSelectedOptions] = useState({
    city: initialOptions.city || "",
    location: initialOptions.location || "",
    startDate: initialOptions.startDate || "",
    endDate: initialOptions.endDate || "",
    customTimeframeEnabled: false, // Manage custom timeframe visibility
    selectedTimeframe: initialOptions.selectedTimeframe || "last24Hours", // Default selected timeframe
    columns: initialColumns || [],
    valueType: initialvalueType,
    showLimits: initialOptions.showLimits || false,
    sensors: initialSensors,
    categories: initialOptions.categories || {
      pressure: {
        minValue: initialOptions.pressure?.minValue || 10,
        maxValue: initialOptions.pressure?.maxValue || 100,
      },
      temperature: {
        minValue: initialOptions.temperature?.minValue || 0,
        maxValue: initialOptions.temperature?.maxValue || 50,
      },
      flow: {
        minValue: initialOptions.flow?.minValue || 0,
        maxValue: initialOptions.flow?.maxValue || 30,
      },
    },
  });

  const cities = useMemo(() => {
    const citySet = new Set(data.map((item) => item.location.city));
    return Array.from(citySet);
  }, [data]);

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    if (!selectedOptions.city) {
      setLocations([]);
    } else {
      const uniqueLocations = new Set(
        data
          .filter((item) => item.location.city === selectedOptions.city)
          .map((item) => item.location.specificLocation)
      );
      setLocations(
        Array.from(uniqueLocations).map((location) => ({
          value: location,
          label: location,
        }))
      );
    }
  }, [selectedOptions.city]);

  const [availableSensors, setAvailableSensors] = useState([]);

  useEffect(() => {
    if (selectedOptions.city && selectedOptions.location) {
      const sensors = extractSensors(
        data,
        selectedOptions.city,
        selectedOptions.location
      );
      setAvailableSensors(sensors);
    } else {
      setAvailableSensors([]);
    }
  }, [data, selectedOptions.city, selectedOptions.location]);

  const handleSelectChange = (field) => (newValue) => {
    setSelectedOptions((prev) => {
      const newSettings = { ...prev, [field]: newValue };

      if (field === "city") {
        newSettings.city = newValue;
        newSettings.location = ""; // Reset location on city change

        const newLocations = data
          .filter((item) => item.location.city === newValue)
          .map((item) => item.location.specificLocation);

        if (newLocations.length > 0) {
          newSettings.location = newLocations[0];
          setLocations(
            newLocations.map((location) => ({
              value: location,
              label: location,
            }))
          );
        }
      }

      if (field === "location") {
        newSettings.location = newValue;
        const sensors = extractSensors(data, newSettings.city, newValue);
        setAvailableSensors(sensors);
        newSettings.sensors = sensors.length > 0 ? [sensors[0]] : []; // Preselect the first sensor if available
      }

      if (field === "sensors" && !Array.isArray(newValue)) {
        const newSensors = [newValue];
        return { ...prev, [field]: newSensors };
      }
      return newSettings;
    });
  };

  // useEffect(() => {
  //   if (!selectedOptions.city && cities.length > 0) {
  //     handleSelectChange("city")(cities[0].value);
  //   }
  // }, [cities, selectedOptions.city]);

  const availabletimeFrames = timeFrames


  useEffect(() => {
    if (!selectedOptions.customTimeframeEnabled) {
      const now = new Date();
      let startDate = new Date();

      // const selectedTimeFrameValue = timeFrames.find(
      //   (tf) => tf.label === selectedOptions.selectedTimeframe
      // )?.value;

      // switch (selectedTimeFrameValue) {
      //   case "last_hour":
      //     startDate.setHours(now.getHours() - 1);
      //     break;
      //   case "last_3_hours":
      //     startDate.setHours(now.getHours() - 3);
      //     break;
      //   case "last_24_hours":
      //     startDate.setDate(now.getDate() - 1);
      //     break;
      //   case "last_3_days":
      //     startDate.setDate(now.getDate() - 3);
      //     break;
      //   case "last_7_days":
      //     startDate.setDate(now.getDate() - 7);
      //     break;
      //   case "last_month":
      //     startDate.setMonth(now.getMonth() - 1);
      //     break;
      //   default:
      //     startDate.setDate(now.getDate() - 1);
      //     break;
      // }

      setSelectedOptions((prev) => ({ ...prev, startDate, endDate: now }));
    }
  }, [
    selectedOptions.selectedTimeframe,
    selectedOptions.customTimeframeEnabled,
  ]);


  const handleTimeframeChange = (value) => {
    const isCustomTimeframe = value === "Rango de tiempo personalizado";
    setSelectedOptions((prev) => ({
      ...prev,
      selectedTimeframe: value,
      customTimeframeEnabled: isCustomTimeframe,
      // startDate: isCustomTimeframe ? prev.startDate : new Date(), // Reset or keep the date based on the selection
      // endDate: isCustomTimeframe ? prev.endDate : new Date(),
    }));
  };

  const handleParameterChange = (parameter, keys, [minValue, maxValue]) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      categories: {
        ...prevOptions.categories,
        [parameter]: {
          ...prevOptions.categories[parameter],
          [keys[0]]: minValue,
          [keys[1]]: maxValue,
        },
      },
    }));
  };

  const handleCheckboxChange = () => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      showLimits: !prevOptions.showLimits,
    }));
  };

  const [errorMessage, setErrorMessage] = useState("");

  const handleSave = () => {
    if (
      !selectedOptions.city ||
      !selectedOptions.location ||
      (type === "table" && selectedOptions.columns.length === 0)
    ) {
      setErrorMessage("Por favor seleccione todos los campos obligatorios.");
      return;
    }

    // Constructing the new options object
    const newOptions = {};

    if (initialOptions.city || initialOptions.city === "")
      newOptions.city = selectedOptions.city;
    if (initialOptions.location || initialOptions.location === "")
      newOptions.location = selectedOptions.location;
    // console.log("This widget has a startDate", initialOptions.startDate, widgetId);
    // console.log("This widget has a startDate", selectedOptions.startDate, widgetId);
    // console.log("This widget has a startDate in ISO", selectedOptions.startDate.toISOString(), widgetId);
    
    if (initialOptions.startDate || initialOptions.startDate === "") {
      newOptions.startDate = formatISO(selectedOptions.startDate, {
        representation: "complete",
      });
    }
    if (initialOptions.endDate || initialOptions.endDate === "") {
      newOptions.endDate = formatISO(selectedOptions.endDate, {
        representation: "complete",
      });
    }
    if (Array.isArray(initialOptions.sensors)) {
      newOptions.sensors = selectedOptions.sensors.map((sensor) =>
        typeof sensor === "string" ? sensor : sensor.value
      );
    }

    if (initialOptions.columns)
      newOptions.columns = selectedOptions.columns || [];
    if (initialOptions.valueType)
      if (
        Array.isArray(selectedOptions.valueType) &&
        selectedOptions.valueType.length > 0 &&
        typeof selectedOptions.valueType[0] === "object"
      ) {
        newOptions.valueType = selectedOptions.valueType.map(
          (detail) => detail.value
        );
      } else if (
        Array.isArray(selectedOptions.valueType) &&
        selectedOptions.valueType.length > 0 &&
        typeof selectedOptions.valueType[0] === "string"
      ) {
        newOptions.valueType = selectedOptions.valueType;
      } else if (typeof selectedOptions.valueType === "string") {
        newOptions.valueType = [selectedOptions.valueType];
      }

    if (initialOptions.categories)
      newOptions.categories = selectedOptions.categories;
    
    // Adding selectedTimeframe to the newOptions
    newOptions.selectedTimeframe = selectedOptions.selectedTimeframe;

    onSave(newOptions); // Passing new options to the onSave function
    console.log("initialOptions", initialOptions);
    console.log("new Options", newOptions);
    setErrorMessage("");
  };

  const ref = useRef(null);

  const [dataSelectorMaxHeight, setDataSelectorMaxHeight] = useState();

  const handleResize = () => {
    if (ref.current) {
      const { top } = ref.current.getBoundingClientRect();
      setDataSelectorMaxHeight(`${window.innerHeight}px - ${top}px - 1rem`);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      ref={ref}
      style={{ maxHeight: `calc(${dataSelectorMaxHeight})` }}
      className={` top-0 z-30  overflow-y-auto custom-scrollbar absolute left-0 pt-2 p-4 rounded-xl  shadow-lg bg-white dark:bg-gray-950 bg-ora nge-200 min-w-full w-fit h-fit flex flex-col gap-y-2 justify-start items-start`}>
      <Button
        className="rounded-full absolute top-2.5 right-4 h-fit p-0.5"
        variant="outline"
        onClick={toggleDataSelector}>
        <CloseIcon className="size-4" />
      </Button>
      <h4 className="text-left">{widgetName?.title}</h4>
      <div className="grid w-full grid-cols-[auto,_1fr]   gap-3">
        <DataSelectorSelect
          label="Ciudad:"
          availableOptions={cities}
          selectedOption={selectedOptions.city}
          onValueChange={handleSelectChange("city")}
          exists={
            initialOptions && initialOptions.city && cities && cities.length > 1
          }
        />

        <DataSelectorSelect
          label="Ubicación:"
          availableOptions={locations}
          selectedOption={selectedOptions.location}
          onValueChange={handleSelectChange("location")}
          exists={
            initialOptions &&
            initialOptions.location &&
            locations &&
            locations.length > 1
          }
        />

        <DataSelectorSelect
          label="Período de Tempo:"
          // availableOptions={locations}
          availableOptions={availabletimeFrames}
          selectedOption={selectedOptions.selectedTimeframe}
          onValueChange={handleTimeframeChange}
          exists={
            type !== "table" &&
            initialOptions && initialOptions.selectedTimeframe
            // &&
            // initialOptions.startDate &&
            // initialOptions.endDate
          }
        />

        <DataSelectorSelect
          label="Dispositivo:"
          availableOptions={availableSensors}
          // enableSearch={false}
          onValueChange={handleSelectChange("sensors")}
          selectedOption={selectedOptions.sensors}
          exists={
            (type === "gaugeChart" || type === "details") &&
            initialOptions &&
            initialOptions.sensors
          }
        />

        <DataSelectorMultiSelect
          label={"Dispositivos:"}
          availableOptions={availableSensors}
          enableSearch={false}
          onValueChange={handleSelectChange("sensors")}
          selectedOptions={selectedOptions.sensors}
          exists={
            type !== "gaugeChart" &&
            type !== "details" &&
            initialOptions &&
            initialOptions.sensors
          }
        />
        <DataSelectorSelect
          label="Parametro:"
          // availableOptions={locations}
          availableOptions={parametersWithLabels}
          selectedOption={selectedOptions.valueType}
          onValueChange={handleSelectChange("valueType")}
          exists={
            initialOptions && initialOptions.valueType && type !== "details"
          }
        />

        <DataSelectorMultiSelect
          label={"Parametros:"}
          availableOptions={parametersWithLabels}
          enableSearch={false}
          onValueChange={handleSelectChange("valueType")}
          selectedOptions={selectedOptions.valueType}
          exists={
            initialOptions &&
            initialOptions.valueType &&
            type !== "lineChart" &&
            type !== "detailBarChart"
          }
        />

        {/* Add back in the DataTimeRangePickerForm */}
        {/* <DateTimeRangePickerForm */}

        {/* TODO: Adjust it so it displays all possible columns from unifiedData.notifications */}

        <DataSelectorMultiSelect
          label="Columns:"
          availableOptions={columnsWithLabels}
          enableSearch={false}
          selectAll={true}
          onValueChange={handleSelectChange("columns")}
          selectedOptions={selectedOptions.columns}
          exists={type === "table" && initialOptions.columns}
        />
        {initialOptions && initialOptions.categories && (
          <Label className="col-span-2 pt-[8px]">Límites:</Label>
        )}
        {/* TODO: Adjust it so it displays all sensors inside unifiedData */}
        {initialOptions &&
          initialOptions.categories &&
          Object.entries(selectedOptions.categories).map(
            ([parameter, values]) => {
              const parameterLabel = parametersWithLabels.find(
                (param) => param.value === parameter
              );
              const parameterMin =
                parameter === "pressure"
                  ? 0
                  : parameter === "temperature"
                  ? 0
                  : 0;
              const parameterMax =
                parameter === "pressure"
                  ? 200
                  : parameter === "temperature"
                  ? 50
                  : 50;
              return (
                <React.Fragment key={parameter}>
                  <Label className="pt-[10px]">
                    {parameterLabel ? parameterLabel.label : parameter}:
                  </Label>
                  <div className="relative flex flex-col gap-y-1 justify-center">
                    <div className="flex justify-between">
                      <div className="text-xs">{values.minValue}</div>
                      <div className="text-xs">{values.maxValue}</div>
                    </div>
                    <DoubleRangeSlider
                      min={parameterMin}
                      max={parameterMax}
                      defaultValue={[values.minValue, values.maxValue]}
                      onValueChange={(newValues) =>
                        handleParameterChange(
                          parameter,
                          ["minValue", "maxValue"],
                          newValues
                        )
                      }
                      className="w-full"
                    />
                  </div>
                </React.Fragment>
              );
            }
          )}

        {type === "lineChart" && (
          <div className="col-span-2 flex justify-between  mt-2">
            <Label className="pt-[8px]">Mostrar valores critíticos:</Label>
            <Checkbox
              className="mt-2"
              checked={selectedOptions.showLimits}
              onCheckedChange={handleCheckboxChange}
            />
          </div>
        )}
        {type === "lineChart" && selectedOptions.showLimits && (
          <span className="col-span-2 h-px bg-gray-500"></span>
        )}

        {type === "lineChart" &&
          selectedOptions.showLimits &&
          Object.entries(selectedOptions.categories).map(
            ([parameter, values]) => {
              const parameterLabel = parametersWithLabels.find(
                (param) => param.value === parameter
              );
              return (
                <React.Fragment key={parameter}>
                  <Label className="pt-[10px]">
                    {parameterLabel ? parameterLabel.label : parameter}:
                  </Label>
                  <div className="relative flex flex-col gap-y-1 justify-center">
                    <div className="flex justify-between">
                      <div className="text-xs">{values.minValue}</div>
                      <div className="text-xs">{values.maxValue}</div>
                    </div>
                    <DoubleRangeSlider
                      limits={true}
                      min={values.minValue}
                      max={values.maxValue}
                      defaultValue={[values.dangerValue, values.criticalValue]}
                      onValueChange={(newValues) =>
                        handleParameterChange(
                          parameter,
                          ["dangerValue", "criticalValue"],
                          newValues
                        )
                      }
                      className="w-full"
                    />
                  </div>
                </React.Fragment>
              );
            }
          )}
      </div>
      <div className="mt-auto  self-end pt-3">
        <Button onClick={handleSave}>Guardar</Button>
      </div>
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
    </div>
  );
};

export default DataSelector;
