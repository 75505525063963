import React from "react";
import { scaleLinear } from "d3-scale";
import { format } from "d3-format";
import { interpolateRgb } from "d3-interpolate";
import { ReactComponent as WaterPressureIcon } from "icons/indicators/water-pressure.svg";
import { ReactComponent as WaterLeakIcon } from "icons/indicators/water-level.svg";
import { ReactComponent as WaterTemperatureIcon } from "icons/indicators/water-temperature.svg";

const Gauge = ({
  value = 50,
  minValue = 0,
  maxValue = 100,
  label,
  visualLabel,
  units,
  startAngle = -Math.PI / 2, // Default start at 9 o'clock
  endAngle = 0, // Default end at 12 o'clock
}) => {
  const outerRadius = 1; // Assuming the outer radius of your gauge is 1
  const innerRadius = 0.85; // Inner radius where the line starts

  // https://2019.wattenberger.com/blog/gauge INSPO
  // const backgroundArc = arc()
  //   .innerRadius(innerRadius)
  //   .outerRadius(outerRadius)
  //   .startAngle(startAngle)
  //   .endAngle(endAngle)
  //   .cornerRadius(1)();

  const percentScale = scaleLinear().domain([minValue, maxValue]).range([0, 1]);

  const percent = percentScale(value);

  const angleScale = scaleLinear()
    .domain([0, 1])
    .range([startAngle, endAngle])
    .clamp(true);

  const angle = angleScale(percent);
  const colorScale = scaleLinear()
    .domain([startAngle, endAngle])
    .range(["#dbdbe7", "#4834d4"]);

  const gradientSteps = colorScale.ticks(10).map((value) => colorScale(value));

  const halfwayPoint = 0.5;
  const adjustedPercent =
    percent < halfwayPoint
      ? percent / halfwayPoint // Scale percent up to 0-1 for the first half
      : (percent - halfwayPoint) / (1 - halfwayPoint); // Scale percent down to 0-1 for the second half

  const needleColor =
    percent < halfwayPoint
      ? interpolateRgb("#30A995", "#EED65D")(adjustedPercent)
      : interpolateRgb("#EED65D", "#ED503B")(adjustedPercent);

  const calculateMarkerLine = (index, totalMarkers) => {
    const angle = angleScale(index / (totalMarkers - 1));

    const innerPoint = {
      x: Math.cos(angle) * innerRadius,
      y: Math.sin(angle) * innerRadius,
    };

    const outerPoint = {
      x: Math.cos(angle) * outerRadius,
      y: Math.sin(angle) * outerRadius,
    };

    return { innerPoint, outerPoint };
  };

  const markers = [];
  const totalMarkers = 11;
  for (let i = 0; i < totalMarkers; i++) {
    const { innerPoint, outerPoint } = calculateMarkerLine(i, totalMarkers);
    markers.push(
      <line
        key={i}
        x1={-innerPoint.x}
        y1={innerPoint.y} // Inverting Y to match SVG's coordinate system
        x2={-outerPoint.x}
        y2={outerPoint.y} // Inverting Y for correct orientation
        className="stroke-gray-300 dark:stroke-gray-600"
        strokeLinecap="round"
        strokeWidth={
          i === 0 ||
          i === totalMarkers - 1 ||
          i === Math.floor(totalMarkers / 2)
            ? "0.08"
            : "0.04"
        }
      />
    );
  }

  const getIcon = () => {
    switch (label) {
      case "pressure":
        return <WaterPressureIcon className="shrink-0 size-6" />;
        case "flow":
          return <WaterLeakIcon className="shrink-0 size-6" />;
          case "temperature":
            return <WaterTemperatureIcon className="shrink-0 size-6" />;
      default:
        return null;
    }
  };

  // Positioning the text
  const highPosition = {
    x: -(Math.cos(startAngle - Math.PI * 0.06) * outerRadius), // Slightly outside the outer radius
    // x: Math.cos(startAngle) * (outerRadius + 0.1), // Slightly outside the outer radius
    y: Math.sin(startAngle - Math.PI * 0.13) * outerRadius,
  };

  const lowPosition = {
    x: -Math.cos(endAngle + Math.PI * 0.13) * outerRadius, // Slightly more outside to position it on the right
    y: Math.sin(endAngle + Math.PI * 0.07) * outerRadius,
  };

  return (
    <div className="grid grid-cols-1 min-w-0 min-h-0 [@container(max-aspect-ratio:2.75_/_1)]:gap-y-2 grid-rows-[2rem,_minmax(auto,_1fr),_2rem] justify-center h-full w-full items-center">
      <div className="flex flex-row w-fit mx-auto min-h-8 items-center gap-2">
        {getIcon(label)}
        {!!label && (
          <div className="text-left capitalize grow text-gray-500 line-clamp-2 text-xs">{visualLabel}</div>
        )}
      </div>
      <div className=" h-full w-full flex items-center">
        <svg
          preserveAspectRatio="xMidYMid meet"
          className="p-1 h-full w-full @sm:p-1 shrink grow-0 overflow-visible"
          viewBox={[-1.3, -0.95, 1.8, 1.1].join(" ")}>
          <defs>
            <linearGradient
              id="Gauge__gradient"
              gradientUnits="userSpaceOnUse"
              x1="-1"
              x2="1"
              y2="0">
              {gradientSteps.map((color, index) => (
                <stop
                  key={color}
                  stopColor={color}
                  offset={`${index / (gradientSteps.length - 1)}`}
                />
              ))}
            </linearGradient>
          </defs>
          <text
            x={-0.4}
            y={-0.25}
            className="text-[0.01em] font-bold @sm:text-[0.018em] fill-gray-300 dark:fill-gray-700 font-sans">
            {units}
          </text>
          {markers}
          <text
            x={highPosition.x} // Adjust according to your SVG's coordinate system
            y={highPosition.y}
            className="text-[0.01em] @sm:text-[0.012em] font-sans fill-gray-500 font-semibold dark:fill-gray-500"
            style={{
              // fontSize: "0.01em",
              textAnchor: "middle",
              alignmentBaseline: "middle",
            }}>
            H
          </text>
          <text
            x={lowPosition.x} // Adjust according to your SVG's coordinate system
            y={lowPosition.y}
            fill="black"
            className="text-[0.01em] @sm:text-[0.012em] font-sans fill-gray-500 font-semibold dark:fill-gray-500"
            style={{
              // fontSize: "0.01em",
              textAnchor: "middle",
              alignmentBaseline: "middle",
            }}>
            L
          </text>
          <g transform={`rotate(${angle * (180 / Math.PI)}) translate(-0, -0)`}>
            <line
              x1="0"
              y1="0"
              x2="0"
              y2={-innerRadius + 0.1}
              stroke={needleColor}
              strokeLinecap="round"
              strokeWidth="0.04"
            />
            <circle cx="-0" cy="-0" r="0.1" fill="gray" />
          </g>
        </svg>
      </div>
      <div className="text-base font-bold text-center @sm:text-xl">
        {format(",")(value)}
      </div>
    </div>
  );
};
// const getCoordsOnArc = (angle, offset = 10) => [
//   Math.cos(angle - Math.PI / 2) * offset,
//   Math.sin(angle - Math.PI / 2) * offset,
// ];

export default Gauge;
