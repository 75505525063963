export const updateWidgetType = (widgetId, newType, userProfile, setUserProfile) => {
  setUserProfile(prevState => ({
    ...prevState,
    userSettings: {
      ...prevState.userSettings,
      widgets: prevState.userSettings.widgets.map(widget =>
        widget.id === widgetId ? { ...widget, type: newType } : widget
      )
    }
  }));
};

export const updateWidgetOptions = (widgetId, newOptions, userProfile, setUserProfile) => {
  console.log("updateWidgetOptions", widgetId, newOptions);
  let updatedWidgets = userProfile.userSettings.widgets.map(widget => {
    if (widget.id === widgetId) {
      return { ...widget, options: { ...widget.options, ...newOptions } };
    }
    return widget;
  });

  console.log("updatedWidgets", updatedWidgets);

  setUserProfile(prevState => ({
    ...prevState,
    userSettings: {
      ...prevState.userSettings,
      widgets: updatedWidgets,
    },
  }));
};

export const resetFrontLayout = (layouts, userProfile, setUserProfile) => {
  setUserProfile(prevProfile => ({
    ...prevProfile,
    userSettings: {
      ...prevProfile.userSettings,
      frontLayout: layouts.default, // Assume 'layouts.default' is your default layout
    },
  }));
};

export const setWidgetDefaultOptions = (widgetId, newType, availableWidgets, userProfile, setUserProfile) => {
  console.log("setWidgetDefaultOptions", widgetId, newType);
  const widgetInfo = availableWidgets.find(widget => widget.type === newType);
  console.log("widgetInfo", widgetInfo);
  if (!widgetInfo || !widgetInfo.defaultOptions) {
    console.error('No default options found for widget type:', newType);
    return;
  }

  setUserProfile(prevState => ({
    ...prevState,
    userSettings: {
      ...prevState.userSettings,
      widgets: prevState.userSettings.widgets.map(widget =>
        widget.id === widgetId ? { ...widget, options: widgetInfo.defaultOptions } : widget
      )
    }
  }));
};