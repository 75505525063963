import { ResponsiveLine } from "@nivo/line";
import PropTypes from "prop-types";
import { useEffect, useState, useMemo } from "react";
import { useStateContext } from "contexts/ContextProvider";
import { filterAndTransformWidgetData } from "helpers/filterAndTransformWidgetData";
import {
  subDays,
  isValid,
  parseISO,
  format,
  subHours,
  subMinutes,
} from "date-fns";
import AddMissingOptions from "../../widgetWrapper/AddMissingOptions";
import { parametersWithLabels, timeFrames } from "data/translationArrays";

import generateTimeframeBounds from "./generateTimeframeBounds";
import { handleSetToNow, handlePrev, handleNext } from "./chartFunctions";
import { Icon } from "@iconify/react";
import { Button } from "components/ui/button";
import { ButtonGroup } from "@mui/material";

const colorPalettes = {
  green: ["hsl(120, 70%, 75%)", "hsl(120, 70%, 50%)", "hsl(120, 70%, 35%)"],
  yellow: ["hsl(60, 70%, 75%)", "hsl(60, 70%, 50%)", "hsl(60, 70%, 35%)"],
  red: ["hsl(0, 70%, 75%)", "hsl(0, 70%, 50%)", "hsl(0, 70%, 35%)"],
};

const CustomTooltip = ({ slice, valueType, isTimeframeOverOneDay }) => {
  if (!slice.points || !slice.points.length) return null;
  const point = slice.points[0]; 
  
  const formattedTime = format(new Date(point.data.x), isTimeframeOverOneDay ? "dd.M. HH:mm" : "HH:mm:ss");

  return (
    <div className="flex flex-col shadow-[0_5px_15px_-2px] shadow-black/30 dark:shadow-gray-50/10 border border-gray-200/50 dark:border-gray-800/50 bg-white p-2 rounded-md dark:bg-gray-950">
      <div className="flex justify-between">
        <span>{valueType}</span>
        <span className="font-semibold">{formattedTime}</span>
      </div>
      <ul>
        {slice.points.map(
          (point, index) => (
            (
              <li
                className="grid grid-cols-[0.5rem,_auto,_auto] w-full gap-x-1.5 items-start text-sm"
                key={index}>
                <div
                  style={{ backgroundColor: point.serieColor }}
                  className="size-2 shrink-0 mt-1.5 rounded-full"></div>

                <span className="w-full max-w-[20ch] text-pretty font-light text-gray-800 dark:text-gray-200">{point.serieId}</span>
                <strong className="ml-auto">{point.data.yFormatted}</strong>
              </li>
            )
          )
        )}
      </ul>
    </div>
  );
};

const CustomLineLayer = ({
  data,
  xScale,
  yScale,
  limits,
  display,
  categories,
  valueType,
}) => {
  const getColorForValue = (value, colorSetIndex) => {
    const { dangerValue, criticalValue } = categories[valueType];
    if (value < dangerValue) return colorPalettes.green[colorSetIndex];
    if (value < criticalValue) return colorPalettes.yellow[colorSetIndex];
    return colorPalettes.red[colorSetIndex];
  };

  const interpolateYValueAtX = (x1, y1, x2, y2, targetY) => {
    const slope = (y2 - y1) / (x2 - x1);
    const intercept = y1 - slope * x1;
    const targetX = (targetY - intercept) / slope;
    return targetX;
  };

  const generateSegmentedLines = (dataSeries) => {
    return dataSeries.map((series, index) => {
      const segments = [];
      const colorSetIndex = index % 3;

      for (let i = 0; i < series.data.length - 1; i++) {
        const point = series.data[i];
        const nextPoint = series.data[i + 1];
        const x1 = xScale(point.x); 
        const y1 = yScale(point.y);
        const x2 = xScale(nextPoint.x); 
        const y2 = yScale(nextPoint.y);
        const thresholds = [limits.yellow, limits.red];
        let lastX = x1,
          lastY = y1,
          lastColor = getColorForValue(point.y, colorSetIndex);

        thresholds.forEach((threshold) => {
          const thresholdY = yScale(threshold);
          if (
            (y1 < thresholdY && y2 > thresholdY) ||
            (y1 > thresholdY && y2 < thresholdY)
          ) {
            const crossingX = interpolateYValueAtX(x1, y1, x2, y2, thresholdY);
            segments.push({
              x1: lastX,
              y1: lastY,
              x2: crossingX,
              y2: thresholdY,
              color: lastColor,
            });
            lastX = crossingX;
            lastY = thresholdY;
            lastColor = getColorForValue(threshold + 1, colorSetIndex);
          }
        });

        segments.push({
          x1: lastX,
          y1: lastY,
          x2,
          y2,
          color: getColorForValue(nextPoint.y, colorSetIndex),
        });
      }

      return segments.map((segment, index) => (
        <line
          key={`line-segment-${series.id}-${index}`}
          x1={segment.x1}
          y1={segment.y1}
          x2={segment.x2}
          y2={segment.y2}
          stroke={segment.color}
          strokeWidth={2}
        />
      ));
    });
  };

  return <g>{display ? generateSegmentedLines(data) : null}</g>;
};

const tailwindColors = {
  gray: {
    100: "#f7fafc",
    200: "#edf2f7",
    300: "#e2e8f0",
    400: "#cbd5e0",
    500: "#a0aec0",
    600: "#718096",
    700: "#4a5568",
    800: "#2d3748",
    900: "#1a202c",
    950: "#171923",
    1000: "#171923",
  },
  red: {
    100: "#fff5f5",
    200: "#fed7d7",
    300: "#feb2b2",
    400: "#fc8181",
    500: "#f56565",
    600: "#e53e3e",
    700: "#c53030",
    800: "#9b2c2c",
    900: "#742a2a",
  },
  "green": {
    50: "#f1fcf9",
    100: "#d1f6ed",
    200: "#a4ebdb",
    300: "#6edac5",
    400: "#40c1ac",
    500: "#27a592",
    600: "#1c8577",
    700: "#1b6a61",
    800: "#1a554f",
    900: "#1a4742",
    950: "#092a28",
  },
};

const darkColors = [
  tailwindColors["green"][300],
  tailwindColors["green"][400],
  tailwindColors["green"][500],
  tailwindColors["green"][600],
  tailwindColors["green"][700],
];

const lightColors = [
"#17B331",
"#f29b3e",
"#f56565",

];

const CustomGridLayer = ({ yScale, width, gridFill }) => {
  
  const yValues = yScale.ticks(5);
  return (
    <g>
      {yValues.map((value, index) => {
        const y = yScale(value);
        return (
          <line
            key={`grid-y-${index}`}
            x1={0} 
            x2={width - 8} 
            y1={y}
            y2={y}
            stroke={gridFill}
          />
        );
      })}
    </g>
  );
};

const ResponsiveLineChart = ({
  data,
  isAnalytics,
  options = {},
  selectedRowIds,
  showHeaderTitle = true,
  selectedTimeframe,
  lastSelectedDate,
  updateOptions,
  setLastSelectedDate,
  onViewLastAvailableData,
  // toggleDataSelector = () => {},
}) => {
  const [lineChartOptions, setLineChartOptions] = useState(options);
  const { userProfile } = useStateContext();
  const [colorScheme, setColorScheme] = useState(lightColors);
  const [legendFill, setLegendFill] = useState(tailwindColors.gray[500]);
  const [axisFill, setAxisFill] = useState(tailwindColors.gray[200]);
  const [gridFill, setGridFill] = useState(tailwindColors.gray[50]);
  const [tooltipColor, setTooltipColor] = useState(tailwindColors.gray[900]);
  const [chartTitle, setChartTitle] = useState("");
  const [chartLocation, setChartLocation] = useState("");


  useMemo(() => {
    if (selectedRowIds && selectedRowIds.length > 0) {
      const sensors = selectedRowIds
        .map((rowId) => {
          const device = data.find((entry) => entry.id === rowId);
          return device ? device.deviceId : null;
        })
        .filter((deviceId) => deviceId !== null);

      setLineChartOptions((prevOptions) => ({
        ...prevOptions,
        sensors: sensors,
      }));
    }
  }, [selectedRowIds]);

  useEffect(() => {
    setLineChartOptions(options);
  }, [options]);

  useMemo(() => {
    if (data && data.length > 0) {
      const valueType = lineChartOptions.valueType[0];
      setChartTitle(
        "Historial de " +
          parametersWithLabels.find((param) => param.value === valueType)
            .label ||
          lineChartOptions.city ||
          "Diagrama"
      );
      setChartLocation(lineChartOptions.location || "Ubicación");
    }
  }, [data, lineChartOptions]);

  const requiredOptions = [
    "city",
    "location",
    "valueType",
    "sensors",
    "selectedTimeframe",
  ];

  function transformData(data, options) {
    const { city, location, valueType, sensors, selectedTimeframe } = options;
    const now = new Date();
    const { start: startDate, end: endDate } = generateTimeframeBounds(
      selectedTimeframe,
      now
    );

    let transformedData = [];
    data.forEach((entry, index) => {
      if (entry.location.city === city && entry.location.specificLocation === location) {
        entry.data.forEach((sensorData) => {
            if (sensors.includes(entry.deviceId) && sensorData.category === valueType[0]) {
               
                const uniqueMeasurements = sensorData.measurements
                    .filter((measurement) => {
                        const measurementDate = new Date(measurement.date).getTime();
                        return measurementDate >= startDate && measurementDate <= endDate;
                    })
                    .filter((measurement, idx, arr) => 
                        idx === arr.findIndex(m => m.date === measurement.date)
                    )
                    .map((measurement) => ({
                        x: measurement.date,
                        y: +measurement.value,
                        category: sensorData.category,
                    }));

            if (uniqueMeasurements.length > 0) {
              transformedData.push({
                id: entry.title,
                title: entry.title,
                color: colorScheme[index % colorScheme.length],
                data: uniqueMeasurements,
                category: sensorData.category,
              });
            }
          }
        });
      }
    });
    return {
      data: transformedData,
      startDate: startDate,
      endDate: endDate,
    };
  }

  const { data: transformedData } = filterAndTransformWidgetData(
    { data: data, options: lineChartOptions },
    requiredOptions,
    transformData
  );

  const filteredData = useMemo(
    () => transformData(data, lineChartOptions),
    [data, lineChartOptions]
  );

  useMemo(() => {
    const isDarkMode = document.documentElement.classList.contains('dark');
    setColorScheme(isDarkMode ? lightColors : darkColors);
    setLegendFill(isDarkMode ? tailwindColors.gray[100] : tailwindColors.gray[900]);
    setAxisFill(isDarkMode ? tailwindColors.gray[300] : tailwindColors.gray[700]);
    setGridFill(isDarkMode ? tailwindColors.gray[800] : tailwindColors.gray[400]);
    setTooltipColor(isDarkMode ? tailwindColors.gray[900] : tailwindColors.gray[100]);
  }, [document.documentElement.classList.contains('dark')]);

  useMemo(() => {
  }, [lineChartOptions]);

  const findMaxValue = () => {
    return (
      data?.data?.reduce((max, series) => {
        const seriesMax = series.data.reduce(
          (seriesMax, item) => Math.max(seriesMax, item.y),
          0
        );
        return Math.max(max, seriesMax);
      }, 0) || 150
    );
  };

  const [limitsState, setLimitsState] = useState(() => {
    const defaultRedLimit = findMaxValue();
    return lineChartOptions && lineChartOptions.limits
      ? lineChartOptions.limits
      : { yellow: 100, red: defaultRedLimit };
  });


  const isTimeframeOverOneDay = () => {
    const timeframe = lineChartOptions.selectedTimeframe;
    return ["last3Days", "last7Days", "last30Days"].includes(timeframe);
  };
  const { minValue, maxValue } = lineChartOptions.categories[
    lineChartOptions.valueType
  ] || {
    minValue: 0,
    maxValue: 100,
  };
 
  const chartOptions = {
    data: transformedData ? transformedData : [],
    motionConfig: "gentle",
    margin: { top: 20, right: 16, bottom: 30, left: 52 },
    sliceTooltip: ({ slice }) => (
      <CustomTooltip
        slice={slice}
        isTimeframeOverOneDay={isTimeframeOverOneDay}
        valueType={
          parametersWithLabels.find(
            (param) => param.value === lineChartOptions.valueType[0]
          ).label
        }
      />
    ),
    
    enableSlices: "x",
    curve: "linear",
    isInteractive: true,
    layers: [
      "markers",
      "axes",
      "areas",
      "crosshair",
      "lines",
      "points",
      "slices",
      "mesh",
      (props) => (
        <CustomGridLayer {...props} gridFill={gridFill} />
      ),
      ...(lineChartOptions.showLimits
        ? [
            (props) => (
              <CustomLineLayer
                {...props}
                valueType={lineChartOptions.valueType}
                display={lineChartOptions.showLimits}
                categories={lineChartOptions.categories}
                limits={limitsState}
              />
            ),
          ]
        : []),
    ],
    axisTop: null,
    axisRight: null,
    xScale: {
      type: "time",
      format: "%Y-%m-%dT%H:%M:%S.%LZ",
      useUTC: false,
      precision: "millisecond",
      min:
        lineChartOptions.selectedTimeFrame === "latest" ? null :
        new Date(lineChartOptions.startDate),
      max:
        lineChartOptions.selectedTimeFrame === "latest" ? null :
        new Date(lineChartOptions.endDate),
    },
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "Tiempo",
      legendOffset: 36,
      legendPosition: "middle",
      format: (value) =>
        isTimeframeOverOneDay()
          ? format(new Date(value), "MMM dd")
          : format(new Date(value), "HH:mm"),

      tickValues: isTimeframeOverOneDay() ? 7 : 12,
    },
    yScale: {
      type: "linear",
      min: minValue || 0,
      max: maxValue || 100,
      stacked: false,
      reverse: false,
    },
    axisLeft: {
      orient: "left",
      tickSize: 5,
      tickValues: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend:
        (lineChartOptions.valueType[0] === "pressure" && "Presión (kPa)") ||
        (lineChartOptions.valueType[0] === "temperature" &&
          "Temperatura (°C)") ||
        (lineChartOptions.valueType[0] === "flow" && "Caudal (m³/s)") ||
        lineChartOptions.valueType,
      legendOffset: -40,
      legendPosition: "middle",
    },

    gridYValues: 5,
    colors: { scheme: "nivo" },
    lineWidth: lineChartOptions.showLimits ? 0 : 2, 
    enablePoints: false,
    pointSize: 4,
    pointColor: { theme: "background" },
    pointBorderWidth: 2,
    pointBorderColor: { from: "serieColor" },
    enablePointLabel: false,
    enableArea: false,
    areaBaselineValue: "auto",
    enableCrosshair: false,
    enableGridX: false,
    enableGridY: true,
    debugSlices: false,
    debugMesh: false,
    areaBlendMode: "multiply",
    areaOpacity: 0,
    enableSlices: "x",
    useMesh: true,
    theme: {
      grid: {
        line: {
          stroke: gridFill,
        },
      },
      axis: {
        ticks: {
          line: {
            stroke: gridFill, 
          },
          text: {
            fill: axisFill,
          },
        },
        legend: {
          text: {
            fill: legendFill,
          },
        },
      },
    },
  };

  const formatDate = (dateInput) => {
    if (!isValid(dateInput)) {
      dateInput = new Date();
    }
    return format(dateInput, "MMM dd, yyyy");
  };

  return (
    <div className="h-full flex flex-col">
      <div className="grid pr-1 grid-cols-1 grid-rows-[3.75rem_minmax(0,_1fr)_auto] h-full ">
      {showHeaderTitle && (
        <div className="flex flex-col">
          <h4 className="px-2 pt -2">{chartTitle}</h4>
          <span className="px-2 text-xs text-green-500 dark:text-green-400">
            {chartLocation}
          </span>
          <span className="pl-2 text-xs text-gray-400">
            {formatDate(lineChartOptions.startDate)} -{" "}
            {formatDate(lineChartOptions.endDate)}
          </span>
        </div>
      )}
        {filteredData.length === 0 ? (
          <div>
            <button onClick={onViewLastAvailableData}>
              Ver últimos datos disponibles
            </button>
          </div>
        ) : (
          <>
            <ResponsiveLine {...chartOptions} />
            <div
              id="lineChart-bottom-bar"
              className="flex shrink-0 px-2 justify-between w-full">
              <div
                id="legends"
                className={`
                  w-fit mx-auto gap-x-2 grow gap-y-px grid p-1 h-fit
            ${transformedData.length === 1 && "grid-cols-1"}
            ${transformedData.length === 2 && "grid-cols-2"}
            ${transformedData.length === 3 && "grid-cols-3 @sm:grid-cols-3"}
            ${transformedData.length > 3 && "grid-cols-3"}
            `}>
                {transformedData.map((series) => (
                  <div key={series.id} className="flex items-start gap-x-1">
                    <div
                      style={{ backgroundColor: series.color }}
                      className="size-2 mt-1 shrink-0 rounded-full"></div>
                    <span className="text-xs h-fit text-gray-400">
                      {series.id}
                    </span>
                  </div>
                ))}
              </div>
              {isAnalytics && (
                <ButtonGroup className="absolute top-11 right-3">
                  <Button
                    variant="outline"
                    size="icon"
                    className="size-6 rounded-l-full"
                    onClick={() =>
                      handlePrev(
                        selectedTimeframe,
                        lastSelectedDate,
                        updateOptions,
                        setLastSelectedDate
                      )
                    }>
                    <Icon icon="fa6-solid:angle-left" className="size-4" />{" "}
                  </Button>

                  <Button
                    variant="outline"
                    size="icon"
                    className="size-6 border-x-0 rounded-none"
                    onClick={() =>
                      handleNext(
                        selectedTimeframe,
                        lastSelectedDate,
                        updateOptions,
                        setLastSelectedDate
                      )
                    }>
                    <Icon icon="fa6-solid:angle-right" className="size-4" />{" "}
                  </Button>

                  <Button
                    variant="secondary"
                    size="icon"
                    className="h-6 w-6 rounded-r-full"
                    onClick={() =>
                      handleSetToNow(
                        selectedTimeframe,
                        lastSelectedDate,
                        updateOptions,
                        setLastSelectedDate
                      )
                    }>
                    <Icon icon="fa6-solid:forward-step" className="size-4" />{" "}
                  </Button>
                </ButtonGroup>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ResponsiveLineChart;
