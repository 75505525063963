import React from "react";
import { Label } from "components/ui/label";
import { MultiSelect } from "components/ui/MultiSelect";

// import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";
import { object } from "zod";

const DataSelectorMultiSelect = ({
  availableOptions,
  selectedOptions,
  onValueChange,
  label,
  exists,
  enableSearch,
  selectAll,
}) => {
  if (!(availableOptions.length > 0 && exists)) {
    return null;
  }

  return (
    <>
      <Label className="pt-[10px]">{label}</Label>
      <MultiSelect
        enableSearch={enableSearch}
        options={availableOptions}
        selected={selectedOptions}
        onChange={onValueChange}
        className="w-full"
        selectAll={selectAll}
      />
    </>
  );
};

export default DataSelectorMultiSelect;
