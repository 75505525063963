import React from "react";
// import { ReactComponent as ProfileIcon } from "icons/profile.svg";
import { ReactComponent as MailIcon } from "icons/mail.svg";
import { ReactComponent as PhoneIcon } from "icons/phone.svg";
// import { ReactComponent as LinkedInIcon } from "icons/social/linkedin.svg";
// import { ReactComponent as TwitterIcon } from "icons/social/twitter.svg";
// import { ReactComponent as GoogleIcon } from "icons/social/google.svg";
// import { ReactComponent as FacebookIcon } from "icons/social/facebook.svg";
import { Icon } from "@iconify/react";
import ProfileImage from "components/ui/profileImage";

const UserProfile = ({ userProfile, className }) => {
  return (
    <div className={`${className} flex min-h-64 relative group flex-col w-full  p-4 gap-3 justify-center items-center shadow-sm rounded-md bg-white dark:bg-gray-950`}>
      <div className="flex size-20 relative">
        <ProfileImage size="xl" image={userProfile.profilePicture} firstName={userProfile.contactDetails.firstName} lastName={userProfile.contactDetails.lastName} userName={userProfile.username} />
        {/* {userProfile.profilePicture && userProfile.profilePicture !== "" ? (
          <img src={userProfile.profilePicture} className="size-20 rounded-full z-[1]" />
        ) : (
          <ProfileIcon className="p-3.5 absolute inset-0 rounded-full bg-gray-100 size-20" />
        )} */}
        {/* <span className={` ${userProfile.isOnline ? 'bg-green-500' : 'bg-red-500'} block right-0 absolute z-10 size-5 border-white dark:border-gray-950 border-2   rounded-full `}></span> */}
      </div>
      <div className="text-center">
        <span className="block dark:text-white">
          {userProfile.contactDetails.firstName + " " + userProfile.contactDetails.lastName}
        </span>
        <span className="text-green-600 dark:text-green-400 text-sm">
          @{userProfile.username}
        </span>
      </div>
      <p className="text-center dark:text-gray-200">{userProfile.contactDetails.workRole}</p>
      {userProfile.contactDetails.bio && (
        <p className="text-center text-sm text-gray-500 dark:text-white">
          {userProfile.contactDetails.bio}
        </p>
      )}
  <div className="flex gap-2">
      {userProfile.contactDetails.phone && (
        <a
        className="w-fit py-1 px-2 rounded-md gap-x-2 text-sm flex items-center bg-gray-100 dark:bg-gray-400 dark:hover:bg-gray-200 hover:bg-gray-200"
        href={`tel:${userProfile.contactDetails.phone}`}>
        <PhoneIcon className="size-4" />
        {userProfile.contactDetails.phone}
      </a>
      )}
      {userProfile.contactDetails && userProfile.contactDetails.allowWhatsapp && (
        <a
        target="_blank"
        rel="noreferrer"
        className="w-fit p-1 px-1.5 rounded-md text-sm flex items-center bg-gray-100 dark:bg-gray-400 dark:hover:bg-gray-200 hover:bg-gray-200"
        href={`https://wa.me/${userProfile.contactDetails.phone.replace(/[^+\d]+/g, '')}`}
        >
        {/* <PhoneIcon className="size-4" /> */}
        <Icon icon="tabler:brand-whatsapp" className="size-4" />
        {/* {userProfile.contactDetails.phone} */}
      </a>
      )}
      </div>
      {userProfile.contactDetails.contactMail && (
        <a
        className="w-fit py-1 px-2 rounded-md gap-x-2 text-sm flex items-center bg-gray-100 dark:bg-gray-400 dark:hover:bg-gray-200 hover:bg-gray-200"
        href={`mailto:${userProfile.contactDetails.contactMail}`}>
        <MailIcon className="size-4" />
        {userProfile.contactDetails.contactMail}
      </a>
      )
        }

      {/* <div className="flex gap-2">
        {userProfile.contactDetails.social.linkedin.length !== 0 && (
          <a
            className="size-7 rounded-md  flex items-center justify-center bg-gray-100 dark:bg-gray-400 dark:hover:bg-gray-200 hover:bg-gray-200"
            href={userProfile.contactDetails.social.linkedin}
            target="_blank"
            rel="noreferrer">
            <LinkedInIcon className="size-5" />
          </a>
        )}
        {userProfile.contactDetails.social.facebook.length !== 0 && (
          <a
            className="size-7 rounded-md  flex items-center justify-center bg-gray-100 dark:bg-gray-400 dark:hover:bg-gray-200 hover:bg-gray-200"
            href={userProfile.contactDetails.social.facebook}
            target="_blank"
            rel="noreferrer">
            <FacebookIcon className="size-5" />
          </a>
        )}
        {userProfile.contactDetails.social.twitter.length !== 0 && (
          <a
            className="size-7 rounded-md  flex items-center justify-center bg-gray-100 dark:bg-gray-400 dark:hover:bg-gray-200 hover:bg-gray-200"
            href={userProfile.contactDetails.social.twitter}
            target="_blank"
            rel="noreferrer">
            <TwitterIcon className="size-5" />
          </a>
        )}
        {userProfile.contactDetails.social.website.length !== 0 && (
          <a
            className="size-7 rounded-md  flex items-center justify-center bg-gray-100 dark:bg-gray-400 dark:hover:bg-gray-200 hover:bg-gray-200"
            href={userProfile.contactDetails.social.website}
            target="_blank"
            rel="noreferrer">
            <GoogleIcon className="size-5" />
          </a>
        )}
      </div> */}
    </div>
  );
};

export default UserProfile;
