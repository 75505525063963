import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { Select, SelectValue, SelectTrigger, SelectContent, SelectItem } from 'components/ui/select';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { rolesWithLabels } from 'data/translationArrays';
import { Icon } from '@iconify/react';

const CreateUser = ({
  isOpen,
  setIsOpen,
  onClose,
  user,
  onSave,
}) => {

  const [errors, setErrors] = useState({});
  const [localProfile, setLocalProfile] = useState({
    contactDetails: {},
    role: '',
    verified: false,
    isEmailVerified: false,
  });

  const roleLabelMap = new Map(rolesWithLabels.map((role) => [role.value, role.label]));

  const getRoleLabel = (roleValue) => {
    return roleLabelMap.get(roleValue) || roleValue;
  };

  useEffect(() => {
    if (user) {
      setLocalProfile({
        contactDetails: {
          firstName: user.contactDetails.firstName || '',
          lastName: user.contactDetails.lastName || '',
          catastro: user.contactDetails.catastro || '',
          parcela: user.contactDetails.parcela || '',
          poligono: user.contactDetails.poligono || '',
        },
        email: user.email || '',
        role: user.role || '',
        verified: user.verified || false,
        isEmailVerified: user.isEmailVerified || false,
      });
    }
  }, [user]);

  const handleSelectChange = (value) => {
    setLocalProfile((prevUser) => ({
      ...prevUser,
      role: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalProfile((prevUser) => ({
      ...prevUser,
      contactDetails: {
        ...prevUser.contactDetails,
        [name]: value,
      },
    }));
  };

  const handleSave = () => {
    if (onSave) {
      onSave(localProfile);
    }
  };


  return (
    <Dialog onOpenChange={onClose}>
        <DialogTrigger
        asChild>
        <Button
          
          variant="outline"
          className="p-1.5 px-2">
          <Icon icon="mdi:plus" className="size-5" />
        </Button>
        </DialogTrigger>

      <DialogContent className="dark:text-white">
        <DialogHeader>
          <DialogTitle>Crear nuevo Usuario</DialogTitle>
        </DialogHeader>
        <form className="grid [&>label]:mt-2 [&>label]:h-6 grid-cols-[auto,_minmax(0,1fr)] gap-2">
          <Label htmlFor="username">Nombre de usuario</Label>
          <Input
            className="h-8"
            id="username"
            name="username"
            value={localProfile.username}
            onChange={handleInputChange}
          />
          <Label htmlFor="firstName">Nombre</Label>
          <Input
            className="h-8"
            id="firstName"
            name="firstName"
            value={localProfile.contactDetails.firstName}
            onChange={handleInputChange}
          />
          <Label htmlFor="lastName">Apellido(s)</Label>
          <Input
            className="h-8"
            id="lastName"
            name="lastName"
            value={localProfile.contactDetails.lastName}
            onChange={handleInputChange}
          />
                    <span class="w-full h-px my-1 bg-gray-100 col-span-2"> </span>

          <Label htmlFor="phone">Teléfono</Label>
          <Input
            className="h-8"
            id="phone"
            name="phone"
            value={localProfile.phone}
            onChange={handleInputChange}
          />

          <Label htmlFor="email">Email</Label>
          <Input
            className="h-8"
            id="parcela"
            name="parcela"
            value={localProfile.email}
            onChange={handleInputChange}
          />
          <Label htmlFor="role">Rol</Label>
          <Select
            className="w-fit mr-auto"
            value={localProfile.role}
            onValueChange={handleSelectChange}
          >
            <SelectTrigger className="h-8 w-full">
              <SelectValue placeholder="Select role">
                {getRoleLabel(localProfile.role)}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {rolesWithLabels.map((role) => (
                <SelectItem key={role.value} value={role.value}>
                  {role.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <span class="w-full h-px my-1 bg-gray-100 col-span-2"> </span>

          <Label htmlFor="catastro">Catastro</Label>
          <Input
            className="h-8"
            id="catastro"
            name="catastro"
            value={localProfile.contactDetails.catastro}
            onChange={handleInputChange}
          />
          <Label htmlFor="parcela">Parcela</Label>
          <Input
            className="h-8"
            id="parcela"
            name="parcela"
            value={localProfile.contactDetails.parcela}
            onChange={handleInputChange}
          />
          <Label htmlFor="poligono">Polígono</Label>
          <Input
            className="h-8"
            id="poligono"
            name="poligono"
            value={localProfile.contactDetails.poligono}
            onChange={handleInputChange}
          />
        </form>
        <DialogFooter className="flex justify-between">
          <Button variant="secondary" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="positive" onClick={handleSave}>
            Guardar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateUser;
