import React from "react";
import { Button } from "components/ui/button";
import { ButtonGroup } from "@mui/material";

const SetupRole = ({ role, setRole }) => {
  const contactOptions = [
    {
      label: "Regante/Agricultor",
      value: "regante",
      description:
        "Informaciones sobre la cola de riego y notificaciones en vivo de la llegada de agua.",
    },
    {
      label: "Técnico de Campo",
      value: "tecnico",
      description:
        "Acceso a más funcionalidad como analiticas y datos de sensores y datos en vivo. Para técnicos de campo.",
    },
    {
      label: "Gestor",
      value: "admin",
      description:
        "Acceso completo a todas las funcionalidades del panel de control con la habilidad de gestionar usuarios. Para gestores.",
    },
  ];

  const handleOptionChange = (value) => {
    setRole(value);
  };

  return (
    <div className="md:col-span-2 max-w-md md:max-w-2xl mx-auto w-full flex flex-col gap-y-4 md:gap-y-8">
      <div className="md:text-center">
        <h2 className="font-medium leading-7 dark:text-white">Rol</h2>
        <span className="text-sm dark:text-gray-200">
          ¿Qué rol desea tener en este dashboard?
        </span>
      </div>
      <div className="flex flex-col gap-2 md:gap-4">
        <ButtonGroup className="flex justify-center">
          {contactOptions.map((option, index) => (
            <Button
              key={option.value}
              className={`${
                index === 0
                  ? "rounded-r-none border border-r"
                  : index === contactOptions.length - 1
                  ? "rounded-l-none border border-l-0 "
                  : "rounded-none border border-l-0 border-r"
              } ${
                role === option.value ? "dark:text-black" : "dark:text-white"
              }  `}
              variant={role === option.value ? "default" : "outline"}
              onClick={() => handleOptionChange(option.value)}
              type="button"
            >
              {option.label}
            </Button>
          ))}
        </ButtonGroup>
        <div className="text-center dark:text-white">
          <span>
            {
              contactOptions.find((option) => option.value === role)
                ?.description
            }
          </span>
          <span>
            Selecciona tu rol en la plataforma. El rol tiene que ser verificado
            por un gestor antes de activar todas las funcionalidades.
          </span>
        </div>
      </div>
    </div>
  );
};

export default SetupRole;
