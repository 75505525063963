import React, { useState } from 'react';
import { Label } from 'components/ui/label';
import { Switch } from 'components/ui/switch';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";import { Button } from 'components/ui/button';
import { useStateContext } from 'contexts/ContextProvider';

const PrivacySettings = () => {
  const { userProfile, setUserProfile } = useStateContext();

  const [preferences, setPreferences] = useState({
    consent: userProfile.userSettings.consent,
    cookiePreferences: userProfile.userSettings.cookiePreferences || 'all', // Assume this to be a string
    whoCanContact: userProfile.userSettings.whoCanContact || 'everyone', // Ensure default is set if undefined
  });

  const contactOptions = [
    { label: 'Todos', value: 'everyone' },
    { label: 'Gestores', value: 'gestors' },
    { label: 'Adminstradores', value: 'admins' },
    { label: 'Nadie', value: 'none' },
  ];
  const cookieOptions = [
    { label: 'Todos', value: 'all' },
    { label: 'Essenciales', value: 'essential' },
    { label: 'Ninguno', value: 'none' },
  ];


  const handleSelectChange = (name) => (value) => {
    setPreferences((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSwitchChange = (value, name) => {
    setPreferences((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDownloadData = () => {
    console.log('Downloading personal data...');
    // Trigger the data download process here
  };

  return (
    <form className="grid grid-cols-[1fr_minmax(min-content_,_12ch)] gap-x-4 gap-y-2 md:gap-y-4 items-center">
      <h2 className="col-span-2">Privacidad</h2>
      
      <Label htmlFor="consent">Consentimiento para la recolección y uso de datos</Label>
      <Switch
      className="ml-auto"
        id="consent"
        name="consent"
        checked={preferences.consent}
        onCheckedChange={(value) => handleSwitchChange(value, 'consent')}
      />

      <Label htmlFor="cookiePreferences">Preferencias de cookies:</Label>
      <Select
        value={preferences.cookiePreferences}
        onValueChange={handleSelectChange('cookiePreferences')}
      >
        <SelectTrigger className="h-8">
          <SelectValue placeholder={preferences.cookiePreferences}>
          {cookieOptions.find(option => option.value === preferences.cookiePreferences)?.label || "Seleccione acceso"}
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          {cookieOptions.map(option => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <Label htmlFor="whoCanContact">Quién puede contactarme:</Label>
      <Select
        value={preferences.whoCanContact}
        onValueChange={handleSelectChange('whoCanContact')}
      >
        <SelectTrigger className="h-8">
          <SelectValue placeholder="Seleccione acceso">
            {contactOptions.find(option => option.value === preferences.whoCanContact)?.label || "Seleccione acceso"}
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          {contactOptions.map(option => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <Label htmlFor="downloadData">Descargar mis datos</Label>
      <Button variant="default" onClick={handleDownloadData}>Descargar</Button>
    </form>
  );
};

export default PrivacySettings;
