import React, { useState, useEffect, useMemo } from "react";
import { useStateContext } from "contexts/ContextProvider";
import ResponsiveLineChart from "components/widgets/widgetTypes/charts/LineChart";
// import ToggleWidgetData from "components/widgets/widgetWrapper/ToggleWidgetData";
import { ReactComponent as WaterPressureIcon } from "icons/indicators/water-pressure.svg";
import { ReactComponent as WaterLevelIcon } from "icons/indicators/water-level.svg";
import { ReactComponent as WaterTemperatureIcon } from "icons/indicators/water-temperature.svg";
import DataSelectorSelect from "components/widgets/DataSelectors/DataSelectorSelect";
import {
  columnsWithLabels,
  parametersWithLabels,
  timeFrames,
} from "data/translationArrays";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { handlePrev, handleNext, handleSetToNow } from "components/widgets/widgetTypes/charts/chartFunctions";
import generateTimeframeBounds from "components/widgets/widgetTypes/charts/generateTimeframeBounds";

const ToggleWidgetData = ({ valueType, onSave, type }) => {
  const categories = [
    { label: "flow", visualLabel: "Caudal", Icon: WaterLevelIcon },
    { label: "pressure", visualLabel: "Presión", Icon: WaterPressureIcon },
    { label: "temperature", visualLabel: "Temperatura", Icon: WaterTemperatureIcon },
  ];

  const initialOptionIndex = categories.findIndex(
    (category) => category.label === (valueType[0] || "flow")
  );

  const [currentOptionIndex, setCurrentOptionIndex] =
    useState(initialOptionIndex);
  const { visualLabel, Icon } = categories[currentOptionIndex];

  const toggleOption = () => {
    const newIndex = (currentOptionIndex + 1) % categories.length;
    setCurrentOptionIndex(newIndex);
    const newOption = categories[newIndex].label;
    onSave({ valueType: [newOption] });
  };

  return (
    <button
      className="opacity-100  w-fit transition-all  group/2 flex @sm:space-x-2 items-center hover:!opacity-100 border-gray-500 dark:text-gray-600 p-1 text-gray-500 dark:border-gray-600 hover:text-gray-800 dark:hover:text-gray-100 rounded-full hover:border-gray-900 dark:hover:border-gray-100 border-[1.6px]"
      onClick={toggleOption}>
      <span className="@sm:inline-flex capitalize @sm:pl-0.5 hidden z-10 w-auto text-xs leading-none text-current">
        {visualLabel}
      </span>
      <Icon className="group-hover:opacity-100 opacity-100 group-hover/2:opacity-100 size-3.5 text-current" />
    </button>
  );
};

const LineChartWrapper = ({
  onRowSelectionChange,
  selectedRowIds,
  toggleDataSelector,
  data,
  options,
}) => {

  const [selectedTimeframe, setSelectedTimeframe] = useState(options.selectedTimeframe || timeFrames[0].value); // Default to "last10Minutes"
  const [lastSelectedDate, setLastSelectedDate] = useState(new Date());
  const [lineChartOptions, setLineChartOptions] = useState({...options,
    selectedTimeframe: options.selectedTimeframe || timeFrames[0].value,
  });

  // useEffect(() => {
  //   setLineChartOptions((prevOptions) => ({
  //     ...prevOptions,
  //     options
  //   }));
  // }, [options]);

  useEffect(() => {
    updateOptions( options );
    setSelectedTimeframe(options.selectedTimeframe);
  }, [options]);


  const updateOptions = (newOptions) => {
    setLineChartOptions((prevOptions) => ({
      ...prevOptions,
      ...newOptions,
    }));
  };

  
  const handleViewLastAvailableData = () => {
    setSelectedTimeframe('latest');
    const { start, end } = generateTimeframeBounds(null, null);
    if (start && end) {
      updateOptions({ startDate: start, endDate: end });
      //console.log("handleViewLastAvailableData - start:", start, "end:", end);
    }
  };
  
  

  useEffect(() => {
    const now = new Date();
    const { start, end } = generateTimeframeBounds(selectedTimeframe, lastSelectedDate || now);
    if (start && end) {
      updateOptions({ startDate: start, endDate: end });
    }
  }, [selectedTimeframe]);



  return (
    <>
      {data && data.length > 0 ? (
        <>
          <ResponsiveLineChart
            isAnalytics={true}
            data={data}
            options={lineChartOptions}
            onRowSelectionChange={onRowSelectionChange}
            selectedRowIds={selectedRowIds}
            toggleDataSelector={toggleDataSelector}
            selectedTimeframe={selectedTimeframe}
            lastSelectedDate={lastSelectedDate}
            updateOptions={updateOptions}
            setLastSelectedDate={setLastSelectedDate}
            // onSetToNow={() => handleSetToNow(selectedTimeframe, lastSelectedDate, updateOptions, setLastSelectedDate)}
            // onPrev={() => handlePrev(selectedTimeframe, lastSelectedDate, updateOptions, setLastSelectedDate)}
            // onNext={() => handleNext(selectedTimeframe, lastSelectedDate, updateOptions, setLastSelectedDate)}
            onViewLastAvailableData={handleViewLastAvailableData}
          />
        </>
      ) : (
        <div className="flex h-full items-center justify-center">
          <span>
            Selecciona uno o más sensores para ver los datos comparativos
          </span>
        </div>
      )}
    </>
  );
};

export default LineChartWrapper;
