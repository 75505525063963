import { v4 as uuidv4 } from 'uuid';

// this will be populated (together with "events" state from the eventHandlers)
export const queueData = [
  {
    id: uuidv4(),
    user_id: 'AliceIba',
    parcela: 1024,
    duration: 60,
    start: '2024-07-04T08:00:00Z',
    end: '2024-07-04T09:00:00Z',
    // assigned: ['Alice', 'Bob'],
  },
  {
    id: uuidv4(),
    user_id: 'BobIba',
    parcela: 1025,
    duration: 60,
    start: '2024-07-04T09:00:00Z',
    end: '2024-07-04T10:00:00Z',
    // assigned: ['Alice', 'Bob'],
  },
  {
    id: uuidv4(),
    user_id: 'CharlieIba',
    parcela: 1026,
    duration: 60,
    start: '2024-07-04T10:00:00Z',
    end: '2024-07-04T11:00:00Z',
    // assigned: ['Alice', 'Bob'],
  },
  {
    id: uuidv4(),
    user_id: 'DavidIba',
    parcela: 1027,
    duration: 60,
    start: '2024-07-04T11:00:00Z',
    end: '2024-07-04T12:00:00Z',
    // assigned: ['Alice', 'Bob'],
  },
  {
    id: uuidv4(),
    user_id: 'EveIba',
    parcela: 1028,
    duration: 60,
    start: '2024-07-04T12:00:00Z',
    end: '2024-07-04T13:00:00Z',
    // assigned: ['Alice', 'Bob'],
  },
  {
    id: uuidv4(),
    user_id: 'FrankIba',
    parcela: 1029,
    duration: 60,
    start: '2024-07-04T13:00:00Z',
    end: '2024-07-04T14:00:00Z',
    // assigned: ['Alice', 'Bob'],
  },
  {
    id: uuidv4(),
    user_id: 'GraceIba',
    parcela: 1030,
    duration: 60,
    start: '2024-07-04T14:00:00Z',
    end: '2024-07-04T15:00:00Z',
    // assigned: ['Alice', 'Bob'],
  },
  {
    id: uuidv4(),
    user_id: 'HankIba',
    parcela: 1031,
    duration: 60,
    start: '2024-07-04T15:00:00Z',
    end: '2024-07-04T16:00:00Z',
    // assigned: ['Alice', 'Bob'],
  },
  {
    id: uuidv4(),
    user_id: 'HankIba',
    parcela: 1022,
    duration: 60,
    start: '2024-07-07T15:00:00Z',
    end: '2024-07-07T16:00:00Z',
    // assigned: ['Alice', 'Bob'],
  },
  {
    id: uuidv4(),
    user_id: 'HankIba',
    parcela: 1026,
    duration: 60,
    start: '2024-07-08T15:00:00Z',
    end: '2024-07-08T16:00:00Z',
    // assigned: ['Alice', 'Bob'],
  },
  // Add more demo queues here
]
