import React, { useState } from "react";
import { usePageTitle } from 'contexts/PageTitleContext';
import { useStateContext } from "contexts/ContextProvider";
import { ReactComponent as PhoneIcon } from "icons/phone.svg";
import { ReactComponent as EmailIcon } from "icons/mail.svg";
import { ReactComponent as MapsIcon } from "icons/maps.svg";
import { InputWithLabelAndError as InputWithLabel } from "components";
import { Label } from "components/ui/label";
import { Textarea } from "components/ui/textarea";
import { Button } from "components/ui/button";
import { toast } from "sonner"

const Help = () => {
  const { userProfile } = useStateContext();
  const [formData, setFormData] = useState({
    name: `${userProfile.contactDetails.firstName} ${userProfile.contactDetails.lastName}`,
    subject: "",
    email: userProfile.contactDetails.contactMail,
    phone: userProfile.contactDetails.phone,
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {

      toast.success("Mensaje enviado. Muchas Gracias!");
      setFormData({
        name: `${userProfile.contactDetails.firstName} ${userProfile.contactDetails.lastName}`,
        subject: "",
        email: "",
        phone: "",
        message: "",
      });
      setErrors({});
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "El nombre es obligatorio.";
    if (!formData.email.trim()) newErrors.email = "El correo electrónico es obligatorio.";
    if (!formData.message.trim()) newErrors.message = "El mensaje es obligatorio.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div className="items-center text-black dark:text-white">
            <p className="text-left">
        Estamos ansiosos por escuchar tus ideas, responder a tus preguntas y explorar cómo podemos trabajar juntos para lograr una gestión sostenible del agua y la preservación del patrimonio en la Huerta de Valencia. ¡Esperamos con entusiasmo tu mensaje!
      </p>
      <form className="p-4 md:p-6 rounded-lg grid sm:grid-cols-2 gap-4 w-full max-w-3xl" onSubmit={handleSubmit}
      action="https://api.staticforms.xyz/submit" method="post"
      >
      <input type="hidden" name="accessKey" value="70a09db0-1eac-4cf6-be9e-41badb1371fe" />

        <InputWithLabel
          required
          label="Nombre"
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          error={errors.name}
        />
        <InputWithLabel
          label="Asunto"
          type="text"
          name="subject"
          value={formData.subject}
          onChange={handleInputChange}
        />
        <InputWithLabel
          required
          label="Correo electrónico"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          error={errors.email}
        />
        <InputWithLabel
          label="Teléfono"
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
        />
        <div className="grid w-full items-center gap-2 md:col-span-2">
          <Label htmlFor="message">Mensaje</Label>
          <Textarea
            required
            id="message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            error={errors.message}
          />
          {errors.message && (
            <p className="mt-2 text-xs text-red-600">
              {errors.message}
            </p>
          )}
        </div>
        <input type="hidden" name="replyTo" value="@" />
        <input className="hidden" type="text" name="honeypot" />
        <Button type="submit" variant="ghost" className="md:col-start-2 ml-auto bg-blue-500 hover:bg-blue-300 text-white w-fit">
          Enviar
        </Button>
      </form>

    </div>
  );
};

export default Help;
