import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Icon } from "@iconify/react";
import { useBrand } from 'contexts/BrandContext';
import { useStateContext } from "contexts/ContextProvider";
import { ReactComponent as HelpIcon } from "icons/help.svg";
import { links } from "data/navLinks";

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "components/ui/sheet";
import Help from "components/help";

const getAllowedLinks = (rolePermissions) => {
  const allowed = new Set(
    rolePermissions.map((perm) => perm.split(".")[0].toLowerCase())
  );
  // TODO: Remove "parcels" once it is in the permissions
  allowed.add("parcels");
  return (link) => allowed.has(link.name.toLowerCase());
};

const SideBar = ({
  isSidebarOpen,
  toggleMobileSidebar,
  sidebarPosition,
  setIsMobileSidebarOpen,
}) => {
  const { userProfile, activeLinkName, handleSetLayout } = useStateContext();
  const { brand } = useBrand();

  const [showIconsOnly, setShowIconsOnly] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const toggleMenuText = () => {
    if (screenSize >= 768) {
      setShowIconsOnly((prev) => !prev);
    } else {
      toggleMobileSidebar();
    }
  };

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useMemo(() => {
    if (screenSize < 768) {
      setShowIconsOnly(false);
    } else if (screenSize >= 768 && screenSize < 1024) {
      setShowIconsOnly(true);
    } else if (screenSize >= 1024) {
      setShowIconsOnly(false);
    }
  }, [screenSize, setShowIconsOnly]);


  const permissionsString = sessionStorage.getItem("frontendPermissions");
  const rolePermissions = permissionsString
    ? JSON.parse(permissionsString)
    : [];
  const canViewLink = getAllowedLinks(rolePermissions);

  const activeLink =
    "text-primary-600 dark:text-primary-300 hover:text-primary-800 dark:hover:text-primary-400";
  const normalLink =
    "dark:text-gray-300 hover:text-primary-800 dark:hover:text-primary-400 text-gray-500";
 
  const filteredLinks = links
    .filter(
      (link) => !["Settings", "Profile", "Help", "Feedback"].includes(link.name)
    ) // Filter out non-sidebar items
    .filter(canViewLink);

  const memoizedHandleSetLayout = useCallback(
    (linkName, layoutName) => {
      handleSetLayout(linkName, layoutName);
      if (screenSize < 768) {
        toggleMobileSidebar();
      }
    },[handleSetLayout, screenSize, toggleMobileSidebar]
  );

  return (
    <nav
      className={`fixed h-svh sm:relative bottom-0 ${
        sidebarPosition === "right"
          ? "row-start-1 col-start-2 rounded-l-2xl border-l"
          : "rounded-r-2xl border-r"
      } top-0 flex flex-col justify-between shadow-md z-20 bg-white dark:bg-gray-950 border-gray-200/50 dark:border-gray-900/80 overflow-clip sidebar ${
        isSidebarOpen ? "mobileOpen " : "mobileClosed  "
      } ${showIconsOnly ? "closed" : "open"}`}
    >
      <>
        <div
          className={`${
            sidebarPosition === "right" && "flex-row-reverse"
          } relative flex justify-between py-2 items-center`}
        >
          <button
            onClick={() => {
              toggleMobileSidebar();
              memoizedHandleSetLayout("Home");
            }}
            className={`items-center ${
              sidebarPosition === "right" ? "w-full" : "ml-4"
            } m-1 flex tracking-tight text-gray-100 ${
              showIconsOnly ? "hidden" : ""
            }`}
          >
            <img
              src={brand.logo}
              alt={brand.brandName}
              className="transition-transform duration-200 p-px hover:scale-110 object-contain object-left h-7 ml-0.5"
            />
          </button>

          <button
            id="expandNavButton"
            className="relative text-xl px-[0.875rem] py-3 dark:text-gray-200 text-gray-700 dark:hover:text-primary-700 hover:text-primary-600"
            onClick={toggleMenuText}>
            <Icon
              className={`transition-all mx-2 size-5 ${
                showIconsOnly ? "rotate-180 " : "rotate-0"
              }`}
              icon={
                userProfile &&
                userProfile.userSettings &&
                userProfile.userSettings.sidebarPosition === "right"
                  ? "tabler:arrow-bar-right"
                  : "tabler:arrow-bar-left"
              }
            />
          </button>
        </div>
        <span className="w-[calc(100%_-_24px)] mx-auto h-px bg-gray-300 dark:bg-gray-700"></span>
        <div className="relative">
          <div
            id="sidebar-links"
            className="absolute right-0 top-1/2 h-full -translate-y-1/2"
          ></div>

          {filteredLinks.map((link) => {
            const isActive = Array.isArray(link.layoutName)
              ? [
                  ...(Array.isArray(link.layoutName)
                    ? link.layoutName
                    : [link.layoutName]),
                  ...(link.alternativeLayouts || []),
                ].includes(activeLinkName)
              : [link.layoutName, ...(link.alternativeLayouts || [])].includes(
                  activeLinkName
                );

            const layoutName = Array.isArray(link.layoutName)
              ? link.layoutName[0]
              : link.layoutName;

            return (
              <button
                key={link.name}
                onClick={() => {
                  memoizedHandleSetLayout(link.name, layoutName);
                  setIsMobileSidebarOpen(false);

                  console.log("App.js Sidebar Clicked:", {
                    linkName: link.name,
                    layoutName,
                    isActive,
                  });
                }}
                className={`${
                  isActive ? activeLink : normalLink
                } transition-colors duration-150 flex group items-center h-12 gap-5 px-[1.375rem] py-3 text-md`}
              >
                <span className="size-5 duration-75 text-current shrink-0">
                  {link.icon}
                </span>
                <span
                  className={`${
                    showIconsOnly
                      ? "opacity-0 duration-75 "
                      : "md:duration-150 md:delay-300 opacity-100"
                  } text-current transition-opacity`}
                >
                  {link.menuNameSpa}
                </span>
              </button>
            );
          })}
        </div>

        <div className="mt-auto flex flex-col w-full">
          <div
            className={`${
              showIconsOnly
                ? "opacity-0 duration-75 "
                : "md:duration-150 md:delay-300 opacity-100"
            } flex flex-col my-2 space-y-1 transition-opacity`}
          >
            <span className="text-center text-gray-300 dark:text-gray-600 text-xs font-sans select-none">
              Desarrollado por Baukunst y Fibsen
            </span>
          </div>
          <div className="relative h-fit" key="help">
            <Sheet>
              <SheetTrigger asChild>
                <button
                  key="help"
                  className={`flex ${normalLink} transition-colors  w-full group items-center h-12 gap-5 px-[1.375rem] py-3 text-md`}
                >
                  <>
                    <span className="size-5 duration-75 text-current shrink-0">
                      <HelpIcon className=" size-full" />
                    </span>
                    <span
                      className={` ${
                        showIconsOnly
                          ? "opacity-0 duration-75 "
                          : "md:duration-150 md:delay-300 opacity-100"
                      }   transition-opacity text-current`}
                    >
                      Ayuda
                    </span>
                  </>
                </button>
              </SheetTrigger>
              <SheetContent className="rounded-l-2xl sm:!max-w-lg md:!max-w-xl">
                <SheetHeader>
                  <SheetTitle>Ayuda</SheetTitle>
                  <SheetDescription>
                    Aqui puedes encontrar ayuda sobre el uso de la aplicación
                  </SheetDescription>
                  <Help />
                </SheetHeader>
              </SheetContent>
            </Sheet>
          </div>
        </div>
      </>
    </nav>
  );
};

export default SideBar;
