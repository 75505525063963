import React, { useState, useEffect, useMemo } from "react";
import { Calendar } from "components/ui/calendar";
import { Button } from "components/ui/button";
import { useStateContext } from "contexts/ContextProvider";
// import { useHandleSetLayout } from "contexts/functions/layoutFunctions";
import { useEventHandlers } from "contexts/EventHandlers";

const SmallCalendar = ({ data, options }) => {
  const { events } = useEventHandlers();
  const { selectedEventId, setSelectedEventId, handleSetLayout } = useStateContext();
  const [date, setDate] = useState(new Date());
  // const handleSetLayout = useHandleSetLayout(); // Use the custom hook
  const initialEvents = events.map((notif) => ({
    // ...notif,
    id: notif.id,
    message: notif.title || notif.message,
    time: notif.start,
    type: "event",
  }));
  const [localEvents, setLocalEvents] = useState(initialEvents || []);

  useMemo(() => {
      setLocalEvents(events.map((notif) => ({
        // ...notif,
        id: notif.id,
        message: notif.title || notif.message,
        time: notif.start,
        type: "event",
      })));

  }, [events]);

  const formatDate = (date) => {
    const options = { day: '2-digit', month: '2-digit', year: '2-digit' };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  
  const filteredEvents = localEvents.filter((event) => {
      return formatDate(event.time) === formatDate(date);
    });
    
  return (
    <div className="flex h-full flex-row gap-2  [@container(max-aspect-ratio:2_/_1)]:flex-col @md:[@container(max-aspect-ratio:2_/_1)]:flex-row ">
      <Calendar
        mode="single"
        events={localEvents}
        selected={date}
        onSelect={setDate}
        className="rounded-md p-0 w-full "
      />
      <div className="border-gray-100 basis-1/2 @md:[@container(max-aspect-ratio:2_/_1)]:shrink-0 [@container(max-aspect-ratio:2_/_1)]:border-t-2 @md:[@container(max-aspect-ratio:2_/_1)]:border-l-2 @md:[@container(max-aspect-ratio:2_/_1)]:border-t-0 mt-2 @md:[@container(max-aspect-ratio:2_/_1)]:mt-0 @md:[@container(max-aspect-ratio:2_/_1)]:pl-2 @md:[@container(max-aspect-ratio:2_/_1)]:h-full pt-2 mb-auto">
        {filteredEvents.length > 0 ? (
          <ul>
            {filteredEvents.map((notification) => (
              <li
                key={notification.id}
                className="flex gap-2 place-items-baseline w-full text-sm">
                <span className="text-xs min-w-8 text-gray-300 dark:text-gray-500">
                  {new Date(notification.time).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
                <span className="truncate font-medium">
                  {notification.message}
                </span>
                <Button
                  onClick={() => {
                    console.log("selected notification.id", notification.id);
                    // TODO: Set selectedEventId to the selected notification id
                    setSelectedEventId(notification.id);
                    console.log("Redirecting to calendar...");
                    console.log("selectedEventId", selectedEventId);
                    handleSetLayout("Calendar");
                  }}
                  variant="link"
                  size="xs"
                  className="text-xs ml-auto font-normal text-green-400 hover:text-green-600">
                  Detalles
                </Button>
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-sm">No hay notificaciones para esta fecha.</div>
        )}
      </div>
    </div>
  );
};

export default SmallCalendar;
