export const hasPermission = async (
  permission,
  isLoadingUserPermissions,
  setIsLoadingUserPermissions
) => {
  let permissionsString = sessionStorage.getItem("frontendPermissions");
  while (!permissionsString) {
    if (isLoadingUserPermissions) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      permissionsString = sessionStorage.getItem("frontendPermissions");
    } else {
      setIsLoadingUserPermissions(true);

      await new Promise((resolve) => setTimeout(resolve, 1000));
      permissionsString = sessionStorage.getItem("frontendPermissions");
    }
  }

  try {
    const permissions = JSON.parse(permissionsString);

    if (!Array.isArray(permissions)) {
      return false;
    }

    return permissions.includes(permission);
  } catch (error) {
    return false;
  }
};
