import React, { useState, useMemo, useEffect } from "react";
import UserProfile from "components/settings/UserProfile";

import { InputWithLabelAndError as Input } from "components/ui/InputWithLabelAndError";
import { Button } from "components/ui/button";
import ExtendedProfile from "components/settings/ExtendedProfile";
import { ReactComponent as CloseIcon } from "icons/close.svg";
import { ButtonGroup } from "@mui/material";
import { Icon } from "@iconify/react";
import { ResponsiveDataTable } from "components/widgets/widgetTypes/tables/ResponsiveDataTable";
import { ScrollArea } from "components/ui/scroll-area";
import axios from "axios";
import { columnHeadersWithLabels, rolesWithLabels } from "data/translationArrays";
import ColumnFilterDropdown from "components/widgets/widgetTypes/tables/ColumnFilterDropdown";
import { toast } from "sonner";
import CommunityVerifyUser from "components/widgets/widgetTypes/CommunityVerifyUser";
import CreateUser from "components/widgets/widgetTypes/community/CreateUser";
const roleLabelMap = new Map(
  rolesWithLabels.map((role) => [role.value, role.label])
);

const getRoleLabel = (roleValue) => {
  return roleLabelMap.get(roleValue) || roleValue; // Return the label or the original value if not found
};

const columnsConfig = [
  {
    id: "image",
    type: "image",
    header: "Profile",
    accessorKey: "image",
    size: 50,
  },

  {
    id: "name",
    type: "sortable",
    header: "Nombre",
    accessorKey: "name",
  },
  {
    id: "lastName",
    type: "sortable",
    header: "Apellidos",
    accessorKey: "lastName",
  },
  {
    id: "team",
    type: "sortable",
    header: "Equipo",
    accessorKey: "team",
  },
  {
    id: "role",
    type: "sortable",
    header: "Rol",
    accessorKey: "role",
  },
  // {
  //   id: "email",
  //   type: "sortable",
  //   header: "Email",
  //   accessorKey: "email",
  // },
  {
    id: "phone",
    type: "button",
    header: "Teléfono",
    accessorKey: "phone",
  },
  {
    id: "parcela",
    type: "sortable",
    header: "Parcela",
    accessorKey: "parcela",
  },
  {
    id: "actions",
    type: "actions",
    header: "Acciones",
    accessorKey: "actions",
    pin: "right",
  },
];

const Community = () => {
  const [searchTerm, setSearchTerm] = useState("");
  // const [filterOnline, setFilterOnline] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null); // Added state for selected profile
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [viewType, setViewType] = useState("list");
  const [tableColumns, setTableColumns] = useState([]); // Add state to manage columns
  const [userData, setUserData] = useState([]);
  // const [responseMessage, setResponseMessage] = useState("");
  //Load user's profile
  const storedUserProfile = JSON.parse(localStorage.getItem("userProfile"));
  const [isVerifyUserDialogOpen, setIsVerifyUserDialogOpen] = useState(false);

  const transformUserData = (data) => {
    return data.map((user) => ({
      id: user.user_id || "",
      username: user.user_name || "",
      isOnline: false, // Placeholder, update based on actual logic
      role: user.role_names || "",
      profilePicture: user.user_photo || "",
      lastLogin: user.last_login || "N/A",
      firstLogin: user.first_login || "N/A",
      isVerified: user.verified || "",
      isEmailVerified: user.verified || "", // Placeholder, adjust based on actual logic
      email: user.user_email || "",
      workRole: user.profession || "", // Placeholder, adjust based on actual logic
      parcela: user.parcel_queue_id || "",
      poligono: user.polygon_queue_id || "",
      catastro: user.cadastre || "",
      parcela_orden: user.order || "",
      contactDetails: {
        team: user.project_name || "", // Placeholder, adjust based on actual logic
        //parcela: "ABC-123" || "", // Placeholder, adjust based on actual logic
        phone: user.user_phone || "N/A",
        firstName: user.first_name || "N/A",
        lastName: user.last_name || "N/A",
        bio: "" || "", // Placeholder, adjust based on actual logic
      },
    }));
  };

  //Fetch data from AWS Lambda function
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/users/manageUsers?action=getUsers"
      );
      //console.log("Community manageUser getUsers response: ", response);

      if (response.data.body) {
        const responseBody = JSON.parse(response.data.body);
        console.log("Community Parsed response body:", responseBody);
        const transformedData = transformUserData(responseBody);
        console.log("Community transformedData:", transformedData);
        setUserData(transformedData);
      } else {
        console.error("No body in response");
      }
    } catch (error) {
      console.error("Error fetching data from AWS Lambda:", error);
    }
  };

  //Fetch users when the component mounts only if the user's admin
  //Change this with the role system
  useEffect(() => {
    
    fetchData();
  }, []);

  const filteredUsers = useMemo(() => {
    //console.log("Community filteredUsers is running", userData);
    return userData.filter((user) => {
      const searchLower = searchTerm.toLowerCase();
      const firstName = user.contactDetails.firstName.toLowerCase();
      const lastName = user.contactDetails.lastName.toLowerCase();
      const team = user.contactDetails.team.toLowerCase();
      const role = user.role && user.role.toLowerCase();
      // Additional fields can be added here
      // const email = user.contactDetails.email.toLowerCase();

      return (
        firstName.includes(searchLower) ||
        lastName.includes(searchLower) ||
        team.includes(searchLower) ||
        role.includes(searchLower)
        // email.includes(searchLower) // Uncomment if email should be included in the search
      );
      // && (!filterOnline || user.isOnline);
    });
  }, [searchTerm, userData]); //filterOnline
  const uniqueTeams = [
    ...new Set(filteredUsers.map((user) => user.contactDetails.team)),
  ];

  const handleViewProfile = (id) => {
    const selectedUser = userData.find((user) => user.id === id); // Ensure ID comparison as number
    setSelectedProfile(selectedUser);
    setSelectedProfileId(id);
  };

  const handleRowSelectionChange = (selectedRowId) => {
    // console.log("handleRowSelectionChange")
    const selectedUser = userData.find(
      (user) => user.id === parseInt(selectedRowId)
    ); // Ensure ID comparison as number
    // console.log("selectedRowId in handleRowSelectionChange", selectedRowId);
    if (!selectedRowId) return;
    setSelectedProfile(selectedUser);
    setSelectedProfileId(selectedRowId);
  };

  // const handleViewChange = (view, selectedRowId) => {
  //   setViewType(view);
  //   const selectedUser = userData.find(
  //     (user) => user.id === parseInt(selectedRowId)
  //   ); // Ensure ID comparison as number
  //   // console.log("selectedRowId in handleRowSelectionChange", selectedRowId);
  //   setSelectedProfile(selectedUser);
  //   setSelectedProfileId(selectedRowId);
  // };
  const handleViewChange = (view) => {
    setViewType(view);
  };

  const viewOptions = [
    { label: "Grid", value: "grid", icon: "bi:grid-fill" },
    { label: "Lista", value: "list", icon: "ri:table-fill" },
  ];

  const passedColumnsConfig = useMemo(() => {
    //console.log("Community columnsConfig in passedColumnsConfig", columnsConfig)
    return columnsConfig.map((column) => ({
      ...column,
      size: column.size && column.size ? column.size : null,
      pin: column.pin && column.pin ? column.pin : null,
      header: column.header,
      type: column.type,
      id: column.id,
      parcela: column.parcela,
      accessorKey: column.accessorKey,
    }));
  }, []);

  const tableData = useMemo(() => {
    //console.log("Community tableData filteredUsers", filteredUsers);
    return filteredUsers.map((user) => ({
      id: user.id,
      image: {
        image: user.profilePicture,
        firstName: user.contactDetails.firstName,
        lastName: user.contactDetails.lastName,
        userName: user.username,
      },
      name: user.contactDetails.firstName,
      lastName: user.contactDetails.lastName,
      team: user.contactDetails.team,
      role: getRoleLabel(user.role),
      email: user.contactDetails.email,
      phone: user.contactDetails.phone,
    }));
  }, [filteredUsers]);

  const manageUsers = async (action, userId) => {
    /* console.log("Community manageUsers action, userId: ",action, userId); */
    try {
      const response = await axios.get(
        `https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/users/manageUsers?action=${action}&userId=${userId}`
      );
      console.log("Community manageUsers getUsers response: ", response);
      //const data = await response.json();
      const responseBody = response.data; //JSON.parse(data.body);

      if (responseBody.statusCode >= 200 && responseBody.statusCode < 300) {
        const parsedBody = JSON.parse(responseBody.body);
        /* console.log("Community Parsed response body:", parsedBody); */
        toast.success(parsedBody.message);
      } else {
        let errorMessage = "Error durante registro";
        switch (responseBody.statusCode) {
          case 400:
            errorMessage = responseBody.message;
            break;
          case 401:
            errorMessage = responseBody.message;
            break;
          case 500:
            errorMessage = responseBody.message;
            break;
          default:
            errorMessage = `Error inesperado: ${response.statusText}`;
        }
        //console.error('Login error:', data.message); //Reads the message sent from the "backend"
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Error fetching data from AWS Lambda:", error);
    }
  };

  const editUser = async (selectedId) => {
    /* console.log("Community editUser id:", selectedId); */
    const selectedUser = userData.find((user) => user.id === selectedId); // Ensure ID comparison as number
    /* console.log("Community editUser selectedUser:", selectedUser); */
    // const userId = selectedProfile.selectedId;

    setSelectedProfile(selectedUser);
    setSelectedProfileId(selectedId);
    // Implement your user editing logic here
  };

  // const handleUserToVerify = async (selectedId) => {
  //   const selectedUser = userData.find((user) => user.id === selectedId); // Ensure ID comparison as number
  //   if (selectedUser.isVerified) {
  //     toast.success(`Usuario ${selectedUser} ya verificado`);
  //     return;
  //   }
  //   /* console.log("Community handleUserToVerify selectedUser", selectedUser); */

  //   setSelectedProfile(selectedUser);
  //   setSelectedProfileId(selectedId);

  //   setIsVerifyUserDialogOpen(true);
  // };

  const verifyUser = async (selectedProfile) => {
    //console.log("Community inside verifyUser");
    if (!selectedProfile) return;
    //const userId = selectedProfile.id;
    //const userName = selectedProfile.username;
    //console.log("Community Verify user:", userId);
    const verUserRespond = await manageUsers("verifyUser", selectedProfile);
    console.log("Community verUser Respond: ", verUserRespond);
  };

  const verifyRole = async (selectedProfile) => {
    if (!selectedProfile) return;
    const verUserRoleRespond = await manageUsers("verifyRole", selectedProfile);
    console.log("Community verUserRole Respond: ", verUserRoleRespond);
  };

  const deleteUser = () => {
    if (!selectedProfile) return;
    const userId = selectedProfile.id;
    toast.error(`Usuario ${userId} eliminado correctamente`);
    //console.log("Community Delete user:", userId);
    // Implement your user deletion logic here
  };

  const handleVerifyUserSave = (updatedUser) => {
    // Handle user save logic here
    const selectedUser = userData.find(
      (user) => user.id === parseInt(updatedUser)
    ); // Ensure ID comparison as number
    setSelectedProfile(selectedUser);
    // You can use `updatedUser` to update the user data or make API calls
    setIsVerifyUserDialogOpen(false);
    toast.success("Usuario verificado correctamente");
  };

  useEffect(() => {
    console.log("selectedProfile", selectedProfile);
    console.log("selectedProfileId", selectedProfileId);
    
  }, [selectedProfile]);

  useEffect(() => {
    const updatedTableColumns = tableColumns.map((column) => {
      const columnLabel = columnHeadersWithLabels.find(
        (header) => header.value === column.id
      );
      return {
        ...column,
        label: columnLabel ? columnLabel.label : column.label,
      };
    });
  
    setTableColumns(updatedTableColumns);
    // console.log("Community useEffect tableColumns", updatedTableColumns);
  }, [tableColumns, columnHeadersWithLabels]);

  return (
    <div
      // key="community"
      // id="community"
      className={`relative z-0 grid 
        ${
          selectedProfile
            ? "md:grid-cols-[minmax(0,_2fr),_minmax(380px,_1fr)]"
            : "md:grid-cols-1"
        }
        grid-cols-1 grid-rows-1
       h-full w-full gap-4`}>
      <CommunityVerifyUser
        isOpen={isVerifyUserDialogOpen}
        onClose={() => setIsVerifyUserDialogOpen(false)}
        user={selectedProfile}
        onSave={handleVerifyUserSave}
      />
      <div
        className={` @container/cmusers ${
          selectedProfile ? "-translate-x-full md:translate-x-0" : ""
        } transition-transform col-span-full row-span-full md:col-span-1 flex flex-col w-full gap-2`}>
        <div className="flex-row items-center justify-between w-full z-20 top-0 flex  px-px pt-px gap-x-4 gap-y-2">
          <Input
          variant="outline"
            type="search"
            placeholder="Filtrar por nombre, apellido, equipo..."
            onChange={(e) => setSearchTerm(e.target.value)}
            containerClassName="w-full max-w-sm focus-within:absolute @xl/cmusers:focus-within:relative focus-within:top-px @xl/cmusers:focus-within:top-0 focus-within:left-px @xl/cmusers:focus-within:left-0 focus-within:z-10 focus-within:w-[calc(100%_-_2px)] focus-within:max-w-full @xl/cmusers:focus-within:max-w-sm"
            className="w-8 focus:w-full px-1 @xl/cmusers:pr-2 @xl/cmusers:w-full"
          />
          <div className="flex gap-x-2">
            {/* HERE: Create new user */}
            <CreateUser />
            {viewType === "list" && (
              <ColumnFilterDropdown columns={tableColumns} />
            )}
            <ButtonGroup className="flex justify-center">
              {viewOptions.map((option, index) => (
                <Button
                  key={option.value}
                  className={`px-3 h-9 ${
                    index === 0
                      ? "rounded-r-none border border-r"
                      : index === viewOptions.length - 1
                      ? "rounded-l-none border border-l-0 "
                      : "rounded-none border border-l-0 border-r"
                  } ${
                    viewType === option.value
                      ? "dark:text-black"
                      : "dark:text-white"
                  } `}
                  variant={viewType === option.value ? "default" : "outline"}
                  onClick={() => handleViewChange(option.value)}>
                  <Icon icon={option.icon} />
                </Button>
              ))}
            </ButtonGroup>
          </div>
        </div>

        <div
          className={`${
            viewType === "grid" ? "overflow-y-scroll custom-scrollbar" : ""
          }  flex relative  h-[calc(100%_-_3rem)]  gap-4 w-full flex-col`}>
          {viewType === "grid" ? (
            <ScrollArea
              orientation="vertical"
              className="h-full @container max-h-full ">
              {uniqueTeams.map((team) => (
                <div key={team} className="flex relative flex-col">
                  <span className="text-xs sticky top-0 pt-2 w-full bg-gray-50 dark:bg-gray-1000 z-10 pb-2 md:pb-4 uppercase text-gray-400">
                    {team !== "undefined" || team !== "" ? team : "Sin equipo"}
                  </span>
                  <div className="grid @md:grid-cols-2 z-0 @xl:grid-cols-3 @3xl:grid-cols-4 @5xl:grid-cols-5 @7xl:grid-cols-6 w-full gap-4">
                    {filteredUsers
                      .filter((user) => user.contactDetails.team === team)
                      .map((user) => (
                        <div
                          key={user.id}
                          className={`flex shrink-0 flex-col items-center gap-2 profile-view`}>
                          <UserProfile
                            className={
                              selectedProfile &&
                              user.id === selectedProfile.id &&
                              "!bg-gray-200 dark:!bg-gray-800"
                            }
                            userProfile={user}
                          />
                          <div className="w-full">
                            <Button
                              onClick={() => handleViewProfile(user.id)}
                              className="w-full">
                              Ver perfil
                            </Button>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </ScrollArea>
          ) : (
            <div className="h-full dashboard-widget p-1 md:p-2">
              <ResponsiveDataTable
                data={tableData}
                showHeaders={false}
                // filterFor="name"
                isEmptyMessage="Cargando usuarios..."
                columnsConfig={passedColumnsConfig}
                editUser={editUser}
                verifyUser={verifyUser} //handleUserToVerify
                verifyRole={verifyRole}
                deleteUser={deleteUser}
                // passedRowSelection={selectedProfileId}
                // setPassedRowSelection={setSelectedProfileId}
                // selectRow={handleSelectRow}
                setSelectedRow={setSelectedProfileId}
                enablePagination={false}
                enableRowSelection={true} // Enable row selection
                onRowSelectionChange={handleRowSelectionChange} // Pass the row selection handler
                singleRowSelection={true} // Enable single row selection
                setColumns={setTableColumns} // Pass the setColumns function
              />
            </div>
          )}
        </div>
      </div>
      {selectedProfile && (
        <div
          className={`w-full h-full ${
            selectedProfile ? "" : "translate-x-full md:translate-x-0"
          } transition-transform col-span-full  row-span-full md:col-span-1 max-w-xl pt-4 sticky top-0 flex justify-center items-start`}>
          <Button
            className="absolute top-1 p-1.5 h-auto left-4"
            onClick={() => setSelectedProfile(null)}>
            <CloseIcon className="size-4" />
          </Button>
          {storedUserProfile && storedUserProfile.role === "admin" ? (
            <ExtendedProfile
              key={selectedProfile.id}
              userProfile={selectedProfile}
              isEditable={true} // This needs to depend on if the user is a gestor
              isEditingCommunity={true} // Set to true if role is admin
              verifyUser={verifyUser}
              verifyRole={verifyRole}
            />
          ) : (
            <ExtendedProfile
              key={selectedProfile.id}
              userProfile={selectedProfileId}
              isEditable={false} // This needs to depend on if the user is a gestor
              isEditingCommunity={false} // Set to false otherwise
            />
          )}
        </div>
      )}
    </div>
    // </div>
  );
};

export default Community;
