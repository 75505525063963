import React from 'react';
import { ReactComponent as Community } from 'icons/community.svg';
import { ReactComponent as Home } from 'icons/home.svg';
import { ReactComponent as Analytics } from 'icons/analytics.svg';
import { ReactComponent as Calendar } from 'icons/events.svg';
import { ReactComponent as Maps } from 'icons/maps.svg';
import { ReactComponent as SettingsIcon } from "icons/settings.svg";
import { ReactComponent as ProfileIcon } from "icons/profile.svg";
import { ReactComponent as HelpIcon } from "icons/help.svg";
import { ReactComponent as FeedbackIcon } from "icons/feedback.svg";
import { ReactComponent as ParcelaIcon } from "icons/lists.svg";

// import { ReactComponent as Reports } from 'icons/reports.svg';
import { Icon } from '@iconify/react';

export const links = [
  {
    name: 'Home',
    // layoutName: 'complex', // this needs to be replaced with the userProfile.userSettings.dashboardLayout
    alternativeLayouts: ['regante', 'queue', 'simple', 'complex', 'queueWithMap', 'sorted', 'advanced', 'example-1'],
    menuNameSpa: 'Inicio',
    icon: <Home className='size-full' />,
  },
  {
    name: 'Analytics',
    layoutName: ['analytics', 'anExpanded'],
    alternativeLayouts: ['anExpanded'],
    menuNameSpa: 'Analíticas',
    icon: <Analytics className='size-full' />,
  },
  {
    name: 'Map',
    layoutName: 'maps',
    menuNameSpa: 'Mapa',
    icon: <Maps className='size-full' />,
  },
  {
    name: 'Calendar',
    layoutName: 'calendar',
    menuNameSpa: 'Calendario',
    icon: <Calendar className='size-full' />,
  },
  {
    name: 'Events',
    layoutName: "events",
    menuNameSpa: 'Eventos',
    icon: <Icon icon="mdi:event-edit" className="size-full" />
  },
  {
    name: 'Community',
    layoutName: 'community',
    menuNameSpa: 'Comunidad',
    icon: <Community className='size-full' />,
  },
  {
    name: "Settings",
    layoutName: "settings",
    menuNameSpa: "Ajustes",
    icon: <SettingsIcon className="size-full" />,
  },
  {
    name: "Profile",
    layoutName: "profile",
    menuNameSpa: "Perfil",
    icon: <ProfileIcon className="size-full" />,
  },
  {
    name: "Help",
    layoutName: "help",
    menuNameSpa: "Ayuda",
    icon: <HelpIcon className="size-full" />,
  },
  {
    name: "Feedback",
    layoutName: "feedback",
    menuNameSpa: "Feedback",
    icon: <FeedbackIcon className="size-full" />,
  },
  // {
  //   name: "Parcels",
  //   layoutName: "parcels",
  //   menuNameSpa: "Parcela",
  //   icon: <ParcelaIcon className="size-full" />,
  // },
  // {
  //   name: 'Reports',
  //   layoutName: 'reports',
  //   menuNameSpa: 'Reportes',
  //   icon: <Reports className='size-full' />,
  // },
];