export const frontLayouts = {
  "queue":
  {
    md: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 0, h: 0 },
      { i: "initialQueue", x: 0, y: 0, w: 12, h: 6 },
    ],
    xxs: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 0, h: 0 },
      { i: "initialQueue", x: 0, y: 0, w: 2, h: 6 },
    ],
  },
  "regante":
  {
    md: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 0, h: 0 },
      { i: "initialQueue", x: 0, y: 0, w: 12, h: 6 },
    ],
    xxs: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 0, h: 0 },
      { i: "initialQueue", x: 0, y: 0, w: 2, h: 6 },
    ],
  },
  "queueWithMap":
  {
    md: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 0, h: 0 },
      { i: "initialQueue", x: 0, y: 0, w: 12, h: 6 },
    ],
    xxs: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 6, w: 2, h: 3 },
      { i: "initialQueue", x: 0, y: 0, w: 2, h: 3 },
    ],
  },

  // Agricultores
  "simple":
  {
    md: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 6, h: 6 },
      // { i: "initialDetails", x: 0, y: 0, w: 6, h: 6 },
      { i: "initialQueue", x: 6, y: 0, w: 6, h: 6, minW: 3, minH: 2 },
      // { i: "initialLineChart", x: 0, y: 3, w: 6, h: 3, minW: 2, minH: 2 },
    ],
    xxs: [
      { i: "initialQueue", x: 0, y: 0, w: 2, h: 6 },
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 6, w: 0, h: 0 },
      // { i: "initialDetails", x: 0, y: 0, w: 4, h: 3 },
      // { i: "initialLineChart", x: 0, y: 0, w: 4, h: 6 },
    ],
  },
  "sorted":
  {
    md: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 0, h: 0 },

      { i: "initialSortedItems1", x: 0, y: 0, w: 6, h: 6 },
      { i: "initialSortedItems2", x: 6, y: 0, w: 6, h: 3, minW: 2, minH: 1 },
      { i: "initialSortedItems3", x: 6, y: 3, w: 6, h: 3, minW: 2, minH: 1 },
    ],
    xxs: [
      { i: "initialSortedItems1", x: 0, y: 0, w: 2, h: 3 },
      { i: "initialSortedItems2", x: 0, y: 2, w: 2, h: 3 },
      { i: "initialSortedItems3", x: 0, y: 4, w: 2, h: 3 },
    ],
  },
  "complex":
  {
    md: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 4, h: 6 },
      { i: "initialLineChart", x: 4, y: 0, w: 8, h: 3, minW: 2, minH: 2 },
      { i: "initialBarChart", x: 4, y: 3, w: 4, h: 3, minW: 2, minH: 2 },
      { i: "initialTable", x: 8, y: 3, w: 4, h: 3, minW: 2, minH: 2 },
    ],
    xxs: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 2, h: 3 },
      { i: "initialBarChart", x: 0, y: 2, w: 2, h: 3 },
      { i: "initialLineChart", x: 0, y: 4, w: 2, h: 4 },
      { i: "initialTable", x: 0, y: 8, w: 2, h: 4 },
    ],
  },
  "advanced":
  {
    md: [
      { i: "mapGis", x: 0, y: 2, w: 6, h: 4, minW: 0, minH: 0 },
      { i: "initialTable", x: 0, y: 0, w: 6, h: 2 },
      { i: "initialLineChart", x: 6, y: 1, w: 6, h: 3, minW: 2, minH: 2 },
      { i: "initialDetails", x: 6, y: 0, w: 6, h: 1, minW: 2, minH: 1 },
      { i: "initialBarChart", x: 6, y: 5, w: 3, h: 2, minW: 2, minH: 2 },
      { i: "initialCalendar", x: 9, y: 5, w: 3, h: 2, minW: 2, minH: 2 },
    ],
    xxs: [
      { i: "mapGis", x: 0, y: 16, w: 2, h: 3, minW: 0, minH: 0 },
      { i: "initialDetails", x: 0, y: 0, w: 2, h: 2 },
      { i: "initialBarChart", x: 0, y: 2, w: 2, h: 3 },
      { i: "initialLineChart", x: 0, y: 4, w: 2, h: 3 },

      { i: "initialTable", x: 0, y: 8, w: 2, h: 3 },
      { i: "initialCalendar", x: 0, y: 24, w: 2, h: 4 },
    ],
  },
  "example-1":
  {
    md: [
      { i: "mapGis", minW: 0, minH: 0, x: 8, y: 3, w: 4, h: 3 },
      { i: "initialDetails", x: 0, y: 0, w: 5, h: 1 },
      { i: "initialGaugeChart", x: 0, y: 0, w: 5, h: 2, minW: 3, minH: 2 },
      { i: "initialLineChart", x: 0, y: 3, w: 5, h: 3, minW: 2, minH: 2 },
      { i: "initialBarChart", x: 5, y: 3, w: 3, h: 3 },
      { i: "initialTable", x: 8, y: 0, w: 4, h: 3, minW: 2, minH: 2 },
      // { i: "test", x: 8, y: 0, w: 4, h: 3, minW: 2, minH: 2 },
      { i: "initialCalendar", x: 5, y: 0, w: 3, h: 3 },

    ],
    xxs: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 8, w: 2, h: 3 },
      { i: "initialDetails", x: 0, y: 0, w: 2, h: 3 },
      { i: "initialBarChart", x: 0, y: 2, w: 2, h: 3 },
      { i: "initialLineChart", x: 0, y: 4, w: 2, h: 3 },
      { i: "initialTable", x: 0, y: 6, w: 2, h: 3 },
      { i: "initialGaugeChart", x: 0, y: 11, w: 2, h: 3, minW: 2, minH: 2 },
      { i: "initialCalendar", x: 0, y: 14, w: 2, h: 3 },

    ],
  },
  "maps":
  {
    md: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 12, h: 6 },

    ],
    xxs: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 2, h: 3 },

    ],
  },
  "community":
  {
    md: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 0, h: 0 },
      { i: "community", x: 0, y: 0, w: 12, h: 6 },
    ],
    xxs: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 0, h: 0 },
      { i: "community", x: 0, y: 0, w: 2, h: 6 },
    ],
  },
  "analytics":
  {
    md: [
      { i: "mapGis", minW: 0, minH: 0, x: 4, y: 2, w: 8, h: 4 },
      { i: "analytics-data-table", x: 0, y: 2, w: 4, h: 6 },
      { i: "analytics-data-table-2", x: 4, y: 0, w: 8, h: 1 },
      { i: "analytics-lineChart", x: 4, y: 4, w: 8, h: 1 },

    ],
    xxs: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 3, w: 2, h: 3 },
      { i: "analytics-data-table", x: 0, y: 0, w: 2, h: 3 },
      { i: "analytics-data-table-2", x: 0, y: 6, w: 2, h: 3 },
      { i: "analytics-lineChart", x: 0, y: 9, w: 2, h: 3 },

    ],
  },
  "anExpanded":
  {
    md: [
      { i: "mapGis", minW: 0, minH: 0, x: 4, y: 2, w: 8, h: 2 },
      { i: "analytics-data-table", x: 0, y: 2, w: 4, h: 6 },
      { i: "analytics-data-table-2", x: 4, y: 0, w: 8, h: 2 },
      { i: "analytics-lineChart", x: 4, y: 4, w: 8, h: 2 },

    ],
    xxs: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 2, h: 3 },
      { i: "analytics-data-table", x: 0, y: 0, w: 2, h: 3 },
      { i: "analytics-data-table-2", x: 0, y: 3, w: 2, h: 3 },
      { i: "analytics-lineChart", x: 0, y: 4, w: 2, h: 6 },

    ],
  },
  "calendar":
  {
    md: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 0, h: 0 },
      { i: "eventsCalendar", x: 0, y: 0, w: 12, h: 6 },

    ],
    xxs: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 6, w: 0, h: 0 },
      { i: "eventsCalendar", x: 0, y: 0, w: 2, h: 6 },

    ],
  },
  "events":
  {
    md: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 0, h: 0 },
      { i: "eventsTable", x: 0, y: 0, w: 12, h: 6 },

    ],
    xxs: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 0, h: 0 },
      { i: "eventsTable", x: 0, y: 0, w: 2, h: 6 },

    ],
  },
  "parcels":
  {
    md: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 0, h: 0 },
      { i: "parcels", x: 0, y: 0, w: 12, h: 6 },

    ],
    xxs: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 0, h: 0 },
      { i: "parcels", x: 0, y: 0, w: 2, h: 6 },

    ],
  },
  "settings":
  {
    md: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 0, h: 0 },
      { i: "settings", x: 0, y: 0, w: 12, h: 6 },
    ],
    xxs: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 0, h: 0 },
      { i: "settings", x: 0, y: 0, w: 2, h: 6 },
    ],
  },
  "profile":
  {
    md: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 0, h: 0 },
      { i: "profile", x: 0, y: 0, w: 12, h: 6 },
    ],
    xxs: [
      { i: "mapGis", minW: 0, minH: 0, x: 0, y: 0, w: 0, h: 0 },
      { i: "profile", x: 0, y: 0, w: 2, h: 6 },
    ],
  },

}

