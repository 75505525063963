import PropTypes from "prop-types";
import React, { useState, useEffect, useMemo } from "react";
import { subDays } from "date-fns";
import { columnsWithLabels, eventOptions } from "data/translationArrays";
import { ReactComponent as CloseIcon } from "icons/close.svg";
import { urgencyColorClasses } from "helpers/urgencyColorClasses";
import { statusIcons } from "helpers/statusIcons";
import { filterAndTransformWidgetData } from "helpers/filterAndTransformWidgetData";
import { indicatorIcons } from "helpers/indicatorIcons";
import { formatTimeToRelative } from "helpers/formatTimeToRelative";
import AddMissingOptions from "components/widgets/widgetWrapper/AddMissingOptions";
import { useEventHandlers } from "contexts/EventHandlers";
import { render } from "@testing-library/react";
import { ReactComponent as WaterDropIcon } from "icons/water-drop.svg";
import { ReactComponent as InboxIcon } from "icons/inbox.svg";
import { ResponsiveDataTable } from "components/widgets/widgetTypes/tables/ResponsiveDataTable";

const ResponsiveTable = ({ data, options, columnsConfig, toggleDataSelector = () => {} }) => {
  const { fetchEventsFromDB } = useEventHandlers();
  const [fetchedData, setFetchedData] = useState([]);
  const [transformedData, setTransformedData] = useState([]);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null); // New state for tracking hovered row index

  const requiredOptions = ["city", "location", "columns"];

  const transformEventData = (events) => {
    return events.map((event) => ({
      message: event.title,
      location: event.parcela || event.location, // Check both possible property names
      updated: event.start,
      urgency: event.urgency ? parseInt(event.urgency, 10) : null,
      assigned: event.assigned,
      status: event.status || "default",
      type:
        eventOptions.find((option) => option.value === event.type)?.label ||
        event.type,
    }));
  };

  function transformData(data, options) {
    const {
      city,
      location,
      startDate: startDateOpt,
      endDate: endDateOpt,
    } = options;

    /* console.log("options in transformData: ", options);
    console.log("data in transformData: ", data); */
    const endDate = endDateOpt || new Date(); // Current time if endDate is not provided
    const startDate = startDateOpt || subDays(new Date(endDate), 30); // Start of the previous day if startDate is not provided

    // Filter devices based on the city and specific location
    const filteredDevices = data.filter(
      (device) =>
        device.location.city === city &&
        device.location.specificLocation === location
      // &&
      // new Date(device.timeFrame.start) <= new Date(endDate) &&
      // new Date(device.timeFrame.end) >= new Date(startDate)
    );

    // Create a combined data array from notifications and other columns specified
    const combinedData = filteredDevices.flatMap((device) =>
      device.notifications.map((notification) => ({
        message: notification.message,
        location: device.title,
        updated: notification.date,
        urgency: notification.urgency,
        assigned: notification.assigned,
        status: notification.status,
      }))
    );

    return combinedData;
  }

  const combineFetchedAndExistingData = (fetchedData, existingData) => {
    return [
      ...transformEventData(fetchedData),
      ...transformData(existingData, options),
    ];
  };

  useEffect(() => {
    const fetchAndSetData = async () => {
      const eventsFromDB = await fetchEventsFromDB();
      /* console.log("Fetched Events from DB:", eventsFromDB); */

      const initialTransformedData = filterAndTransformWidgetData(
        { data, options },
        requiredOptions,
        transformData
      );

      const combinedData = combineFetchedAndExistingData(
        eventsFromDB,
        initialTransformedData
      );

      setTransformedData(combinedData || []);
    };

    fetchAndSetData();
  }, [data, options]); // Recalculate when data or options change

  const handleDeleteRow = (indexToDelete) => {
    setTransformedData((currentData) =>
      currentData.map((entry, index) => {
        // Assuming you're only working with the first entry in transformedData for display
        if (index === 0) {
          return {
            ...entry,
            data: entry.data.filter(
              (_, itemIndex) => itemIndex !== indexToDelete
            ),
          };
        }
        return entry;
      })
    );
  };

  if (!transformedData || transformedData.length === 0) {
    return (
      <AddMissingOptions
        data={data}
        toggleDataSelector={toggleDataSelector}
        requiredOptions={requiredOptions}
        options={options}
      />
    );
  }

  const tableData = transformedData[0]; // Assuming the first entry is what you're displaying
  const columnKeys = options.columns; // Use columns from options for headers

  const columnKeysWithLabels = columnsWithLabels.filter((column) =>
    columnKeys.includes(column.key)
  );

  const renderIconFromMessage = (message) => {
    if (!message) return null; // Ensure message is present

    const IconComponent = indicatorIcons.find((iconObject) =>
      iconObject.keywords.some((keyword) =>
        message.toLowerCase().includes(keyword)
      )
    )?.icon;

    return IconComponent ? <IconComponent className="h-6 w-6" /> : null;
  };

  // Render status with icon
  const renderStatus = (status) => {
    const statusInfo = statusIcons.find((iconObject) =>
      iconObject.keywords.some(
        (keyword) => keyword.toLowerCase() === status.toLowerCase()
      )
    );
    const StatusIcon = statusInfo?.icon;

    return (
      <div className="flex gap-x-2 items-center dark:text-white text-black">
        {StatusIcon ? (
          <StatusIcon className="h-4 w-4 shrink-0 text-current" />
        ) : null}
        <span>{status}</span>
      </div>
    );
  };

  const renderUrgency = (urgency) => {
    const urgencyInfo = urgencyColorClasses.find(
      (colorObj) => colorObj.value === urgency
    );

    const urgencyClass = urgencyInfo ? urgencyInfo.className : "";
    return (
      <span className={`capitalize font-semibold ${urgencyClass}`}>
        {urgencyInfo ? urgencyInfo.label : urgency}
      </span>
    );
  };

  const renderUrgencyClass = (urgency) => {
    const urgencyInfo = urgencyColorClasses.find(
      (colorObj) => colorObj.value === urgency
    );
    return urgencyInfo ? urgencyInfo.className : "default-class";
  };

  const gridTemplateColumns = `repeat(${options.columns.length}, auto)`; // Defines a repeat function for as many columns as specified in options
  const gridColumnSpan = `span ${options.columns.length}`;
  const gridBodySpan = `span ${options.columns.length}`;

  return (
    <div className="h-full overflow-hidden">
      {transformedData.length === 0 ? (
        <div>No data available</div>
      ) : (
        <div className="h-full flex flex-col gap-y-2 overflow-hidden">
          {/* Added overflow-hidden to contain the scrollbar within the component */}
          <div className="flex flex-none flex-col">

            <h4 className="px-2 pt-2">{tableData.title || "Eventos"}</h4>
            <span className="px-2 text-xs text-green-500 dark:text-green-400">
              {tableData.location}
            </span>
          </div>
          <div className="flex-auto overflow-auto custom-scrollbar">
            <div className="block @xl:hidden">
              {transformedData.map((item, index) => (
                // console.log("item in eventsTable", item),
                <div
                  key={index}
                  className="grid w-full relative grid-cols-[minmax(auto,_2rem),auto,1fr] gap-2 p-2 items-center border-b border-gray-200 dark:border-gray-700"
                  onMouseEnter={() => setHoveredRowIndex(index)}
                  onMouseLeave={() => setHoveredRowIndex(null)}>
                  <div className="flex justify-center ">
                    {item.type === "Mantenimiento" ? (
                      <InboxIcon className="size-7" />
                    ) : item.type === "Turno de Riego" ? (
                      <WaterDropIcon className="size-7 text-blue-500" />
                    ) : (
                      renderIconFromMessage(item.message)
                    )}
                  </div>
                  <div
                    className={`text-xs md:text-sm bg-current w-1 h-full ${renderUrgencyClass(
                      item.urgency
                    )}`}></div>
                  <div className="col-span-1 md:col-span-1">
                    <div className="text-xs font-medium text-gray-500 dark:text-gray-200 md:text-sm">
                      <span className="capitalize">{item.message} </span>
                      {item.location && <>en {item.location}</>}
                    </div>
                    <div className="text-xs flex items-center justify-between md:text-xs">
                      <span>{item.status !== "default" && renderStatus(item.status)}</span>
                      <span className="text-gray-500 ml-auto text-xs">
                        {formatTimeToRelative(item.updated)}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div 
            className="hidden h-full @xl:block">
            <ResponsiveDataTable
            tableBodyClass={"border-0"}
        data={transformedData}
        columnsConfig={columnsConfig}
        showHeaders={false}
        enablePagination={false}
        // isEmptyMessage={selectedToggleOptions.length > 0 ? "Cargando eventos..." : "De-Seleccione un filtro"}
        enableRowSelection={true} // Enable row selection
        singleRowSelection={true}
      />
              </div>

            {/* 
              <div
                style={{ gridTemplateColumns }}
                className="grid grid-flow-row auto-rows-min">
                <div
                  style={{ gridColumn: gridColumnSpan }}
                  className="sticky  grid grid-cols-subgrid  top-0 z-10 bg-white">
                  {columnKeysWithLabels.map((column) => (
                    <div
                      key={column.key}
                      className="px-3 py-2 border-b h-8 border-gray-500 dark:bg-gray-950 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      {column.label.charAt(0).toUpperCase() +
                        column.label.slice(1)}
                    </div>
                  ))}
                </div>

                {transformedData.map((item, index) => (
                  <React.Fragment key={`${index}-row-fragment`}>
                    <div
                      onMouseEnter={() => setHoveredRowIndex(index)}
                      onMouseLeave={() => setHoveredRowIndex(null)}
                      className="grid-cols-subgrid grid relative"
                      style={{ gridColumn: gridBodySpan }}>
                      <React.Fragment>
                        {columnKeys.map((key, index) => (
                          <div
                            key={`${index}-col-fragment`}
                            className="px-3 py-2 whitespace-nowrap border-b border-gray-200 text-xs @md:text-sm">
                            {key === "urgency" ? (
                              renderUrgency(item[key])
                            ) : key === "status" ? (
                              renderStatus(item[key])
                            ) : key === "message" ? (
                              <span className="flex gap-x-2">
                                {renderIconFromMessage(item[key])}
                                <span className="text-xs font-medium text-gray-500 dark:text-gray-200 md:text-sm">
                                  {item[key]}
                                </span>
                              </span>
                            ) : key === "updated" ? (
                              <span className="text-xs font-medium text-gray-500 dark:text-gray-200 md:text-sm">
                                {new Date(item[key]).toLocaleDateString(
                                  "es-ES",
                                  {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "2-digit",
                                  }
                                )}
                              </span>
                            ) : (
                              item[key]
                            )}
                          </div>
                        ))}
                      </React.Fragment>
                    </div>
                  </React.Fragment>
                ))}
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

ResponsiveTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.shape({
        city: PropTypes.string,
        specificLocation: PropTypes.string,
      }),
      timeFrame: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
      }),
      notifications: PropTypes.arrayOf(
        PropTypes.shape({
          message: PropTypes.string,
          date: PropTypes.string,
          urgency: PropTypes.string,
          assigned: PropTypes.string,
          status: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  options: PropTypes.shape({
    city: PropTypes.string,
    location: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.string),
    endDate: PropTypes.string,
    startDate: PropTypes.string,
  }).isRequired,
};

export default ResponsiveTable;
