import * as React from "react";
import { cn } from "lib/utils";
// import { Check, X, ChevronsUpDown } from "lucide-react";
import { Button } from "components/ui/button";
import {
  Command,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { Badge } from "components/ui/badge";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { Icon } from "@iconify/react";

function MultiSelect({
  options,
  selected,
  enableSearch = true,
  onChange,
  className,
  selectAll,
}) {
  const [open, setOpen] = React.useState(false);
  const [allSelected, setAllSelected] = React.useState(false);

  React.useEffect(() => {
    if (selectAll) {
      if (selected.length === options.length) {
        setAllSelected(true);
      } else {
        setAllSelected(false);
      }
    }
  }, [selectAll, selected, options]);

  // console.log("XA selected in MultiSelect ", selected);
  // console.log("XA Available options in MultiSelect ", options);

  // Example of adjusting the MultiSelect component to handle object selections
  const handleSelect = (option) => {
    const isSelected = selected.some(item => item.value === option.value);
    const newSelected = isSelected
        ? selected.filter(item => item.value !== option.value)
        : [...selected, { value: option.value, label: option.label }];
    onChange(newSelected);
};

const handleUnselect = (item) => {
    onChange(selected.filter(s => s.value !== item.value));
};



const handleSelectAll = () => {
  if (allSelected) {
      onChange([]);
  } else {
      onChange(options.map(opt => ({ value: opt.value, label: opt.label })));
  }
  setAllSelected(!allSelected);
};


  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={`w-full justify-between pl-2 pr-3 ${selected.length > 1 ? "h-full" : "h-8"}`}
          onClick={() => setOpen(!open)}>
          <div className="flex gap-1 flex-wrap">
            {selected.map((item) => (
              <Badge key={item.value} 
              >
                {item.label}
                <Button asChild
                  variant="ghost"
                  className="ml-1 p-0 h-fit !bg-transparent ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                  onMouseDown={(e) => {
                    e.preventDefault(); // prevent button from stealing focus from the input
                    handleUnselect(item);
                  }}>
                  <Icon icon="material-symbols:close" className="h-3 w-3 text-muted-foreground hover:text-foreground"/>
                </Button>
              </Badge>
            ))}
          </div>
          <CaretSortIcon className="h-4 w-4 opacity-50 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={`p-0 ${className}`}>
        <Command>
          <CommandList>
            {enableSearch && (
              <>
              <CommandInput placeholder="Search ..." />
            <CommandEmpty>No item found.</CommandEmpty>
              </>
            )}
            <CommandGroup className="max-h-64 overflow-auto">
              <CommandItem onSelect={handleSelectAll}>
                {allSelected ? "Unselect All" : "Select All"}
              </CommandItem>
              {options.map((option) => (
                <CommandItem key={option.value} onSelect={() => handleSelect(option)}>
                <Icon
                    icon="material-symbols:check"
                    className={cn("mr-2 h-4 w-4", selected.some(item => item.value === option.value) ? "opacity-100" : "opacity-0")}
                  />
                {option.label}
              </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export { MultiSelect };
