import * as React from "react"
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons"
import { DayPicker } from "react-day-picker"

import { cn } from "lib/utils"
import { buttonVariants } from "components/ui/button"

function Calendar({
  className,
  classNames,
  events = [], // show the events on the calendar
  showOutsideDays = true,
  ...props
}) {

  const eventDates = events.map(event => new Date(event.time));
  const modifiers = {
    highlighted: eventDates,
  };

  // Using modifiersClassNames to apply Tailwind classes
  const modifiersClassNames = {
    highlighted: cn(
      "bg-green-600 !text-gray-50", // Normal background
      "hover:bg-green-300", // Hover state
      "focus:bg-green-400", // Focus state
      "text-green-800" // Text color
    ),
    // You can define more modifier classes here if needed
  };


  return (
    (<DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      modifiers={modifiers}
      modifiersClassNames={modifiersClassNames}
      classNames={{

        months: "flex flex-col sm:flex-row h-full space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4 w-full",
        caption: "grid grid-cols-[auto,_1fr] gap-x-1 grid-rows-1 justify-center pt-1.5 relative items-center",
        caption_label: "col-start-2 group-hover:pr-8 transition-all text-right text-sm font-medium",
        nav: "space-x-0.5 col-start-1 row-start-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "h-5 w-5 bg-transparent p-0 opacity-50 hover:opacity-100"
        ),
        nav_button_previous: "",
        nav_button_next: "",
        table: "w-full h-full flex flex-col border-collapse space-y-1",
        head_row: "flex w-full border-b-gray-100 border-b-2",
        head_cell:
          "text-gray-500  w-full rounded-full w-full  font-normal text-[0.8rem] dark:text-gray-400",
        row: "flex w-full my-1 ",
        cell: cn(
          "relative p-0 w-full  @md:[@container(max-aspect-ratio:2_/_1)]:h-full text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:  [&:has([aria-se lected].day-outside)]:bg-gray-100/50 [&:has([aria-selected].day-range-end)]:rounded-r-full  dark:[&:has([aria-selected].day-outside)]:bg-gray-800/50",
          props.mode === "range"
            ? "[&:has(>.day-range-end)]:rounded-r-full [&:has(>.day-range-end)]:bg-gray-100 [&:has([aria-selected])]:bg-gray-100 [&:has(>.day-range-start)]:bg-gray-100 [&:has(>.day-range-start)]:rounded-l-full first:[&:has([aria-selected])]:rounded-l-full last:[&:has([aria-selected])]:rounded-r-full"
            : "[&:has([aria-selected])]:rounded-full"
        ),
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-5 w-5 @xxxs:h-6 @xxxs:w-6 @sm:h-7 @sm:w-7 @lg:h-8 @lg:w-8 p-0 text-xs @sm:text-sm rounded-full font-normal aria-selected:opacity-100",
          
        ),
        day_range_start: "day-range-start",
        day_range_end: "day-range-end",
        day_selected:
          "bg-gray-900 text-gray-50 hover:bg-gray-900 hover:text-gray-50 focus:bg-gray-900 focus:text-gray-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50 dark:hover:text-gray-900 dark:focus:bg-gray-50 dark:focus:text-gray-900",
        day_today: "bg-gray-200 text-gray-50 dark:bg-gray-800 dark:!text-gray-100",
        day_outside:
          "day-outside text-gray-500 opacity-50  aria-selected:bg-gray-100/50 aria-selected:!text-gray-500 aria-selected:opacity-30 dark:text-gray-400 dark:aria-selected:bg-gray-800/50 dark:aria-selected:text-gray-400",
        day_disabled: "text-gray-500 opacity-50 dark:text-gray-400",
        day_range_middle:
          "aria-selected:bg-gray-100 aria-selected:text-gray-900 dark:aria-selected:bg-gray-800 dark:aria-selected:text-gray-50",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <ChevronLeftIcon className="h-4 w-4" />,
        IconRight: ({ ...props }) => <ChevronRightIcon className="h-4 w-4" />,
      }}
      {...props}
      captionLayout="dropdown"

       />)
  );
}
Calendar.displayName = "Calendar"

export { Calendar }
