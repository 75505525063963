import React from 'react';

import ResponsiveBarChart from 'components/widgets/widgetTypes/charts/BarChart';
// import ResponsiveLineChart from 'components/widgets/widgetTypes/charts/LineChart';
import DetailChart from 'components/widgets/widgetTypes/details/DetailChart';
import DetailBarChart from 'components/widgets/widgetTypes/details/DetailBarChart';
import ResponsiveCalendar from 'components/widgets/widgetTypes/calendar/SmallCalendar';
// import ResponsiveMap from 'components/widgets/widgetTypes/maps/Map';
import ResponsiveTable from 'components/widgets/widgetTypes/tables/ResponsiveTable';
import ResponsiveGaugeChart from 'components/widgets/widgetTypes/gauge/GaugeChart';
import ResponsiveSelectorWidget from 'components/widgets/widgetWrapper/SelectorWidget';
import QueueWidget from 'components/widgets/queue/QueueWidget';
// TODO: Fix circular dependency
import { Community, Profile, FullCalendar, EventsTable, MapGis, 
    AnalyticsLineChart, AnalyticsSensorTable, ComparativeDataTable, UserSettings, Parcels } from 'pages';
import LineChartWrapper from 'components/widgets/widgetTypes/charts/LineChartWrapper';

    /**
 * WidgetContent component renders the appropriate widget based on the type prop.
 * It passes the data and options props to the rendered widget.
 * 
 * @param {Object} props - The component props.
 * @param {string} props.type - The type of the widget to render.
 * @param {Array} props.data - The data for the widget.
 * @param {Object} props.options - The options for the widget.
 */

const WidgetContent = ({ id, 
    type, 
    data, 
    options, 
    onUpdateType, 
    toggleDataSelector, 
    filterFor, 
    filterForLabel, 
    columnsConfig, 
    showHeaderTitle,
    selectedRowIds,
onUpdateColumnsConfig,
    onRowSelectionChange }) => {

    switch (type) {
        case "queue": 
            return <QueueWidget 
                // events={events}
            />;
        case 'lineChart':
            return <LineChartWrapper 
                    selectedRowIds={selectedRowIds}
                    isAnalytics={false}
                    toggleDataSelector={toggleDataSelector}
                    data={data}
                    options={options}
                    showHeaderTitle={showHeaderTitle}
                    />;
        case 'analyticsLineChart':
            return <AnalyticsLineChart 
                    onRowSelectionChange={onRowSelectionChange}
                    selectedRowIds={selectedRowIds}
                    toggleDataSelector={toggleDataSelector}
                    data={data}
                    options={options} 
                    />;
        case 'barChart':
            return <ResponsiveBarChart toggleDataSelector={toggleDataSelector} data={data} options={options} />;
        case 'detailBarChart':
            return <DetailBarChart toggleDataSelector={toggleDataSelector} data={data} options={options} />;
        case 'details':
            return <DetailChart toggleDataSelector={toggleDataSelector} data={data} options={options} />;
        case 'calendar':
            return <ResponsiveCalendar toggleDataSelector={toggleDataSelector} data={data} options={options} />;
        case 'gaugeChart':
            return <ResponsiveGaugeChart toggleDataSelector={toggleDataSelector} data={data} options={options} />;
        case 'map':
            // return <ResponsiveMap toggleDataSelector={toggleDataSelector} data={data} options={options} />;
            // if hortatech
            return <MapGis onRowSelectionChange={onRowSelectionChange} selectedRowIds={selectedRowIds} toggleDataSelector={toggleDataSelector} data={data} options={options} />;
            // else 
            // return <OurGis onRowSelectionChange={onRowSelectionChange} selectedRowIds={selectedRowIds} toggleDataSelector={toggleDataSelector} data={data} options={options} />;

        case 'table':
            return <ResponsiveTable columnsConfig={columnsConfig} toggleDataSelector={toggleDataSelector} data={data} options={options} />;
        case 'interactiveTable':
            return <AnalyticsSensorTable showHeaders={false} toggleDataSelector={toggleDataSelector} data={data} options={options} columnsConfig={columnsConfig} filterFor={filterFor} filterForLabel={filterForLabel} onRowSelectionChange={onRowSelectionChange} />;
            // comparativeTable used in Analytics to compare sensor
        case 'comparativeTable':
            return <ComparativeDataTable selectedRowIds={selectedRowIds} toggleDataSelector={toggleDataSelector} data={data} options={options} columnsConfig={columnsConfig} onUpdateColumnsConfig={onUpdateColumnsConfig} />;
            // comparativeTable used in Analytics to select Sensors
        case 'filteredDataTable':
            return <EventsTable toggleDataSelector={toggleDataSelector} data={data} options={options} columnsConfig={columnsConfig} filterFor={filterFor} filterForLabel={filterForLabel} onRowSelectionChange={onRowSelectionChange} />;
        case 'communityUsers':
            return <Community  />;
        case 'fullCalendar':
            return <FullCalendar />;
        case 'parcels':
            return <Parcels />;
        case 'profile':
            return <Profile />;
        case 'settings':
            return <UserSettings />;
        case "selector":
            return <ResponsiveSelectorWidget id={id} onUpdateType={onUpdateType} />;
        default :
            return <ResponsiveSelectorWidget id={id} onUpdateType={onUpdateType} />;
        }
    };

export default WidgetContent;
