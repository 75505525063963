import React, { useState, useEffect } from "react";
import { Popover, PopoverTrigger, PopoverContent } from "components/ui/popover";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Checkbox } from "components/ui/checkbox";
import { Label } from "components/ui/label";
import { Icon } from "@iconify/react";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "components/ui/select";
import { DateTimePickerForm } from "components/ui/time-picker/date-time-picker-form";
import { ButtonGroup } from "@mui/material";
import axios from "axios";
import { maintenanceItemsWithLabels } from "data/translationArrays";

const AddTaskForm = ({ onAddTask, eventOptions }) => {
  const [task, setTask] = useState({
    nameTask: "",
    priority: null, // 1 - 5
    assignedUser: "",
    start: "",
    end: "",
    parcela: null,
    recurrence: false,
    allDay: true,
    daysOfWeek: [], // For weekly recurrence, store days as [1, 3, 5] for MWF
    type: "", // Default type
  });

  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState({});

  const [userData, setUserData] = useState([]);

  //TEST to fix error Encountered two children with the same key, null null. Keys should be unique so that components maintain their identity across updates. when selecting event type
  const transformUserData = (data) => {
    return data.map((user, index) => ({
      key: user.user_id || `user_${index}`, // Provide a unique fallback key
      label: user.first_name + " " + user.last_name || "",
      role: user.role_names || "",
    }));
  };

  const userTaskMappings = [
    { role: "admin", types: ["event", "notification", "queue"] },
    { role: "regante", types: ["queue"] },
    { role: "tecnico", types: ["event", "notification"] },
  ];

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/users/manageUsers?action=getUsers"
      );
      if (response.data.body) {
        const responseBody = JSON.parse(response.data.body);
        const transformedData = transformUserData(responseBody);
        setUserData(transformedData);
      } else {
        console.error("No body in response");
      }
    } catch (error) {
      console.error("Error fetching data from AWS Lambda:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  /* useEffect(() => {
    console.log("userData", userData);
  }, [userData]); */

  const handleTaskChange = (e) => {
    const { name, value } = e.target;
    setTask({
      ...task,
      [name]: value,
    });
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleCheckboxChange = () => {
    setTask((prevTask) => ({
      ...prevTask,
      recurrence: !prevTask.recurrence,
    }));
  };

  const handleTitleChange = (value) => {
    setTask({
      ...task,
      nameTask: value,
    });
    /* console.log("AddTaskForm handleTitleChange Title selected:", value); */
    if (errors.nameTask) {
      setErrors({ ...errors, nameTask: null });
    }
  };

  const handlePriorityChange = (value) => {
    const numericValue = Number(value); // Ensure the value is a number
    setTask((prevTask) => {
      const updatedTask = { ...prevTask, priority: numericValue };
      return updatedTask;
    });
    if (errors.priority) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        priority: null,
      }));
    }
    /* console.log(
      "AddTaskForm handlePriorityChange Priority selected:",
      numericValue
    ); */
  };

  const logUserFind = (assignedUser) => {
    // console.log("AddTaskForm Current users array in logUserFind:", userData);

    const foundUser = userData.find((u) => String(u.key) === assignedUser );
    // console.log("AddTaskForm task.assignedUser:", assignedUser);
    // console.log("AddTaskForm foundUser:", foundUser);
    return foundUser?.label || "Usuario no encontrado";
  };

  const logPriorityFind = (priority) => {
    //console.log("AddTaskForm Current priorities array in logPriorityFind:", priorities);
    const foundPriority = priorities.find((p) => p.value === priority);
    //console.log("AddTaskForm task.priority:", priority);
    //console.log("AddTaskForm foundPriority:", foundPriority);
    return foundPriority?.label || "Prioridad no encontrada";
  };

  //--------------------

  const handleTypeChange = (value) => {
    setTask({
      ...task,
      type: value,
    });
  };

  const handleAssignedUserChange = (value) => {
    setTask({
      ...task,
      assignedUser: value,
    });
    const assignedArray = task.assignedUser
        .split(",")
        .map((item) => item.trim());
    // console.log("AddTaskForm handleAssignedUserChange Assigned User selected:", logUserFind(value));
    // console.log("AddTaskForm handleAssignedUserChange Assigned User selected assignedArray:", assignedArray);
  };

  const handleDateTimeChange = (dateTime, type) => {
    setTask({
      ...task,
      [type]: dateTime,
    });
    if (errors[type]) {
      setErrors({ ...errors, [type]: null });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!task.nameTask) newErrors.nameTask = "El título es obligatorio.";
    if (!task.priority) newErrors.priority = "La prioridad es obligatoria.";
    if (!task.start) newErrors.start = "La hora de inicio es obligatoria.";
    if (!task.end) newErrors.end = "La hora de finalización es obligatoria.";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const assignedArray = task.assignedUser
        .split(",")
        .map((item) => item.trim());
      console.log("assignedArray", assignedArray);
      const updatedTask = { ...task, assigned: logUserFind(task.assignedUser) };
      onAddTask(updatedTask);
      setIsOpen(false); // Close the modal
    }
  };

  const handleDayOfWeekChange = (day) => {
    const scopedDaysOfWeek = [...task.daysOfWeek];
    const dayIndex = scopedDaysOfWeek.indexOf(day);
    if (dayIndex === -1) {
      scopedDaysOfWeek.push(day); // Add day
    } else {
      scopedDaysOfWeek.splice(dayIndex, 1); // Remove day
    }
    setTask({ ...task, daysOfWeek: scopedDaysOfWeek });
  };

  const daysOfWeek = [
    { value: 1, label: "Lu" },
    { value: 2, label: "Ma" },
    { value: 3, label: "Mi" },
    { value: 4, label: "Ju" },
    { value: 5, label: "Vi" },
    { value: 6, label: "Sa" },
    { value: 0, label: "Do" }, // Sunday as 0 to match JavaScript Date API
  ];

  const priorities = [
    { value: 1, label: "Bajo" },
    { value: 2, label: "Medio" },
    { value: 3, label: "Alto" },
    { value: 4, label: "Urgente" },
    { value: 5, label: "Crítico" },
  ];

  const filterUsersByRole = (type) => {
    return userData.filter((user) => {
      const userMapping = userTaskMappings.find((mapping) =>
        user.role.includes(mapping.role)
      );
      return userMapping && userMapping.types.includes(type);
    });
  };

  return (
    <Popover open={isOpen}>
      <PopoverTrigger
        asChild
        className="data-[state=open]:bg-gray-100 dark:data-[state=open]:text-gray-900">
        <Button
          onClick={() => {setIsOpen(!isOpen); setErrors({});}}
          variant="outline"
          className="p-1.5 px-2">
          <Icon icon="mdi:plus" className="size-5" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        onInteractOutside={(e) => {
          e.preventDefault();
          setIsOpen(false);
          setErrors({});
        }}
        className="w-fit overflow-y-auto">
        <div className="p-2">
          <h3 className="mb-3">Añadir fecha</h3>
          <form
            className="grid [&>label]:mt-2 [&>label]:h-6 [&>label]:block   *:flex grid-cols-[auto,_minmax(0,1fr)] gap-2"
            onSubmit={handleSubmit}>
            <Label htmlFor="type">Tipo</Label>
            <Select
              id="type"
              className="rounded-md border mt-2"
              value={task.type}
              onValueChange={handleTypeChange}>
              <SelectTrigger className="h-8">
                <SelectValue placeholder="Selecciona típo">
                  {task.type
                    ? eventOptions.find((p) => p.value === task.type)?.label
                    : "Selecciona típo"}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {eventOptions.map((eventType) => (
                  <SelectItem
                    key={`event_${eventType.value}`}
                    value={eventType.value}>
                    {eventType.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <Label htmlFor="nameTask">Titulo</Label>
            <div className="flex flex-col">
              {task.type && task.type !== "" && task.type !== "event" ? (
                <>
                  <Input
                    id="nameTask"
                    name="nameTask"
                    className="h-8"
                    placeholder="E.g. Riego de Agua"
                    value={task.nameTask}
                    onChange={handleTaskChange}
                  />
                </>
              ) : (
                <>
                  <Select
                    id="nameTask"
                    className="rounded-md border mt-2"
                    value={task.nameTask}
                    onValueChange={handleTitleChange}>
                    <SelectTrigger className="h-8">
                      <SelectValue placeholder="Selecciona Título">
                        {task.nameTask
                          ? maintenanceItemsWithLabels.find(
                              (p) => p.value === task.nameTask
                            )?.label
                          : "Selecciona Título"}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      {maintenanceItemsWithLabels.map((maintenanceItem) => (
                        <SelectItem
                          key={`maintenance_${maintenanceItem.value}`}
                          value={maintenanceItem.value}>
                          {maintenanceItem.label}
                          
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </>
              )}
              {errors.nameTask && (
                <p className="text-red-500 text-sm mr-auto">
                  {errors.nameTask}
                </p>
              )}
            </div>
            <Label htmlFor="priority">Prioridad</Label>
            <div className="flex-col">
              <Select
                id="priority"
                className="rounded-md border mt-2"
                value={task.priority}
                onValueChange={handlePriorityChange}>
                <SelectTrigger className="h-8">
                  <SelectValue placeholder="Selecciona prioridad">
                    {task.priority !== null && task.priority !== undefined
                      ? logPriorityFind(task.priority)
                      : "Selecciona prioridad"}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {priorities.map((priority) => (
                    <SelectItem key={priority.value} value={priority.value}>
                      {priority.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {errors.priority && (
                <p className="text-red-500 text-sm mr-auto">
                  {errors.priority}
                </p>
              )}
            </div>

            {task.type && task.type !== "" && (
              <>
                <Label htmlFor="assignedUser">Asignar Usuario</Label>
                <Select
                  id="assignedUser"
                  className="rounded-md border mt-2"
                  value={task.assignedUser}
                  onValueChange={handleAssignedUserChange}>
                  <SelectTrigger className="h-8">
                    <SelectValue placeholder="Selecciona usuario">
                    {task.assignedUser
                        ? logUserFind(task.assignedUser)
                        : "Selecciona usuario"}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Ninguno">Ninguno</SelectItem>
                    {filterUsersByRole(task.type).map((user) => (
                      <SelectItem key={user.key} value={user.key}>
                        {user.label}
                        
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </>
            )}

{/* // TODO Disabled for now */}
            {/* <Label htmlFor="recurrence" className="h-8">
              Repitir
            </Label>
            <div className="flex !items-start ">
              <Checkbox
                className="my-auto"
                id="recurrence"
                name="recurrence"
                checked={task.recurrence}
                onCheckedChange={handleCheckboxChange}
              />
              {task.recurrence && (
                <ButtonGroup className="flex ml-auto justify-center">
                  {daysOfWeek.map((day, index) => (
                    <Button
                      key={day.value}
                      className={`text-sm h-8 py-0 px-1.5 ${
                        index === 0
                          ? "rounded-r-none border border-r"
                          : index === daysOfWeek.length - 1
                          ? "rounded-l-none border border-l-0 "
                          : "rounded-none border border-l-0 border-r"
                      } ${
                        task.daysOfWeek.includes(day.value)
                          ? "dark:text-black"
                          : "dark:text-white"
                      } `}
                      variant={
                        task.daysOfWeek.includes(day.value)
                          ? "default"
                          : "outline"
                      }
                      onClick={() => handleDayOfWeekChange(day.value)}>
                      {day.label}
                    </Button>
                  ))}
                </ButtonGroup>
              )}
            </div> */}

            <Label htmlFor="start">Fecha Inicio</Label>
            <div className="flex-col">
              <DateTimePickerForm
                date={task.start}
                onDateTimeChange={(dateTime) =>
                  handleDateTimeChange(dateTime, "start")
                }
                type="start"
              />
              {errors.start && (
                <p className="text-red-500 text-sm mr-auto">{errors.start}</p>
              )}
            </div>
            <Label htmlFor="endTimeTask">Fecha Fin</Label>
            <div className="flex-col">
              <DateTimePickerForm
                date={task.end}
                onDateTimeChange={(dateTime) =>
                  handleDateTimeChange(dateTime, "end")
                }
                type="end"
              />
              {errors.end && (
                <p className="text-red-500 text-sm mr-auto">{errors.end}</p>
              )}
            </div>
            <Button type="submit" className="col-span-2 w-fit ml-auto mt-4">
              Añadir evento
            </Button>
          </form>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default AddTaskForm;
