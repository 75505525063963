export const columnsWithLabels = [
  { value: "message", label: "Mensaje" },
  { value: "location", label: "Ubicación" },
  { value: "updated", label: "Actualizado" },
  { value: "urgency", label: "Urgencia" },
  { value: "assigned", label: "Asignado" },
  { value: "status", label: "Estado" },
];

export const columnHeadersWithLabels = [
  { value: "image", label: "Imagen" },
  { value: "name", label: "Nombre" },
  { value: "lastName", label: "Apellidos" },
  { value: "email", label: "Correo Electrónico" },
  { value: "phone", label: "Teléfono" },
  { value: "role", label: "Rol" },
  { value: "status", label: "Estado" },
  { value: "team", label: "Equipo" },
  { value: "location", label: "Ubicación" },
  { value: "status", label: "Estado" },
  { value: "lastUpdate", label: "Última actualización" },
  { value: "actions", label: "Acciones" },
];

export const parametersWithLabels = [
  { value: "pressure", label: "Presión" },
  { value: "temperature", label: "Temperatura" },
  { value: "flow", label: "Caudal" },

];

export const selectorsWithLabels = [
  { value: "city", label: "Ciudad" },
  { value: "country", label: "Pais" },
  { value: "state", label: "Provincia" },
  { value: "valueType", label: "Parametro" },
  { value: "value", label: "Valor" },
  { value: "location", label: "Ubicación" },
  { value: "urgency", label: "Urgencia" },
  { value: "sensors", label: "Sensores" },
  { value: "date", label: "Fecha" },
  { value: "time", label: "Hora" },
  { value: "categories", label: "Categorias" },
  { value: "startTime", label: "Hora de inicio" },
  { value: "endTime", label: "Hora de fin" },
]

export const rolesWithLabels = [
  { value: "admin", label: "Gestor" },
  { value: "user", label: "Usuario" },
  { value: "gestor", label: "Gestor" },
  { value: "guest", label: "Invitado" },
  { value: "superuser", label: "Super Usuario" },
  { value: "regante", label: "Regante" },
  { value: "tecnico", label: "Técnico de campo" },
  // { value: "technician", label: "Técnico de campo" },
];

export const timeFrames = [
  { value: "latest", label: "Últimos datos" },
  { value: "last10Minutes", label: "10 minutos" },
  { value: "lastHour", label: "1 hora" },
  { value: "last3Hours", label: "3 horas" },
  { value: "last24Hours", label: "24 horas" },
  { value: "last3Days", label: "3 días" },
  { value: "last7Days", label: "7 días" },
  { value: "last30Days", label: "30 días" },
];


export const maintenanceItemsWithLabels = [
  { value: "construction", label: "Construcción" },
  { value: "robbery", label: "Robo" },
  { value: "overwater", label: "Superficie inundada" },
  { value: "leak", label: "Fuga" },
  { value: "maintenance", label: "Mantenimiento" },
  { value: "excavation", label: "Excavación" },
  { value: "highTemperature", label: "Temperatura alta" },
  { value: "lowTemperature", label: "Temperatura baja" },
  { value: "highPressure", label: "Presión alta" },
  { value: "lowPressure", label: "Presión baja" },

];

export const eventOptions = [
  { value: "event", label: "Mantenimiento", color: "#0073b7" },
  { value: "notification", label: "Alertas de Sensores", color: "#ff851b" },
  { value: "queue", label: "Turno de Riego", color: "#00a65a" },
];

// export const notificationsWithLabels = [

// ]
