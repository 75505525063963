// "use client"
import { Icon } from "@iconify/react";

import * as React from "react";
import {
  CaretSortIcon,
  ChevronDownIcon,
  DotsHorizontalIcon,
} from "@radix-ui/react-icons";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ScrollArea } from "components/ui/scroll-area";
import { Button } from "components/ui/button";
import { Checkbox } from "components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Input } from "components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import {
  Popover,
  PopoverContent,
  PopoverProvider,
  PopoverTrigger,
} from "components/ui/popover";
import ProfileImage from "components/ui/profileImage";
import { statusIcons } from "helpers/statusIcons";
import { urgencyColorClasses } from "helpers/urgencyColorClasses";
import { indicatorIcons } from "helpers/indicatorIcons";

const getCommonPinningClasses = (column) => {
  if (column.id === "actions" || column.id === "actionsEvents") {
    // Apply pinning only to the actions column
    return "sticky right-0 z-[1] bg-gradient-to-l from-white via-white to-transparent dark:from-gray-950 dark:via-gray-950 dark:to-transparent";
  }
  return "";
};

const generateColumns = (
  columnsConfig,
  {
    editUser,
    verifyUser,
    verifyRole,
    deleteUser,
    editEvent,
    deleteEvent,
    rowSelection,
    enableRowSelection,
    singleRowSelection,
    selectRow,
    handleRowClick,
  }
) => {
  return columnsConfig
  .filter((config) => config) //TEST to try to fix config is null error
  .map((config) => {
    switch (config.type) {
      case "select":
        return {
          id: config.id,
          header: ({ table }) => (
            <Checkbox
              checked={
                table.getIsAllPageRowsSelected() ||
                (table.getIsSomePageRowsSelected() && "indeterminate")
              }
              onCheckedChange={(value) =>
                table.toggleAllPageRowsSelected(!!value)
              }
              aria-label="Select all"
            />
          ),
          cell: ({ row }) => ( //Analiticas Select row
            <Checkbox
              checked={row.getIsSelected()}
              onCheckedChange={(value) => row.toggleSelected(!!value)}
              aria-label="Select row"
            />
          ),
          enableSorting: false,
          enableHiding: false,
        };
      case "image":
        return {
          id: config.id,
          accessorKey: config.accessorKey,
          header: "",
          enableResizing: false,
          size: config.size || 40,
          cell: ({ row }) => (
            <div className="flex w-fit items-center">
              <ProfileImage
                image={row.getValue(config.accessorKey).image}
                firstName={row.getValue(config.accessorKey).firstName}
                lastName={row.getValue(config.accessorKey).lastName}
                userName={row.getValue(config.accessorKey).userName}
                size="sm"
              />
            </div>
          ),
        };
      case "sortable":
        return {
          id: config.id,
          accessorKey: config.accessorKey,
          header: ({ column }) => (
            <Button
              variant="ghost"
              className="capitalize px-2"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }>
              {config.header}
              <CaretSortIcon className="ml-2 h-4 w-4" />
            </Button>
          ),
          cell: ({ row }) => {
            switch (config.format) {
              case "urgency":
                const urgencyValue = row.getValue(config.accessorKey);
                // console.log("urgencyValue", urgencyValue)
                const urgencyInfo = urgencyColorClasses.find(
                  (colorObj) => colorObj.value === urgencyValue
                );
                // Log the values for debugging
                //console.log("urgencyValue:", urgencyValue);
                //console.log("urgencyInfo:", urgencyInfo);

                const urgencyClass = urgencyInfo ? urgencyInfo.className : "";

                // Provide default values to avoid undefined errors
                const urgencyLabel = urgencyInfo ? urgencyInfo.label : "";

                /* return urgencyClass ? (
                  <span className={urgencyClass}>{urgencyLabel}</span>
                ) : (
                  <span>{urgencyInfo.label}</span>
                ); */
                return (
                  <span className={urgencyClass}>{urgencyLabel ? urgencyLabel : ''}</span>
                );
              case "status":
                const statusInfo = statusIcons.find((iconObject) =>
                  iconObject.keywords.some(
                    (keyword) =>
                      keyword.toLowerCase() ===
                      row.getValue(config.accessorKey).toLowerCase()
                  )
                );
                const StatusIcon = statusInfo?.icon;

                return (
                  <div className="flex gap-x-2 items-center">
                    {StatusIcon ? (
                      <StatusIcon className="h-4 w-4 text-current" />
                    ) : null}
                    <span>{row.getValue(config.accessorKey)}</span>
                  </div>
                );
              case "indicator":
                const IndicatorInfo = indicatorIcons.find((iconObject) =>
                  iconObject.keywords.some(
                    (keyword) =>
                      keyword.toLowerCase() ===
                      row.getValue(config.accessorKey).toLowerCase()
                  )
                );
                const IndicatorIcon = IndicatorInfo?.icon;

                return (
                  <div className="flex gap-x-2 items-center">
                    {IndicatorIcon ? (
                      <IndicatorIcon className="h-4 w-4 text-current" />
                    ) : null}
                    <span>{row.getValue(config.accessorKey)}</span>
                  </div>
                );
              case "date":
                return (
                  <span>
                    {new Date(
                      row.getValue(config.accessorKey)
                    ).toLocaleDateString("es-ES", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "2-digit",
                    })}
                  </span>
                );
              case "time":
                return (
                  <span>
                    {new Date(
                      row.getValue(config.accessorKey)
                    ).toLocaleDateString("es-ES", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                );
              default:
                return <span>{row.getValue(config.accessorKey)}</span>;
            }
          },
        };
      case "default":
        if (config.format === "currency") {
          return {
            id: config.id,
            accessorKey: config.accessorKey,
            header: () => <div className="text-right">{config.header}</div>,
            cell: ({ row }) => {
              const amount = parseFloat(row.getValue(config.accessorKey));
              const formatted = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(amount);
              return <div className="text-right font-medium">{formatted}</div>;
            },
          };
        } else if (config.format === "capitalize") {
          return {
            id: config.id,
            accessorKey: config.accessorKey,
            header: config.header,
            cell: ({ row }) => (
              <div className="capitalize">
                {row.getValue(config.accessorKey)}
              </div>
            ),
          };
        } else  {
          return {
            id: config.id,
            accessorKey: config.accessorKey,
            header: config.header,
            cell: ({ row }) => (
              <div className="">
                {row.getValue(config.accessorKey)}
              </div>
            ),
          };
        }

      case "button":
        return {
          id: config.id,
          accessorKey: config.accessorKey,
          header: config.header,
          cell: ({ row }) => (
            <DropdownMenu>
              <DropdownMenuTrigger asChild className="px-0 -translate-x-2.5">
                <Button variant="ghost" className="px-1 py-1 h-fit font-normal">
                  {row.getValue(config.accessorKey)}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent 
              onClick={(e) => {
                e.stopPropagation();
              }}
               className="w-fit bg-gray-50 dark:bg-gray-900 p-1.5 flex gap-1.5">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() =>
                    navigator.clipboard.writeText(
                      row.getValue(config.accessorKey)
                    )
                  }>
                  Copiar numero
                </Button>
                <Button asChild variant="outline" size="sm">
                  <a href={`tel:${row.getValue(config.accessorKey)}`}>Llamar</a>
                </Button>
              </DropdownMenuContent>
            </DropdownMenu>
          ),
        };
      /* Yo creo que 
        Nombre, 
        Apellidos, 
        rol, si tiene el rol verificado 
        (cuando el usuario se registra, solicita un rol y el admin se lo ha de dar), 
        si tiene la cuenta verificada, 
        tal vez profesion, 
        email. 
        Para el rol estaria bien que ademas de salir el rol que ha solicitado, el admin tenga la opcion de asignarle otro. Tal vez estaria bien que apareciera el rol que tiene temporalmente hasta que el admin se lo confirme. 
        Obviamente hasta que se lo asigne, sera guest. */
      case "actions":
        return {
          id: config.id,
          enableHiding: false,
          pin: "right", // Pinning the column to the right by default

          cell: ({ row }) => {
            const user = row.original;
            return (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" className="size-7 p-0">
                    <span className="sr-only">Open menu</span>
                    <DotsHorizontalIcon className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  align="end">
                  <DropdownMenuLabel>Acciones</DropdownMenuLabel>
                  {/* <DropdownMenuItem onClick={() => selectRow(row)}>View user</DropdownMenuItem> */}
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onClick={() => editUser(user.id)}>
                    Editar
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => verifyUser(user.id)}>
                    Verificar cuenta
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => verifyRole(user.id)}>
                    Verificar rol
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => deleteUser(user.id)}>
                    Borrar usuario
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            );
          },
        };
      case "actionsEvents":
        return {
          id: config.id,
          enableHiding: false,
          pin: "right", // Pinning the column to the right by default

          cell: ({ row }) => {
            const user = row.original;
            return (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" className="size-7 p-0">
                    <span className="sr-only">Open menu</span>
                    <DotsHorizontalIcon className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  align="end">
                  <DropdownMenuLabel>Acciones</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onClick={() => editEvent(user.id)}>
                    Editar
                  </DropdownMenuItem>
                  {/* <DropdownMenuItem onClick={() => deleteEvent(user.id)}>
                    Borrar evento
                  </DropdownMenuItem> */}
                </DropdownMenuContent>
              </DropdownMenu>
            );
          },
        };
      default:
        return {};
    }
  });
};

function ResponsiveDataTable({
  data = [], //data, 
  columnsConfig,
  enablePagination = false,
  showHeaders = true,
  filterFor = "",
  passedRowSelection = {},
  setPassedRowSelection = () => {},
  selectRow,
  setSelectedRow,
  editUser,
  verifyUser,
  verifyRole,
  deleteUser,
  editEvent,
  deleteEvent,
  isEmptyMessage,
  tableBodyClass,
  showLoadingAnimation = true,
  filterForLabel = "Ubicación",
  onRowSelectionChange = () => {}, // This callback function will be called with the selected row IDs
  enableRowSelection = false, // Optional prop for row selection
  singleRowSelection = false, // Optional prop for single row selection
  setColumns = () => {}, // Add this prop to pass the columns to the parent component

}) {
  /* console.log("ResponsiveDataTable data", data);
  console.log("ResponsiveDataTable selectRow", selectRow);
  console.log("ResponsiveDataTable selectRow", selectRow); */

  const [sorting, setSorting] = React.useState([]);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [rowSelection, setRowSelection] = React.useState(passedRowSelection || {});

  // React.useEffect(() => {
  //   console.log("rowSelection", rowSelection);
  //   console.log("passedRowSelection", passedRowSelection);
    
  // }, [passedRowSelection]);

  // const memoizedData = React.useMemo(() => {
  //   // console.log("Memoizing data");
  //   return data;
  // }, [data]);

  const memoizedColumns = React.useMemo(() => {
    // console.log("Memoizing columns");
    return generateColumns(columnsConfig, {
      editUser,
      verifyUser,
      verifyRole,
      deleteUser,
      selectRow,
      editEvent,
      deleteEvent,
    });
  }, [columnsConfig]); // Include dependencies here

  React.useEffect(() => {
    const selectedRowIds = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );
    //console.log("ResponsiveDataTable useEffect selectedRowIds: ",selectedRowIds);
    onRowSelectionChange(
      singleRowSelection ? selectedRowIds[0] : selectedRowIds
    );
  }, [rowSelection]);  

  //TEST to try to fix selectedProfile null 
  //Call the onRowSelectionChange function
  /* React.useEffect(() => {
    const selectedRowIds = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );
    //console.log("ResponsiveDataTable useEffect selectedRowIds: ",selectedRowIds);
    onRowSelectionChange(
      singleRowSelection ? selectedRowIds[0] : selectedRowIds
    );
  }, [rowSelection, onRowSelectionChange, singleRowSelection]); */

  const table = useReactTable({
    data: data,
    columns: memoizedColumns,
    autoResetPageIndex: false,
    autoResetExpanded: false,
    defaultColumn: {
      size: 40, //starting column size
      minSize: 40, //enforced during column resizing
      maxSize: 300, //enforced during column resizing
    },
    getSize: (column) => column.size,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: enablePagination
      ? getPaginationRowModel()
      : undefined,
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => row.id,
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    // verifyUser: verifyUser(),
    // deleteUser: deleteUser(),
    // editUser: editUser(),
    // selectRow: selectRow(),
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      // Only include pagination state if enabled
      ...(enablePagination ? { pageIndex: 0, pageSize: 10 } : {}),
    },
    manualPagination: !enablePagination, // This tells React Table you'll handle pagination externally
    pageCount: enablePagination ? undefined : -1, // -1 disables internal page count checks
  });

  React.useEffect(() => {
    setColumns(table.getAllColumns());
  }, [table, setColumns]);

  //TEST to try to fix originalRows is undefined error
  //Ensure data is properly initialized
  if (!data || data.length === 0) {
    // console.warn("ResponsiveDataTable received empty or undefined data.");
    return (
      <div className="w-full h-full flex items-center justify-center">
        
        {isEmptyMessage ? 
        <>
        <span className="inline-flex items-center gap-x-2">
        {showLoadingAnimation &&
          <Icon icon="svg-spinners:180-ring" className="text-black dark:text-white" /> 
        }
        {isEmptyMessage}
        </span>
        </>
         : "No data available."}
      </div>
    );
  }

  const handleRowClick = (row) => {
    //console.log("ResponsiveDataTable handleRowClick row: ",row);
    if (!enableRowSelection) return;

    if (singleRowSelection) {
      const newSelection = { [row.id]: !row.getIsSelected() };
      setRowSelection(newSelection);
      setSelectedRow && setSelectedRow(newSelection);
      console.log("select setSelectedRow in handleRowClick", newSelection);
    } else {
      row.toggleSelected(!row.getIsSelected());
    }
  };

  //TEST to try to fix selectedProfile null 
  //Handle row click to select a row
  /* const handleRowClick = (row) => {
    if (!enableRowSelection) return;
  
    const newSelection = singleRowSelection
      ? { [row.id]: !row.getIsSelected() }
      : { ...rowSelection, [row.id]: !row.getIsSelected() };
  
    setRowSelection(newSelection);
  }; */

  return (
    <div className="w-full h-full ">
      {showHeaders && filterFor !== "" && (
        <div className="flex gap-x-4 items-center py-4">
          <Input
            placeholder={`Filtrar ${filterForLabel}s...`}
            value={table.getColumn(filterFor)?.getFilterValue() ?? ""}
            onChange={(event) =>
              table.getColumn(filterFor)?.setFilterValue(event.target.value)
            }
            className="max-w-sm"
          />
          <DropdownMenu>
            <DropdownMenuTrigger className="" asChild>
              <Button variant="outline" className="ml-auto ">
                Columnas <ChevronDownIcon className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {table
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .map((column) => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className="capitalize"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) =>
                        column.toggleVisibility(!!value)
                      }>
                      {column.id}
                    </DropdownMenuCheckboxItem>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      )}
      <div
        className={`rounded-md ${tableBodyClass} border ${
          showHeaders && "max-h-[calc(100%_-_84px)]"
        } overflow-clip shr ink h-full  dark:border-gray-700`}>
        <ScrollArea orientation="both" className="h-full  max-h-full ">
          {/* <div className="overflow-y-scroll custom-scrollbar h-full max-h-full"> */}
          <Table >
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        key={header.id}
                        className={getCommonPinningClasses(header.column)}
                        // style={getCommonPinningStyles(header.column)}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody className=" relative h-fit">
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    onClick={() => handleRowClick(row)}>
                    {row.getVisibleCells().map((cell) => (
                      // console.log("cell", cell),
                      <TableCell
                        // style={getCommonPinningStyles(cell.column)}

                        // style={{ width: cell.column.getSize ? `${cell.column.getSize()}px` : undefined }}
                        className={`${getCommonPinningClasses(
                          cell.column
                        )} not-first:pl-4 whitespace-nowrap`}
                        key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columnsConfig.length}
                    className="h-24 text-center">
                      {isEmptyMessage ? isEmptyMessage : 
                      <span className="inline-flex w-fit gap-2 justify-center items-center">
                        <Icon icon="svg-spinners:180-ring" className="text-black dark:text-white" />
                      Cargando
                      </span>
                    }

                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {/* </div> */}
        </ScrollArea>
      </div>
      {enablePagination && (
        <div className="flex items-center justify-end space-x-2 py-4">
          <div className="flex-1 text-sm text-muted-foreground">
            {table.getFilteredSelectedRowModel().rows.length} of{" "}
            {table.getFilteredRowModel().rows.length} row(s) selected.
          </div>
          <div className="space-x-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}>
              Previous
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}>
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export { ResponsiveDataTable };
