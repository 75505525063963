// utility.js (This is a hypothetical file name. Place this utility in an appropriate file.)

/**
 * Filters and transforms widget data based on the required options and provided transformer function.
 * 
 * @param {Object} widget - The widget object containing data and options.
 * @param {Array} requiredOptions - The array of keys that must be present in the widget's options.
 * @param {Function} transformer - A function to transform the widget's data based on its options.
 * @returns {Object|null} - Transformed data if options are valid, otherwise null.
 */
export function filterAndTransformWidgetData(widget, requiredOptions, transformer) {
  // Validate the presence of the options object
  if (!widget.options) {
    console.warn(`Widget is missing the options object.`);
    return null;
  }
  const missingOptions = requiredOptions.filter(option => !(option in widget.options));

  if (missingOptions.length > 0) {
    console.warn(`Missing required options: ${missingOptions.join(', ')}`);
    return null;  // Optionally, you might still want to return null if any are missing
  }

  // Use the transformer function to process and return the widget's data
  const transformed = transformer(widget.data, widget.options);
  return transformed ? transformed : null;
}
