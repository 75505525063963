import { v4 as uuidv4 } from 'uuid';
// 25, 24, 26, 33, 34, 32
// this will be populated (together with "events" state from the eventHandlers)
export const eventData = [
  {
    event_id: 123,
    title: 'Cambio de sensor',
    user_id: 32,
    parcela: null,
    start: '2024-06-30T08:00:00Z',
    end: '2024-06-30T09:00:00Z',
    duration: null,
    urgency: 3,
    comments: ['Check water levels', 'Coordinate with team'],
    assigned: [12, 32],
    type: 'event'
    // 
  },
  {
    event_id: 451,
    title: null,
    user_id: 26,
    parcela: 1024,
    start: '2024-07-12T08:00:00Z',
    end: null,
    type: 'queue',
    duration: 60,
    urgency: null,
    assigned: null,
    comments: null,
    type: 'queue'
  },
  {
    event_id: uuidv4(),
    user_id: 34,
    parcela: 1025,
    start: '2024-07-12T09:00:00Z',
    duration: 60,
    end: null,
    type: 'queue',
    // end: '2024-07-12T10:00:00Z',
  },
  {
    event_id: uuidv4(),
    user_id: 24,
    parcela: 1026,
    start: '2024-07-12T10:00:00Z',
    duration: 60,
    end: null,
    type: 'queue',
    // end: '2024-07-12T11:00:00Z',
  },
  {
    event_id: uuidv4(),
    user_id: 'DavidIba',
    parcela: 1027,
    start: '2024-07-12T11:00:00Z',
    duration: 60,
    end: null,
    type: 'queue',
    // end: '2024-07-12T12:00:00Z',
  },
  {
    event_id: uuidv4(),
    user_id: 'EveIba',
    parcela: 1028,
    start: '2024-07-12T12:00:00Z',
    duration: 60,
    end: null,
    type: 'queue',
    // end: '2024-07-12T13:00:00Z',
  },
  {
    event_id: uuidv4(),
    user_id: 'FrankIba',
    parcela: 1029,
    start: '2024-07-12T13:00:00Z',
    duration: 60,
    end: null,
    type: 'queue',
    // end: '2024-07-12T14:00:00Z',
  },
  {
    event_id: uuidv4(),
    user_id: 'GraceIba',
    parcela: 1030,
    start: '2024-07-12T14:00:00Z',
    duration: 60,
    end: null,
    type: 'queue',
    // end: '2024-07-12T15:00:00Z',
  },
  {
    event_id: uuidv4(),
    user_id: 'HankIba',
    parcela: 1031,
    start: '2024-07-12T15:00:00Z',
    duration: 60,
    end: null,
    type: 'queue',
    // end: '2024-07-12T16:00:00Z',
  },
  {
    event_id: uuidv4(),
    user_id: 'HankIba',
    parcela: 1022,
    start: '2024-07-07T15:00:00Z',
    duration: 60,
    end: null,
    type: 'queue',
    // end: '2024-07-07T16:00:00Z',
  },
  {
    event_id: uuidv4(),
    user_id: 'HankIba',
    parcela: 1026,
    start: '2024-07-08T15:00:00Z',
    duration: 60,
    end: null,
    type: 'queue',
    // end: '2024-07-08T16:00:00Z',
  },
]

