// AccountSettings.js
import React, { useState, useEffect } from "react";
import { InputWithLabelAndError as Input } from "components/ui/InputWithLabelAndError"; // Assuming Input.js is in the same directory
import { useStateContext } from "contexts/ContextProvider";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import { Slider } from "components/ui/slider";
import axios from "axios";

const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const AccountSettings = () => {
  const { userProfile, setUserProfile } = useStateContext();
  const [deleteAccountOpen, setDeleteAccountOpen] = useState(false);

  const [formData, setFormData] = useState({
    email: userProfile.email,
    newEmail: "",
    newEmailConfirm: "",
    username: userProfile.username,
    password: "",
    newPassword: "",
    newPasswordConfirm: "",
    apiCallFrequency: userProfile.userSettings.apiCallFrequency,
  });
  const [sliderValue, setSliderValue] = useState(formData.apiCallFrequency); // Local state for slider

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");

  const sliderConfig = [
    { value: 10, actualValue: 0.1667, label: "10s" },
    { value: 20, actualValue: 0.5, label: "30s" },
    { value: 35, actualValue: 1, label: "1m" },
    { value: 65, actualValue: 5, label: "5m" },
    { value: 100, actualValue: 15, label: "15m" },
  ];
  const minValue = sliderConfig[0].value;
  const maxValue = sliderConfig[sliderConfig.length - 1].value;

  const handleSliderChange = (visualValue) => {
    const match = sliderConfig.find((item) => item.value === visualValue);
    if (match) {
      setSliderValue(visualValue);
      setFormData((prevState) => ({
        ...prevState,
        apiCallFrequency: match.actualValue,
      }));
    }
  };

  const calculateVisualPosition = (value, min, max) => {
    const normalized = (value - min) / (max - min);

    return normalized * 100;
  };

  useEffect(() => {
    saveChanges();
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDeleteAccount = () => {
    console.log("Deleting account");
    setDeleteAccountOpen(false);
  };

  const saveChanges = debounce(() => {
    console.log("Saving data:", formData);
  }, 100);

  const validateEmail = () => {
    let isValid = true;
    let errors = {};

    if (!formData.newEmail) {
      isValid = false;
      errors.newEmail = "El email es necesario";
    } else if (!/\S+@\S+\.\S+/.test(formData.newEmail)) {
      isValid = false;
      errors.newEmail = "El email no es válido";
    }

    if (!formData.newEmailConfirm) {
      isValid = false;
      errors.newEmailConfirm = "La confirmación de email es necesaria";
    }

    setErrors(errors);
    return isValid;
  };

  const validatePassword = () => {
    let isValid = true;
    let errors = {};

    if (!formData.newPassword) {
      isValid = false;
      errors.newPassword = "La contraseña es necesaria";
    } else if (formData.newPassword.length < 8) {
      isValid = false;
      errors.newPassword = "La contraseña debe tener al menos 8 caracteres";
    } else if (!/(?=.*[A-Z])(?=.*\d)/.test(formData.newPassword)) {
      isValid = false;
      errors.newPassword =
        "La contraseña debe contener al menos una letra mayúscula y un número";
    }

    if (!formData.newPasswordConfirm) {
      isValid = false;
      errors.newPasswordConfirm = "La confirmación de contraseña es necesaria";
    } else if (formData.newPassword !== formData.newPasswordConfirm) {
      isValid = false;
      errors.newPasswordConfirm = "Las contraseñas no coinciden.";
    }

    setErrors(errors);
    return isValid;
  };

  const handleSaveEmail = async () => {
    try {
      if (validateEmail()) {
        const userId = JSON.parse(sessionStorage.getItem("userId"));
        console.log("AccountSettings userId: ", userId);
        const response = await axios.post(
          "https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/users/profile?action=accountSettings",
          {
            userId: userId,
            email: formData.newEmail,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          setMessage("Email actualizado correctamente");

          const updatedProfile = {
            ...userProfile,
            email: formData.newEmail,
          };

          setUserProfile(updatedProfile);

          localStorage.setItem("userProfile", JSON.stringify(updatedProfile));
        } else {
          setMessage("Error actualizando email");
        }

        console.log("AccountSettings response: ", response);
      }
    } catch (error) {
      setMessage("Error actualizando email");
      console.error("Error updating account settings:", error);
    }
  };

  const handleSavePassword = async () => {
    try {
      if (validatePassword()) {
        const userId = JSON.parse(sessionStorage.getItem("userId"));
        console.log("AccountSettings userId: ", userId);

        const response = await axios.post(
          "https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/users/profile?action=accountSettings",
          {
            userId: userId,
            password: formData.newPassword,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          setMessage("Contraseña actualizada correctamente");

          const updatedProfile = {
            ...userProfile,
            password: formData.newPassword,
          };

          setUserProfile(updatedProfile);

          localStorage.setItem("userProfile", JSON.stringify(updatedProfile));
        } else {
          setMessage("Error actualizando contraseña");
        }

        console.log("AccountSettings response: ", response);
      }
    } catch (error) {
      setMessage("Error actualizando contraseña");
      console.error("Error updating account settings:", error);
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-[minmax(auto,_240px),_1fr] [&_label]:self-start [&_label]:pt-2 gap-x-4 gap-y-2 md:gap-y-4 items-center">
      <h2 className="col-span-1 md:col-span-2">Cuenta</h2>

      <h3 className="col-span-1 md:col-span-2">Funcionamiento</h3>
      <Label className="h-9 items-center flex">
        Frecuencia de llamadas API
      </Label>
      <div className="relative h-auto pt-2">
        <Slider
          name="apiCallFrequency"
          value={[sliderValue]}
          onValueChange={(values) => handleSliderChange(values[0])}
          min={sliderConfig[0].value}
          max={sliderConfig[sliderConfig.length - 1].value}
          step={1}
          values={sliderConfig.map((item) => ({
            value: item.value,
            label: item.label,
          }))}
        />
        <div className="ml-[7px] w-[calc(100%_-_16px)] relative mt-2.5">
          {sliderConfig.map((item) => (
            <div
              key={item.value}
              style={{
                left: `${calculateVisualPosition(
                  item.value,
                  minValue,
                  maxValue
                )}%`,
              }}
              className="absolute w-px flex flex-col justify-center items-center"
            >
              <span className="bg-gray-400 w-px h-2 mb-1"></span>
              <span className="text-xs text-gray-700 dark:text-gray-200">
                {item.label}
              </span>
            </div>
          ))}
        </div>
      </div>
      <h3 className="col-span-1 md:col-span-2 mt-6">Cambiar Clave</h3>
      <Label>Clave actual</Label>
      <Input
        type="password"
        name="password"
        placeholder="Tu clave actual"
        value={formData.password}
        error={errors.password}
        onChange={handleChange}
      />
      <Label>Nueva clave</Label>
      <Input
        type="password"
        name="newPassword"
        placeholder="Nueva clave"
        value={formData.newPassword}
        error={errors.newPassword}
        onChange={handleChange}
      />
      {formData.newPassword && (
        <>
          <Label>Confirmar nueva clave</Label>
          <Input
            type="password"
            name="newPasswordConfirm"
            placeholder="Confirma tu clave"
            error={errors.newPasswordConfirm}
            value={formData.newPasswordConfirm}
            onChange={handleChange}
          />
        </>
      )}
      <Button
        disabled={!formData.newPasswordConfirm}
        className="w-fit ml-auto md:col-start-2"
        onClick={handleSavePassword}
      >
        {" "}
        {/**disabled onClick={saveChanges} */}
        Guardar cambios
      </Button>

      <h3 className="col-span-1 md:col-span-2 mt-6">
        Cambiar correo electronico
      </h3>
      <Label>Correo electronico actual</Label>
      <Input
        name="email"
        placeholder="Correo actual"
        error={errors.email}
        value={formData.email}
        onChange={handleChange}
      />
      <Label>Nuevo correo electronico</Label>
      <Input
        name="newEmail"
        placeholder="Nuevo correo"
        error={errors.newEmail}
        value={formData.newEmail}
        onChange={handleChange}
      />
      {formData.newEmail && (
        <>
          <Label>Confirmar nuevo correo electronico</Label>
          <Input
            name="newEmailConfirm"
            placeholder="Confirmar nuevo correo"
            error={errors.newEmailConfirm}
            value={formData.newEmailConfirm}
            onChange={handleChange}
          />
        </>
      )}
      <Button
        disabled={!formData.newEmailConfirm}
        className="w-fit ml-auto md:col-start-2"
        onClick={handleSaveEmail}
      >
        {" "}
        {/**disabled onClick={saveChanges} */}
        Guardar cambios
      </Button>
      {message && (
        <div className="mt-4 p-2 flex justify-centertext-black ">{message}</div>
      )}
      {/* {errors.newEmail && <span className="error-text">{errors.newEmail}</span>}
      {errors.newPassword && <span className="error-text">{errors.newPassword}</span>}
      {errors.newPasswordConfirm && <span className="error-text">{errors.newPasswordConfirm}</span>} */}

      {/**
     * <h3 className="col-span-1 md:col-span-2 mt-6">Cuenta</h3>
      <Label>Borrar cuenta</Label>
      <Popover open={deleteAccountOpen} onOpenChange={setDeleteAccountOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className="w-fit border-red-500 text-red-500 hover:bg-red-500 hover:text-white">
            Eliminar
          </Button>
        </PopoverTrigger>
        <PopoverContent className="flex flex-col gap-y-2">
          <Label className="leading-5">
            Estas a punto de elimiar tu cuenta.
          </Label>
          <Button
            className="w-fit ml-auto"
            variant="destructive"
            onClick={handleDeleteAccount}>
            Eliminar
          </Button>
        </PopoverContent>
      </Popover>
     */}
    </div>
  );
};

export default AccountSettings;
