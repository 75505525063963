import React, {useState} from "react";
// import DataSelector from "./DataSelector";
import {
  availableWidgets,
} from "data/chartData/frontLayout";
import {Button } from "components/ui/button";
import { Icon } from "@iconify/react";

const ResponsiveSelectorWidget = ({ id, onUpdateType }) => {
  const [enableSelectorMode, setEnableSelectorMode] = useState(false);

  if (!enableSelectorMode) {
    return (
      <div className="p-2 flex items-center justify-center w-full h-full absolute group-hover:bg-gray-200/30 transition-all inset-0">
        <Button className="rounded-full p-3 h-auto" onClick={() => setEnableSelectorMode(true)}>
        <Icon
            className="size-10 p-1 text-current"
            icon="fluent:add-12-filled"
          />
        </Button>
      </div>
    );
  }

  return (
    <div className="p-2 flex flex-col items-center justify-center w-full h-full gap-2 ">

                <h4 className="px-2 col-span-2">Selecciona un tipo de widget</h4>
    <div className="grid grid-cols-2 w-full @sm:grid-cols-2 gap-2 overflow-y-auto custom-scrollbar">

      {availableWidgets.map((widget) => (
        <button
        key={widget.type}
        onClick={() => onUpdateType(id, widget.type)}
        className="w-full grid grid-cols-[auto_1fr] @sm:grid-cols-1 @sm:grid-rows-[1fr_auto] justify-start items-center @sm:justify-center p-1 bg-gray-50 dark:bg-gray-1000 hover:bg-gray-100 dark:hover:bg-gray-950 transition-colors rounded-md"
        >
          <div className="w-12 mx-auto p-2 h-full flex items-center justify-center">
            {widget.icon}
          </div>
          <span className="block font-bold text-left @sm:text-center">{widget.title}</span>
        </button>
      ))}
    </div>
      </div>
  );
};

export default ResponsiveSelectorWidget;

