import React, { useMemo } from "react";
import Gauge from "./Gauge";
import { filterAndTransformWidgetData } from "helpers/filterAndTransformWidgetData"; // Adjust the import path as necessary
import { parametersWithLabels } from "data/translationArrays";
import AddMissingOptions from "../../widgetWrapper/AddMissingOptions";

const ResponsiveGaugeChart = ({ data,   options = {}
  , showHeaderTitle = true, toggleDataSelector = () => {} }) => {

  // useMemo(() => console.log("options in Gauge:", options), [options]);
  if (!data || !Array.isArray(data) || data.length === 0) {
    return <div>No data provided.</div>;
  }

  // if (!options || typeof options !== 'object') {
  //   return <div>Invalid or missing configuration options.</div>;
  // }
  console.log("GaugeChart data", data);
  console.log("GaugeChart options", options);

  function transformData(data, options) {
    const deviceData = data.find(
      (device) =>
        device.location.city === options.city &&
        device.location.specificLocation === options.location &&
        device.deviceId === options.sensors[0]
    );
    console.log("deviceData", deviceData);

    if (!deviceData) {
      console.warn("No matching device data found.");
      return null;
    }
    const detailedData =
      options.categories &&
      Object.keys(options.categories).map((categoryKey) => {
        const categoryData = deviceData.data.find(
          (cat) => cat.category === categoryKey
        );
        console.log("categoryData", categoryData);
        if (!categoryData || categoryData.measurements.length === 0) {
          return {
            label: categoryKey,
            value: "N/A",
            unit: "",
            ...options.categories[categoryKey],
          };
        }
        console.log("no data found for category", categoryKey);

        const latestMeasurement =
          categoryData.measurements[categoryData.measurements.length - 1];
        return {
          label: categoryData.category,
          value: typeof latestMeasurement.value === 'string' ? Number(latestMeasurement.value) : latestMeasurement.value,

          // value: latestMeasurement.value,
          unit: latestMeasurement.unit,
          ...options.categories[categoryKey],
        };
      });

    return { ...deviceData, data: detailedData };
  }

  const requiredOptions = ["city", "location", "categories"];
  const transformedData = filterAndTransformWidgetData(
    { data, options },
    requiredOptions,
    transformData
  );
  // console.log("transformedData in Gauge", transformedData);

  if (!transformedData || transformedData.length === 0) {
    return (
      <AddMissingOptions data={data} toggleDataSelector={toggleDataSelector} requiredOptions={requiredOptions} options={options} />
    );
  }

  transformedData.data = transformedData.data.map((item) => ({
    ...item,
    visualLabel: parametersWithLabels.find((param) => param.key === item.label)
      ?.description,
  }));


  const itemCount = transformedData.data.length;
  let gridClasses;
  if (itemCount === 1) {
    gridClasses = showHeaderTitle
      ? "grid-cols-1 [@container(max-aspect-ratio:2_/_1)]:grid-cols-1 @sm:grid-cols-3 [@container(max-aspect-ratio:1.2_/_1)]:grid-rows-[auto,_1fr]"
      : "@sm:grid-cols-1";
  } else if (itemCount === 2) {
    gridClasses = showHeaderTitle
      ? "grid-cols-2 [@container(max-aspect-ratio:2_/_1)]:grid-cols-2 @sm:grid-cols-4 [@container(max-aspect-ratio:1.2_/_1)]:grid-rows-[auto,_1fr]"
      : "@sm:grid-cols-2";
  } else if (itemCount === 3) {
    gridClasses = showHeaderTitle
      ? "grid-cols-3 [@container(max-aspect-ratio:2_/_1)]:grid-cols-3 @sm:grid-cols-5 [@container(max-aspect-ratio:1.2_/_1)]:grid-rows-[auto,_1fr] "
      : "@sm:grid-cols-3 ";
  } else if (itemCount === 4) {
    gridClasses = showHeaderTitle
      ? "grid-cols-4 [@container(max-aspect-ratio:2_/_1)]:grid-cols-4 @sm:grid-cols-6 [@container(max-aspect-ratio:1.2_/_1)]:grid-rows-[auto,_1fr]"
      : "@sm:grid-cols-4";
  }

  let headerClasses;
  if (itemCount === 1) {
    headerClasses = "@sm:col-span-2 col-span-1";
  } else if (itemCount === 2) {
    headerClasses = "@sm:col-span-2 col-span-2";
  } else if (itemCount === 3) {
    headerClasses =
      "@sm:col-span-2 col-span-3 [@container(max-aspect-ratio:1.2_/_1)]:";
  } else if (itemCount === 4) {
    headerClasses = "@sm:col-span-2 col-span-4";
  }

  let subGridClasses;
  if (itemCount === 1) {
    subGridClasses = "col-span-1 @sm:grid-cols-1";
  } else if (itemCount === 2) {
    subGridClasses = "col-span-2 @sm:grid-cols-2";
  } else if (itemCount === 3) {
    subGridClasses = "col-span-3 @sm:grid-cols-3";
  } else if (itemCount === 4) {
    subGridClasses = "col-span-4 @sm:grid-cols-4";
  }

  return (
    <div className="h-full relative w-full">
      <div className={`${gridClasses} grid h-full  gap-3`}>
        {showHeaderTitle && (
          <div className="flex flex-col col-span-4 justify-center @sm:col-span-2">
            <h4 className="px-2">{transformedData.deviceId}</h4>
            <span className="px-2 text-xs text-green-500 dark:text-green-400">
              {transformedData.location.city},{" "}
              {transformedData.location.specificLocation}
            </span>
          </div>
        )}
        <div
          className={`${subGridClasses} grid-cols-subgrid gap-2 [@container(max-aspect-ratio:2.75_/_1)]:h-fit [@container(max-aspect-ratio:2.75_/_1)]:gap-y-6 h-full my-auto grid`}>
          {transformedData.data.map((item) => (
            <Gauge
              key={item.label}
              units={item.unit}
              value={item.value}
              minValue={item.minValue}
              maxValue={item.maxValue}
              label={item.label}
              visualLabel={item.visualLabel}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResponsiveGaugeChart;
