import React, { useState, useEffect } from "react";
import { InputWithLabelAndError as Input } from "components/ui/InputWithLabelAndError";
import { Textarea } from "components/ui/textarea";
import { useStateContext } from "contexts/ContextProvider";
import { Label } from "components/ui/label";
const SetupProfile = ({ userProfile, updateUserProfile }) => {
  const [formValues, setFormValues] = useState({
    firstName: userProfile.contactDetails.firstName || "",
    lastName: userProfile.contactDetails.lastName || "",
    phone: userProfile.contactDetails.phone || "",
    workRole: userProfile.contactDetails.workRole || "",
    bio: userProfile.contactDetails.bio || "",
    catastro: userProfile.catastro || "",
    parcela: userProfile.parcela || "",
    poligono: userProfile.poligono || "",
  });

  const { setUserProfile } = useStateContext(); // State for user profile
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    console.log("SetupProfile formValues", formValues);
  }, [formValues]);

  const validate = () => {
    let isValid = true;
    let newErrors = {};

    // Validation logic for each field
    if (!formValues.firstName.trim()) {
      isValid = false;
      newErrors.firstName = "El nombre es necesario";
    }

    if (!formValues.lastName.trim()) {
      isValid = false;
      newErrors.lastName = "El apellido es necesario";
    }

    if (!formValues.phone.trim()) {
      isValid = false;
      newErrors.phone = "El numero de teléfono es necesario";
    } else if (!/^[\d\s+]+$/.test(formValues.phone)) {
      isValid = false;
      newErrors.phone = "El numero de teléfono no es válido";
    }

    if (!formValues.workRole.trim()) {
      isValid = false;
      newErrors.workRole = "La profesión es necesaria";
    }

    setErrors(newErrors);
    return isValid;
  };

  /*  useEffect(() => {
    if (validate()) {
      updateUserProfile({ contactDetails: formValues });
    }
  }, [formValues, updateUserProfile]); */

  /* const handleSubmit = async (e) => {
    //HANDLE SAVING DATA TO DB
    e.preventDefault();
    if (validate()) {
      // Call updateUserProfile function with the updated formValues
      updateUserProfile(formValues);

      setLoading(true);
      setErrors({});

      try {
        console.log("SetupProfile data: name, email, pass");
        //Calling the serverless function to connect to the DB and update it with the data of the new user
        //const response = await fetch("/api/handleUserProfile?action=setupProfile", {
        const response = await fetch(
          "https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/users/profile?action=setupProfile",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              firstName: formValues.firstName,
              lastName: formValues.lastName,
              phone: formValues.phone,
              workRole: formValues.workRole,
              bio: formValues.bio,
              //name,
              //email,
              //password,
              //role,
            }),
          }
        );
        const data = await response.json();
        console.log("SetupProfile data response", data);

        if (!response.ok) {
          //Handle HTTP errors
          console.error("SetupProfile response error: ", data);
          try {
            const errorData = JSON.parse(data);
            setResponseMessage(
              `Error ${response.status}: ${errorData.message}`
            );
          } catch (parseError) {
            setResponseMessage(`Error ${response.status}: ${data}`);
          }
          setLoading(false);
          return;
        }

        if (response.ok) {
          //Profile updated successful
          console.log("Profile updated successful:", data);
          setSuccessMessage("Se ha actualizado de forma correcta");
          //set a delay for showing the success message
        } else {
          //Is this necessary having the if (!response.ok) above?
          //Handle profile update error
          console.error("Profile update error:", data.message);
          setResponseMessage(data.message || "Error during registration");
        }
      } catch (error) {
        console.error("Request error:", error);
        setResponseMessage("Error connecting to the server");
      } finally {
        setLoading(false); // Hide spinner after request completes
      }
    }
  }; */

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    //console.log("SetupProfile name, value", name, value);
    setFormValues((prevState) => {
      const updatedFormValues = {
        ...prevState,
        [name]: value,
      };
      setUserProfile((prevProfile) => ({
        ...prevProfile,
        contactDetails: {
          ...prevProfile.contactDetails,
          firstName: updatedFormValues.firstName,
          lastName: updatedFormValues.lastName,
          phone: updatedFormValues.phone,
          workRole: updatedFormValues.workRole,
          bio: updatedFormValues.bio,
        },
        catastro: updatedFormValues.catastro,
        parcela: updatedFormValues.parcela,
        poligono: updatedFormValues.poligono,
      }));
      return updatedFormValues;
    });

    // Clear errors when user starts typing
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  /* useEffect( () => {
    console.log("SetupProfile userProfile", userProfile);
  }, [userProfile]) */

  return (
    <div className="max-w-md md:max-w-2xl mx-auto w-full flex flex-col gap-y-4 md:gap-y-8">
      <div className="md:text-center">
        <h2 className="font-medium leading-7 dark:text-white">Perfil</h2>
        <span className="text-sm dark:text-gray-200">
          Completa tus datos y configura tu perfil
        </span>
      </div>
      <form className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
        {/**onSubmit={handleSubmit}>**/}
        <Input
          type="text"
          name="firstName"
          label="Nombre"
          placeholder="Javier"
          value={formValues.firstName}
          onChange={handleInputChange}
          error={errors.firstName}
        />
        <Input
          type="text"
          name="lastName"
          label="Apellido(s)"
          placeholder="Sánchez"
          value={formValues.lastName}
          onChange={handleInputChange}
          error={errors.lastName}
        />
        <Input
          type="phone"
          name="phone"
          label="Teléfono"
          placeholder="+34 123456789"
          value={formValues.phone}
          onChange={handleInputChange}
          error={errors.phone}
        />
        <Input
          type="text"
          name="workRole"
          label="Profesión"
          placeholder="Agricultor"
          value={formValues.workRole}
          onChange={handleInputChange}
          error={errors.workRole}
        />
        <div className="md:col-span-2 flex flex-col">
          <label className="text-base mb-1  font-bold dark:text-gray-100 text-gray-900">
            <span>Biografía</span>
          </label>
          <Textarea
            name="bio"
            className="bg-gray-100 max-h-40 border-none dark:bg-gray-800 dark:text-white dark:focus-visible:bg-gray-700 focus-visible:ring-0 focus-visible:bg-gray-200"
            label="Biografía"
            placeholder="Presentase en una o dos frases."
            value={formValues.bio}
            onChange={handleInputChange}
            error={errors.bio}
          />
        </div>
        <div className="md:col-span-2 h-px w-full mr-auto bg-gray-300 dark:bg-gray-700" />
        <div className="md:col-span-2 grid-cols-3 gap-4 grid">
          <Input
            type="text"
            name="catastro"
            label="Catastro"
            placeholder="12345678"
            value={formValues.catastro}
            onChange={handleInputChange}
            error={errors.catastro}
          />
          <Input
            type="text"
            name="parcela"
            label="Parcela"
            placeholder="1234"
            value={formValues.parcela}
            onChange={handleInputChange}
            error={errors.parcela}
          />
          <Input
            type="text"
            name="poligono"
            label="Polígono"
            placeholder="123"
            value={formValues.poligono}
            onChange={handleInputChange}
            error={errors.poligono}
          />
        </div>
      </form>
    </div>
  );
};

export default SetupProfile;
