import { v4 as uuidv4 } from "uuid"; // Ensure uuid is imported if used for generating IDs

export const addWidget = (widgetType, availableWidgets, unifiedData, userProfile, setUserProfile) => {
  const widgetInfo = availableWidgets.find((w) => w.type === widgetType);

  if (!widgetInfo) {
    console.error('Widget type not found:', widgetType);
    return; // Exit if no widget type matches
  }

  const newWidget = {
    id: uuidv4(),
    type: widgetType,
    options: widgetInfo.defaultOptions || {},
    data: unifiedData || [],
  };



  setUserProfile(prevProfile => ({
    ...prevProfile,
    userSettings: {
      ...prevProfile.userSettings,
      widgets: [...prevProfile.userSettings.widgets, newWidget],
      dashboardLayout: {
        ...prevProfile.userSettings.dashboardLayout,
        md: [...prevProfile.userSettings.dashboardLayout.md, {
          i: newWidget.id, x: 0, y: 0, w: widgetInfo.defaultSize.w, h: widgetInfo.defaultSize.h, minW: widgetInfo.defaultSize.minW, minH: widgetInfo.defaultSize.minH
        }],
        xxs: [...prevProfile.userSettings.dashboardLayout.xxs, {
          i: newWidget.id, x: 0, y: 0, w: widgetInfo.defaultSize.w, h: widgetInfo.defaultSize.h, minW: widgetInfo.defaultSize.minW, minH: widgetInfo.defaultSize.minH
        }]
      },
    },
  }));

};

// Function to remove a widget from the user profile
export const removeWidget = (widgetId, userProfile, setUserProfile) => {
  setUserProfile((prevProfile) => ({
    ...prevProfile,
    userSettings: {
      ...prevProfile.userSettings,
      widgets: prevProfile.userSettings.widgets.filter(
        (widget) => widget.id !== widgetId
      ),
      dashboardLayout: {
        ...prevProfile.userSettings.dashboardLayout,
        md: prevProfile.userSettings.dashboardLayout.md.filter(
          (layout) => layout.i !== widgetId
        ),
        xxs: prevProfile.userSettings.dashboardLayout.xxs.filter(
          (layout) => layout.i !== widgetId
        ),
      },
    },
  }));
};

export const storeWidget = (widgetId, userProfile, setUserProfile) => {

  setUserProfile((prevProfile) => {
    const widgetToStore = prevProfile.userSettings.widgets.find(
      (w) => w.id === widgetId
    );
    if (!widgetToStore) {
      console.error("Widget not found");
      return prevProfile;
    }

    if (userProfile.userSettings.storedWidgets.some(w => w.id === widgetId)) {
      console.error("Widget already stored");
      return;
    }
    // Find the widget's layout configuration for all breakpoints
    const widgetLayoutMd = prevProfile.userSettings.dashboardLayout.md.find(
      (l) => l.i === widgetId
    );
    const widgetLayoutXxs = prevProfile.userSettings.dashboardLayout.xxs.find(
      (l) => l.i === widgetId
    );

    // Store the widget with all its layout configurations
    return {
      ...prevProfile,
      userSettings: {
        ...prevProfile.userSettings,
        storedWidgets: [
          ...prevProfile.userSettings.storedWidgets,
          {
            ...widgetToStore,
            layout: {
              md: widgetLayoutMd || {},
              xxs: widgetLayoutXxs || {},
            },
          },
        ],
      },
    };
  });

};

// Add a function to remove a widget from the store
export const restoreStoredWidget = (widgetId, userProfile, setUserProfile) => {

  setUserProfile((prevProfile) => {
    const widgetIndex = prevProfile.userSettings.storedWidgets.findIndex(
      (widget) => widget.id === widgetId
    );
    if (widgetIndex === -1) {
      console.error("Stored widget not found");
      return;
    }
    const widgetToRestore =
      prevProfile.userSettings.storedWidgets[widgetIndex];

    // Remove the widget from storedWidgets and prepare to add it to active widgets with its layout
    const newStoredWidgets = prevProfile.userSettings.storedWidgets.slice();
    newStoredWidgets.splice(widgetIndex, 1);

    return {
      ...prevProfile,
      userSettings: {
        ...prevProfile.userSettings,
        widgets: [...prevProfile.userSettings.widgets, widgetToRestore],
        dashboardLayout: {
          ...prevProfile.userSettings.dashboardLayout,
          md: [
            ...prevProfile.userSettings.dashboardLayout.md,
            widgetToRestore.layout.md,
          ],
          xxs: [
            ...prevProfile.userSettings.dashboardLayout.xxs,
            widgetToRestore.layout.xxs,
          ],
        },
        storedWidgets: newStoredWidgets,
      },
    };
  });
};