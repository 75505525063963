import * as React from "react"
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area"

import { cn } from "lib/utils"

const ScrollArea = React.forwardRef(({ className, children, orientation = "vertical", ...props }, ref) => (
  <ScrollAreaPrimitive.Root
    
    className={cn("relative overflow-hidden", className)}
    {...props}>
    <ScrollAreaPrimitive.Viewport ref={ref} className=" h-full w-full rou nded-[inherit]">
      {children}
    </ScrollAreaPrimitive.Viewport>
    {orientation === "vertical" || orientation === "both" ? (
      <ScrollBar orientation="vertical" />
    ) : null}
    {orientation === "horizontal" || orientation === "both" ? (
      <ScrollBar orientation="horizontal" />
    ) : null}
    <ScrollAreaPrimitive.Corner />
  </ScrollAreaPrimitive.Root>
))
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName

const ScrollBar = React.forwardRef(({ className, orientation = "vertical", ...props }, ref) => (
  
  <>
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    ref={ref}
    orientation={orientation}
    className={cn(
      "flex touch-none z-[21] select-none transition-all",
      orientation === "vertical" &&
        "h-full w-2 bor der-l border-l-transparent p-[1px]",
      orientation === "horizontal" &&
        "h-2.5 flex-col border-t border-t-transparent p-[1px]",
      className
    )}
    {...props}>
    <ScrollAreaPrimitive.ScrollAreaThumb className="relative flex-1 hover:bg-gray-300 hover:opacity-100 duration-150 transition-opacity opacity-50 dark:hover:bg-gray-700 rounded-full bg-gray-200 dark:bg-gray-800" />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
  </>

))
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName

export { ScrollArea, ScrollBar }
