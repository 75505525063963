import React from "react";
import { ResponsiveDataTable } from "components/widgets/widgetTypes/tables/ResponsiveDataTable";
// import { useHandleSetLayout } from "contexts/functions/layoutFunctions";
import { useStateContext } from "contexts/ContextProvider";
const AnalyticsSensorTable = ({
  onRowSelectionChange,
  showHeaders,
  toggleDataSelector,
  data,
}) => {
  const filterFor = "deviceId";
  const filterForLabel = "Dispositivo";
  const { handleSetLayout } = useStateContext();

  const selectRow = (selectedRowIds) => {
    onRowSelectionChange(selectedRowIds);
    if (selectedRowIds.length === 0) {
      handleSetLayout("Analytics", "analytics");
    } else {
      handleSetLayout("Analytics", "anExpanded");
    }
  };

  const options = {
    city: "Valencia",
    location: "Marjals i Extremals",
    sensors: ["Entrada Alqueria Ros"],
    columns: [
      "message",
      "location",
      "updated",
      "urgency",
      "assigned",
      "status",
    ],

    categories: {
      pressure: { minValue: 0, maxValue: 100 },
      temperature: { minValue: 0, maxValue: 50 },
      flow: { minValue: 0, maxValue: 30 },
    },
  };

  const localColumnsConfig = [
    { id: "select", type: "select" },

    {
      id: "title",
      type: "sortable",
      accessorKey: "title",
      header: "Sensor",
    },
  ];

  return (
    <>
      <ResponsiveDataTable
        showHeaders={showHeaders}
        toggleDataSelector={toggleDataSelector}
        data={data}
        options={options}
        columnsConfig={localColumnsConfig}
        filterFor={filterFor}
        filterForLabel={filterForLabel}
        enableRowSelection={true}
        onRowSelectionChange={selectRow}
      />
    </>
  );
};

export default AnalyticsSensorTable;
