// contexts/BrandContext.js
import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';

const BrandContext = createContext();

export const useBrand = () => useContext(BrandContext);

export const BrandProvider = ({ brandConfigs, children }) => {
  const [brand, setBrand] = useState(brandConfigs[0]);
  const domain = window.location.hostname;

  useEffect(() => {
    console.log('brand Current domain:', domain);

    const foundBrand = brandConfigs.find((config) => domain.includes(config.url));
    if (foundBrand) {
      setBrand(foundBrand);
      console.log('Current brand:', foundBrand);
      fetchBrandManifest(foundBrand);

    }
  }, [brandConfigs, domain]);

  const fetchBrandManifest = async (brandConfig) => {
    try {
      // Dynamically load the manifest.json from the correct folder
      const response = await fetch(`/${brandConfig.folder}/manifest.json`);
      const manifest = await response.json();

      // Merge the fetched manifest data with the brandConfig
      const fullBrandConfig = { ...brandConfig, ...manifest };
      setBrand(fullBrandConfig);

      console.log('Loaded brand:', fullBrandConfig);
    } catch (error) {
      console.error('Error loading brand manifest:', error);
    }
  };

  const updateIcons = useCallback((icons) => {
    // Update the favicon
    if (icons.favicon) {
      const faviconElement = document.getElementById('favicon');
      if (faviconElement) {
        faviconElement.href = `/${brand.folder}/${icons.favicon}`;
      }
    }

    // Update other icons if needed (e.g., web app icons)
    // Add similar logic for other icons if they need to be dynamically changed
  }, [brand]);

  const applyColors = useCallback((colors) => {
    Object.keys(colors).forEach((colorKey) => {
      const colorValues = colors[colorKey];
      Object.keys(colorValues).forEach((shade) => {
        document.documentElement.style.setProperty(`--color-${colorKey}-${shade}`, colorValues[shade]);
      });
    });
  }, []);

  useEffect(() => {
    if (brand) {
      if (brand.colors) {
        applyColors(brand.colors);
      }
      if (brand.typography) {
        document.documentElement.style.setProperty('--font-family', brand.typography.fontFamily);
      }
      if (brand.icons) {
        updateIcons(brand.icons);
      }
    }
  }, [brand, applyColors, updateIcons]);



  useEffect(() => {
    if (brand && brand.typography && brand.typography.fontFamily) {
      document.documentElement.style.setProperty('--font-family', brand.typography.fontFamily);
    }
  }, [brand]);

  return (
    <BrandContext.Provider value={{ brand, setBrand }}>
      {children}
    </BrandContext.Provider>
  );
};
