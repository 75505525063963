import React, { useMemo, useState } from "react";
import { ReactComponent as WaterPressureIcon } from "icons/indicators/water-pressure.svg";
import { ReactComponent as WaterLevelIcon } from "icons/indicators/water-level.svg";
import { ReactComponent as WaterTemperatureIcon } from "icons/indicators/water-temperature.svg";
import { useStateContext } from "contexts/ContextProvider";
import { Button } from "components/ui/button";
const ToggleWidgetData = ({ type, widgetId, onSave = () => {} }) => {
  const { userProfile, handleUpdateWidgetOptions } = useStateContext();

  const widget = userProfile?.userSettings?.widgets.find((w) => w.id === widgetId);


  // useMemo(() => {
  //   console.log("widget", widget)
  // }, [widget]); // Ensure this useMemo has dependencies listed

  const categories = [
    { label: "flow", visualLabel: "Caudal", Icon: WaterLevelIcon },
    { label: "pressure", visualLabel: "Presión", Icon: WaterPressureIcon },
    { label: "temperature", visualLabel: "Temperatura", Icon: WaterTemperatureIcon },
  ];
  
  const initialValueType = (widget?.options?.valueType && widget?.options?.valueType[0] !== "" ) ? widget?.options?.valueType : ['flow'];  // Default to 'flow' if undefined
  
  const initialOptionIndex = initialValueType ? categories.findIndex(category => category.label === initialValueType[0]) : 0;
  const [currentOptionIndex, setCurrentOptionIndex] = useState(initialOptionIndex);

  const { visualLabel, Icon } = categories[currentOptionIndex];

  const toggleOption = () => {
    const newIndex = (currentOptionIndex + 1) % categories.length;
    setCurrentOptionIndex(newIndex);
    const newOption = categories[newIndex].label;

    // console.log("newOption", newOption);
    const updatedOptions = { ...widget.options, valueType: [newOption] };

    onSave(widgetId, updatedOptions);
    // console.log("updatedOptions", updatedOptions);
    handleUpdateWidgetOptions(widgetId, updatedOptions);
  };


  return type === "lineChart" ||
    type === "barChart" ||
    type !== "analyticsLineChart" ||
    type === "detailBarChart" ? (
    <Button
    variant="outline"
      className="opacity-100 h-6 bg-white dark:bg-gray-950  translate-x-[33px] transition-all group-hover:translate-x-0 group-hover:opacity-100 group/2 flex @sm:space-x-2 items-center hover:!opacity-100 border-gray-200 dark:text-gray-500 p-1 text-gray-200  hover:text-gray-800 dark:hover:text-gray-100 rounded-full  "
      onClick={toggleOption}>
      <span className="@sm:inline-flex capitalize @sm:pl-0.5 hidden z-10 w-auto text-xs leading-none text-current">
        {visualLabel}
      </span>
      <Icon className=" group-hover:opacity-100 group-hover/2:opacity-100 size-3.5 text-current" />
    </Button>
  ) : null;
};

export default ToggleWidgetData;
