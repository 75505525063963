import axios from "axios";
import { widgetData } from "data/chartData/frontLayout";
import { frontLayouts } from "data/frontLayouts";

const loadUserProfile = async (
  setUserProfile,
  userId,
  userProfile,
  setIsLoadingUserPermissions
) => {
  try {
    if (!userId) {
      console.log("loadUserProfile user ID not found in session storage");
      return;
    }

    const response = await axios.post(
      "https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/users/profile?action=getProfile",
      {
        userId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const parsedData = JSON.parse(response.data.body);

    console.log("loadUserProfile fetch user's profile data:", parsedData);

    const { userProfile: data, permissions } = parsedData;

    const profileData = {
      username: data.user_name || "",
      role: data.role_name || "",
      parcela: data.parcel_queue_id || null,
      poligono: data.polygon_queue_id || null,
      catastro: data.cadastre || "",
      parcela_orden: data.order || null,
      isUserAGuest: false,
      phone: data.user_phone || "",
      email: data.user_email || "",
      profilePicture: data.user_photo || "",
      contactDetails: {
        firstName: data.first_name || "",
        lastName: data.last_name || "",
        workRole: data.workRole || "",

        bio: data.bio || "",
        phone: data.user_phone || "",
        contactMail: data.user_email || "",
        allowWhatsapp:
          data.allow_whatsapp !== undefined ? data.allow_whatsapp : "",
        address: {
          city: data.city || "",
          state: data.state || "",
          country: data.country || "",
          postalCode: data.postal_code || "",
          street: data.adress || "",
        },
      },
      userSettings: {
        darkMode: data.dark_mode !== undefined ? data.dark_mode : "",
        dashboardLayout: data.dashboardLayout || frontLayouts["simple"], // should be "example-1 for demo" //sorted
        widgets: data.widgets || widgetData,
        layoutMode: data.layoutMode || "simple",
      },
    };

    setUserProfile((prevProfile) => ({
      ...prevProfile,
      ...profileData,
      contactDetails: {
        ...prevProfile.contactDetails,
        ...profileData.contactDetails,
      },
      userSettings: {
        ...prevProfile.userSettings,
        ...profileData.userSettings,
      },
    }));

    localStorage.setItem("userProfile", JSON.stringify(profileData));
    localStorage.setItem(
      "contactDetails",
      JSON.stringify(profileData.contactDetails)
    );
    localStorage.setItem(
      "userSettings",
      JSON.stringify(profileData.userSettings)
    );
    sessionStorage.setItem("frontendPermissions", JSON.stringify(permissions));

    setIsLoadingUserPermissions(false);

    //Check local storage
    /*const storedUserProfile = JSON.parse(localStorage.getItem('userProfile'));
    const storedContactDetails = JSON.parse(localStorage.getItem('contactDetails'));
    const storedUserSettings = JSON.parse(localStorage.getItem('userSettings'));
    
    if (storedUserProfile && storedContactDetails && storedUserSettings) {
      console.log("Found profile data in local storage, updating state");
      setUserProfile(prevProfile => ({
        ...prevProfile,
        ...storedUserProfile,
        contactDetails: storedContactDetails,
        userSettings: storedUserSettings,
        }));
        //return;
        } else {
          console.log("loadUserProfile userProfile, ContactDetails or userSettings couldn't been saved")
      }*/
  } catch (error) {
    console.error("Error fetching profile data:", error);
  } finally {
    console.log("loadUserProfile done");
    setIsLoadingUserPermissions(false);
  }
};

export default loadUserProfile;
