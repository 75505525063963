import React from 'react';
import PropTypes from 'prop-types';
import { cva } from 'class-variance-authority';

const profileVariants = cva(
  'rounded-full z-10 object-cover transition-all',
  {
    variants: {
      size: {
        sm: 'size-8 md:size-10 text-sm',
        md: 'size-10 md:size-12 text-base',
        lg: 'size-12 md:size-14 text-lg',
        xl: 'size-20 text-4xl',
        full: 'size-full text-lg',
      }
    },
    defaultVariants: {
      size: 'sm',
    }
  }
);

// Simple hash function to convert a string into a number
const hashString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

// Function to generate a color from a string
const getColorFromString = (str) => {
  const hash = hashString(str);
  const color = `hsl(${hash % 360}, 70%, 60%)`; // Generate HSL color
  return color;
};

const ProfileImage = ({ image, firstName, lastName, userName, size, isUserAGuest }) => {
  const renderInitials = () => {
    const firstInitial = (firstName && firstName.charAt(0)) || (userName && userName.charAt(0)) || '';
    const lastInitial = lastName.charAt(0) || '';
    return `${firstInitial}${lastInitial}`;
  };

  const imageClasses = profileVariants({ size: size });
  const backgroundColor = getColorFromString(userName || 'guestUserName'); // Generate background color based on userName

  if (isUserAGuest && !firstName && !lastName && !userName) {
    return (
      <div
        className={`${imageClasses} aspect-square select-none flex uppercase items-center justify-center text-white`}
        style={{ backgroundColor }}
      >
        <span className='mix-blend-screen cursor-default text-white'>
          HT
        </span>
      </div>
    );
  }

  return (
    <>
      {image ? (
        <img
          className={imageClasses}
          src={image}
          alt={`${firstName} ${lastName}`}
        />
      ) : (
        <div
          className={`${imageClasses} aspect-square select-none flex uppercase items-center justify-center text-white`}
          style={{ backgroundColor }} // Apply the generated background color
        >
          <span className='mix-blend-screen cursor-default text-white'>
            {renderInitials()}
          </span>
        </div>
      )}
    </>
  );
};

ProfileImage.propTypes = {
  image: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  userName: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'full']),
  isUserAGuest: PropTypes.bool,
};

ProfileImage.defaultProps = {
  image: '',
  firstName: '',
  lastName: '',
  userName: '',
  size: 'sm',
  isUserAGuest: false,
};

export default ProfileImage;
