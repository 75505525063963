import React, { useEffect, useState } from "react";
import { Button } from "components/ui/button";
import { ButtonGroup } from "@mui/material";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import {
  addMinutes,
  parseISO,
  formatISO,
  format,
  startOfDay,
  endOfDay,
  isWithinInterval,
  isSameDay,
  addDays,
} from "date-fns";
import { toast } from "sonner";
import { ReactComponent as WaterDropIcon } from "icons/water-drop.svg";
import { ReactComponent as CalendarIcon } from "icons/calendar.svg";
import { ReactComponent as ClockIcon } from "icons/clock.svg";
import { ReactComponent as CloseIcon } from "icons/close.svg";
import { ReactComponent as EditIcon } from "icons/edit.svg";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useEventHandlers } from "contexts/EventHandlers";

const durationOptions = [
  { value: "30", label: "30 minutos" },
  { value: "60", label: "60 minutos" },
  { value: "90", label: "90 minutos" },
  { value: "120", label: "2 horas" },
  { value: "150", label: "2:30 horas" },
  { value: "180", label: "3 horas" },
];

// const getNextWeekDates = (events, currentUserId) => {
//   const today = new Date();
//   const nextWeekDates = [];

//   for (let i = 0; i < 7; i++) {
//     const date = startOfDay(addDays(today, i));
//     const formattedDate = formatISO(date, { representation: "date" });

    // const queueForDate = events.filter(
    //   (event) =>
    //     //Original code
    //     //event.type === "queue" && isSameDay(parseISO(event.start), date)
    //     //TEST to try to fix dateString.split is not a function error
    //     event.type === "queue" && isSameDay(typeof event.start === 'string' ? parseISO(event.start) : event.start, date)
    // );

//     const isUserInQueue = queueForDate.some((event) => event.userId === currentUserId);

//     nextWeekDates.push({
//       date: formattedDate,
//       hasQueue: queueForDate.length > 0,
//       isUserInQueue,
//     });
//   }

//   return nextWeekDates;
// };

const MobileWateringManager = ({
selectedDate,
setSelectedDate,
isAddingDuration,
setIsAddingDuration,
user_id,
}) => {
  const { events } = useEventHandlers();
  const [state, setState] = useState("tomorrow"); // tomorrow or waterArrived or manage
  const [openQueue, setOpenQueue] = useState(false);
  const [visible, setVisible] = useState(true);
  const [preferences, setPreferences] = useState({
    duration: "",
  });
  const [nextWeekDates, setNextWeekDates] = useState([]);
  const [usersInOrder, setUsersInOrder] = useState([]);

  const currentUserId = 1026; // Example current user ID


  const calculateArrivalTime = () => {
    const user = usersInOrder.find((user) => user.userId === currentUserId);
    if (!user) return "";

    //Original code
    //let arrivalTime = parseISO(user.start);
    //TEST to try to fix dateString.split is not a function error
    let arrivalTime = typeof user.start === 'string' ? parseISO(user.start) : user.start;

    // usersInOrder.forEach((user) => {
    //   if (user.userId !== currentUserId) {
    //     arrivalTime = addMinutes(arrivalTime, user.duration);
    //   }
    // });

    return format(arrivalTime, "HH:mm");
  };

  const handleWaterArrived = () => {
    toast.success("Gracias! Las horas de riego detrás de tí se ajustaron.")
    setVisible(false)
  };

  const handleDontWantToWaterTomorrow = () => {
    toast.success("Gracias! No te vamos a preguntar hasta el próximo día antes de regar.")
    setVisible(false);
  };

  const handleStateChange = (newState) => {
    setState(newState);
    setOpenQueue(null);
  };

  const saveChanges = () => {
    toast.success("Los datos se guardaron.", {
      description: `Tu hora de riego es de las ${calculateArrivalTime()} por ${preferences.duration} minutos.`,
    });
    setVisible(false);
  };

  let content;
  switch (state) {
    case "tomorrow":
      content = (
        <Button
          className="py-6 pl-8 pr-6 bg-blue-500 mb-4 shadow-lg text-lg rounded-full"
          onClick={() => handleStateChange("tomorrowExpanded")}>
          Vas a regar mañana?
          <WaterDropIcon className="w-6 h-6 ml-3" />
        </Button>
      );
      break;
    case "tomorrowExpanded":
      content = (
        <QueueManager
          title="Vas a regar mañana?"
          handleDontWantToWaterTomorrow={handleDontWantToWaterTomorrow}
          openQueue={openQueue}
          toggleOpen={setOpenQueue}
          usersInOrder={usersInOrder}
          selectedDate={selectedDate}
          nextWeekDates={nextWeekDates}
          setSelectedDate={setSelectedDate}
          currentUserId={currentUserId}
          isAddingDuration={isAddingDuration}
          setVisible={setVisible}
          setIsAddingDuration={setIsAddingDuration}
          preferences={preferences}
          // handleSelectChange={handleSelectChange}
          durationOptions={durationOptions}
          calculateArrivalTime={calculateArrivalTime}
          saveChanges={saveChanges}
          handleStateChange={handleStateChange}
        />
      );
      break;
    case "waterArrived":
      content = (
        <Button
          className="py-6 pl-6 pr-4 bg-green-500 mb-4 shadow-lg text-lg rounded-full"
          onClick={() => handleStateChange("waterArrivedExpanded")}>
          El agua ya llego?
          <ClockIcon className="w-6 h-6 ml-3" />
        </Button>
      );
      break;
    case "waterArrivedExpanded":
      content = (
        <ExpandedView
          title="El agua ya llego?"
          setVisible={setVisible}
          handleWaterArrived={handleWaterArrived}
          handleStateChange={handleStateChange}
        />
      );
      break;
    default:
      toast("Los datos se guardaron.");
      setVisible(false);
      content = <div></div>;
  }
  const handleDemoStateChange = () => {
    setVisible(true);
    if (state === "tomorrow" || state === "tomorrowExpanded") {
      setState("waterArrived");
    }
    if (state === "waterArrived" || state === "waterArrivedExpanded") {
      setState("manage");
    }
    if (state === "manage" || state === "manageExpanded") {
      setState("tomorrow");
    }
    
  };
  return (
    <>
      {visible && (
        <div className="h-0 w-full">

        <div className="-bottom-1 -inset-x-1 sm:-bottom-2 sm:-inset-x-2 absolute w-[calc(100%_+_0.5rem)] sm:w-[calc(100%_+_1rem)]  flex justify-center z-50 h-fit ">
          {content}
        </div>
        </div>
      )}
    </>
  );
};

const QueueManager = ({
  title,
  openQueue,
  toggleOpen,
  usersInOrder,
  selectedDate,
  setSelectedDate,
  currentUserId,
  isAddingDuration,
  setIsAddingDuration,
  preferences,
  nextWeekDates,
  handleSelectChange,
  durationOptions,
  setVisible,
  calculateArrivalTime,
  saveChanges,
  handleDontWantToWaterTomorrow,
  handleStateChange,
}) => {
  return (
    <div className="scroll-fade-in-upwards w-full absolute bottom-0 flex flex-col pt-8 gap-y-2 rounded-t-xl shadow-[0_-5px_15px_-2px] shadow-black/20 bg-white p-4">
      <Button
        className="px-2 py-3 absolute top-0 right-0 rounded-none rounded-tr-xl rounded-bl-sm"
        variant="default"
        onClick={() => setVisible(false)}>
        <CloseIcon className="w-6 h-6" />
      </Button>
      <div className=" text-center flex justify-center text-lg font-medium items-center mb-2">
        {title}
        <WaterDropIcon className="w-6 h-6 ml-2" />
      </div>
      <div className="flex gap-x-4 mb-2 justify-center">
        <Button
          size="lg"
          className={`rounded-full text-base transition-colors hover:bg-blue-400 ${
            openQueue ? "bg-blue-500" : "bg-blue-600"
          }`}
          onClick={toggleOpen}>
          Si
        </Button>
        <Button
          size="lg"
          className="rounded-full text-base"
          onClick={handleDontWantToWaterTomorrow}>
          No
        </Button>
      </div>
      {/* {openQueue && (
        <>
          <DateButtons
            nextWeekDates={nextWeekDates}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          <QueueList
            selectedDate={selectedDate}
            usersInOrder={usersInOrder}
            currentUserId={currentUserId}
            isAddingDuration={isAddingDuration}
            setIsAddingDuration={setIsAddingDuration}
            preferences={preferences}
            handleSelectChange={handleSelectChange}
            durationOptions={durationOptions}
            calculateArrivalTime={calculateArrivalTime}
          />
          <div className="flex gap-x-4 mb-2 justify-center">
            <Button
              disabled={preferences.duration === ""}
              onClick={saveChanges}
              variant="outline"
              size="lg"
              className="rounded-full text-base">
              Listo
            </Button>
          </div>
        </>
      )} */}
    </div>
  );
};

const DateButtons = ({ nextWeekDates, selectedDate, setSelectedDate }) => (
  <ButtonGroup className="w-full flex flex-col gap-y-2">
    {nextWeekDates.map((day) => {
      const dateButton = (
        <Button
          className={`w-full ${
            selectedDate === day.date ? "bg-gray-500" : ""
          } ${day.hasQueue ? "text-bold" : ""} ${
            day.isUserInQueue ? "bg-green-500" : ""
          }`}
          variant={selectedDate === day.date ? "default" : "outline"}
          key={day.date}
          onClick={() => setSelectedDate(day.date)}>
          {new Intl.DateTimeFormat("es-ES", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(day.date))}
        </Button>
      );

      if (selectedDate) {
        if (selectedDate === day.date) {
          return (
            <div className="w-full flex flex-row gap-x-2" key={day.date}>
              <Button
                className="p-2"
                variant="outline"
                onClick={() => setSelectedDate(null)}>
                <ArrowLeftIcon className="w-5 h-5" />
              </Button>
              {dateButton}
            </div>
          );
        }
        return null;
      }
      return dateButton;
    })}
  </ButtonGroup>
);

const QueueList = ({
  selectedDate,
  usersInOrder,
  currentUserId,
  isAddingDuration,
  setIsAddingDuration,
  preferences,
  handleSelectChange,
  durationOptions,
  calculateArrivalTime,
}) => {
  //Original code
  /* const usersForSelectedDate = usersInOrder.filter((user) =>
    isSameDay(parseISO(user.start), parseISO(selectedDate))
  ); */
  //TEST to try to fix dateString.split is not a function error
  const usersForSelectedDate = usersInOrder.filter((user) =>
    isSameDay(typeof user.start === 'string' ? parseISO(user.start) : user.start, typeof selectedDate === 'string' ? parseISO(selectedDate) : selectedDate)
  );
  //Original code
  /* const usersBeforeYou = usersForSelectedDate.filter(
    (user) => user.userId !== currentUserId && parseISO(user.start) < parseISO(selectedDate)
  ); */
  //TEST to try to fix dateString.split is not a function error
  const usersBeforeYou = usersForSelectedDate.filter(
    (user) => user.userId !== currentUserId && (typeof user.start === 'string' ? parseISO(user.start) : user.start) < (typeof selectedDate === 'string' ? parseISO(selectedDate) : selectedDate)
  );
  console.log("usersBeforeYou", usersBeforeYou);
  //Original code
  /* const usersAfterYou = usersForSelectedDate.filter(
    (user) => user.userId !== currentUserId && parseISO(user.start) > parseISO(selectedDate)
  ); */
  //TEST to try to fix dateString.split is not a function error
  const usersAfterYou = usersForSelectedDate.filter(
    (user) => user.userId !== currentUserId && (typeof user.start === 'string' ? parseISO(user.start) : user.start) > (typeof selectedDate === 'string' ? parseISO(selectedDate) : selectedDate)
  );
  console.log("usersAfterYou", usersAfterYou);

  const currentUser = usersForSelectedDate.find((user) => user.userId === currentUserId);

  return (
    <div>
      <div className="mb-2 pt-2 w-full flex gap-x-2 font-medium text-center">
        <CalendarIcon className="w-6 h-6" />
        Cola
        <span className="ml-auto">
          {new Intl.DateTimeFormat("es-ES", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(selectedDate))}
        </span>
      </div>
      <ul className="flex flex-col gap-y-1 ">
        {usersBeforeYou.map((user) => (
          <QueueItem user={user} key={user.id} />
        ))}
        {isAddingDuration ? (
          <DurationInput
            preferences={preferences}
            handleSelectChange={handleSelectChange}
            durationOptions={durationOptions}
            calculateArrivalTime={calculateArrivalTime}
          />
        ) : (
          <li>
            {currentUser ? (
              <Button size="lg" className="w-full" variant="default" onClick={() => setIsAddingDuration(true)}>
                Modificar duración
                <EditIcon className="w-5 h-5 ml-2" />
              </Button>
            ) : (
              <Button size="lg" className="w-full" variant="default" onClick={() => setIsAddingDuration(true)}>
                Quiero regar
                <WaterDropIcon className="w-6 h-6 ml-2" />
              </Button>
            )}
          </li>
        )}
        {usersAfterYou.map((user) => (
          <QueueItem user={user} key={user.id} />
        ))}
      </ul>
    </div>
  );
};

const DurationInput = ({
  preferences,
  handleSelectChange,
  durationOptions,
  calculateArrivalTime,
}) => (
  <li className="border text-gray-700 gap-x-1 flex bg-gray-900 flex-col border-gray-200 rounded-md px-3 py-2.5">
    <div className="mb-2 flex gap-x-2 justify-center text-center text-white pt-2">
      <ClockIcon className="w-6 h-6" />
      Cuanto tiempo vas a regar?
    </div>
    <div className="text-gray-100 pb-1 gap-x-1 flex items-center justify-between ">
      <span>Arribo:</span>
      <span>{calculateArrivalTime()} hs</span>
    </div>
    <Select
      value={preferences.duration}
      onValueChange={handleSelectChange("duration")}
      className="w-full mb-4">
      <SelectTrigger className="bg-white">
        <SelectValue placeholder="Selecciona una duracion">
          {durationOptions.find(
            (option) => option.value === preferences.duration
          )?.label || "Seleccione una duración"}
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {durationOptions.map((option) => (
          <SelectItem key={option.value} value={option.value}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </li>
);

const QueueItem = ({ user }) => (
  <li
    className="border text-gray-700 gap-x-1 items-center grid grid-cols-[auto_1fr_auto] border-gray-200 rounded-md px-3 py-1"
    key={user.id}>
    <span className="font-medium">{user.time}</span>
    <span className="text-gray-300 text-sm">{user.username}</span>
    <span>
      {new Date(
        0,
        0,
        0,
        user.duration / 60,
        user.duration % 60
      ).toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      })}{" "}
      hs
    </span>
  </li>
);

const ExpandedView = ({ setVisible, title, handleStateChange, handleWaterArrived }) => (
  <div className="scroll-fade-in-upwards w-full flex flex-col gap-y-2 pt-8 rounded-t-xl shadow-[0_-5px_15px_-2px] shadow-black/20 bg-white p-4">
    <Button
      className="px-2 py-3 absolute top-0 right-0 rounded-none rounded-tr-xl rounded-bl-sm"
      variant="default"
      onClick={() => setVisible(false)}>
      <CloseIcon className="w-6 h-6" />
    </Button>
    <div className=" text-center flex justify-center text-lg font-medium items-center mb-2">
      {title}
      <ClockIcon className="w-6 h-6 ml-3" />
    </div>
    <div className="flex gap-x-4 mb-2 justify-center">
      <Button
        size="lg"
        className={`rounded-full text-base transition-colors bg-green-600`}
        onClick={handleWaterArrived}>
        Si
      </Button>
      <Button
        size="lg"
        className="rounded-full text-base"
        onClick={() => handleStateChange("tomorrow")}>
        No
      </Button>
    </div>
  </div>
);

export default MobileWateringManager;
