// export const urgencyColorClasses = [
//   { keywords: ["critico", "crítico", "crítica", "critical"], className: "text-red-500" },
//   { keywords: ["alto", "alta", "high"], className: "text-orange-500" },
//   { keywords: ["medio", "media", "medium"], className: "text-yellow-500" },
//   { keywords: ["bajo", "baja", "low"], className: "text-blue-500" },
//   { keywords: ["muy bajo", "muy baja", "very low"], className: "text-green-500" },
// ];
export const urgencyColorClasses = [
  { value: 5, label: "Crítico", keywords: ["critico", "crítico", "crítica", "critical"], className: "text-red-500" },
  { value: 4, label: "Alto", keywords: ["alto", "alta", "high"], className: "text-orange-500" },
  { value: 3, label: "Medio", keywords: ["medio", "media", "medium"], className: "text-yellow-500" },
  { value: 2, label: "Bajo", keywords: ["bajo", "baja", "low"], className: "text-blue-500" },
  { value: 1, label: "Muy bajo", keywords: ["muy bajo", "muy baja", "very low"], className: "text-green-500" },
];

