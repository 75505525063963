import React, { useEffect, useMemo, useState } from "react";
import { checkbox } from "components/ui/checkbox"; // Ensure the correct import path
import Checkbox from "components/ui/checkboxLabelError"; // Ensure the correct import path
import { RangeSliderSteps } from "components/ui/ranger-slider-steps";
import { Slider } from "components/ui/slider";
import { Button } from "components/ui/button";
import { ButtonGroup } from "@mui/material";
import { Label } from "components/ui/label";
import { useStateContext } from "contexts/ContextProvider";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";

const AppearanceSettings = () => {
  const {
    userProfile,
    setUserProfile,
    handleResetFrontLayout,
    setDashboardLayoutByName,
  } = useStateContext();
  const storedUserProfile = JSON.parse(localStorage.getItem("userProfile"));

  const initialFontSize =
    typeof userProfile.userSettings.fontSize === "string"
      ? userProfile.userSettings.fontSize
      : `${userProfile.userSettings.fontSize || 16}px`;

  const [settings, setSettings] = useState({
    darkMode: userProfile.userSettings.darkMode || "system",
    sidebarPosition: userProfile.userSettings.sidebarPosition, // or 'right'
    fontSize: initialFontSize,
    layoutMode: userProfile.userSettings.layoutMode || "simple",
  });
  const [resetFrontLayoutOpen, setResetFrontLayoutOpen] = useState(false);

  const fontSizeForSlider = parseInt(
    (settings.fontSize || "16px").replace("px", "")
  );

  const handleChange = (e) => {
    const isDirectValue = typeof e === "string";
    if (isDirectValue) {
      const layoutMode = e;
      setSettings((prevSettings) => ({
        ...prevSettings,
        layoutMode: layoutMode,
      }));
      setDashboardLayoutByName(layoutMode, "Home");
    } else {
      const { name, value, checked, type } = e.target;
      let newValue = type === "checkbox" ? checked : value;

      if (name === "fontSize") {
        newValue = `${parseInt(value)}px`;
      }

      setSettings((prevSettings) => ({
        ...prevSettings,
        [name]: newValue,
      }));

      setUserProfile((prevProfile) => ({
        ...prevProfile,
        userSettings: {
          ...prevProfile.userSettings,
          [name]: newValue,
        },
      }));
    }
  };

  const advancedLayout = [
    {
      label: "Simple",
      value: "simple",
      grid: [1, 2],
      gridItems: [
        [1, 1],
        [1, 1],
      ],
      mobileGrid: [2, 1],
      mobileGridItems: [
        [1, 1],
        [1, 1],
      ],
    },
    {
      label: "Sorteado",
      value: "sorted",
      grid: [2, 2],
      gridItems: [
        [1, 1],
        [1, 2],
        [1, 1],
      ],
      mobileGrid: [2, 2],
      mobileGridItems: [
        [2, 1],
        [1, 1],
        [1, 1],
      ],
    },
    {
      label: "Complejo",
      value: "complex",
      grid: [2, 3],
      gridItems: [
        [1, 2],
        [2, 1],
        [1, 1],
        [1, 1],
      ],
      mobileGrid: [3, 2],
      mobileGridItems: [
        [2, 1],
        [2, 1],
        [1, 1],
        [1, 1],
      ],
    },
    {
      value: "advanced",
      label: "Personalizado",
      grid: [4, 4],
      gridItems: [
        [2, 1],
        [2, 2],
        [2, 3],
        [1, 1],
        [1, 1],
        [2, 1],
      ],
      mobileGrid: [6, 2],
      mobileGridItems: [
        [2, 1],
        [2, 2],
        [2, 1],
        [1, 1],
        [1, 1],
        [2, 1],
      ],
    },
  ];

  const layout = [
    {
      label: "Cola",
      value: "queue",
      grid: [1, 1],
      gridItems: [[1, 1]],
    },
    {
      label: "Cola con mapa",
      value: "queueWithMap",
      grid: [1, 2],
      gridItems: [
        [1, 1],
        [1, 1],
      ],
    },
  ];

  const handleLocalResetFrontLayout = () => {
    handleResetFrontLayout();
    setResetFrontLayoutOpen(false);
    // Implement reset logic here
  };

  const renderGrid = (gridItems, rows, columns) => {
    return (
      <div
        style={{
          gridTemplateColumns: `repeat(${columns}, 1fr)`,
          gridTemplateRows: `repeat(${rows}, 1fr)`,
        }}
        className="grid w-full h-full *:bg-gray-200 dark:*:bg-gray-400 *:rounded-sm gap-1"
      >
        {gridItems.map((cell, index) => (
          <div
            key={index}
            style={{
              gridColumn: `span ${cell[0]}`,
              gridRow: `span ${cell[1]}`,
            }}
            className=" rounded-md"
          ></div>
        ))}
      </div>
    );
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-[fit-content_1fr] gap-x-4 gap-y-2 md:gap-y-4  items-start ">
      <h2 className="md:col-span-2">Apariencia</h2>
      <h3 className="md:col-span-2  mt-2">Diseño</h3>

      <Label className="h-9 items-center flex">Modo Oscuro</Label>
      <ButtonGroup>
        <Button
          className="rounded-r-none border border-r-0"
          variant={settings.darkMode === "light" ? "default" : "outline"}
          onClick={() =>
            handleChange({ target: { name: "darkMode", value: "light" } })
          }
        >
          Claro
        </Button>
        <Button
          className="rounded-none border border-r-0"
          variant={settings.darkMode === "dark" ? "default" : "outline"}
          onClick={() =>
            handleChange({ target: { name: "darkMode", value: "dark" } })
          }
        >
          Oscuro
        </Button>
        <Button
          className="rounded-l-none border-l-0 border"
          variant={settings.darkMode === "system" ? "default" : "outline"}
          onClick={() =>
            handleChange({ target: { name: "darkMode", value: "system" } })
          }
        >
          Automático
        </Button>
      </ButtonGroup>
      <Label className="h-9 items-center flex" id="tamano-de-fuente">
        Tamaño de fuente
      </Label>
      <div className="relative pt-4">
        <Slider
          name="fontSize"
          value={[fontSizeForSlider]}
          onValueChange={(value) =>
            handleChange({ target: { name: "fontSize", value: value[0] } })
          } // Handle value changes
          min={12}
          max={20}
          step={2}
          values={[
            { value: 12, label: "Muy pequeño" },
            { value: 14, label: "Pequeño" },
            { value: 16, label: "Predeterminado" },
            { value: 18, label: "Grande" },
            { value: 20, label: "Muy grande" },
          ]}
        />
        <div className="w-full flex justify-between px-1.5 mt-3">
          {["12px", "14px", "16px", "18px", "20px"].map((size, index) => (
            <div
              key={size}
              className={`h-2 w-px ${
                index === 2 ? "bg-black dark:bg-white w-0.5" : "bg-gray-400"
              }`}
            ></div>
          ))}
        </div>
        <div className="*:w-3 *:text-center mt-1 *:flex *:items-center *:justify-center w-full flex justify-between text-xs">
          <span style={{ fontSize: "12px" }}>Aa</span>
          <span style={{ fontSize: "14px" }}></span>
          <span style={{ fontSize: "16px" }}>Aa</span>
          <span style={{ fontSize: "18px" }}></span>
          <span style={{ fontSize: "20px" }}>Aa</span>
        </div>
      </div>

      <h3 className="md:col-span-2  mt-2">Diseño</h3>
      <Label className="h-9 items-center flex">
        Diseño de la página principal
      </Label>
      {storedUserProfile &&
        (storedUserProfile.role === "admin" ||
          storedUserProfile.role === "tecnico") && (
          <>
            <ButtonGroup className="!grid w-full grid-cols-2 justify-center">
              {advancedLayout.map((option, i) => (
                <Button
                  key={option.value}
                  className={`border border-gray-300 rounded-md h-fit flex flex-col gap-2 
                  ${
                    i === 0 &&
                    "rounded-none rounded-tl-md border border-r border-b"
                  }
                  ${
                    i === 1 &&
                    "rounded-none rounded-tr-md border border-l-0 border-b"
                  }
                  ${
                    i === 2 &&
                    "rounded-none rounded-bl-md border border-r border-t-0"
                  }
                  ${
                    i === 3 &&
                    "rounded-none rounded-br-md border border-l-0 border-t-0"
                  }
                  `}
                  variant={
                    settings.layoutMode === option.value ? "default" : "outline"
                  }
                  onClick={() => handleChange(option.value)}
                >
                  <div className="w-20 h-14">
                    {renderGrid(
                      option.gridItems,
                      option.grid[0],
                      option.grid[1]
                    )}
                  </div>
                  {option.label}
                </Button>
              ))}
            </ButtonGroup>
          </>
        )}
      {storedUserProfile &&
        (storedUserProfile.role === "guest" ||
          storedUserProfile.role === "regante" ||
          storedUserProfile.role === "agricultor") && (
          <>
            <ButtonGroup className="!grid w-full grid-cols-2 justify-center">
              {layout.map((option, i) => (
                <Button
                  key={option.value}
                  className={`border border-gray-300 rounded-md h-fit flex flex-col gap-2 
                  ${
                    i === 0 &&
                    "rounded-none rounded-l-md border border-r border-y"
                  }
                  ${
                    i === 1 &&
                    "rounded-none rounded-r-md border border-l-0 border-y"
                  }
                  `}
                  variant={
                    settings.layoutMode === option.value ? "default" : "outline"
                  }
                  onClick={() => handleChange(option.value)}
                >
                  <div className="w-20 h-14">
                    {renderGrid(
                      option.gridItems,
                      option.grid[0],
                      option.grid[1]
                    )}
                  </div>
                  {option.label}
                </Button>
              ))}
            </ButtonGroup>
          </>
        )}

      <Label className="h-9 items-center flex">
        Restablecer diseño de pagina inicial
      </Label>
      <Popover
        open={resetFrontLayoutOpen}
        onOpenChange={setResetFrontLayoutOpen}
      >
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className="w-fit border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
          >
            Resetear
          </Button>
        </PopoverTrigger>
        <PopoverContent className="flex flex-col gap-y-2">
          <Label className="h-9 items-center flex leading-5">
            Estas a punto de resetear el Layout.
          </Label>
          <Button
            className="w-fit ml-auto"
            variant="destructive"
            onClick={handleLocalResetFrontLayout}
          >
            Resetear
          </Button>
        </PopoverContent>
      </Popover>
      {storedUserProfile &&
        (storedUserProfile.role === "admin" ||
          storedUserProfile.role === "tecnico") && (
          <>
            <Label className="h-9 items-center flex" id="sidebarPosition">
              Posición barra lateral
            </Label>
            <div className="flex w-full">
              <Button
                className="rounded-r-none w-full border border-r-0"
                variant={
                  settings.sidebarPosition === "left" ? "default" : "outline"
                }
                onClick={() =>
                  handleChange({
                    target: { name: "sidebarPosition", value: "left" },
                  })
                }
              >
                Izquierda
              </Button>
              <Button
                className="rounded-l-none w-full border border-l-0"
                variant={
                  settings.sidebarPosition === "right" ? "default" : "outline"
                }
                onClick={() =>
                  handleChange({
                    target: { name: "sidebarPosition", value: "right" },
                  })
                }
              >
                Derecha
              </Button>
            </div>
          </>
        )}
    </div>
  );
};

export default AppearanceSettings;
