import React, { useState, useEffect } from "react";
import { ResponsiveDataTable } from "components/widgets/widgetTypes/tables/ResponsiveDataTable";
import proj4 from "proj4";

const ComparativeDataTable = ({
  data,
  options,
  columnsConfig,
  onUpdateColumnsConfig,
  selectedRowIds,
  onRowSelectionChange,
  enablePagination = false,
  showHeaders = true,
  filterFor = "location",
  filterForLabel = "Ubicación",
}) => {
  const updateColumnsConfig = (newConfig) => {
    onUpdateColumnsConfig(newConfig);
  };
  const wgs84 = "EPSG:4326";
  const utmZone30 = "+proj=utm +zone=30 +datum=WGS84 +units=m +no_defs";

  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    let tempData = [];

    selectedRowIds.forEach((rowId) => {
      const entry = data.find((entry) => entry.id === rowId);

      if (!entry) return;

      const dynamicKey = entry.deviceId
        .replace(/[^a-z0-9]/gi, "")
        .toLowerCase();

      const [longitude, latitude] = proj4(utmZone30, wgs84, [
        entry.location.coordinates[0],
        entry.location.coordinates[1],
      ]);

      const getLastMeasurementDate = () => {
        let lastDate = null;
        entry.data.forEach((category) => {
          category.measurements.forEach((measurement) => {
            const measurementDate = new Date(measurement.date);
            if (!lastDate || measurementDate > lastDate) {
              lastDate = measurementDate;
            }
          });
        });
        return lastDate;
      };

      const lastMeasurementDate = getLastMeasurementDate();

      const entryData = [
        { label: "Ciudad", [dynamicKey]: entry.location.city },
        {
          label: "Ubicación",
          [dynamicKey]: entry.location.specificLocation,
        },
        {
          label: "Presión (kPa)",
          [dynamicKey]: entry.data
            .find((d) => d.category === "pressure")
            ?.measurements.slice(-1)[0]?.value,
        },
        {
          label: "Temperatura (°C)",
          [dynamicKey]: entry.data
            .find((d) => d.category === "temperature")
            ?.measurements.slice(-1)[0]?.value,
        },
        {
          label: "Caudal (m³/s)",
          [dynamicKey]: entry.data
            .find((d) => d.category === "flow")
            ?.measurements.slice(-1)[0]?.value,
        },
        {
          label: "Coordenadas",
          [dynamicKey]: `Lat: ${latitude.toFixed(6)}, Lng: ${longitude.toFixed(
            6
          )}`,
        },
        {
          label: "Última Medición",
          [dynamicKey]: lastMeasurementDate
            ? Intl.DateTimeFormat("es-ES", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }).format(lastMeasurementDate)
            : "Fecha no disponible",
        },
      ];

      tempData.push(...entryData);
    });

    const formattedData = tempData.reduce((acc, item) => {
      const label = item.label;
      const keys = Object.keys(item).filter((k) => k !== "label");
      if (!acc[label]) {
        acc[label] = { label };
      }
      keys.forEach((key) => {
        acc[label][key] = item[key];
      });
      return acc;
    }, {});

    const result = Object.values(formattedData);

    setFilteredData(result);
  }, [selectedRowIds, data]);

  useEffect(() => {
    const dynamicColumns = selectedRowIds.map((rowId) => {
      const deviceEntry = data.find((entry) => entry.id === rowId);
      if (!deviceEntry) return null;

      return {
        id: deviceEntry.deviceId.replace(/[^a-z0-9]/gi, "").toLowerCase(),
        type: "default",
        accessorKey: deviceEntry.deviceId
          .replace(/[^a-z0-9]/gi, "")
          .toLowerCase(),
        header: deviceEntry.title,
      };
    });

    const baseColumns = [
      {
        id: "label",
        type: "default",
        accessorKey: "label",
        header: "Sensor",
      },
    ];
    updateColumnsConfig([...baseColumns, ...dynamicColumns]);
  }, [selectedRowIds, data]);

  return (
    <>
      {data && data.length && selectedRowIds.length > 0 ? (
        <div
          key="analytics-data-table-2"
          id="analytics-data-table-2"
          className="  overflow-y-auto w-full h-full"
        >
          <ResponsiveDataTable
            data={filteredData}
            isEmptyMessage={
              "Selecciona uno o más sensores para ver los datos comparativos"
            }
            columnsConfig={columnsConfig}
            showHeaders={false}
          />
        </div>
      ) : (
        <div className="flex h-full items-center justify-center">
          <span>
            Selecciona uno o más sensores para ver los datos comparativos
          </span>
        </div>
      )}
    </>
  );
};

export default ComparativeDataTable;
