import React from "react";
import { useStateContext } from "contexts/ContextProvider";
import { ReactComponent as StoreIcon } from "icons/archive.svg";
import { Button } from "components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { availableWidgets } from "data/chartData/frontLayout";

const StoredWidgets = () => {
  const { userProfile, handleRestoreStoredWidget } = useStateContext(); // Assuming handleRestoreStoredWidget function exists

  const getWidgetIcon = (type) => {
    // Find the widget in availableWidgets by type
    const widgetInfo = availableWidgets.find((widget) => widget.type === type);
    return widgetInfo ? widgetInfo.icon : null; // Assuming icon is a React component
  };
  const getWidgetTitle = (type) => {
    // Find the widget in availableWidgets by type
    const widgetInfo = availableWidgets.find((widget) => widget.type === type);
    return widgetInfo ? widgetInfo.title : null; // Assuming icon is a React component
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          className="text-gray-500 pl-0.5 pr-2 dark:text-gray-200 h-7 items-center flex hover:text-green-600 dark:hover:text-green-300"
          variant="ghost">
          <StoreIcon className="size-6 p-1 text-current" />
          <span className="text-xs hidden md:inline-flex">Almacenado</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p w-fit">
        {userProfile.userSettings.storedWidgets.length === 0 ? (
          <span>No stored widgets</span>
        ) : (
          <>
            <h4 className="mb-2">Stored Widgets</h4>
            <ul className="flex flex-col gap-y-2">
              {userProfile.userSettings.storedWidgets.map(
                (widget) => (
                  console.log("widget stored", widget),
                  (
                    <li
                      className=" flex p-3 gap-y-4 bg-gray-50 dark:bg-gray-1000 flex-col transition-colors rounded-md"
                      key={widget.id}>
                      <div className="h-10 items-center gap-x-2 flex">
                        <div className="size-8">
                          {getWidgetIcon(widget.type)}
                        </div>
                        <span>{getWidgetTitle(widget.type)}</span>
                      </div>
                      {/* <span>Options:</span> */}
                      <div className=" text-sm items-center gap-x-3 gap-y-0.5 grid grid-cols-[auto_1fr]">
                        {widget.options.location && (
                          <>
                          <span className="font-semibold">Location:</span>
                            <span>{widget.options.location}</span>
                          </>
                        )}
                        {widget.options.city && (
                          <>
                          <span className="font-semibold">City:</span>
                            <span>{widget.options.city}</span>
                          </>
                        )}
                        {widget.options.valueType && 
                          <>
                            <span className="font-semibold">Parameter:</span>
                            <span>{widget.options.valueType}</span>
                          </>
                        }
                        {widget.options.limits && 
                          <>
                            <span className="font-semibold mb-auto">Limits:</span>
                          <div className="grid gap-x-2 grid-cols-[auto_1fr]">

                            <span className="">Danger:</span>
                            <span>{widget.options.limits.yellow}</span>
                            <span className="">Critical:</span>
                            <span>{widget.options.limits.red}</span>
                          </div>

                          </>
                        }
                      </div>
                      <Button
                        variant="secondary"
                        className="text-gray-500  pl-0.5 pr-2 dark:text-gray-200 h-7 items-center flex hover:text-green-600 dark:hover:text-green-300"
                        onClick={() => handleRestoreStoredWidget(widget.id)}>
                        Restaurar
                      </Button>
                    </li>
                  )
                )
              )}
            </ul>
          </>
        )}
      </PopoverContent>
    </Popover>
  );
};

export default StoredWidgets;
