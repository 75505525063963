import React, { useState, useEffect } from 'react';

function formatDateTime(dateTime) {
  const date = new Date(dateTime);
  // Adjust for the timezone offset to get the correct local time
  const localTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
  return localTime.toISOString().slice(0, 16); // "YYYY-MM-DDTHH:MM" format
}

const EditTaskModal = ({ isOpen, onClose, event, onSave }) => {

  const [task, setTask] = useState({
    nameTask: event?.title || '',
    typeTask: event?.typeTask || '',
    startTimeTask: event?.start || '',
    endTimeTask: event?.end || '',
  });

  useEffect(() => {
    if (event) {
      setTask({
        nameTask: event.title,
        typeTask: event.typeTask,
        startTimeTask: event.start,
        endTimeTask: event.end,
      });
    }
  }, [event]);

  useEffect(() => {
    // Use the formatDateTime function to format the event's start and end times
    if (event) {
      setTask({
        nameTask: event.title || '',
        typeTask: event.typeTask || '',
        startTimeTask: event.start ? formatDateTime(event.start) : '',
        endTimeTask: event.end ? formatDateTime(event.end) : '',
      });
    }
  }, [event]);

  const handleTaskChange = (e) => {
    setTask({
      ...task,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({ ...event, ...task });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className='fixed inset-0 bg-gray-500 z-10 bg-opacity-75 flex justify-center items-center'>
      <div className='bg-white dark:bg-gray-950 shadow-xl rounded-md p-4 w-full max-w-md'>
        <h2>Edit Task</h2>
        <form onSubmit={handleSubmit} className='flex flex-col gap-y-2'>
          <label className='text-sm -mb-2.5'>Title:</label>
          <input 
            className='rounded-md border dark:bg-gray-950 mt-2'
            type='text'
            name='nameTask'
            value={task.nameTask}
            onChange={handleTaskChange}
          />
          <label className='text-sm -mb-2.5'>Priority:</label>
          <select
            className='rounded-md border dark:bg-gray-950 mt-2'
            name='typeTask'
            value={task.typeTask}
            onChange={handleTaskChange}
          >
            <option value='green'>Low</option>
            <option value='yellow'>Mid</option>
            <option value='red'>High</option>
          </select>
          <label className='text-sm -mb-2.5'>Start:</label>
          <input 
            className='rounded-md border dark:bg-gray-950 mt-2'
            type='datetime-local'
            name='startTimeTask'
            value={task.startTimeTask}
            onChange={handleTaskChange}
          />
          <label className='text-sm -mb-2.5'>End:</label>
          <input 
            className='rounded-md border dark:bg-gray-950 mt-2'
            type='datetime-local'
            name='endTimeTask'
            value={task.endTimeTask}
            onChange={handleTaskChange}
          />
          <div className='flex justify-between mt-4'>
            <button type='submit' className='bg-blue-500 text-white rounded-md px-4 py-2'>Save</button>
            <button onClick={onClose} className='bg-gray-500 text-white rounded-md px-4 py-2'>Close</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditTaskModal;
