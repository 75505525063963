export const getFormattedDate = (date) => {
  const daysOfWeek = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
  const monthsOfYear = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  const dayOfWeek = daysOfWeek[date.getDay()];
  const day = date.getDate().toString();
  const month = monthsOfYear[date.getMonth()];
  const year = date.getFullYear();

  return `${dayOfWeek}, ${day}. ${month} ${year}`;
};

export const getFormattedTime = (date) => {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes}`;
};

export const formatDateTimeRange = (start, end) => {
  const startDate = getFormattedDate(start);
  const startTime = getFormattedTime(start);
  const endDate = getFormattedDate(end);
  const endTime = getFormattedTime(end);

  if (startDate === endDate) {
    if (startTime === endTime) {
      return (
        <>
          {startDate}
          <br />
          A las {startTime}
        </>
      );
    } else {
      return (
        <>
          {startDate}
          <br />
          De {startTime} hasta {endTime}
        </>
      );
    }
  } else {
    return (
      <>
        De {startTime} {startDate}
        <br />
        hasta {endTime} {endDate}
      </>
    );
  }
};
