import React, { useState, useEffect, useMemo } from "react";
import DataSelector from "../DataSelectors/DataSelector";
import EditDashboardMenu from "./EditDashboardMenu";
import ToggleWidgetData from "./ToggleWidgetData";
import WidgetContent from "./WidgetContent";
import { useStateContext } from "contexts/ContextProvider";
// import { ReactComponent as CloseIcon } from "icons/close.svg";
import { Button } from "components/ui/button";
import ProtectedWidget from "components/authentication/ProtectedWidget";
import useWidgetPermissions from "components/authentication/useWIdgetPermissions";

const DashboardWidget = ({
  id,
  type,
  key,
  columnsConfig,
  selectedRowIds,
  onRowSelectionChange,
  onUpdateColumnsConfig,
  isHidden = false,
}) => {
  const widgetType = type;
  // const hasPermission = permissionType === 'view' ? canView : permissionType === 'edit' ? canEdit : false;
  
  // TODO get Data from global state
  
  const [showDataSelector, setShowDataSelector] = useState(false);
  const {
    userProfile,
    handleUpdateWidgetOptions,
    handleUpdateWidgetType,
    handleSetWidgetDefaultOptions,
    isLoadingUserPermissions,
    setIsLoadingUserPermissions,
    activeLinkName,
    unifiedData,
  } = useStateContext();
  
  const { permissions } = useWidgetPermissions(widgetType, isLoadingUserPermissions, setIsLoadingUserPermissions);


  const widget = userProfile.userSettings.widgets.find((w) => w.id === id);
  // const canEdit = hasPermission("notificationData.view", isLoadingUserPermissions, setIsLoadingUserPermissions);

  const canEdit = permissions.canEdit;

  const [data, setData] = useState(unifiedData);

  useEffect(() => {
    setData(unifiedData);
    // console.log("DashboardWidget unifiedData: ",unifiedData);
  }, [unifiedData]);

  const handleUpdateOptions = (newOptions) => {
    console.log("DashboardWidget: ToggleWidgetData ", newOptions);
    handleUpdateWidgetOptions(id, newOptions); // Assuming handleUpdateWidgetOptions accepts widgetId and new options
    setShowDataSelector(false);
  };

  const handleUpdateType = (widgetId, newType) => {
    handleUpdateWidgetType(widgetId, newType); // This function should just update the type
    handleSetWidgetDefaultOptions(widgetId, newType); // This function sets the default options for the new type
  };

  const toggleDataSelector = () => {
    setShowDataSelector(!showDataSelector);
  };

  function displayEditMenu(activeLinkName, type) {
    // Step 1: Define the array of strings with link names
    // const linkNames = ["simple", "complex", "advanced"];
    const unEditableDashboardLinks = [
      "community",
      "maps",
      "settings",
      "queue",
      "queueWithMap",
      "events",
      "parcels",
      "calendar",
      "profile",
      "analytics",
    ];

    // Step 2: Check if activeLinkName is included in the array
    // const isActiveLink = linkNames.includes(activeLinkName);
    const isActiveLink = !unEditableDashboardLinks.includes(activeLinkName);

    // Define a set of widget types that should display the edit menu
    const editableWidgetTypes = new Set([
      "calendar",
      "details",
      "map", // Uncomment or add more types as needed
      "lineChart",
      "gaugeChart",
      "detailBarChart",
      "queue",
      "table",
      "editable", // Assuming 'editable' is also a type that should display the edit menu
    ]);

    // Step 3: Check if the widget type is in the set of editable widget types
    const isEditableWidget = editableWidgetTypes.has(type);

    // Step 4: Combine conditions
    return isActiveLink && isEditableWidget;
  }
  const shouldDisplayEditMenu = displayEditMenu(activeLinkName, type);

  // useEffect(() => {
  //   console.log("DashboardWidget: ", type, shouldDisplayEditMenu);
  // }),[];
    

  return (
    <div
      key={key}
      className={`${
        type !== "filteredDataTable" &&
        type !== "communityUsers" &&
        type !== "profile" &&
        type !== "fullCalendar" &&
        type !== "parcels" &&
        type !== "settings" &&
        !isHidden &&
        "dashboard-widget p-1 sm:p-2"
      }  group w-full h-full relative flex flex-col ${
        showDataSelector ? "active " : "overflow-clip"
      } h-full [container-type:size] `}>
      {/* <div className="absolute">
          {activeLinkName}
        </div> */}
      {!showDataSelector && ( //Editar widgets
        <>
          <div className={`top-3 right-3  absolute z-40 flex gap-x-2`}>
            {(type === "lineChart" ||
              type === "analyticsLineChart" ||
              type === "detailBarChart") && (
              <ToggleWidgetData
                type={type}
                widgetId={id}
                onSave={handleUpdateOptions}
              />
            )}
            {canEdit && shouldDisplayEditMenu && (
              <EditDashboardMenu
                id={id}
                activeLinkName={activeLinkName}
                type={type}
                toggleDataSelector={toggleDataSelector}
              />
            )}
          </div>
        </>
      )}
      {showDataSelector ? ( //
        <DataSelector
          toggleDataSelector={toggleDataSelector}
          type={type}
          widgetId={id}
          onSave={handleUpdateOptions}
        />
      ) : (
        
        //Displays widget if user's role has permission to do it. NOT TESTED YET
        <ProtectedWidget
          component={WidgetContent}
          isLoadingUserPermissions={isLoadingUserPermissions}
          setIsLoadingUserPermissions={setIsLoadingUserPermissions}
          widgetType={type}
          permissionType="view"
          id={id}
          type={type}
          data={data} // TODO: REPLACE WITH GLOBAL STATE
          toggleDataSelector={toggleDataSelector}
          options={widget.options}
          columnsConfig={widget.columnsConfig || columnsConfig}
          onUpdateType={handleUpdateType}
          selectedRowIds={selectedRowIds}
          onRowSelectionChange={onRowSelectionChange}
          onUpdateColumnsConfig={onUpdateColumnsConfig}
        />
        
      )}
    </div>
  );
};

export default DashboardWidget;

// <WidgetContent
// widgetType={type}
// id={id}
// data={data} // TODO: REPLACE WITH GLOBAL STATE
// toggleDataSelector={toggleDataSelector}
// options={widget.options}
// columnsConfig={widget.columnsConfig || columnsConfig}
// onUpdateType={handleUpdateType}
// selectedRowIds={selectedRowIds}
// onRowSelectionChange={onRowSelectionChange}
// onUpdateColumnsConfig={onUpdateColumnsConfig}
// />