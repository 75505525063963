import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import Logo from "logo.svg";
import { InputWithLabelAndError } from "components/ui/InputWithLabelAndError";
import { useStateContext } from "contexts/ContextProvider";

const ForgotPasswordFrame = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const { setActiveComponent } = useStateContext();

  const validate = () => {
    let isValid = true;
    let errors = {};

    if (!email) {
      isValid = false;
      errors.email = "El email es necesario";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      isValid = false;
      errors.email = "El email no es válido";
    }

    setErrors(errors);
    return isValid;
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        // Call the serverless function to send reset token
        //https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/auth/passwordResetRequest
        //const response = await fetch("/api/passwordResetRequest", {
        const response = await fetch(
          "https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/auth/passwordResetRequest",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, baseUrl: window.location.origin }),
          }
        );
        console.log(
          "ForgotPasswordFrame email, baseUrl, window.location.origin: ",
          email,
          window.location.origin
        );
        if (response.ok) {
          const data = await response.json();
          console.log("ForgotPassword data", data);
          setMessage(
            "Te hemos enviado un email para crear una nueva contraseña."
          );
        } else {
          console.error("Non-OK response:", response);
          setMessage("Error en la respuesta del servidor");
        }
      } catch (error) {
        console.error("Request error:", error);
        setMessage("Error conectando con el servidor");
      }
    }
  };

  return (
    <div className="max-w-md w-full flex flex-col gap-y-4 md:gap-y-8">
      <div>
        <h2 className=" font-medium leading-7 dark:text-white">
          Recuperar contraseña
        </h2>
        <span className="text-sm dark:text-gray-200">
          Escriba el correo y le enviaremos un enlace para restablecer la
          contraseña.
        </span>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-5">
          <InputWithLabelAndError
            value={email}
            onChange={handleChange}
            type="email"
            name="email"
            label="Email"
            placeholder="johndoe@mail.com"
            hasFloatingLabel={false}
            error={errors.email}
          />
        </div>
        <button
          type="submit"
          className="mt-2 w-full bg-[#1D91E0] text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
        >
          Enviar correo de recuperación
          {/* Enviar correo de restablecimiento */}
        </button>
      </form>
      {message && <p className="text-dark-blue-950 w-fit">{message}</p>}
      {/* {messageRed && <p className="text-dark-blue-950 w-fit">{messageRed}</p>} */}
      {errors.success && (
        <p className="text-sm text-green-500">{errors.success}</p>
      )}

      <div className="flex flex-col sm:flex-row justify-between items-center gap-2">
        <p className="text-sm text-center dark:text-white ">
          ¿No te redirecciona a la pantalla de login?
          <Link
            className="inline-block text-center ml-1 text-[#1D91E0] hover:text-blue-600"
            //to="/login"
            onClick={() => setActiveComponent("login")}
          >
            Login
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ForgotPasswordFrame;
