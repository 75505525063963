import React, { useEffect, useMemo, useState } from "react";
import { Button } from "components/ui/button";
import { parseISO, isSameDay } from "date-fns";
import { ReactComponent as CalendarIcon } from "icons/calendar.svg";
import { ReactComponent as EditIcon } from "icons/edit.svg";
import { ReactComponent as WaterDropIcon } from "icons/water-drop.svg";
import QueueItem from "components/widgets/queue/QueueItem";
import QueueDurationInput from "components/widgets/queue/QueueDurationInput";
import { useEventHandlers } from "contexts/EventHandlers";
import { toDate } from "date-fns-tz";

const timeZone = "Europe/Madrid";

const QueueCurrentUserItem = ({
  isAddingDuration,
  setIsAddingDuration,
  preferences,
  setPreferences,
  handleSelectChange,
  selectedDuration,
  durationOptions,
  calculateArrivalTime,
  selectedDate,
  isUpdating,
  setIsUpdating,
  currentUserEvent,
  handleEventExists,
  handleSubmit,
}) => {
  useEffect(() => {
    if (isUpdating && currentUserEvent) {
      setPreferences({ duration: currentUserEvent.duration });
    }
  }, [isUpdating]);

  return (
    <>
      {isAddingDuration ? (
        <li className="flex gap-x-1">
          <QueueDurationInput
            isAddingDuration={isAddingDuration}
            setIsAddingDuration={setIsAddingDuration}
            preferences={preferences}
            handleSelectChange={handleSelectChange}
            selectedDuration={selectedDuration}
            durationOptions={durationOptions}
            calculateArrivalTime={calculateArrivalTime}
            selectedDate={selectedDate}
            isUpdating={isUpdating}
            currentUserEvent={currentUserEvent}
            onEventExists={handleEventExists}
            onSubmit={handleSubmit}
          />
        </li>
      ) : (
        <>
          {currentUserEvent ? (
            <li className="gap-y-1 gap-x-2 flex flex-col md:flex-row">
              <Button
                size="lg"
                className="w-full"
                variant="default"
                onClick={() => {
                  setIsAddingDuration(true);
                  setIsUpdating(true);
                }}>
                Modificar duración
                <EditIcon className="w-5 h-5 ml-2" />
              </Button>
            </li>
          ) : (
            <li className="gap-2 flex">
              <Button
                size="lg"
                className="w-full"
                variant="default"
                onClick={() => {
                  setIsAddingDuration(true);
                  setIsUpdating(false);
                }}>
                Quiero regar
                <WaterDropIcon className="w-6 h-6 ml-2" />
              </Button>
            </li>
          )}
        </>
      )}
    </>
  );
};

const QueueList = ({
  selectedDate,
  usersInOrder,
  currentUserParcela,
  isAddingDuration = false,
  setIsAddingDuration,
  preferences,
  setPreferences,
  handleSelectChange,
  durationOptions,
  calculateArrivalTime,
  user_id,
}) => {
  const today = new Date();
  const isThisDayAfterToday = (date) => {
    // console.log("isThisDayAfterToday date", date.toISOString());
    // console.log("isThisDayAfterToday today", today.toISOString());
    return date.toISOString() >= today.toISOString();
  };
  // console.log("QueueList selectedDate", selectedDate);
  // const { isEditingEvent, setIsEditingEvent } = useEventHandlers();
  const usersForSelectedDate = usersInOrder.filter((user) => {
    //const userStart = typeof user.start === 'string' ? parseISO(user.start) : new Date(user.start); //Original code
    //return isSameDay(userStart, parseISO(selectedDate));
    //const parsedSelectedDate = typeof selectedDate === 'string' ? parseISO(selectedDate) : new Date(selectedDate); //Original code
    //return isSameDay(userStart, parsedSelectedDate); //Original code
    const userStart =
      typeof user.start === "string"
        ? new Date(user.start)
        : new Date(user.start);
    //console.log("QueueList userStart", userStart);
    const parsedSelectedDate =
      typeof selectedDate === "string"
        ? new Date(selectedDate)
        : new Date(selectedDate);
    //console.log("QueueList parsedSelectedDate", parsedSelectedDate);
    return isSameDay(userStart, parsedSelectedDate);
    /* const userStart = typeof user.start === 'string' ? new Date(user.start) : user.start;
    return isSameDay(userStart, new Date(selectedDate)); */
  });

  // useEffect(() => {
  //   console.log("QueueList usersInOrder", usersInOrder);
  //   console.log("QueueList selectedDate", selectedDate);
  //   console.log("QueueList usersForSelectedDate", usersForSelectedDate);
  // }, []);

  const currentUserEvent = usersForSelectedDate.find(
    (user) => user.user_id === user_id
  );

  useEffect(() => {
    //console.log("QueueList currentUserEvent: ", currentUserEvent);
  }, [currentUserEvent]);

  const [eventExists, setEventExists] = useState(false); //TODO: Modify it for updating
  const [currentUser, setCurrentUser] = useState(currentUserEvent);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setCurrentUser(currentUserEvent);
  }, [currentUserEvent]);

  //Temporarily use user_id instead of parcela because the db doesn't have data for parcelas yet

  const handleRowClick = (user) => {
    /* console.log("QueueList inside handleRowClick user: ",user);
    console.log("QueueList Event ID:", user.event_id);
    console.log("QueueList Start:", user.start);
    console.log("QueueList User ID:", user.user_id); */

    if (user.user_id === user_id) {
      console.log("QueueList event's user is the same as user logged in");
      setCurrentUser(user);
      setIsAddingDuration(!isAddingDuration);
      // setIsUpdating(true);
    }
  };

  // This needs to get details from QueueWidget
  const handleSubmit = () => {
    //Handle the submit logic
    /* console.log("QueueList Submitted duration: ", preferences.duration); */
    setIsAddingDuration(false); //Close the duration input after submission
    setIsUpdating(false); //Reset isUpdating state

    // setIsEditingEvent(false); //Reset isEditingEvent state
  };

  const handleEventExists = () => {
    setEventExists(true); // ! TODO REMOVE Este no hace nada creo - SJ
    setIsAddingDuration(true);
  };
  // useEffect(() => {
  //   console.log("currentUser", currentUser);
  //   console.log("currentUserEvent", currentUserEvent);
  //   console.log("isUpdating", isUpdating);
  //   console.log("isAddingDuration", isAddingDuration);
  // }, [currentUser]);

  return (
    <div className="h-fit flex-col flex">
      <div className="mb-2 pt-2 w-full flex gap-x-2 font-medium text-center">
        <CalendarIcon className="w-6 h-6" />
        Cola
        <span className="ml-auto">
          {new Intl.DateTimeFormat("es-ES", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(selectedDate))}
        </span>
      </div>
      <ul className="flex flex-col gap-y-1">
        {usersForSelectedDate.length === 0 &&
        !isThisDayAfterToday(selectedDate) ? (
          <li className="text-center text-gray-500 pb-2">Este día nadie regó.</li>
        ) : (
          usersForSelectedDate.length === 0 &&
          isThisDayAfterToday(selectedDate) && (
            <li className="text-center text-gray-500 pb-2">
              No hay ningún usuario en cola
            </li>
          )
        )}
        {usersForSelectedDate.length === 0 &&
          isThisDayAfterToday(selectedDate) && (
            <QueueCurrentUserItem
              isAddingDuration={isAddingDuration}
              setIsAddingDuration={setIsAddingDuration}
              preferences={preferences}
              setPreferences={setPreferences}
              handleSelectChange={handleSelectChange}
              selectedDuration={currentUser?.duration || preferences.duration}
              durationOptions={durationOptions}
              calculateArrivalTime={calculateArrivalTime}
              selectedDate={selectedDate}
              setIsUpdating={setIsUpdating}
              currentUserEvent={currentUserEvent}
              handleEventExists={handleEventExists}
              onSubmit={handleSubmit}></QueueCurrentUserItem>
          )}

        {usersForSelectedDate.map((user) => {
          // console.log("QueueList user", user);
          // console.log("QueueList user_id", user_id);
          return (
            <>
              <QueueItem
                user_id={user_id}
                user={user}
                key={user.id}
                onRowClick={handleRowClick}
              />
              {(currentUserEvent) &&
                isThisDayAfterToday(selectedDate) && (
                  <QueueCurrentUserItem
                    isAddingDuration={isAddingDuration}
                    setIsAddingDuration={setIsAddingDuration}
                    preferences={preferences}
                    setPreferences={setPreferences}
                    handleSelectChange={handleSelectChange}
                    selectedDuration={preferences.duration}
                    isUpdating={isUpdating}
                    setIsUpdating={setIsUpdating}
                    durationOptions={durationOptions}
                    calculateArrivalTime={calculateArrivalTime}
                    selectedDate={selectedDate}
                    currentUserEvent={currentUserEvent}
                    handleEventExists={handleEventExists}
                    onSubmit={handleSubmit}></QueueCurrentUserItem>
                )}
            </>
          );
        })}
      </ul>
    </div>
  );
};

export default QueueList;
