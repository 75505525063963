import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useStateContext } from "contexts/ContextProvider";
import { debounce } from "lodash"; // Ensure lodash is installed or use your own debounce function
import { ReactComponent as EditIcon } from "icons/edit.svg";
import DashboardWidget from "components/widgets/widgetWrapper/DashboardWidget";
import AddWidgetGallery from "components/widgets/widgetWrapper/AddWidgetGallery";
import StoredWidgets from "components/widgets/widgetWrapper/StoredWidgets";
import { Button } from "components/ui/button";
import { Icon } from "@iconify/react";
import { ReactComponent as SyncIcon } from "icons/sync.svg";
import { Responsive, WidthProvider } from "react-grid-layout"; // correct
// import { useHandleSetLayout } from "contexts/functions/layoutFunctions";
import { hasPermission } from "components/authentication/hasPermission";
import {
  fetchSensorsDataLatest,
  fetchSensorsDataLast5,
} from "helpers/apiFIbsenCalls";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import { ScrollArea } from "components/ui/scroll-area";

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = () => {
 
  const {
    userProfile,
    activeLinkName,
    isEditingDashboardLayout,
    setIsEditingDashboardLayout,
    isLoading,
    isLoadingUserPermissions,
    setIsLoadingUserPermissions,
    setIsLoading,
    handleSetLayout,
    latestUpdateTime,
    fetchSensorsDataFromAPI,
  } = useStateContext();
  

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const handleRowSelectionChange = (rowIds) => {
    setSelectedRowIds(rowIds); 
  };

  const [columnsConfig, setColumnsConfig] = useState([]);
  const handleUpdateColumnsConfig = (newConfig) => {
    setColumnsConfig(newConfig);
  };

  const [currentTime, setCurrentTime] = useState(new Date());
  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date()); 
    }, 1000);

    return () => clearInterval(timerId); 
  }, []);


  const [rowHeight, setRowHeight] = useState(25); 
  const MIN_ROW_HEIGHT = 30; 

  const gridContainerRef = React.useRef(null); 
  const margin = [16, 16]; 
  const maxRows = 6;

  const toggleEditMode = () => {
    setIsEditingDashboardLayout(!isEditingDashboardLayout);
  };

  const debouncedUpdateRowHeight = useCallback(
    debounce(() => {
      if (gridContainerRef.current) {
        const containerHeight = gridContainerRef.current.clientHeight;
        const numberOfRows = maxRows;
        const totalMarginHeight = margin[1] * (numberOfRows - 1);
        const effectiveHeight = containerHeight - totalMarginHeight;

        let calculatedRowHeight = effectiveHeight / numberOfRows;

        if (calculatedRowHeight < MIN_ROW_HEIGHT) {
          calculatedRowHeight = MIN_ROW_HEIGHT;
        }

        setRowHeight(calculatedRowHeight);
      }
    }, 250),
    [MIN_ROW_HEIGHT] 
  );

  const handleResize = () => debouncedUpdateRowHeight();

  useEffect(() => {
    handleResize(); 
  }, [MIN_ROW_HEIGHT]); 

  useEffect(() => {
    const handleResize = () => debouncedUpdateRowHeight();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      debouncedUpdateRowHeight.cancel();
    };
  }, [debouncedUpdateRowHeight]); 

  const widgetElements = useMemo(() => {
    if (
      !userProfile ||
      !userProfile.userSettings ||
      !userProfile.userSettings.dashboardLayout ||
      !Array.isArray(userProfile.userSettings.widgets) 
    ) {
      return [];
    }

    return Object.values(userProfile.userSettings.dashboardLayout)
      .flatMap((layout) => layout)
      .reduce((acc, layoutItem) => {
        if (!acc.some((item) => item.id === layoutItem.i)) {
          const matchingWidget = userProfile.userSettings.widgets.find(
            (widget) => widget.id === layoutItem.i
          );
          if (matchingWidget) {
            acc.push({
              ...matchingWidget,
              i: layoutItem.i,
            });
          }
        }
        return acc;
      }, []);
  }, [
    userProfile.userSettings.dashboardLayout,
    userProfile.userSettings.widgets,
  ]);

  useEffect(() => {
    
  }, [widgetElements, userProfile]);

  const mapIsHidden = [
    "community",
    "settings",
    "parcels",
    "events",
    "calendar",
    "profile",
  ];
  const unEditableDashboardLinks = [
    "community",
    "maps",
    "settings",
    "queue",
    "queueWithMap",
    "events",
    "calendar",
    "profile",
    "analytics",
  ];

  const [userIsAGuest, setUserIsAGuest] = useState(false);
  useEffect(() => {
    if (
      userProfile &&
      userProfile.isUserAGuest &&
      userProfile.isUserAGuest === true
    ) {
      setUserIsAGuest(true);
    }
  }, [userProfile]);

  
  const [canEditHome, setCanEditHome] = useState(false);
  useEffect(() => {
    const checkPermission = async () => {
      const permission = await hasPermission(
        "home.edit",
        isLoadingUserPermissions,
        setIsLoadingUserPermissions
      );
      setCanEditHome(permission);
    };
    checkPermission();
  }, [isLoadingUserPermissions, setIsLoadingUserPermissions]);

  return (
    <div className="w-full md:overflow-y-clip h-full flex flex-col">

      <div className="absolute top-2 md:top-0 inset-x-0 bg-gray-50 dark:bg-gray-1000 h-4 z-10"></div>
      <div className="absolute bottom-0 inset-x-0 bg-gray-50 dark:bg-gray-1000 h-2 z-10"></div>

      {isEditingDashboardLayout && (
        <>
          <div className="absolute pointer-events-none inset-2 border-dashed animate-pulse border-[1.5px]  rounded-xl border-green-500 dark:border-green-700 bg-opacity-50 dark:bg-opacity-90 z-10"></div>
        </>
      )}
      {isLoading ||
        (isLoadingUserPermissions && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-50 dark:bg-gray-1000 z-50">
            <Icon
              icon="svg-spinners:blocks-shuffle-3"
              className="size-16 text-green-500"
            />
          </div>
        ))}
      <div className="flex flex-row sticky px-2 md:px-4 top-14 md:top-0 h-8 pb-1 z-20 bg-gray-50 dark:bg-gray-1000 items-center justify-between">
        <div className="flex flex-col sm:flex-row gap-x-4">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild className="ml-auto">
                <Button
                  variant="ghost"
                  className="group flex h-fit py-1.5 px-2 flex-row items-center gap-1 text-xxs text-gray-400 dark:text-gray-400 sm:text-xs"
                  onClick={() => fetchSensorsDataFromAPI()}>
                  <div
                    id="last-update"
                    className="flex flex-row items-center gap-1 text-xs text-gray-400 dark:text-gray-400 sm:text-xs">
                    <SyncIcon className="size-3 group-hover:rotate-180 transition-transform text-current" />
                    <span>
                      {latestUpdateTime.toLocaleDateString("es-ES", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </span>
                    <span>
                      {latestUpdateTime.toLocaleTimeString("es-ES", {
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                      })}
                    </span>
                  </div>
                </Button>
              </TooltipTrigger>
              <TooltipContent side="bottom" className="max-w-60">
                {/* <span>
                  </span> */}
                  La página se actualiza cada 10 segundos de forma automática y aquí puedes verificar la ultima fecha. <br /> Haz click para actualizar manualmente.
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        
        {canEditHome &&
          !userIsAGuest &&
          !unEditableDashboardLinks.includes(activeLinkName) && (
            <div className="flex md:gap-x-2 items-center flex-row">
              {isEditingDashboardLayout && (
                <>
                  <StoredWidgets />
                  <span className="w-px h-4 bg-gray-300"></span>
                  <AddWidgetGallery />
                  <span className="w-px h-4 bg-gray-300"></span>
                </>
              )}
              <Button
                id="modify-dashboard"
                variant="ghost"
                className={`${
                  isEditingDashboardLayout
                    ? "text-green-500 "
                    : "text-gray-500 dark:text-gray-200"
                } pl-0.5 pr-2 h-7 items-center flex hover:text-green-600 dark:hover:text-green-300`}
                onClick={toggleEditMode}>
                <EditIcon className="size-6 p-1" />
                <span className="text-xs hidden md:inline-flex">
                  Modificar vista
                </span>
              </Button>
            </div>
          )}
      </div>
      <div className="h-[calc(100%_-_2.5rem)] w-full " ref={gridContainerRef}>
      <ScrollArea
      scrollHideDelay={300}
       className="h-full w-full px-2 md:px-4" orientation="vertical">
        {!isLoading && !isLoadingUserPermissions && (
          <ResponsiveGridLayout
            className="layout"
            layouts={userProfile.userSettings.dashboardLayout}
            breakpoints={{ md: 768, xxs: 0 }}
            cols={{ md: 12, xxs: 2 }}
            rowHeight={rowHeight}
            margin={margin}
            maxRows={maxRows}
            compactType={"vertical"}
            containerPadding={[0, 0]}
            isBounded={false}
            autoSize={true}
            resizeHandles={["nw", "se"]}
            isResizable={isEditingDashboardLayout}
            isDraggable={isEditingDashboardLayout}
            minHeight={0}>
            {widgetElements.map((widget) => (
              <div id={widget.id} key={widget.id}>
                <DashboardWidget
                  id={widget.id}
                  type={widget.type}
                  columnsConfig={columnsConfig}
                  selectedRowIds={selectedRowIds}
                  onRowSelectionChange={handleRowSelectionChange}
                  onUpdateColumnsConfig={handleUpdateColumnsConfig}
                  isHidden={
                    widget.id === "mapGis" &&
                    mapIsHidden.includes(activeLinkName)
                  }
                />
              </div>
            ))}
          </ResponsiveGridLayout>
        )}
        </ScrollArea>
      </div>
    </div>
  );
};

export default Dashboard;
