import React, { useEffect, useMemo, useState, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import interactionPlugin from '@fullcalendar/interaction';
import HeaderBar from '../../components/widgets/widgetTypes/calendar/HeaderBar';
// import AddTaskForm from './AddTaskForm';
import { useEventHandlers } from 'contexts/EventHandlers';
import CustomPopover from '../../components/widgets/widgetTypes/calendar/CustomPopover';
import 'components/widgets/widgetTypes/calendar/calendar.css';
import { useStateContext } from 'contexts/ContextProvider';
import axios from 'axios';
import { eventOptions } from 'data/translationArrays';
const ResponsiveCalendar = () => {
  const {
    events,
    isDropdownOpen,
    currentEvent,
    currentDate,
    currentView,
    handleEventClick,
    handleUpdateEvent,
    handleAddTask,
    setCurrentDate,
    setCurrentView,
    setCurrentEvent,
    isPopoverOpen,
    setIsPopoverOpen,
    fetchEventsFromDB,
    setIsEditingEvent,
  } = useEventHandlers();

  const {
    selectedEventId,
    setSelectedEventId,
  } = useStateContext();

  useMemo(() => {
    if (selectedEventId) {
      const event = events.find((event) => event.id === selectedEventId);
      if (event) {
        setCurrentEvent(event);
      }
    }
  }, [selectedEventId, events]);

  const calendarRef = useRef(null);

  const handleTodayClick = () => {
    const calendarAPI = calendarRef.current.getApi();
    calendarAPI.today();
    setCurrentDate(new Date());
  };

  const handleDayViewClick = () => {
    const calendarAPI = calendarRef.current.getApi();
    calendarAPI.changeView('timeGridDay');
    setCurrentView('timeGridDay');
    setCurrentDate(new Date(calendarAPI.getDate()));
  };

  const handleWeekViewClick = () => {
    const calendarAPI = calendarRef.current.getApi();
    calendarAPI.changeView('timeGridWeek');
    setCurrentView('timeGridWeek');
    setCurrentDate(new Date(calendarAPI.getDate()));
  };

  const handleMonthViewClick = () => {
    const calendarAPI = calendarRef.current.getApi();
    calendarAPI.changeView('dayGridMonth');
    setCurrentView('dayGridMonth');
    setCurrentDate(new Date(calendarAPI.getDate()));
  };

  const handlePrevClick = () => {
    const calendarAPI = calendarRef.current.getApi();
    calendarAPI.prev();
    setCurrentDate(new Date(calendarAPI.getDate()));
  };

  const handleNextClick = () => {
    const calendarAPI = calendarRef.current.getApi();
    calendarAPI.next();
    setCurrentDate(new Date(calendarAPI.getDate()));
  };

  const handleClosePopover = () => {
    setIsPopoverOpen(false);
    setCurrentEvent(null);
  };

  const handleSave = (updatedTask) => {
    console.log('updatedTask:', updatedTask);
    handleUpdateEvent(updatedTask);
    
    handleClosePopover();
  };

  const [eventTypes, setEventTypes] = useState(["event", "notification", "queue"]);

  const filteredEvents = useMemo(() => {
    return events.filter(event => eventTypes.includes(event.type));
  }, [events, eventTypes]);

  const handleEventDrop = (eventDropInfo) => {
    const updatedEvent = {
      ...eventDropInfo.event,
      start: eventDropInfo.event.start,
      end: eventDropInfo.event.end,
    };
    handleUpdateEvent(updatedEvent);
  };

  const handleEventResize = (eventResizeInfo) => {
    const updatedEvent = {
      ...eventResizeInfo.event,
      start: eventResizeInfo.event.start,
      end: eventResizeInfo.event.end,
    };
    handleUpdateEvent(updatedEvent);
  };

  return (
    <div
      id="calendarfull"
      className="h-full flex flex-col w-full gap-2 relative ">
      <HeaderBar
        handlePrevClick={handlePrevClick}
        handleNextClick={handleNextClick}
        handleTodayClick={handleTodayClick}
        handleDayViewClick={handleDayViewClick}
        handleWeekViewClick={handleWeekViewClick}
        handleMonthViewClick={handleMonthViewClick}
        currentDate={currentDate}
        currentView={currentView}
        onAddTask={handleAddTask}
        eventOptions={eventOptions}
        eventTypes={eventTypes}
        // setIsEditingEvent={setIsEditingEvent}

        setEventTypes={setEventTypes}
      />
            <div className="h-full dashboard-widget p-1 md:p-2">

      
      <FullCalendar
        ref={calendarRef}
    
        viewClassNames={"dark:text-white border border-gray-200 dark:border-gray-800 rounded-md"}
        plugins={[dayGridPlugin, timeGridPlugin, multiMonthPlugin, interactionPlugin]}
        multiMonthMaxColumns={1}
        initialView="timeGridWeek"
        events={filteredEvents}
        height="100%"
        editable={true}
        slotLabelFormat={{ hour: '2-digit', minute: '2-digit', hour12: false, meridiem: false }}
        eventTimeFormat={{ hour: '2-digit', minute: '2-digit', meridiem: false }}
        contentHeight="100%"
        windowResizeDelay={0}
        eventClick={handleEventClick}
        selectable={true}
        headerToolbar={false}
        dayHeaderFormat={{ weekday: 'short', day: 'numeric', omitCommas: true }}
        titleFormat={{ day: 'numeric', month: 'long', year: 'numeric' }}
        views={{ timeGridWeek: { titleFormat: { year: 'numeric', month: 'long' } } }}
        nowIndicator={true}
        // eventDrop={handleEventDrop}
        // eventResize={handleEventResize}
      />
      <div className="absolute top-4 right-1/2 -translate-x-1/2">
        <CustomPopover
          eventOptions={eventOptions}
          isOpen={isPopoverOpen}
          onClose={handleClosePopover}
          event={currentEvent}
          setIsEditingEvent={setIsEditingEvent}
          containerId="calendarfull"
          onSave={handleSave}
        />
      </div>
    </div>
    </div>
  );
};

export default ResponsiveCalendar;
