import React, { useState } from "react";
import AccountSettings from "../../components/settings/AccountSettings";
import NotificationsSettings from "../../components/settings/NotificationSettings";
import AppearanceSettings from "../../components/settings/AppearanceSettings";
import PrivacySettings from "../../components/settings/PrivacySettings";
import { ReactComponent as ExternalLinkIcon } from "icons/externalLink.svg";
import { ReactComponent as ArrowLeftIcon } from "icons/arrow/left.svg";
// import { useHandleSetLayout } from "contexts/functions/layoutFunctions";
import { Button } from "components/ui/button";
import { useStateContext } from "contexts/ContextProvider";
const settingsOptions = [
  { label: "Cuenta", value: "account" },
  { label: "Apariencia", value: "appearance" },
  { label: "Notificaciones", value: "notifications" },
  { label: "Privacidad", value: "privacy" },
  // Add more settings options here
];

const UserSettings = () => {
  
  const [selectedSetting, setSelectedSetting] = useState(null);
  // const handleSetLayout = useHandleSetLayout(); // Use the custom hook
const { handleSetLayout } = useStateContext();

  const renderContent = () => {
    switch (selectedSetting) {
      case "account":
        return <AccountSettings />;
      case "appearance":
        return <AppearanceSettings />;
      case "notifications":
        return <NotificationsSettings />;
      case "privacy":
        return <PrivacySettings />;
      // Handle other cases
      default:
        return <div className="flex items-center justify-center h-full">Selecciona un botón para abrir los ajustes adecuados</div>;
    }
  };

  return (
    <div key="userSettings"
          id="userSettings"
          className="relative grid gap-4 grid-cols-1 md:grid-cols-[minmax(auto,_20rem),_minmax(0,_1fr)] h-full rounded-lg overflow-clip bg-white md:bg-transparent">
            {selectedSetting && (
          <Button
            className="absolute top-2 left-2 "
            onClick={() => setSelectedSetting(null)}
          >
            <ArrowLeftIcon className="size-5 mr-2" />
            Atrás
          </Button>
        )}
      <div className={`w-full col-span-full md:col-span-1 md:h-fit transition-transform row-span-full h-full md:bg-white md:dark:bg-gray-950 rounded-lg shadow-md md:max-w-xs md:bl ock ${selectedSetting ? '-translate-x-full md:translate-x-0' : ''}`}>
        <ul className="p-4 flex flex-col gap-y-2">
          {settingsOptions.map((option) => (
            <li key={option.value} className="w-full">
              <button
                className={`p-2 w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md dark:text-white ${
                  selectedSetting === option.value
                    ? "bg-gray-200 dark:bg-gray-700"
                    : ""
                }`}
                onClick={() => setSelectedSetting(option.value)}>
                {option.label}
              </button>
            </li>
          ))}
          <li key="user settings" className="w-full">
            <button
              className={`p-2 w-full flex group justify-between items-center text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md dark:text-white `}
              to={`/profile`}
              key="profile"
              onClick={() => handleSetLayout("Profile")}
            >
              <span>
              Perfíl
              </span>
          <ExternalLinkIcon className="size-5 text-gray-300 dark:text-gray-800 group-hover:text-gray-900 dark:group-hover:text-gray-100 inline-block" />
            </button>
          </li>
        </ul>
      </div>
      <div className={`w-full h-full pt-12 md:pt-4 col-span-full md:col-span-1 transition-transform row-span-full custom-scrollbar p-4 overflow-y-scroll md:dark:text-white md:bg-white md:dark:bg-gray-950 rounded-lg shadow-lg ${selectedSetting ? '' : 'translate-x-full md:translate-x-0'}`}>
        
        {renderContent()}
      </div>
    </div>
  );
};

export default UserSettings;
