"use client";

import * as React from "react";
// import { Clock } from "lucide-react";
import { Label } from "components/ui/label";
import { TimePickerInput } from "./time-picker-input";
import { ReactComponent as Clock } from "icons/clock.svg";
// interface TimePickerProps {
//   date: Date | undefined;
//   setDate: (date: Date | undefined) => void;
// }

// https://time.openstatus.dev

export function TimePicker({ date, setDate, displaySeconds = true, label}) {
  const minuteRef = React.useRef(null);
  const hourRef = React.useRef(null);
  const secondRef = React.useRef(null);

  return (
    <div className="flex flex-col gap-1">
        
      <Label>{label}</Label>
    <div className="flex items-end  gap-2">
      

      <div className="grid gap-1 text-center">
        <Label htmlFor="hours" className="text-xxs">
          Hora
        </Label>
        <TimePickerInput
          picker="hours"
          date={date}
          setDate={setDate}
          ref={hourRef}
          onRightFocus={() => minuteRef.current?.focus()}
        />
      </div>
      <div className="grid gap-1 text-center">
        <Label htmlFor="minutes" className="text-xxs">
          Minutos
        </Label>
        <TimePickerInput
          picker="minutes"
          date={date}
          setDate={setDate}
          ref={minuteRef}
          onLeftFocus={() => hourRef.current?.focus()}
          onRightFocus={() => secondRef.current?.focus()}
        />
      </div>
      {displaySeconds && (
        <div className="grid gap-1 text-center">
          <Label htmlFor="seconds" className="text-xxs">
            Seconds
          </Label>
          <TimePickerInput
            picker="seconds"
            date={date}
            setDate={setDate}
            ref={secondRef}
            onLeftFocus={() => minuteRef.current?.focus()}
          />
        </div>
      )}
    <div className="flex h-8 items-center">
        <Clock className="ml-2 h-5 w-5" />
      </div>
    </div>
      </div>

  );
}
