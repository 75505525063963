"use client";
import React from "react";
import { format } from "date-fns";
import { ReactComponent as CalendarIcon } from "icons/calendar.svg";
import { cn } from "lib/utils";
import { Button } from "components/ui/button";
import { Calendar } from "components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/ui/popover";
import { TimePicker } from "./time-picker";
import { toast } from "sonner";

export function DateTimePickerForm({ date, onDateTimeChange, type, emitToast = false }) {
  const [selectedDate, setSelectedDate] = React.useState(date ? new Date(date) : new Date()); // Initialize with the passed date or a new Date
  const [isOpen, setIsOpen] = React.useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onDateTimeChange(date, type);
  };

  const handleSave = () => {
    setIsOpen(false);
    if (emitToast === true && selectedDate){
    toast({
      title: "DateTime Saved",
      description: format(selectedDate, "PPP HH:mm"),
    });
    }
  };

  return (
    <div>
      <Popover open={isOpen}>
        <PopoverTrigger asChild>
          <Button
            onClick={() => setIsOpen(!isOpen)}
            variant="outline"
            className={cn(
              "w-[14.6rem] justify-start h-8 px-3 text-left font-normal",
              !selectedDate && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {selectedDate ? format(selectedDate, "PPP HH:mm") : <span>Seleccione una fecha</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent 
          onInteractOutside={() => setIsOpen(false)}
          className="w-auto min-w-52 p-0">
          <Calendar
            mode="single"
            selected={selectedDate}
            onSelect={handleDateChange}
            initialFocus
          />
          <div className="p-3 border-t gap-3 flex items-center flex-col border-border">
            <TimePicker
              displaySeconds={false}
              setDate={handleDateChange}
              date={selectedDate}
            />
            <Button className="w-full" size="sm" onClick={handleSave} type="button">
              Guardar
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
