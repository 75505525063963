// MapContext.js
import React, { createContext, useState, useContext } from 'react';

const MapContext = createContext();

export const MapProvider = ({ children }) => {
  const [isMapVisible, setIsMapVisible] = useState(false);

  return (
    <MapContext.Provider value={{ isMapVisible, setIsMapVisible }}>
      {children}
    </MapContext.Provider>
  );
};

export const useMap = () => useContext(MapContext);
