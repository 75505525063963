import React, { useEffect, useMemo } from "react";

import PropTypes from "prop-types";
import { ReactComponent as UpIcon } from "icons/arrow/up.svg";
import { ReactComponent as DownIcon } from "icons/arrow/down.svg";
import { ReactComponent as RightIcon } from "icons/arrow/right.svg";
import {
  isValid,
  format,
} from "date-fns";
import { filterAndTransformWidgetData } from "helpers/filterAndTransformWidgetData";
import AddMissingOptions from "../../widgetWrapper/AddMissingOptions";
import { parametersWithLabels } from "data/translationArrays";
const DetailChart = ({
  data,
  options,
  showHeaderTitle = true,
  toggleDataSelector,
}) => {
  // console.log("DetailChart data", data);
  // console.log("DetailChart options", options);

  function transformData(data, options) {
    // Find device entry based on city and specific location
    const deviceData = data.find(
      (device) =>
        device.location.city === options.city &&
        device.location.specificLocation === options.location &&
        device.deviceId === options.sensors[0]
    );
    // console.log("deviceData", deviceData);
    const initialValueType =
      options?.valueType && options?.valueType[0] !== ""
        ? options?.valueType
        : ["flow", "pressure", "temperature"]; // Default to 'flow' if undefined

    // Handle no matching data scenario gracefully
    if (!deviceData) {
      // console.warn('No matching device data found.');
      return null; // Return null to indicate no data found
    }

    // Map through valueType required and fetch the latest measurement for each category
    const detailedData = initialValueType.map((detail) => {
      const categoryData = deviceData.data.find(
        (cat) => cat.category === detail
      );

      // console.log("categoryData", categoryData);
      // console.log("categoryData measure length", categoryData.measurements.length);
      if (!categoryData || categoryData.measurements.length < 2) {
        return {
          label: detail,
          value: "N/A",
          unit: "",
          percentChange: 0,
          changeIndicator: 0,
        };
      }

      // Assuming measurements are ordered by date, with the most recent last
      const latestMeasurement =
        categoryData.measurements[categoryData.measurements.length - 1];
      const previousMeasurement =
        categoryData.measurements[categoryData.measurements.length - 2];
      // console.log("latestMeasurement", latestMeasurement);
      let percentChange = 0;
      if (
        previousMeasurement.value !== "0" &&
        latestMeasurement.value !== "0"
      ) {
        percentChange =
          ((latestMeasurement.value - previousMeasurement.value) /
            previousMeasurement.value) *
          100;
      }

      const changeIndicator = percentChange >= 0 ? 1 : -1;
      const adjustedPercentChange =
        percentChange === 0 ? 0 : Math.abs(percentChange).toFixed(2);

      return {
        label: parametersWithLabels.find((param) => param.value === detail)
          .label,
        value:
          typeof latestMeasurement.value === "string"
            ? Number(latestMeasurement.value).toFixed(2)
            : latestMeasurement.value.toFixed(2),
        date: latestMeasurement.date,
        unit: latestMeasurement.unit,
        percentChange: adjustedPercentChange,
        changeIndicator: changeIndicator,
      };
    });

    return { ...deviceData, data: detailedData };
  }

  const requiredOptions = ["city", "location", "valueType", "sensors"];
  const transformedData = filterAndTransformWidgetData(
    { data, options },
    requiredOptions,
    transformData
  );

  function getColorClass(percentChange, changeIndicator) {
    let percentage = percentChange * changeIndicator;
    if (percentage > 2) {
      return "text-green-500"; // Green for more than 2%
    } else if (percentChange <= 2 && percentChange > 0) {
      return "text-lime-400"; // Light green for between 0 and 2%
    } else if (percentChange < 0 && percentChange > -2) {
      return "text-yellow-500"; // Yellow for between 0 and -2%
    } else if (percentChange === 0) {
      return "text-blue-500"; // Gray for 0%
    } else {
      return "text-red-500"; // Red for -2% and below
    }
  }

  const formatDate = (dateInput) => {
    if (!isValid(dateInput)) {
      dateInput = new Date();
    }
    return format(dateInput, "MMM dd, yyyy HH:mm");
  };

  const latestDate = useMemo(() => {
    if (transformedData && transformedData.data.length > 0) {
      const dates = transformedData.data.map((item) => item.date);
      return Math.max(...dates);
    }
    return null;
  }, [transformedData]);
  
  if (!transformedData || transformedData.length === 0) {
    return (
      <AddMissingOptions
        data={data}
        toggleDataSelector={toggleDataSelector}
        requiredOptions={requiredOptions}
        options={options}
      />
    );
  }



  const itemCount = transformedData.data.length;

  // useEffect(() => {
  // console.log("itemCount", itemCount);
  // }, [itemCount]);
  let gridClasses;
  if (itemCount === 1) {
    gridClasses = showHeaderTitle
      ? "@lg:pr-6 @sm:grid-cols-3 [@container(max-aspect-ratio:1.2_/_1)]:grid-rows-[auto,_1fr]"
      : "@sm:grid-cols-1";
  } else if (itemCount === 2) {
    gridClasses = showHeaderTitle
      ? "@lg:pr-6 @sm:grid-cols-4 [@container(max-aspect-ratio:1.2_/_1)]:grid-rows-[auto,_1fr]"
      : "@sm:grid-cols-2";
  } else if (itemCount === 3) {
    gridClasses = showHeaderTitle
      ? "@lg:pr-6 @sm:grid-cols-5 grid-cols-3 [@container(max-aspect-ratio:1.2_/_1)]:grid-rows-[auto,_1fr] "
      : "@sm:grid-cols-3";
  } else if (itemCount === 4) {
    gridClasses = showHeaderTitle
      ? "@lg:pr-6 @sm:grid-cols-6 [@container(max-aspect-ratio:1.2_/_1)]:grid-rows-[auto,_1fr]"
      : "@sm:grid-cols-4";
  }

  let subGridClasses;
  if (itemCount === 1) {
    subGridClasses = "col-span-3 @sm:col-span-1 @sm:grid-cols-1";
  } else if (itemCount === 2) {
    subGridClasses = "col-span-4 @sm:col-span-2 @sm:grid-cols-2";
  } else if (itemCount === 3) {
    subGridClasses = " col-span-3 @sm:col-span-3 grid-cols-3 @sm:grid-cols-3";
  } else if (itemCount === 4) {
    subGridClasses = "col-span-6 @sm:col-span-4 @sm:grid-cols-4";
  }



  return (
    <div className="h-full relative">
      <div className={`${gridClasses} grid h-full  gap-3`}>
        {showHeaderTitle && (
          <div className="flex pl-2 h-fit max-h-full my-auto  flex-col col-span-4 justify-center @sm:col-span-2">
            <h4 className="">{transformedData.title}</h4>
            <span className=" text-xs  text-green-500 dark:text-green-400">
              {transformedData.location.city},{" "}
              {transformedData.location.specificLocation}
            </span>
            <span className=" text-xs text-gray-400 dark:text-gray-500">
              {formatDate(latestDate)}
            </span>
          </div>
        )}
        <div
          className={`${subGridClasses} grid-cols-subgrid pl-2 gap-2 [@container(max-aspect-ratio:2.75_/_1)]:h-full h-fit my-auto grid`}
        >
          {transformedData.data.map((item, index) => (
            <div
              key={index}
              className="flex relative my-auto flex-col h-full top-1/2 [@container(max-aspect-ratio:2.75_/_1)]:top-0 -translate-y-[calc(50%_-_0.25rem)] [@container(max-aspect-ratio:2.75_/_1)]:translate-y-0 @sm:flex-col -space-y-1 @sm:-space-y-1 col-s pan-2 @sm:col-span-1 justify-center items-start"
            >
              <div
                className={`text-base min-h-8 @sm:text-xl @md:text-xl @lg:text-2xl w-1/2 @sm:w-full [@container(max-aspect-ratio:2.75_/_1)]:text-xl flex-nowrap flex -translate-x-1 justify-start items-center font-semibold ${getColorClass(
                  item.percentChange,
                  item.changeIndicator
                )}`}
              >
                <span className="inline-block ">
                  {item.percentChange === 0 ? (
                    <RightIcon className="  size-3.5 @sm:size-4 mr-px" />
                  ) : item.changeIndicator > 0 ? (
                    <UpIcon className="size-3.5 @sm:size-4 mr-px" />
                  ) : (
                    <DownIcon className="size-3.5 @sm:size-4 mr-px" />
                  )}
                </span>
                <span className="min-h-3 ">
                  {Math.abs(item.percentChange)}
                  {item.percentChange !== 0 && (
                    <span className="inline-block origin-bottom-left -translate-y-0.5 scale-75">
                      {" %"}
                    </span>
                  )}
                </span>
              </div>
              <div className="flex flex-col justify-start items-center w-1/2 @sm:w-full">
                <div className="text-sm w-full  @md:text-base @lg:text-lg [@container(max-height:100px)]:text-base flex gap-y-0 @sm:justify-start">
                  <span>
                    {item.value} {item.unit}
                  </span>
                  <span className=""> </span>
                </div>
                <span className="text-xxxs capitalize min-h-5 w-full [@container(max-height:100px)]:text-xxxs [@container(max-height:100px)]:min-h-6 min -h-10 @md:text-xxs leading-none text-left text-gray-300 dark:text-gray-600">
                  {item.label}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
// };

DetailChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      deviceId: PropTypes.string.isRequired,
      // title: PropTypes.string.isRequired,
      location: PropTypes.shape({
        city: PropTypes.string.isRequired,
        specificLocation: PropTypes.string.isRequired,
        coordinates: PropTypes.arrayOf(PropTypes.number).isRequired,
      }),
      data: PropTypes.arrayOf(
        PropTypes.shape({
          category: PropTypes.string.isRequired,
          measurements: PropTypes.arrayOf(
            PropTypes.shape({
              date: PropTypes.string.isRequired,
              value: PropTypes.string.isRequired,
              // unit: PropTypes.string.isRequired,
              meta: PropTypes.shape({
                percentChange: PropTypes.number,
                changeIndicator: PropTypes.number,
              }),
            })
          ),
        })
      ),
    })
  ).isRequired,
  options: PropTypes.shape({
    city: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    valueType: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default DetailChart;
