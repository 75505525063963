import React, { useState } from "react";
import { ReactComponent as MoonIcon } from "icons/moon.svg";
import { ReactComponent as SunIcon } from "icons/sun.svg";
import { useStateContext } from "contexts/ContextProvider";

const DarkmodeButton = () => {
  const { userProfile, toggleDarkMode } = useStateContext();
  // console.log("DarkmodeButton userProfile: ",userProfile.userSettings.darkMode);

  return (
    <button
      className={`relative group/darkmodebtn size-8 md:size-10 p-1 sm:p-1 md:p-2 rounded-full after:content-[''] after:transition-colors after:-inset-1 after:rounded-full after:z-0 after:hover:bg-gray-100 dark:after:hover:bg-gray-800 after:absolute dark:text-gray-100 after:hover:text-green-500 transition-colors items-center justify-center flex`}
      onClick={toggleDarkMode}>

      {userProfile.userSettings.darkMode === "dark"
        ? <MoonIcon className="size-full z-10" />
        : userProfile.userSettings.darkMode === "system" ?
      <div className="relative block z-10 size-full">
        <SunIcon className="absolute top-[5px] -left-[5px] size-full z-10" />
        <div className="absolute flex items-center rotate-45 transition-colors group-hover/darkmodebtn:bg-gray-200 dark:group-hover/darkmodebtn:bg-gray-800 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-8 bg-white dark:bg-gray-950  h-1.5  z-20">
          <div className=" w-8 bg-current h-0.5 block z-10"></div>
        </div>
        <MoonIcon className="absolute -top-[5px] left-[5px] size-full z-10" />
      </div> :
        <SunIcon className="size-full z-10" />
      }
    </button>
  );
};

export default DarkmodeButton;
