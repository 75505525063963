// EditMenu.js
import React from "react";
import { useStateContext } from "contexts/ContextProvider";

import { Button } from "components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";

import { ReactComponent as TrashIcon } from "icons/trash.svg";
import { ReactComponent as EditIcon } from "icons/edit.svg";
import { ReactComponent as DuplicateIcon } from "icons/copy.svg";
import { ReactComponent as StoreIcon } from "icons/archive.svg";
import { ReactComponent as AdjustmentsIcon } from "icons/adjustments.svg";

const EditDashboardMenu = ({ id, toggleDataSelector, activeLinkName, type }) => {
  const { handleRemoveWidget, handleStoreWidget } = useStateContext(); // Assumed you've added storeWidget to your context
  // console.log("type in EditDashboardMenu: ", type);
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          // className="rounded-full  h-fit p-0.5"
          className="opacity-0 h-auto p-1 group-hover:opacity-100 focus:opacity-100 dark:text-gray-600 text-gray-200  hover:text-gray-800 dark:hover:text-gray-100 rounded-full "
          variant="outline">
          <AdjustmentsIcon className="size-3.5 text-current" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-fit">
        <div className="flex flex-col overflow-y-auto transition-all duration-500">
          <div className="flex w-full flex-col divide-y dark:divide-gray-600 ">
            {(type !== "map" && type !== "queue" ) && (
              <button
              className="flex w-full focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 dark:focus-visible:ring-gray-300 text-gray-500 hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-950  transition-colors px-4 py-2 flex-row items-center space-x-2"
              onClick={toggleDataSelector}>
              <EditIcon className="size-4 " />
              <span>Editar</span>
            </button>
              )}
            {/* <button
              className="flex w-full text-gray-500 hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-950  transition-colors px-4 py-2 flex-row items-center space-x-2"
              onClick={() => console.log("duplicate")}>
              <DuplicateIcon className="size-4 " />
              <span>Duplicatar</span>
            </button> */}
            {/* <button
                  className="flex w-full text-gray-500 hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-950  transition-colors px-4 py-2 flex-row items-center space-x-2"
                  onClick={() => console.log("customize")}>
                  <CustomizeIcon className="size-4 " />
                  <span>Customize</span>
                </button> */}
            <button
              className="flex w-full focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 dark:focus-visible:ring-gray-300 text-gray-500 hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-950  transition-colors px-4 py-2 flex-row items-center space-x-2"
              onClick={() => handleStoreWidget(id)}>
              <StoreIcon className="size-4 " />
              <span>Al almanecamiento</span>
            </button>
            {(type !== "map") &&
            (
              <button
                className="flex w-full focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-red-950 dark:focus-visible:ring-red-300 text-red-500 hover:text-red-600 hover:bg-red-50 dark:hover:bg-red-950  transition-colors px-4 py-2 flex-row items-center space-x-2"
                onClick={() => handleRemoveWidget(id)}>
                <TrashIcon className="size-4 " />
                <span>Borrar</span>
              </button>
            )}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default EditDashboardMenu;
