import React from "react";
import { Button } from "components/ui/button";
import { ButtonGroup } from "@mui/material";
import { format, startOfWeek, endOfWeek } from "date-fns";
import { es } from "date-fns/locale";
import { ReactComponent as ArrowRight } from "icons/arrow/right.svg";
import { ReactComponent as ArrowLeft } from "icons/arrow/left.svg";
import AddTaskForm from "./AddTaskForm";
import { Icon } from "@iconify/react";
import { MultiSelect } from "components/ui/MultiSelect";
// import { Command, CommandList, CommandItem } from "components/ui/command";
// import { Popover, PopoverTrigger, PopoverContent } from "components/ui/popover";
import LegendButton from "./LegendButton";
const capitalizeFirstLetter = (string) => {
  if (string !== "de") return string.charAt(0).toUpperCase() + string.slice(1);
  // return string.charAt(0).toUpperCase() + string.slice(1);
};

const HeaderBar = ({
  handlePrevClick,
  handleNextClick,
  handleTodayClick,
  handleDayViewClick,
  handleWeekViewClick,
  handleMonthViewClick,
  currentDate,
  currentView,
  onAddTask,
  eventTypes,
  setEventTypes,
  eventOptions,
}) => {
  const today = new Date().toDateString();
  const isToday = currentDate.toDateString() === today;

  const formatDate = () => {
    if (currentView === "timeGridDay") {
      const formattedDate = format(currentDate, "EEEE, d 'de' MMMM yyyy", {
        locale: es,
      });
      return formattedDate
        .split(" ")
        .map((word) => capitalizeFirstLetter(word))
        .join(" ");
    } else if (currentView === "timeGridWeek") {
      const start = startOfWeek(currentDate, { weekStartsOn: 1 });
      const end = endOfWeek(currentDate, { weekStartsOn: 1 });
      const sameMonth = start.getMonth() === end.getMonth();
      const sameYear = start.getFullYear() === end.getFullYear();

      if (sameMonth && sameYear) {
        const startFormatted = format(start, "d", { locale: es });
        const endFormatted = format(end, "d", { locale: es });
        const monthYear = capitalizeFirstLetter(
          format(start, "MMMM yyyy", { locale: es })
        );
        return `${startFormatted} - ${endFormatted} de ${monthYear}`;
      } else if (sameYear) {
        const startFormatted = capitalizeFirstLetter(
          format(start, "d MMM", { locale: es })
        );
        const endFormatted = capitalizeFirstLetter(
          format(end, "d MMM yyyy", { locale: es })
        );
        return `${startFormatted} - ${endFormatted}`;
      } else {
        const startFormatted = capitalizeFirstLetter(
          format(start, "d MMM yyyy", { locale: es })
        );
        const endFormatted = capitalizeFirstLetter(
          format(end, "d MMM yyyy", { locale: es })
        );
        return `${startFormatted} - ${endFormatted}`;
      }
    } else if (currentView === "dayGridMonth") {
      const formattedDate = format(currentDate, "MMMM yyyy", { locale: es });
      return capitalizeFirstLetter(formattedDate);
    }
    return format(currentDate, "EEEE, d 'de' MMMM yyyy", { locale: es });
  };

  return (
    <div className="flex flex-col gap-2  md:flex-row justify-between items-start md:items-center pt-px px-px">
      <div className="flex w-full sm:w-fit justify-between sm:justify-start gap-1 md:gap-2">
        <ButtonGroup className="flex justify-center">
          <Button
            className="rounded-r-none border py-2 px-3 border-r"
            onClick={handleDayViewClick}
            variant={currentView === "timeGridDay" ? "default" : "outline"}>
            Día
          </Button>
          <Button
            className="rounded-none border py-2 px-3 border-l-0 border-r"
            onClick={handleWeekViewClick}
            variant={currentView === "timeGridWeek" ? "default" : "outline"}>
            Semana
          </Button>
          <Button
            className="rounded-l-none border py-2 px-3 border-l-0 border-r"
            onClick={handleMonthViewClick}
            variant={currentView === "dayGridMonth" ? "default" : "outline"}>
            Més
          </Button>
        </ButtonGroup>
        <ButtonGroup className="flex justify-center">
          <Button
            variant="outline"
            className="rounded-r-none p-2  border border-r"
            onClick={handlePrevClick}>
            <Icon icon="fe:arrow-left" className="size-4" />
          </Button>
        <Button
          className="rounded-none border py-2 px-3 border-l-0 border-r"
          onClick={handleTodayClick}
          variant={isToday ? "default" : "outline"}>
          Hoy
        </Button>
          <Button
            variant="outline"
            className="rounded-l-none p-2 border border-l-0 border-r"
            onClick={handleNextClick}>
            <Icon icon="fe:arrow-right" className="size-4" />
          </Button>
        </ButtonGroup>
        
      </div>
      <div className="flex gap-2 w-full items-center">
      <LegendButton
          options={eventOptions}
          selected={eventTypes}
          onChange={setEventTypes}
        />

        <AddTaskForm onAddTask={onAddTask} eventOptions={eventOptions} />

      <div className="text-lg font-bold shrink-0 ml-auto">{formatDate()}</div>

      </div>
    </div>
  );
};

export default HeaderBar;
