import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useStateContext } from "contexts/ContextProvider";
import { usePageTitle } from "contexts/PageTitleContext";

import { Button } from "components/ui/button";
import { ReactComponent as ArrowLeftIcon } from "icons/arrow/left.svg";
import { ReactComponent as ArrowRightIcon } from "icons/arrow/right.svg";
import { ReactComponent as CheckmarkIcon } from "icons/status-icons/complete.svg";
import SetupProfile from "components/authentication/SetupProfile";
import SetupPrivacy from "components/authentication/SetupPrivacy";
import SetupFrontLayout from "components/authentication/SetupFrontlayout";
import SetupAppearance from "components/authentication/SetupAppearance";
import SetupRole from "components/authentication/SetupRole";
import { toast } from "sonner";

const ProfileSetup = () => {
  const navigate = useNavigate();
  const { setPageTitle } = usePageTitle();
  useEffect(() => {
    setPageTitle("Configuración de perfil");
  }, [setPageTitle]);
  const { userProfile, setUserProfile, setActiveComponent } = useStateContext(); // State for user profile
  const [localUserProfile, setLocalUserProfile] = useState(userProfile); // Local state for user profile
  const [registrationStep, setRegistrationStep] = useState(0);
  //
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const userId = JSON.parse(sessionStorage.getItem("userId"));

  // console.log("userProfile", userProfile);

  const updateLocalUserProfile = (updatedProfile) => {
    setLocalUserProfile((prevProfile) => ({
      ...prevProfile,
      ...updatedProfile,
    }));
  };

/*   useEffect( () => {
    console.log("ProfileSetup userProfile", userProfile)
  }, [userProfile])
 */
  // Callback function to update user profile state
  const updateUserProfile = (updatedProfile) => {
    setUserProfile(updatedProfile);
  };

// TODO: Adjust this step to talk to database
  const completeRegistration = async () => {
    setRegistrationStep((prevStep) => prevStep + 1);
    updateUserProfile(localUserProfile);
    //navigate("/");
    setLoading(true);
    setErrors({});

    try {
      console.log("SetupProfile data: name, email, pass");
      //Calling the serverless function to connect to the DB and update it with the data of the new user
      //const response = await fetch("/api/handleUserProfile?action=setupProfile", {
      const response = await fetch(
        "https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/users/profile?action=setupProfile",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId,
            firstName: userProfile.contactDetails.firstName,
            lastName: userProfile.contactDetails.lastName,
            phone: userProfile.contactDetails.phone,
            workRole: userProfile.contactDetails.workRole,
            bio: userProfile.contactDetails.bio,
            catastro: userProfile.catastro,
            parcela: userProfile.parcela,
            poligono: userProfile.poligono,
            darkMode: userProfile.userSettings.darkMode,
            layoutMode: userProfile.userSettings.layoutMode,
          }),
        }
      );
      const data = await response.json();
      console.log("ProfileSetup data response", data);

      if (response.ok) {
        //Profile updated successful
        console.log("ProfileSetup Profile updated successful:", data);
        toast.success("Se ha actualizado de forma correcta");
        setActiveComponent("dashboard")
        //set a delay for showing the success message
      } else {
        //Is this necessary having the if (!response.ok) above?
        //Handle profile update error
        console.error("ProfileSetup Profile update error:", data.message);
        toast.error(data.message || "Error during registration");
      }
    } catch (error) {
      console.error("ProfileSetup Request error:", error);
      toast.error("Error connecting to the server");
    } finally {
      setLoading(false); // Hide spinner after request completes
    }
  };

  return (
    <div className="grid grid-cols-1 grid-rows-[auto,1fr,auto,auto,auto] h-full w-full flex-col max-w-2xl mx-auto justify-center gap-y-4 items-center p-2">
      <div></div>
      <div className="w-full">
        {registrationStep === 0 && (
          <SetupProfile
            userProfile={userProfile}
            updateUserProfile={updateLocalUserProfile} // Passing callback function
          />
        )}
        {registrationStep === 1 && (
          <SetupPrivacy
            userProfile={userProfile}
            updateUserProfile={updateLocalUserProfile} // Passing callback function
          />
        )}
          {/* {registrationStep === 2 && (
            <SetupRole
              userProfile={userProfile}
              updateUserProfile={updateLocalUserProfile} // Passing callback function
            />
          )} */}
        {registrationStep === 2 && (
          <SetupAppearance
            userProfile={userProfile}
            updateUserProfile={updateLocalUserProfile} // Passing callback function
          />
        )}
        {registrationStep === 3 && (
          <SetupFrontLayout
            userProfile={userProfile}
            updateUserProfile={updateLocalUserProfile} // Passing callback function
          />
        )}
      </div>
      <div>
        {registrationStep === 0 && (
          <p className="text-center text-sm dark:text-gray-200">
            Todos los campos se pueden editar más tarde en los ajustes.
          </p>
        )}
        {registrationStep === 1 && (
          <p className="text-center text-sm dark:text-gray-200">
            Configura tus preferencias de privacidad. Puedes cambiarlas en cualquier momento.
          </p>
        )}
        {/* {registrationStep === 2 && (
          <p className="text-center text-sm dark:text-gray-200">
            Selecciona tu rol en la plataforma. El rol tiene que ser verificado por un gestor antes de activar todas las funcionalidades.
          </p>
        )} */}
        {registrationStep === 2 && (
          <p className="text-center text-sm dark:text-gray-200">
            Todos los ajustes se pueden editar más tarde.
          </p>
        )}
        {registrationStep === 3 && (
          <p className="text-center text-sm dark:text-gray-200">
Ya estas listo! Aprieta "Completar" para comenzar el uso del panel de control.          </p>
        )}
        
      </div>
      {/* Navigation buttons */}
      <div className="flex justify-center gap-4 w-full">
        <Button
          disabled={registrationStep === 0}
          onClick={() => setRegistrationStep(registrationStep - 1)}
          className="flex group justify-center text-white rounded-md p-2 w-36">
          <ArrowLeftIcon className="size-5 group-hover:-translate-x-2 transition-transform p-px mr-1" />
          <span className="mr-4">Volver</span>
        </Button>
        {registrationStep === 3 ? (
          <Button
            onClick={completeRegistration}
            className="bg-green-500 dark:bg-green-500 dark:hover:bg-green-400 hover:bg-green-600 flex group justify-center text-white rounded-md p-2 w-36">
            <span className="ml-4">Completar</span>
            <CheckmarkIcon className="size-5 *:!stroke-[3] group-hover:scale-110 transition-transform p-px ml-1 " />
          </Button>
        ) : (
          <Button
            onClick={() => setRegistrationStep(registrationStep + 1)}
            className="bg-[#1D91E0] flex group justify-center text-white rounded-md p-2 w-36">
            <span className="ml-4">Continuar</span>
            <ArrowRightIcon className="size-5 group-hover:translate-x-2 transition-transform p-px ml-1 " />
          </Button>
        )}
      </div>
      {/* Step indicators */}
      <div className="flex justify-center mt-auto">
        <ol
          className="flex rounded-full items-center justify-center gap-x-1.5 list-none p-0"
          role="navigation">
          {[...Array(4)].map((_, i) => (
            <li
              key={i}
              className={`w-4 h-4 items-center text-center text-white text-xxs font-semibold transition-[width] duration-1000  rounded-full ${
                registrationStep === i ? "w-8" : ""
              } ${registrationStep > i ? "bg-green-600 dark:bg-green-500" : "bg-gray-300 dark:bg-gray-500"}`}
              aria-current={registrationStep === i ? "step" : undefined}>
              {i + 1}
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default ProfileSetup;
