import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { filterAndTransformWidgetData } from "helpers/filterAndTransformWidgetData"; // Adjust the import path as necessary
import AddMissingOptions from "../../widgetWrapper/AddMissingOptions";
import { parametersWithLabels } from "data/translationArrays";

import {
  isValid,
  format,
} from "date-fns";

const DetailBarChart = ({ data, options, showHeaderBar = true, toggleDataSelector }) => {
  function transformData(data, options) {
    const selectedDeviceData = data.filter((device) =>
      options.sensors.includes(device.deviceId)
    );

    if (selectedDeviceData.length === 0) {
      // console.warn("No matching device data found.");
      return null;
    }

    // Constructing formatted data considering category maxValues
    const transformedData = selectedDeviceData
      .map((device) => {
        const categoryData = device.data.find(
          (cat) =>
            cat.category.toLowerCase() === options.valueType[0].toLowerCase()
        );
        if (!categoryData) {
          console.warn(
            "No data for the selected category in device:",
            device.deviceId
          );
          return null;
        }

        // console.log("categoryData", categoryData);

        // TODO ERROR HANDLING -> No measurements for this category
        const latestMeasurement = categoryData.measurements.reduce(
          (prev, current) =>
            new Date(prev.date) > new Date(current.date) ? prev : current,
          categoryData.measurements[0]
        );

        // console.log("latestMeasurement", latestMeasurement);

        // Use category settings for min/max if available
        const categorySettings =
          options.categories[options.valueType[0].toLowerCase()];
        const maxValue = categorySettings
          ? categorySettings.maxValue
          : latestMeasurement.value;

        return {
          label: device.title,
          deviceId: device.deviceId,
          date: new Date(latestMeasurement.date),
          value: typeof latestMeasurement.value === 'string' ? Number(latestMeasurement.value) : latestMeasurement.value,
          max: typeof maxValue === 'string' ? Number(maxValue) : maxValue,
          unit: categoryData.unit,
        };
      })
      .filter((item) => item !== null);

      const title = parametersWithLabels.map((param) =>
        param.value === options.valueType[0] ? param.label + " actual" : ""
      );
    return {
      title: title,
      location: `${options.city}, ${options.location}`,
      data: transformedData,
    };
  }

  const requiredOptions = [
    "city",
    "location",
    "valueType",
    "sensors",
    "categories",
  ];

  // Use the utility function to validate and transform the data
  const transformedData = filterAndTransformWidgetData(
    { data: data, options }, // Wrap data in an array if filterAndTransformWidgetData expects an array
    requiredOptions,
    transformData
  );

  const formatDate = (dateInput) => {
    if (!isValid(dateInput)) {
      dateInput = new Date();
    }
    return format(dateInput, "MMM dd, yyyy HH:mm");
  };


  const getPercentage = (value, max) => (value / max) * 100;
  function getColorClass(percentage) {
    // let percentage = percentChange * changeIndicator;
    if (percentage < 0) {
      return "bg-red-500"; // Red for negative percentages
    } else if (percentage <= 50) {
      return "bg-green-500"; // Green until 50%
    } else if (percentage <= 65) {
      return "bg-lime-400"; // Light green for between 65% and 80%
    } else if (percentage <= 80) {
      return "bg-yellow-500"; // Yellow
    } else {
      return "bg-red-500"; // Red for > 80%
    }
  }

  const latestDate = useMemo(() => {
    if (transformedData && transformedData.data.length > 0) {
      const dates = transformedData.data.map((item) => item.date);
      return Math.max(...dates);
    }
    return null;
  }, [transformedData]);

  if (!transformedData || transformedData.length === 0) {
    return (
      <AddMissingOptions data={data} toggleDataSelector={toggleDataSelector} requiredOptions={requiredOptions} options={options} />
    );
  }
  return (
    <div className="h-full relative">
      <div className="grid h-full [@container(max-aspect-ratio:2_/_1)]:grid-rows-[auto,_1fr] grid-cols-[auto,_repeat(2,minmax(0,_1fr)),_auto] gap-3 @sm:grid-cols-[auto,_repeat(4,minmax(0,_1fr),_auto] @lg:pr-6">
        {/* <div className="grid h-full grid-cols-[auto,_repeat(2,_minmax(0,_1fr)),_auto] gap-3"> */}
        {showHeaderBar && (
          <div className="flex pl-2 h-fit  max-h-full mb-auto grid-cols-3 flex-col col-span-4 justify-center @sm:col-span-2">
            <h4 className="dark:text-white">{transformedData.title}</h4>
            <span className="text-xs text-green-500">
              {transformedData.location}
            </span>
            <span className=" text-xs text-gray-400 dark:text-gray-500">
            {formatDate(latestDate)}
            </span>
          </div>
        )}
        <div className="col-span-4 pb-2 px-2 overflow-y-auto custom-scrollbar">
          <div className="grid grid-cols-[auto,_repeat(2,_minmax(0,_1fr)),_auto] gap-x-2 @sm:gap-y-2 w-full items-center">
            {transformedData.data.map((item, index) => (
              <React.Fragment key={`detail-bar-chart-item-${index}`}>
                {/* <div key={index} className="grid grid-cols-3 gap-x-2 items-center"> */}
                <span className="text-gray-700 dark:text-gray-200 col-span-3 @sm:col-span-1 @sm:max-w-32 max-w-full leading-4 tex t-right text-sm">
                  {/* {item.label} - {item.deviceId} */}
                  {item.label}
                </span>
                <div className=" col-span-3 @sm:col-span-2 bg-gray-100 dark:bg-gray-800 rounded-full h-2 w-full overflow-hidden">
                  <div
                    className={`h-full rounded-full ${
                      getPercentage(item.value, item.max) > 100 &&
                      "animate-pulse"
                    } ${getColorClass(getPercentage(item.value, item.max))}`}
                    style={{
                      width: `${getPercentage(item.value, item.max)}%`,
                    }}></div>
                </div>
                <div className="flex flex-col row-span-1 @sm:row-span-1">
                  <span className="text-sm text-gray-700 dark:text-gray-200">
                    {`${item.value.toFixed(1).replace(/\.0$/, "")}`}
                    {options.valueType[0] === "flow"
                      ? " m3/s"
                      : options.valueType[0] === "pressure"
                      ? " bar"
                      : options.valueType[0] === "temperature"
                      ? " °C"
                      : ""}
                  </span>
                  <span className="text-xs text-gray-300 dark:text-gray-600">
                    {`${item.max.toFixed(1).replace(/\.0$/, "")}`}
                    {options.valueType[0] === "flow"
                      ? " m3/s"
                      : options.valueType[0] === "pressure"
                      ? " bar"
                      : options.valueType[0] === "temperature"
                      ? " °C"
                      : ""}
                  </span>
                </div>
                {/* </div> */}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

DetailBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  options: PropTypes.shape({
    city: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    // startDate: PropTypes.string.isRequired,
    // endDate: PropTypes.string.isRequired,
    valueType: PropTypes.arrayOf(PropTypes.string).isRequired,
    sensors: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  showHeaderBar: PropTypes.bool,
};

export default DetailBarChart;
