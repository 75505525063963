import React from 'react';
import useWidgetPermissions from './useWIdgetPermissions';
import { Icon } from '@iconify/react';
import { Button } from 'components';
import { useStateContext } from "contexts/ContextProvider";


const ProtectedWidget = ({ component: Component, widgetType, permissionType, isLoadingUserPermissions, setIsLoadingUserPermissions, ...rest }) => {
  const { permissions, loading } = useWidgetPermissions(widgetType, isLoadingUserPermissions, setIsLoadingUserPermissions);
  const { handleSetLayout } = useStateContext();

  if (loading) {
    return (
      <div className="flex items-center justify-center">
        <Icon icon="svg-spinners:180-ring" className="dark:text-white" />
      </div>
    );
  }

  const hasPermission =
    permissionType === "view"
      ? permissions.canView
      : permissionType === "edit"
      ? permissions.canEdit
      : false;

  if (hasPermission) {
    return <Component {...rest} />;
  } else {
    console.warn(`ProtectedWidget unauthorized access to ${widgetType} with ${permissionType} permission`);
    return <div className="flex w-full flex-col gap-2 h-full items-center justify-center">
      <span>
        No tienes permiso para ver esta página.
      </span>
      <Button
        className="ml-4"
        onClick={() => {
          handleSetLayout("Home");
        }}
      >
        Ir a la página de inicio
      </Button>

    </div>; // Or render an unauthorized message/component
  }
};

export default ProtectedWidget;
