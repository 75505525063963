import { ResponsiveBar } from "@nivo/bar";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useStateContext } from "contexts/ContextProvider";
import DataSelector from "../../DataSelectors/DataSelector";

const tailwindColors = {
  gray: {
    100: "#f7fafc",
    200: "#edf2f7",
    300: "#e2e8f0",
    400: "#cbd5e0",
    500: "#a0aec0",
    600: "#718096",
    700: "#4a5568",
    800: "#2d3748",
    900: "#1a202c",
  },
  red: {
    100: "#fff5f5",
    200: "#fed7d7",
    300: "#feb2b2",
    400: "#fc8181",
    500: "#f56565",
    600: "#e53e3e",
    700: "#c53030",
    800: "#9b2c2c",
    900: "#742a2a",
  },
  "green": {
    50: "#f1fcf9",
    100: "#d1f6ed",
    200: "#a4ebdb",
    300: "#6edac5",
    400: "#40c1ac",
    500: "#27a592",
    600: "#1c8577",
    700: "#1b6a61",
    800: "#1a554f",
    900: "#1a4742",
    950: "#092a28",
  },
};
const darkColors = [tailwindColors["green"][300], tailwindColors["green"][400], tailwindColors["green"][500]];
const lightColors = [tailwindColors["green"][500], tailwindColors["green"][600], tailwindColors["green"][700]];

const ResponsiveBarChart = ({ data }) => {
  const { userProfile } = useStateContext();
  const [colorScheme, setColorScheme] = useState(lightColors);
  const [legendFill, setLegend] = useState("#000000");
  const [axisFill, setAxisFill] = useState(tailwindColors.gray[900]);
  const [tooltipColor, setTooltipColor] = useState(tailwindColors.gray[900]);

// const colors = {
//     sold: "#FED0A2",
//     unsold: "#E0E0E0",
//     send: "#FDAE6B",
//     claimed: "#969696"
//   };
//   const getColor = (bar) => colors[bar.id];

  useEffect(() => {
    setColorScheme(userProfile.darkMode ? lightColors : darkColors);
    setTooltipColor(userProfile.darkMode ? tailwindColors.gray[900] : tailwindColors.gray[100]);
    setLegend(userProfile.darkMode ? tailwindColors.gray[100] : tailwindColors.gray[900]);
    setAxisFill(userProfile.darkMode ? tailwindColors.gray[300] : tailwindColors.gray[700]);
  }, [userProfile.darkMode]);

    if (!data || !data.data || data.data.length === 0) {
      <DataSelector type={"barChart"} data={data} />;
    } else {

    
  const chartOptions = {
    data: data?.data || [],
    keys: data?.legends?.y || [],
    indexBy: data.legends.x || "x",
    margin: { top: 10, right: 0, bottom: 40, left: 50 },
    padding: 0.3,
    colors: colorScheme,
    // colors: { scheme: colorScheme },
    borderColor: { from: "color", modifiers: [["darker", 1.6]] },
    axisTop: null,
    axisRight: null,
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: data.legends.x,
      legendPosition: "middle",
      legendOffset: 32,
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: data.legends.y,
      legendPosition: "middle",
      legendOffset: -40,
    },
    theme: {
      tooltip: {
        container: {
          background: tooltipColor, // Change the background color of the tooltip
        },
      },
      axis: {
        ticks: {
          // line: {
          //   stroke: axisFill // Adjust tick line color
          // },
          text: {
            fill: axisFill // Adjust tick label color
          }
        },
        legend: {
          text: {
            fontSize: 12,
            fill: legendFill,
          }
        }
      },
      labels: {
        text: {
          fontSize: 12,
          fill: legendFill,
        }
      }
    },
    labelTextColor: !legendFill,
    labelSkipWidth: 12,
    labelSkipHeight: 12,
  };

  return (<div>
<div className="flex flex-col">
        <h4 className="px-2 pt -2">{data.title}</h4>
        <span className="px-2 text-xs text-green-500 dark:text-green-400">
          {data.location}
        </span>
      </div>
   <ResponsiveBar {...chartOptions} />
  </div>);
  }
};

ResponsiveBarChart.propTypes = {
  data: PropTypes.shape({
    legends: PropTypes.shape({
      x: PropTypes.string.isRequired,
      y: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
};

export default ResponsiveBarChart;
