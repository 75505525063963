import { ReactComponent as BurglaryIcon } from "icons/indicators/burglary.svg";
import { ReactComponent as WaterLevelIcon } from "icons/indicators/water-level.svg";
import { ReactComponent as WaterLeakIcon } from "icons/indicators/water-leak.svg";
import { ReactComponent as ConstructionIcon } from "icons/indicators/construction.svg";
import { ReactComponent as ConstructionFaceIcon } from "icons/indicators/construction-face.svg";
import { ReactComponent as TemperatureIcon } from "icons/indicators/water-temperature.svg";
import { ReactComponent as ExcavatorIcon } from "icons/indicators/excavator.svg";
import { ReactComponent as PressureIcon } from "icons/indicators/water-pressure.svg";
import { ReactComponent as WaterDropIcon } from "icons/water-drop.svg";
import { ReactComponent as InboxIcon } from "icons/inbox.svg";

export const indicatorIcons = [
  { keywords: ["burglary", "asaltos", "asalto", "robar", "robo", "robarse"], icon: BurglaryIcon },
  { keywords: ["water", "overwater", "spillover", "superficie inundada", "inundación", "inundar", "inundación de agua", "nivel de agua"], icon: WaterLevelIcon },
  { keywords: ["leak", "water leak", "goteo", "goteo de agua", "goteo de agua", "goteo", "goteo en el suelo", "goteo en la tierra", "espacio inundado"], icon: WaterLeakIcon },
  { keywords: ["construction", "excavator", "mantenimiento", "trabajo", "obra", "trabajos", "construcción"], icon: ConstructionIcon },
  { keywords: ["maintenance", "reemplazar", "mantenimiento", "reparación"], icon: ConstructionFaceIcon },
  { keywords: ["excavator", "excavation", "excavar", "excavación", "excavador"], icon: ExcavatorIcon },
  { keywords: ["temperature", "heat", "high temperature", "low temperature", "temperatura", "calor", "temperatura alta", "temperatura baja", "calor", "calor alto", "calor bajo"], icon: TemperatureIcon },
  { keywords: ["pressure", "low pressure", "high pressure", "presión", "presión baja", "presión alta", "presión de agua", "presión del agua", "alto nivel de agua", "bajo nivel de agua"], icon: PressureIcon },
  { keywords: ["turno de riego", "Turno de Riego"], icon: WaterDropIcon },
  { keywords: ["Alertas de Sensores"], icon: InboxIcon },
];
