import { v4 as uuidv4 } from 'uuid';

const unifiedData = [
  {
    //id: uuidv4(),
    deviceId: "Entrada Alqueria Ros",
    title: "Acequia l'Agulla",
    // Add some more details, such as notifications, latest alerts, etc
    notifications: [
      {
        id: uuidv4(),
        urgency: 4,
        message: "Excavador",
        status: "En espera",
        assigned: "John Doe",
        date: "2024-05-09T12:00:00Z",
      },
      {
        id: uuidv4(),
        urgency: 3,
        message: "Superficie inundada",
        location: "Oficina 408",
        status: "En funcionamiento",
        assigned: "Ethan Wright",
        date: "2024-04-22T12:00:00",
      },
      {
        id: uuidv4(),
        urgency: 2,
        message: "Asalto",
        location: "Bodega",
        status: "En espera",
        assigned: "Mia Wong",
        date: "2024-04-22T14:00:00",
      },
      {
        id: uuidv4(),
        urgency: 1,
        message: "Temperatura",
        location: "Oficina 501",
        status: "Sondeada",
        assigned: "Noah Lee",
        date: "2024-12-22T12:00:00",
      }
    ],
    location: {
      city: "Valencia",
      specificLocation: "Marjals i Extremals",
      //coordinates: [39.4702, -0.3768]
      coordinates: [726665.02, 4369676.67]
    },
    timeFrame: {
      start: "2024-05-08T12:00:00Z",
      end: "2024-05-09T12:00:00Z"
    },
    data: [
      {
        category: "pressure",
        measurements: [
          { date: "2024-05-08T12:00:00Z", value: 47.8, unit: "bar" },
          { date: "2024-05-08T13:00:00Z", value: 44.5, unit: "bar" },
          { date: "2024-05-08T14:00:00Z", value: 48.2, unit: "bar" },
          { date: "2024-05-08T15:00:00Z", value: 48.1, unit: "bar" },
          { date: "2024-05-08T16:00:00Z", value: 47.9, unit: "bar" },
          { date: "2024-05-08T17:00:00Z", value: 47.8, unit: "bar" },
          { date: "2024-05-08T18:00:00Z", value: 48.1, unit: "bar" },
          { date: "2024-05-08T19:00:00Z", value: 48.3, unit: "bar" },
          { date: "2024-05-08T20:00:00Z", value: 48.5, unit: "bar" },
          { date: "2024-05-02T12:00:00Z", value: 48.5, unit: "bar" },
          { date: "2024-05-03T12:00:00Z", value: 47.9, unit: "bar" },
          { date: "2024-05-04T12:00:00Z", value: 48.3, unit: "bar" },
          { date: "2024-05-05T12:00:00Z", value: 48.1, unit: "bar" }
        ]
      },
      {
        category: "temperature",
        measurements: [
          { date: "2024-05-08T12:00:00Z", value: 16.2, unit: "°C" },
          { date: "2024-05-02T12:00:00Z", value: 16.6, unit: "°C" },
          { date: "2024-05-03T12:00:00Z", value: 17.1, unit: "°C" },
          { date: "2024-05-04T12:00:00Z", value: 16.4, unit: "°C" },
          { date: "2024-05-05T12:00:00Z", value: 16.8, unit: "°C" }
        ]
      },
      {
        category: "flow",
        measurements: [
          { date: "2024-05-08T12:00:00Z", value: 25.1, unit: "m³/s" },
          { date: "2024-05-02T12:00:00Z", value: 24.9, unit: "m³/s" },
          { date: "2024-05-03T12:00:00Z", value: 25.4, unit: "m³/s" },
          { date: "2024-05-04T12:00:00Z", value: 25.0, unit: "m³/s" },
          { date: "2024-05-05T12:00:00Z", value: 25.3, unit: "m³/s" },
          { date: "2024-05-06T12:00:00Z", value: 28.2, unit: "m³/s" }
        ]
      }
    ],
  },
  {
    id: uuidv4(),
    deviceId: "Campo 2 Casa Bajoca",
    title: "Acequia Fabiana",
    notifications: [
      {
        id: uuidv4(),
        urgency: 5,
        message: "Nivel de agua bajo",
        status: "Pendiente",
        assigned: "John Doe",
        date: "2024-05-09T12:00:00Z",
      },
      {
        id: uuidv4(),
        urgency: 5,
        message: "Reparación de planta de tratamiento de agua",
        location: "Planta de tratamiento de agua",
        status: "Suspendida",
        assigned: "Derek Sun",
        date: "2024-04-22T12:00:00",
      },
      {
        id: uuidv4(),
        urgency: 4,
        message: "Reemplazar tubería defectuosa",
        location: "Presa Cima Negra",
        status: "Completada",
        assigned: "Olivia Jones",
        date: "2024-04-22T12:00:00",
      },

    ],
    location: {
      city: "Valencia",
      specificLocation: "Marjals i Extremals",
      //coordinates: [39.469, -0.377]
      coordinates: [726696.58, 4369663.44]
    },
    timeFrame: {
      start: "2024-05-08T12:00:00Z",
      end: "2024-05-09T12:00:00Z"
    },
    data: [
      {
        category: "pressure",
        measurements: [
          { date: "2024-05-08T12:00:00Z", value: 32.8, unit: "bar" },
          { date: "2024-05-08T13:00:00Z", value: 22.5, unit: "bar" },
          { date: "2024-05-08T14:00:00Z", value: 12.2, unit: "bar" },
          { date: "2024-05-08T15:00:00Z", value: 32.1, unit: "bar" },
          { date: "2024-05-08T16:00:00Z", value: 32.9, unit: "bar" },
          { date: "2024-05-08T17:00:00Z", value: 32.8, unit: "bar" },
          { date: "2024-05-08T18:00:00Z", value: 32.1, unit: "bar" },
          { date: "2024-05-08T19:00:00Z", value: 42.3, unit: "bar" },
          { date: "2024-05-08T20:00:00Z", value: 42.5, unit: "bar" },
          { date: "2024-05-02T12:00:00Z", value: 38.5, unit: "bar" },
          { date: "2024-05-03T12:00:00Z", value: 32.9, unit: "bar" },
          { date: "2024-05-04T12:00:00Z", value: 22.3, unit: "bar" },
          { date: "2024-05-05T12:00:00Z", value: 32.1, unit: "bar" }
        ]
      },
      {
        category: "temperature",
        measurements: [
          { date: "2024-05-08T12:00:00Z", value: 16.2, unit: "°C" },
          { date: "2024-05-02T12:00:00Z", value: 16.6, unit: "°C" },
          { date: "2024-05-03T12:00:00Z", value: 17.1, unit: "°C" },
          { date: "2024-05-04T12:00:00Z", value: 16.4, unit: "°C" },
          { date: "2024-05-05T12:00:00Z", value: 16.8, unit: "°C" }
        ]
      },
      {
        category: "flow",
        measurements: [
          { date: "2024-05-08T12:00:00Z", value: 25.1, unit: "m³/s" },
          { date: "2024-05-02T12:00:00Z", value: 24.9, unit: "m³/s" },
          { date: "2024-05-03T12:00:00Z", value: 25.4, unit: "m³/s" },
          { date: "2024-05-04T12:00:00Z", value: 25.0, unit: "m³/s" },
          { date: "2024-05-05T12:00:00Z", value: 25.3, unit: "m³/s" },
          { date: "2024-05-06T12:00:00Z", value: 28.2, unit: "m³/s" }
        ]
      }
    ],
  },
  {
    id: uuidv4(),
    deviceId: "Pozo del Agricultor",
    location: {
      city: "Valencia",
      specificLocation: "Marjals i Extremals",
      //coordinates: [39.446774, -0.366722] // Valencia
      coordinates: [726766.50, 4369627.38]
    },
    timeFrame: {
      start: "2024-05-08T12:00:00Z",
      end: "2024-05-09T12:00:00Z"
    },
    notifications: [
      {
        id: uuidv4(),
        urgency: 5,
        message: "High temperature",
        location: "Server Room",
        status: "Waiting",
        assigned: "John Doe",
        date: "2024-01-01T12:00:00Z",
      }
    ],
    data: [
      {
        category: "pressure",
        measurements: [
          { date: "2024-05-08T12:00:00Z", value: 47.8, unit: "bar" },
          { date: "2024-05-02T12:00:00Z", value: 47.5, unit: "bar" },
          { date: "2024-05-03T12:00:00Z", value: 47.9, unit: "bar" },
          { date: "2024-05-04T12:00:00Z", value: 47.5, unit: "bar" },
          { date: "2024-05-05T12:00:00Z", value: 47.3, unit: "bar" }
        ]
      },
      {
        category: "temperature",
        measurements: [
          { date: "2024-05-08T12:00:00Z", value: 16.2, unit: "°C" },
          { date: "2024-05-02T12:00:00Z", value: 16.6, unit: "°C" },
          { date: "2024-05-03T12:00:00Z", value: 17.1, unit: "°C" },
          { date: "2024-05-04T12:00:00Z", value: 16.4, unit: "°C" },
          { date: "2024-05-05T12:00:00Z", value: 16.8, unit: "°C" }
        ]
      },
      {
        category: "flow",
        measurements: [
          { date: "2024-05-08T12:00:00Z", value: 25.1, unit: "m³/s" },
          { date: "2024-05-02T12:00:00Z", value: 24.9, unit: "m³/s" },
          { date: "2024-05-03T12:00:00Z", value: 25.4, unit: "m³/s" },
          { date: "2024-05-04T12:00:00Z", value: 25.0, unit: "m³/s" },
          { date: "2024-05-05T12:00:00Z", value: 25.3, unit: "m³/s" },
          { date: "2024-05-06T12:00:00Z", value: 28.2, unit: "m³/s" }
        ]
      }
    ],
  },
  {
    id: uuidv4(),
    deviceId: "Acequia Fabiana",
    location: {
      city: "Valencia",
      specificLocation: "Marjals i Extremals",
      //coordinates: [39.446442, -0.364911] // North of Valencia
      coordinates: [726862.67, 4369557.60]
    },
    timeFrame: {
      start: "2024-05-08T12:00:00Z",
      end: "2024-05-09T12:00:00Z"
    },
    notifications: [
      {
        id: uuidv4(),
        urgency: 3,
        message: "Construction",
        location: "Data Center",
        status: "Completed",
        assigned: "Alex Johnson",
        date: "2024-05-09T12:00:00Z",
      }
    ],
    data: [
      {
        category: "pressure",
        measurements: [
          { date: "2024-05-08T12:00:00Z", value: 47.8 * 0.9, unit: "bar" },
          { date: "2024-05-02T12:00:00Z", value: 47.5 * 0.9, unit: "bar" },
          { date: "2024-05-03T12:00:00Z", value: 47.9 * 0.9, unit: "bar" },
          { date: "2024-05-04T12:00:00Z", value: 47.5 * 0.9, unit: "bar" },
          { date: "2024-05-05T12:00:00Z", value: 47.3 * 0.9, unit: "bar" }
        ]
      },
      {
        category: "temperature",
        measurements: [
          { date: "2024-05-08T12:00:00Z", value: 16.2 * 0.9, unit: "°C" },
          { date: "2024-05-02T12:00:00Z", value: 16.6 * 0.9, unit: "°C" },
          { date: "2024-05-03T12:00:00Z", value: 17.1 * 0.9, unit: "°C" },
          { date: "2024-05-04T12:00:00Z", value: 16.4 * 0.9, unit: "°C" },
          { date: "2024-05-05T12:00:00Z", value: 16.8 * 0.9, unit: "°C" }
        ]
      },
      {
        category: "flow",
        measurements: [
          { date: "2024-05-08T12:00:00Z", value: 25.1 * 0.9, unit: "m³/s" },
          { date: "2024-05-02T12:00:00Z", value: 24.9 * 0.9, unit: "m³/s" },
          { date: "2024-05-03T12:00:00Z", value: 25.4 * 0.9, unit: "m³/s" },
          { date: "2024-05-04T12:00:00Z", value: 25.0 * 0.9, unit: "m³/s" },
          { date: "2024-05-05T12:00:00Z", value: 25.3 * 0.9, unit: "m³/s" },
          { date: "2024-05-06T12:00:00Z", value: 28.2 * 0.9, unit: "m³/s" }
        ]
      }
    ],
  },
  {
    id: uuidv4(),
    deviceId: "Partidor C. Maripepa Colomer",
    location: {
      city: "Madrid",
      specificLocation: "Acequia de Favara",
      //coordinates: [39.446326, -0.364675] // West of Valencia
      coordinates: [726865.60, 4369556.49]
    },
    timeFrame: {
      start: "2024-05-08T12:00:00Z",
      end: "2024-05-09T12:00:00Z"
    },
    notifications: [
      {
        id: uuidv4(),
        urgency: 2,
        message: "Superficie inundada",
        location: "Server Room 2",
        status: "Working",
        assigned: "Chris Lee",
        date: "2024-04-01T12:00:00Z",
      }
    ],
    data: [
      {
        category: "flow",
        measurements: [
          {
            date: "2024-05-08T12:00:00Z",
            value: 0.3,
          }
        ],
      },
      {
        category: "pressure",
        measurements:
          [
            {
              date: "2024-05-08T12:00:00Z",
              value:
                0.15,
            }
          ]
      },
      {
        category: "temperature",
        measurements: [
          {
            date:
              "2024-05-08T12:00:00Z", value: 17,
          }

        ]
      }
    ]
  },
  {
    id: uuidv4(),
    deviceId: "Inicio Acequia C. Maripepa Colomer",
    location: {
      city: "Valencia",
      specificLocation: "Marjals i Extremals",
      //coordinates: [39.446152, -0.364378] // South-West of Valencia
      coordinates: [726955.12, 4369493.74]
    },
    timeFrame: {
      start: "2024-05-08T12:00:00Z",
      end: "2024-05-09T12:00:00Z"
    },
    notifications: [
      {
        id: uuidv4(),
        urgency: 1,
        message: "asalto",
        location: "Office 305",
        status: "Waiting",
        assigned: "Samanta Doe",
        date: "2024-05-01T12:00:00Z",
      }
    ],
    data: [
      {
        category: "flow",
        measurements: [
          {
            date: "2024-05-08T12:00:00Z",
            value: 0.3,
          }
        ],
      },
      {
        category: "pressure",
        measurements:
          [
            {
              date: "2024-05-08T12:00:00Z",
              value:
                0.15,
            }
          ]
      },
      {
        category: "temperature",
        measurements: [
          {
            date:
              "2024-05-08T12:00:00Z", value: 17,
          }

        ]
      }
    ]
  },
  /* {//7
    id: uuidv4(),
    deviceId: "Campo 1 Acequia C. M. Colomer",
    location: {
      city: "Valencia",
      specificLocation: "Marjals i Extremals",
      coordinates: [39.445618, -0.363435] // North-West of Valencia
    },
    timeFrame: {
      start: "2024-05-08T12:00:00Z",
      end: "2024-05-09T12:00:00Z"
    },
    notifications: [
      {
        id: uuidv4(),
        urgency: 5,
        message: "Water leak",
        location: "Main Hall",
        status: "Snoozed",
        assigned: "Derek Sun",
        date: "2024-06-01T12:00:00Z",
      }
    ],
    data: [
      {
        category: "flow",
        measurements: [
          {
            date: "2024-05-08T12:00:00Z",
            value: 0.3,
          }
        ],
      },
      {
        category: "pressure",
        measurements:
          [
            {
              date: "2024-05-08T12:00:00Z",
              value:
                0.15,
            }
          ]
      },
      {
        category: "temperature",
        measurements: [
          {
            date:
              "2024-05-08T12:00:00Z", value: 17,
          }

        ]
      }
    ]
  },
  {
    id: uuidv4(),
    deviceId: "Campo 2 Acequia C. M. Colomer",
    location: {
      city: "Valencia",
      specificLocation: "Marjals i Extremals",
      coordinates: [39.445067, -0.362563] // Central Valencia
    },
    timeFrame: {
      start: "2024-05-08T12:00:00Z",
      end: "2024-05-09T12:00:00Z"
    },
    notifications: [
      {
        id: uuidv4(),
        urgency: "High",
        message: "Low Pressure",
        location: "Lab",
        status: "Completed",
        assigned: "Olivia Jones",
        date: "2024-07-01T12:00:00Z",
      }
    ],
    data: [
      {
        category: "flow",
        measurements: [
          {
            date: "2024-05-08T12:00:00Z",
            value: 0.3,
          }
        ],
      },
      {
        category: "pressure",
        measurements:
          [
            {
              date: "2024-05-08T12:00:00Z",
              value:
                0.15,
            }
          ]
      },
      {
        category: "temperature",
        measurements: [
          {
            date:
              "2024-05-08T12:00:00Z", value: 17,
          }

        ]
      }
    ]
  },
  {
    id: uuidv4(),
    deviceId: "Partidor Entrada Alqueria Ros",
    location: {
      city: "Valencia",
      specificLocation: "Marjals i Extremals",
      coordinates: [39.445647, -0.361802] // South of Valencia
    },
    timeFrame: {
      start: "2024-05-08T12:00:00Z",
      end: "2024-05-09T12:00:00Z"
    },
    notifications: [
      {
        id: uuidv4(),
        urgency: "Medium",
        message: "Maintenance",
        location: "Office 408",
        status: "Working",
        assigned: "Ethan Wright",
        date: "2024-08-01T12:00:00Z",
      }
    ],
    data: [
      {
        category: "flow",
        measurements: [
          {
            date: "2024-05-08T12:00:00Z",
            value: 0.3,
          }
        ],
      },
      {
        category: "pressure",
        measurements:
          [
            {
              date: "2024-05-08T12:00:00Z",
              value:
                0.15,
            }
          ]
      },
      {
        category: "temperature",
        measurements: [
          {
            date:
              "2024-05-08T12:00:00Z", value: 17,
          }

        ]
      }
    ]
  },
  {
    id: uuidv4(),
    deviceId: "Campo 1 Entrada Alqueria Ros",
    location: {
      city: "Valencia",
      specificLocation: "Marjals i Extremals",
      coordinates: [39.445208, -0.360505] // North-East of Valencia
    },
    timeFrame: {
      start: "2024-05-08T12:00:00Z",
      end: "2024-05-09T12:00:00Z"
    },
    notifications: [
      {
        id: uuidv4(),
        urgency: "Low",
        message: "High temperature",
        location: "Warehouse",
        status: "Waiting",
        assigned: "Mia Wong",
        date: "2024-09-01T12:00:00Z",
      }
    ],
    data: [
      {
        category: "flow",
        measurements: [
          {
            date: "2024-05-08T12:00:00Z",
            value: 0.3,
          }
        ],
      },
      {
        category: "pressure",
        measurements:
          [
            {
              date: "2024-05-08T12:00:00Z",
              value:
                0.15,
            }
          ]
      },
      {
        category: "temperature",
        measurements: [
          {
            date:
              "2024-05-08T12:00:00Z", value: 17,
          }

        ]
      }
    ]
  },
  {
    id: uuidv4(),
    deviceId: "Campo 2 Entrada Alqueria Ros",
    location: {
      city: "Valencia",
      specificLocation: "Marjals i Extremals",
      coordinates: [39.444843, -0.357947] // West-Central of Valencia
    },
    timeFrame: {
      start: "2024-05-08T12:00:00Z",
      end: "2024-05-09T12:00:00Z"
    },
    notifications: [
      {
        id: uuidv4(),
        urgency: "High",
        message: "Maintenance",
        location: "Office 101",
        status: "Snoozed",
        assigned: "Jane Smith",
        date: "2024-10-01T12:00:00Z",
      }
    ],
    data: [
      {
        category: "flow",
        measurements: [
          {
            date: "2024-05-08T12:00:00Z",
            value: 0.3,
          }
        ],
      },
      {
        category: "pressure",
        measurements:
          [
            {
              date: "2024-05-08T12:00:00Z",
              value:
                0.15,
            }
          ]
      },
      {
        category: "temperature",
        measurements: [
          {
            date:
              "2024-05-08T12:00:00Z", value: 17,
          }

        ]
      }
    ]
  },
  {
    id: uuidv4(),
    deviceId: "Campo 3 Entrada Alqueria Ros",
    location: {
      city: "Valencia",
      specificLocation: "Marjals i Extremals",
      coordinates: [39.444552, -0.357072] // Central Valencia
    },
    timeFrame: {
      start: "2024-05-08T12:00:00Z",
      end: "2024-05-09T12:00:00Z"
    },
    notifications: [
      {
        id: uuidv4(),
        urgency: "Low",
        message: "Water leak",
        location: "Office 501",
        status: "Snoozed",
        assigned: "Noah Lee",
        date: "2024-11-01T12:00:00Z",
      }
    ],
    data: [
      {
        category: "flow",
        measurements: [
          {
            date: "2024-05-08T12:00:00Z",
            value: 0.3,
          }
        ],
      },
      {
        category: "pressure",
        measurements:
          [
            {
              date: "2024-05-08T12:00:00Z",
              value:
                0.15,
            }
          ]
      },
      {
        category: "temperature",
        measurements: [
          {
            date:
              "2024-05-08T12:00:00Z", value: 17,
          }

        ]
      }
    ]
  },
  {
    id: uuidv4(),
    deviceId: "Campo 1 Entrada Casa Bajoca",
    location: {
      city: "Valencia",
      specificLocation: "Marjals i Extremals",
      coordinates: [39.444304, -0.356308] // East of Valencia
    },
    timeFrame: {
      start: "2024-05-08T12:00:00Z",
      end: "2024-05-09T12:00:00Z"
    },
    notifications: [
      {
        id: uuidv4(),
        urgency: "Medium",
        message: "high pressure",
        location: "Office 517",
        status: "Completed",
        assigned: "John Doe",
        date: "2024-12-01T12:00:00Z",
      }
    ],
    data: [
      {
        category: "flow",
        measurements: [
          {
            date: "2024-05-08T12:00:00Z",
            value: 0.3,
          }
        ],
      },
      {
        category: "pressure",
        measurements:
          [
            {
              date: "2024-05-08T12:00:00Z",
              value:
                0.15,
            }
          ]
      },
      {
        category: "temperature",
        measurements: [
          {
            date:
              "2024-05-08T12:00:00Z", value: 17,
          }

        ]
      }
    ]
  },
  {
    id: uuidv4(),
    deviceId: "Campo 2 Entrada Casa Bajoca",
    location: {
      city: "Valencia",
      specificLocation: "Marjals i Extremals",
      coordinates: [39.444045, -0.355629] // South-East of Valencia
    },
    timeFrame: {
      start: "2024-05-08T12:00:00Z",
      end: "2024-05-09T12:00:00Z"
    },
    notifications: [
      {
        id: uuidv4(),
        urgency: "Low",
        message: "excavator",
        location: "Office 521",
        status: "Working",
        assigned: "Alex Johnson",
        date: "2024-13-01T12:00:00Z",
      }
    ],
    data: [
      {
        category: "flow",
        measurements: [
          {
            date: "2024-05-08T12:00:00Z",
            value: 0.3,
          }
        ],
      },
      {
        category: "pressure",
        measurements:
          [
            {
              date: "2024-05-08T12:00:00Z",
              value:
                0.15,
            }
          ]
      },
      {
        category: "temperature",
        measurements: [
          {
            date:
              "2024-05-08T12:00:00Z", value: 17,
          }

        ]
      }
    ]
  },
  {
    id: uuidv4(),
    deviceId: "Campo 3 Entrada Casa Bajoca",
    location: {
      city: "Valencia",
      specificLocation: "Marjals i Extremals",
      coordinates: [39.443668, -0.354885] // North of Valencia
    },
    timeFrame: {
      start: "2024-05-08T12:00:00Z",
      end: "2024-05-09T12:00:00Z"
    },
    notifications: [
      {
        id: uuidv4(),
        urgency: "High",
        message: "asalto",
        location: "Office 528",
        status: "Waiting",
        assigned: "Chris Lee",
        date: "2024-14-01T12:00:00Z",
      }
    ],
    data: [
      {
        category: "flow",
        measurements: [
          {
            date: "2024-05-08T12:00:00Z",
            value: 0.3,
          }
        ],
      },
      {
        category: "pressure",
        measurements:
          [
            {
              date: "2024-05-08T12:00:00Z",
              value:
                0.15,
            }
          ]
      },
      {
        category: "temperature",
        measurements: [
          {
            date:
              "2024-05-08T12:00:00Z", value: 17,
          }

        ]
      }
    ]
  }, */
]

export default unifiedData;
