import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import React, { useEffect, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { Form } from "components/ui/form";
import { Button } from "components/ui/button";
import { Calendar } from "components/ui/calendar";
import { TimePicker } from "./time-picker";

const formSchema = z.object({
  dateTimeStart: z.date(),
  dateTimeEnd: z.date(),
});

export function DateTimeRangePickerForm({ startDate, endDate, onDateChangeStart, onDateChangeEnd }) {
  const { control, handleSubmit, setValue, watch } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      dateTimeStart: startDate || new Date(),
      dateTimeEnd: endDate || new Date(),
    }
  });

  const [selectionStarted, setSelectionStarted] = useState(false);
  const dateTimeStart = watch("dateTimeStart");
  const dateTimeEnd = watch("dateTimeEnd");

  useEffect(() => {
    setValue('dateTimeStart', startDate);
    setValue('dateTimeEnd', endDate);
  }, [startDate, endDate, setValue]);

  function onSubmit(data) {
    console.log(data); // Trigger external action
  }

  function formatRangeLabel(start, end) {
    if (!start || !end) return "Select date range";
    return `${format(start, "MMM dd, yyyy HH:mm")} - ${format(end, "MMM dd, yyyy HH:mm")}`;
  }

  function handleDateSelection(range) {
    if (!range.from || !range.to) return; // Ignore incomplete selections

    // Allow "restarting" the selection within the range
    if (selectionStarted || range.from === range.to) {
      setValue('dateTimeStart', range.from);
      setValue('dateTimeEnd', range.to);
      if (onDateChangeStart) onDateChangeStart(range.from);
      if (onDateChangeEnd) onDateChangeEnd(range.to);
      setSelectionStarted(false); // Reset the selection start
    } else {
      // Detect if the new start is between the old start and end dates
      if (dateTimeStart && dateTimeEnd && range.from > dateTimeStart && range.from < dateTimeEnd) {
        setValue('dateTimeStart', range.from);
        setValue('dateTimeEnd', dateTimeEnd);
        if (onDateChangeStart) onDateChangeStart(range.from);
        setSelectionStarted(true); // Start new selection
      } else {
        setValue('dateTimeStart', range.from);
        setValue('dateTimeEnd', range.to);
        if (onDateChangeStart) onDateChangeStart(range.from);
        if (onDateChangeEnd) onDateChangeEnd(range.to);
        setSelectionStarted(false);
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col items-center gap-4">
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline">
              {formatRangeLabel(dateTimeStart, dateTimeEnd)}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="p-4 w-full">
            <Calendar
              mode="range"
              selected={{ from: dateTimeStart, to: dateTimeEnd }}
              onSelect={handleDateSelection}
              numberOfMonths={2}
            />
            <div className="mt-4 grid grid-cols-2 gap-4">
              <TimePicker
                label="Start Time:"
                displaySeconds={false}
                date={dateTimeStart}
                setDate={(date) => {
                  setValue('dateTimeStart', date);
                  if (onDateChangeStart) onDateChangeStart(date);
                }}
              />
              <TimePicker
                label="End Time:"
                displaySeconds={false}
                date={dateTimeEnd}
                setDate={(date) => {
                  setValue('dateTimeEnd', date);
                  if (onDateChangeEnd) onDateChangeEnd(date);
                }}
              />
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </Form>
  );
}
