import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
/* import fetchHortatech from "../helpers/fetchHortatech";
import axios from "axios"; */
import sensorCoords from "data/sensor_data";
// import ProfilePicBlank from "assets/profile-pics/UserBlank.png";
import { useAuth } from "./AuthContext";
import {
  updateWidgetType,
  updateWidgetOptions,
  resetFrontLayout,
  setWidgetDefaultOptions,
} from "./functions/widgetFunctions";
import {
  addWidget,
  removeWidget,
  storeWidget,
  restoreStoredWidget,
} from "./functions/widgetDashboardFunctions";
import {
  availableWidgets,
  widgetData,
} from "data/chartData/frontLayout";

import { frontLayouts } from "data/frontLayouts";
import { usePageTitle } from "contexts/PageTitleContext";

//Helper functions to get data from Fibsen's API
import { AuthApiFibsen, fetchDevicesData, fetchPressureData, fetchTemperatureData, fetchFlowData, fetchSensorsData, fetchSensorsDataLast5, fetchSensorsDataLatest, fetchSensorsDataLast30} from "helpers/apiFIbsenCalls";
//import unifiedData from "data/chartData/unifiedData";
import { setDynamicData, getDynamicData } from "data/chartData/unifiedDataSensors";

import { v4 as uuidv4 } from 'uuid';
import { useHandleSetLayout } from "./functions/layoutFunctions";
const StateContext = createContext();

const initialUserProfile = {
  username: "",
  role: "admin",
  password: "",
  phone: "",
  email: "",
  profilePicture: "",
  parcela: 1026, // a number
  poligono: 3,
  catastro: "ABCDEF",
  parcela_orden: "",
  isOnline: true,
  userSettings: {
    apiCallFrequency: 5,
    enableNotifications: true,
    notificationChannels: {
      email: true,
      push: true,
      whatsapp: true,
    },
    notificationTypes: {
      watering: true,
      maintenance: true,
      incidents: true,
    },
    consentToCookies: true,
    consentToPersonalData: true,
    cookiePreferences: "essential", // "necessary", "preferences", "statistics", "marketing"
    whoCanContact: "everyone",
    darkMode: "system", // "light", "dark", "system" en db
    fontSize: "16px",
    sidebarPosition: "left", // "left" or "right" or maybe in the future - "bottom"
    // layoutMode: "queueWithMap",
    // dashboardLayout: frontLayouts["queueWithMap"], // should be "example-1 for demo" //sorted
    layoutMode: "advanced",
    dashboardLayout: frontLayouts["advanced"], // should be "example-1 for demo" //sorted
    widgets: widgetData,
    storedWidgets: [],
  },
  contactDetails: {
    firstName: "",
    lastName: "",
    workRole: "",
    bio: "",
    phone: "",
    contactMail: "",
    allowWhatsapp: true,
    
    address: {
      city: "",
      state: "",
      country: "",
      postalCode: "",
      street: "",
    },
  },
};

//console.log("ContextProvider initialUserProfile: ",initialUserProfile);

export const ContextProvider = ({ children }) => {
  
  const [activeLinkName, setActiveLinkName] = useState(() => {
    // Load from session storage
    const storedActiveLinkName = sessionStorage.getItem("activeLinkName");
    return storedActiveLinkName ? JSON.parse(storedActiveLinkName) : "Home"; // Default to "Home"
  });

    const [isEditingDashboardLayout, setIsEditingDashboardLayout] = useState(false); // Add state for draggability
    const { setPageTitle } = usePageTitle();

  const [activeLoginForm, setActiveLoginForm] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null); // Added state for selected profile
  const [selectedEventId, setSelectedEventId] = useState(""); // Added state for selected profile

  const [sensorsDataLatest, setSensorsDataLatest] = useState(null);
  const [sensorsDataLast5, setSensorsDataLast5] = useState(null);
  //const [dataLoaded, setDataLoaded] = useState(false);
  const [unifiedData, setUnifiedData] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUserPermissions, setIsLoadingUserPermissions] = useState(true);
  //States to handle user data between events
  const [userData, setUserData] = useState([]);

  const [latestUpdateTime, setLatestUpdateTime] = useState(new Date());

  useEffect( () =>
  {
    //console.log("ContextProvider isLoading", isLoading)
    console.log("ContextProvider isLoadingUserPermissions", isLoadingUserPermissions)

  }
  , [isLoadingUserPermissions])
  /* const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authTokens, setAuthTokens] = useState({
    accessToken: null,
    refreshToken: null,
  });  */


  // useEffect(() => {
  // //console.log("ContextProvider selectedEventId", selectedEventId);
  // }, [selectedEventId]);
  // useEffect(() => {
  // //console.log("ContextProvider selectedEvent", selectedEvent);
  // }, [selectedEvent]);

  //Tokens for authentication for fibsen's API
  const [accessToken, setAccessToken] = useState(""); //useState("");
  const [refreshToken, setRefreshToken] = useState(""); //useState("");
  const { isAuthenticated } = useAuth();
  //const [refreshData, setRefreshData] = useState(false);
  const [activeComponent, setActiveComponent] = useState("");

  // console.log("ContextProvider activeComponent", activeComponent);


  //-----MANAGE USER PROFILE-------
  //----GLOBAL STATE TO SAVE USER'S DATA
    
  const [userProfile, setUserProfile] = useState(() => {
    const storedUserProfile = JSON.parse(localStorage.getItem("userProfile"));
    return storedUserProfile || initialUserProfile;
  });
  const handleSetLayout = useHandleSetLayout(userProfile, setUserProfile, setActiveLinkName, setIsEditingDashboardLayout, setPageTitle);

  useEffect(() => {
    setIsLoadingUserPermissions(false);
    setIsLoading(false);
  }, [userProfile]);

  //TEST trying to fix empty widgets due to widgetData not properly set for the user profile
  /* const [userProfile, setUserProfile] = useState(() => {
    const storedUserProfile = JSON.parse(localStorage.getItem("userProfile"));
    if (storedUserProfile) {
      return storedUserProfile;
    } else {
      // Initialize the widgets with the data available at start
      return {
        ...initialUserProfile,
        userSettings: {
          ...initialUserProfile.userSettings,
          widgets: widgetData(unifiedData),
        }
      };
    }
  }); */

  //console.log("ContextProvider userProfile: ", userProfile);

  useEffect(() => {
    //Load user profile from local storage
    const storedUserProfile = JSON.parse(localStorage.getItem("userProfile"));
    if (storedUserProfile) {
      setUserProfile(storedUserProfile);
    }
  }, []);

  useEffect(() => {
    //Load contactDetails from local storage
    const storedContactDetails = JSON.parse(
      localStorage.getItem("contactDetails")
    );
    if (storedContactDetails) {
      setUserProfile((prevProfile) => ({
        ...prevProfile,
        contactDetails: storedContactDetails,
      }));
    }
  }, []);

  useEffect(() => {
    //Load userSettings from local storage
    const storedUserSettings = JSON.parse(localStorage.getItem("userSettings"));
    if (storedUserSettings) {
      setUserProfile((prevProfile) => ({
        ...prevProfile,
        userSettings: storedUserSettings,
      }));
    }
  }, []);

  // Persist activeLinkName from local storage to state
  useEffect(() => {
    localStorage.setItem("activeLinkName", JSON.stringify(activeLinkName));
    console.log("ContextProvider useEffect activeLinkName", activeLinkName)
  }, [activeLinkName]);

  useEffect(() => {
    if (userProfile && userProfile.userSettings) {
      const newLayout = frontLayouts[userProfile.userSettings.layoutMode];
      setUserProfile((prevProfile) => ({
        ...prevProfile,
        userSettings: {
          ...prevProfile.userSettings,
          dashboardLayout: newLayout,
        },
      }));
    }
  }, [userProfile.userSettings.layoutMode]);

  useEffect(() => {
    // Load userSettings from local storage
    const activeLinkName = JSON.parse(localStorage.getItem("activeLinkName"));
    if (activeLinkName) {
      setUserProfile((prevProfile) => ({
        ...prevProfile,
        userSettings: {
          ...prevProfile.userSettings,
          dashboardLayout: frontLayouts[activeLinkName],
        }
      }));
    }
  }, []);
    
  useEffect(() => {
    //Save user profile to local storage
    localStorage.setItem("userProfile", JSON.stringify(userProfile));
    //console.log("ContextProvider useEffect userProfile", userProfile)
    console.log("ContextProvider useEffect userProfile layoutMode:", userProfile.userSettings.layoutMode)
  }, [userProfile]);

  useEffect(() => {
    //Save contactDetails to local storage
    localStorage.setItem(
      "contactDetails",
      JSON.stringify(userProfile.contactDetails)
    );
  }, [userProfile.contactDetails]);

  useEffect(() => {
    //Save userSettings to local storage
    localStorage.setItem(
      "userSettings",
      JSON.stringify(userProfile.userSettings)
    );
  }, [userProfile.userSettings]);

  //-----
  const updateUser = (newUser) => {
    setUserProfile((prevUser) => ({
      ...prevUser,
      ...newUser,
    }));
  };

  const logoutRemoveProfile = async () => {
    //Remove user profile from context state
    setUserProfile({
      username: "",
      role: "guest",
      password: "",
      phone: "",
      email: "",
      profilePicture: "",
      parcela: "", // EN: parcela, ES: parcela
      poligono: "", // EN: polygon, Poligono
      catastro: "", // EN: Cadastre, ES: Catastro
      isUserAGuest: false,
      //profilePicture: ProfilePic,
      isOnline: true,
      userSettings: {
        apiCallFrequency: 5,
        enableNotifications: true,
        notificationChannels: {
          email: true,
          push: true,
          whatsapp: true,
        },
        notificationTypes: {
          watering: true,
          maintenance: true,
          incidents: true,
        },
        consentToCookies: true,
        consentToPersonalData: true,
        cookiePreferences: "essential", // "necessary", "preferences", "statistics", "marketing"
        whoCanContact: "everyone",
        darkMode: "system", // "light", "dark", "system" en db
        // https://tailwindcss.com/docs/dark-mode for dark mode to be "true", "false" or "auto"
        fontSize: "16px",
        sidebarPosition: "left", // "left" or "right" or maybe in the future - "bottom"
        layoutMode: "simple",
        dashboardLayout: frontLayouts["simple"], // should be "example-1 for demo" //sorted
        widgets: widgetData,
        storedWidgets: [],
      },
      contactDetails: {
        firstName: "",
        lastName: "",
        workRole: "",
        bio: "",
        phone: "",
        contactMail: "",
        allowWhatsapp: true,
        
        address: {
          city: "",
          state: "",
          country: "",
          postalCode: "",
          street: "",
        },
      },
    });
  }

  //-----END MANAGE USER PROFILE

  //-----MANAGE DATA RETRIEVED FROM SENSORS BY FIBSEN------
  // Code to retrieve data from fibsen's API.
  //Get auth tokens
  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const response = await AuthApiFibsen("sebastian", "sebastian");
        const responseBody = JSON.parse(response.body);
        //console.log("ContextProvider fetchFibTokens tokens", responseBody);
        if (response.accessToken) {
          setAccessToken({ accessToken: response.accessToken }); //{ accessToken: response.accessToken }
          setRefreshToken({ refreshToken: response.refreshToken }); //{ refreshToken: response.refreshToken }
        }
      } catch (error) {
        console.error("Error fetching tokens:", error.message);
      }
    };
    if (isAuthenticated) {
      fetchTokens();
    }
  }, [isAuthenticated]);
  //console.log("ContextProvider Fibsen Tokens:", accessToken, refreshToken);

  //Get Pressure Data
  const fetchPressure = async (params) => {
    try {
      const {
        entityId, startDate, endDate, min_pressure, max_pressure, maxResults, category, accessToken, refreshToken,
      } = params;

      const response = await fetchPressureData(params);
      //console.log("ContextProvider fetchPressure fetched", response);
      if (response.OK) {
        //console.log("ContextProvider fetchPressureData response ok", response);
        //Handle response
      }
      if (response) {
        const formattedData = response.map((item) => ({
          id: item.deviceID, // Using deviceID as a unique identifier for each entry
          title: "Pressure Station " + item.deviceID, // Example title, adjust as needed
          city: "Valencia", // Static or based on deviceID mapping if available
          location: "Marjals i Extremals", // Static or dynamic based on a lookup table for device IDs
          date: item.measurementTime, // Assuming a date field is available in the response
          data: [
            {
              label: "Water Pressure",
              value: item.measurements.pressure,
              maxValue: max_pressure || 100, // Assuming a default or maximum based on the context
              unit: "bar",
            },
          ],
        }));

        //console.log("ContextProvider Transformed pressure data:", formattedData);
        return formattedData;
      }
    } catch (error) {
      console.error("Error fetching Pressure data:", error.message);
      return []; // Return an empty array to handle errors gracefully
    }
  };
  //Get temp data
  const fetchTemperature = async (params) => {
    /*if (!accessToken || !refreshToken) {
        console.log("Missing tokens: Cannot fetch temperature data");
        return;
    }*/
    //console.log("fetchTemperature accessToken: ", accessToken);
    try {
      const {
        entityId, startDate, endDate, min_temperature, max_temperature, maxResults, category, accessToken, refreshToken,
      } = params;
      //console.log("ContextProvider fetchTemperature params: ", params);
      const response = await fetchTemperatureData(params);
      //console.log("ContextProvider fetchTemperature fetched", response);
      if (response.OK) {
        console.log("ContextProvider fetchTemperature response ok", response);
        //Handle response
      }
    } catch (error) {
      console.error("Error fetching Temperature data:", error.message);
    }
  };
  //Get flow data
  const fetchFlow = async (params) => {
    //console.log("fetchFlow accessToken: ", accessToken);
    try {
      const {
        entityId, startDate, endDate, min_flow, max_flow, maxResults, category, accessToken, refreshToken,
      } = params;

      const response = await fetchFlowData(params);
      //console.log("ContextProvider fetchFlow fetched", response);
      if (response.OK) {
        //console.log("ContextProvider fetchFlow response ok", response);
        //Handle response
      }
    } catch (error) {
      console.error("Error fetching Flow data:", error.message);
    }
  };

  //Adapt data retrieved from backend to unifiedData structure
  /* const transformData = (data) => {
    const transformedData = [];

    Object.keys(data).forEach((deviceId) => {
      const deviceData = data[deviceId];
      const unifiedDevice = {
        deviceId,
        title: `Sensor ${deviceId}`,
        notifications: [],
        location: {
          city: "Unknown",
          specificLocation: "Unknown",
        },
        timeFrame: {},
        data: [
          {
            category: "pressure",
            measurements: deviceData.map(d => ({
              date: d.recvtime,
              value: d.pressure,
              unit: "bar"
            })).filter(d => d.value !== null)
          },
          {
            category: "temperature",
            measurements: deviceData.map(d => ({
              date: d.recvtime,
              value: d.temperature,
              unit: "°C"
            })).filter(d => d.value !== null)
          },
          {
            category: "flow",
            measurements: deviceData.map(d => ({
              date: d.recvtime,
              value: d.flow,
              unit: "m³/s"
            })).filter(d => d.value !== null)
          }
        ]
      };
      transformedData.push(unifiedDevice);
    });

    return transformedData;
  }; */

  
  

  /* const transformData = (data) => {
    return Object.keys(data).map((deviceId) => ({
      id: uuidv4(),
      deviceId,
      title: `Sensor ${deviceId}`,
      notifications: [],
      location: {
        city: "Unknown",
        specificLocation: "Unknown",
        coordinates: [726665.02, 4369676.67]
      },
      timeFrame: {},
      data: [
        {
          category: "pressure",
          measurements: data[deviceId].map(d => ({
            date: d.recvtime,
            value: d.pressure,
            unit: "bar"
          })).filter(d => d.value !== null)
        },
        {
          category: "temperature",
          measurements: data[deviceId].map(d => ({
            date: d.recvtime,
            value: d.temperature,
            unit: "°C"
          })).filter(d => d.value !== null)
        },
        {
          category: "flow",
          measurements: data[deviceId].map(d => ({
            date: d.recvtime,
            value: d.flow,
            unit: "m³/s"
          })).filter(d => d.value !== null)
        }
      ]
    }));
  }; */
  // const [sensorCoordinates, setSensorCoordinates] = useState(sensorCoords);

  // useMemo(() => {
  //   setSensorCoordinates(sensorCoords);
  // }, [sensorCoords]);
  const sensorCoordinates = {
    "29": {
      "title": "Acequia Fabiana - Pozo del Agricultor",
      "coordx": 726665.02,
      "coordy": 4369676.67
    },
    "63": {
      "title": "Acequia Fabiana",
      "coordx": 726696.58,
      "coordy": 4369663.44
    },
    "142": {
      "title": "Partidor C/ Maripepa Colomer",
      "coordx": 726766.5,
      "coordy": 4369627.38
    },
    "261": {
      "title": "Entrada Alqueria Ros a/Compuerta ",
      "coordx": 726862.67,
      "coordy": 4369557.6
    },
    "263": {
      "title": "Entrada Alqueria Ros d/Compuerta",
      "coordx": 726865.6,
      "coordy": 4369556.49
    },
    "371": {
      "title": "Entrada Casa Conole",
      "coordx": 726955.12,
      "coordy": 4369493.74
    }
  };

  //Mock fetchSensorsDataLast5 to rest re-rendering of widgets in analiticas
  const generateMockData = (deviceId, numEntries = 1) => {
    const now = new Date();
    // console.log("ContextProvider fetchSensorsData generateMockData now:", now);

    const generateDate = (daysAgo, secondsAgo) => {
        const date = new Date(now);
        //console.log("ContextProvider fetchSensorsData generateMockData generateDate date:", date);

        date.setDate(now.getDate() - daysAgo);
        // console.log("ContextProvider fetchSensorsData generateMockData generateDate date setDate:", date);

        date.setSeconds(now.getSeconds() - secondsAgo);
        // console.log("ContextProvider fetchSensorsData generateMockData generateDate date setseconds:", date);

        return date;
    };

    const generateValue = (base, variation) => {
        return (base + (Math.random() * variation - variation / 2)).toFixed(2);
    };

    const mockData = [];
    for (let i = 0; i < numEntries; i++) {
        mockData.push({
            recvtime: generateDate(i, i * 10), // each entry is 10 seconds apart
            pressure: generateValue(1.5, 0.5), // random value around 1.5
            temperature: generateValue(25, 5), // random value around 25
            flow: generateValue(3.0, 1.0) // random value around 3.0
        });
    }
    //console.log("ContextProvider fetchSensorsData generateMockData mockData:", mockData);
    return mockData;
};



  

  const MockfetchSensorsDataLast5 = async () => {
    const mockData = {
      "29": generateMockData("29", 5),
      "63": generateMockData("63", 5),
      "142": generateMockData("142", 5),
      "261": generateMockData("261", 5),
      "263": generateMockData("263", 5),
      "371": generateMockData("371", 5),
  };
  
    return {
      statusCode: 200,
      body: JSON.stringify(mockData),
    };
  };

  const MockfetchSensorsDataLastest = async () => {
    const mockData = {
      "29": generateMockData("29", 1),
      "63": generateMockData("63", 1),
      "142": generateMockData("142", 1),
      "261": generateMockData("261", 1),
      "263": generateMockData("263", 1),
      "371": generateMockData("371", 1),
  };
  
    return {
      statusCode: 200,
      body: JSON.stringify(mockData),
    };
  };
  
  /* //Testing function that prints the fetched data
  const testFetch = async () => {
    const response = await fetchSensorsDataLast5();
    if (response.statusCode === 200) {
      const data = JSON.parse(response.body);
      console.log("Fetched Data:", data);
    }
  };
  
  testFetch(); */
  

  const transformData = (data) => {
    //console.log("ContextProvider fetchSensorsData transformData data:", data);

    const formatValue = (value) => {
      // If value is null or undefined, return "0"
      if (value === null || value === undefined) {
        return "0";
      }

      //Convert the value to a number, replace negative values with 0, and format to two decimal places
      const numericValue = parseFloat(value);
      if (isNaN(numericValue) || numericValue < 0) {
        return "0";
      }
      return numericValue.toFixed(2);
    };

    return Object.keys(data).map((deviceId) => {
        //Get coordinates from sensorCoordinates based on deviceId
        const coordinates = sensorCoordinates[deviceId]
        ? [sensorCoordinates[deviceId].coordx, sensorCoordinates[deviceId].coordy]
        : [0, 0]; //Default coordinates if deviceId not found

      //Original code dealt with the function fetchSensorsDataLast5() that fetches the latest 5 sensor's data in an array of objects. So it used this:
      //measurements: data[deviceId].map(d => ({
      //Now depending on the function used: fetchSensorsDataLast5() or fetchSensorsDataLatest() it needs to figure out if it's an array or a single object.

      //Determine if the data for the deviceId is an array or a single object
      const measurementsData = Array.isArray(data[deviceId]) ? data[deviceId] : [data[deviceId]];
      
      //console.log(`ContextProvider fetchSensorsData transformData: ${deviceId}, data:`, measurementsData);
      //console.log("ContextProvider fetchSensorsData measurementsData:", measurementsData);

      return {
      id: uuidv4(),
      deviceId,
      title: sensorCoordinates[deviceId]?.title || `Sensor ${deviceId}`,
      // title: `Sensor ${deviceId}`,
      notifications: [],
      location: {
        city: "Valencia", // TODO Unknown
        specificLocation: "Marjals i Extremals", // TODO Unknown 
        coordinates: coordinates
      },
      timeFrame: {
        start: "",
        end: ""
      },
      data: [
        {
          category: "pressure",
          measurements: measurementsData.map(d => {
            let date = new Date(d.recvtime);
            date.setHours(date.getHours() + 1); // Keep as Date object with added 2 hours
            return {
              date: date, 
              value: formatValue(d.pressure), //d.pressure,
              unit: "bar"
            };
          }).filter(d => d.value !== null)
        },
        {
          category: "temperature",
          measurements: measurementsData.map(d => {
            let date = new Date(d.recvtime);
            date.setHours(date.getHours() + 1); // Keep as Date object with added 2 hours. 2 hours ahead of DB's date and time in summer and 1 hour in winter.
            return {
              date: date, 
              value: formatValue(d.temperature), //d.temperature,
              unit: "°C"
            };
          }).filter(d => d.value !== null)
        },
        {
          category: "flow",
          measurements: measurementsData.map(d => {
            let date = new Date(d.recvtime);
            date.setHours(date.getHours() + 1); // Keep as Date object with added 2 hours
            return {
              date: date, 
              value: formatValue(d.flow), //d.flow,
              unit: "m³/s"
            };
          }).filter(d => d.value !== null) //.filter(d => d.value !== null && d.value !== "0")
        }
      ]   
    }});
  };

  //Get devices
  const fetchSensorsDataFromAPI = async (isFullUpdate = false) => {
    try {
      //const { category, accessToken, refreshToken } = params;

      //TEST to fetch the latest records of every sensor at the same time as the latest
      /* const response = await fetchSensorsDataLatest();
      console.log("ContextProvider fetchSensorsDataLatest fetched", response);
      if (response.ok) {
        //console.log("ContextProvider fetchSensorsDataLatest response ok", response);
        const data = await response.json();
        console.log("ContextProvider fetchSensorsDataLatest data", data);
        setSensorsDataLatest(data);
      } */
      //TEST to fetch the last 5 records, the latest or a mock of both
      /*//const response5 = await fetchSensorsDataLast5();
      const response5 = await fetchSensorsDataLatest();
      //const response5 = await MockfetchSensorsDataLast5();*/

      let response;
        if (isFullUpdate) {
            //response = await fetchSensorsDataLast5(); //Fetch last 5 records
            response = await fetchSensorsDataLast30(); //Fetch last 30 records
            //response = await MockfetchSensorsDataLast5(); //Fetch last 5 records
            //console.log("ContextProvider fetchSensorsDataLast5 response", response); //shows database db date and time
        } else {
            response = await fetchSensorsDataLatest(); //Fetch latest record
            // response = await MockfetchSensorsDataLastest(); //Fetch latest record
            //console.log("ContextProvider fetchSensorsDataLatest response", response); //shows database db date and time
        }
      //console.log("ContextProvider fetchSensorsDataLast5 fetched", response5);
      if (response.statusCode === 200) {
        //console.log("ContextProvider fetchSensorsData response.statusCode 200", response);
        
        const data = JSON.parse(response.body);

        //console.log("ContextProvider fetchSensorsData inside response parsed data", data);
        //const data = await response5.json();        
        //console.log("ContextProvider fetchSensorsDataLast5 data", data);
        const transformedData = transformData(data);
        //console.log("ContextProvider fetchSensorsData inside response transformedData", transformedData); //shows database db date and time
        let newDataAppended = false;
        //Since setUnifiedData is asynchronous, directly accessing unifiedData after it might not reflect the latest state. Instead, we can use a local variable to store the updated unifiedData.
        let updatedUnifiedData;
        let totalDataPoints = 0;

        //Update unifiedData state
        setUnifiedData(prevData => {
          if (prevData.length === 0) {
            //If there's no previous data, initialize the state with transformedData
            updatedUnifiedData = transformedData;
            //console.log("ContextProvider fetchSensorsData updatedUnifiedData no previous data:", updatedUnifiedData);
            return transformedData;
          }

          //If there's previous data, append new data
          const updatedData = prevData.map(sensor => {
            const newSensorData = transformedData.find(d => d.deviceId === sensor.deviceId);
            if (newSensorData) {
              const updatedCategories = sensor.data.map(category => {
                const newCategoryData = newSensorData.data.find(d => d.category === category.category);
  
                // Get the date of the last measurement in the existing data
                const lastMeasurementDate = category.measurements.length > 0 
                  ? new Date(category.measurements[category.measurements.length - 1].date) 
                  : null;
  
                const filteredMeasurements = newCategoryData.measurements.filter(m => {
                  const measurementDate = new Date(m.date);
                  return !lastMeasurementDate || measurementDate > lastMeasurementDate;
                });
  
                if (filteredMeasurements.length > 0) {
                  newDataAppended = true;
                }
  
                //Update total data points count
                totalDataPoints += category.measurements.length + filteredMeasurements.length;
                //
                const allMeasurements = [...category.measurements, ...filteredMeasurements];

                //Sort measurements by date in ascending order to get the most recent last
                allMeasurements.sort((a, b) => new Date(a.date) - new Date(b.date));

                return {
                  ...category,
                  measurements: allMeasurements.slice(-1000) //(0, 100) //Keep only the most recent 100 data points
                  // measurements: allMeasurements.slice(-50) //(0, 100) //Keep only the most recent 100 data points
                };
              });
  
              return {
                ...sensor,
                data: updatedCategories
              };
            }
            //Return unchanged sensor if no new data found
            totalDataPoints += sensor.data.reduce((acc, cat) => acc + cat.measurements.length, 0);
            //console.log("ContextProvider fetchSensorsData returning unchanged sensor. New data not found");
            return sensor;
          });

          //Return updated data which includes new measurements
          updatedUnifiedData = updatedData;
          //console.log("ContextProvider fetchSensorsData updatedUnifiedData after append new data:", updatedUnifiedData);
          return updatedData;
        });
        setLatestUpdateTime(new Date()); // TODO HERE ADD TIMER TO UPDATE ANALITICAS PAGE

        //setDynamicData(transformedData);
        //setUnifiedData(transformedData);
        //setDataLoaded(true);

        //console.log("ContextProvider fetchSensorsData after setUnifiedData and dates in asc order ", unifiedData);

        //Update userProfile with the newly fetched unifiedData
        //TEST
        /* const widgetDataUnified = widgetData(transformedData); //widgetData(unifiedData);
        setUserProfile(prevProfile => ({
          ...prevProfile,
          userSettings: {
            ...prevProfile.userSettings,
            widgets: widgetDataUnified, //widgetData(transformedData),
          }
        })); */
        //only if there's a fullUpdate to avoid continuous re-rendering of widgets and screen flickering      
        /* if (isFullUpdate || newDataAppended) {
          const widgetDataUnified = widgetData(updatedUnifiedData); //widgetData(unifiedData);
          setUserProfile(prevProfile => ({
            ...prevProfile,
            userSettings: {
              ...prevProfile.userSettings,
              widgets: widgetDataUnified, //widgetData(transformedData),
            }
          }));
          //console.log("ContextProvider fetchSensorsDataLast5 userProfiler ", userProfile);

        } */
      }
     
    } catch (error) {
      console.error("Error fetching Pressure data:", error.message);
    } finally {
      setIsLoading(false); 
    }
  };
  //console.log("ContextProvider unifiedData: ",unifiedData);

  /* useEffect(() => {
      fetchSensorsDataFromAPI();
  }, [isAuthenticated]); */

  //Original code. Fetches whatevery function is configured in fetchSensorsDataFromAPI()
  /* useEffect(() => {
    let intervalId, fullUpdateIntervalId;

    if (isAuthenticated) {
      fetchSensorsDataFromAPI();

      //Fetch sensorsData every 10 seconds (time interval set by the user)
      intervalId = setInterval(() => {
        fetchSensorsDataFromAPI();
      }, 10000); //10 seconds interval 

      //TEST ¿updating analiticas page every 5 minutes? 
      fullUpdateIntervalId = setInterval(() => {
        fetchSensorsDataFromAPI(true); //Full update
      }, 300000); //Every 5 minutes
    }

    return () =>  {
      clearInterval(intervalId);
      clearInterval(fullUpdateIntervalId);
    }
  }, [isAuthenticated]); */

  //A little modification of the former useEffect. Fetches initially the last 5 records of every sensor and then only the latest
  const startDataFetching = (isAuthenticated, fetchSensorsDataFromAPI) => {
    let intervalId, fullUpdateIntervalId;

    const initialFetch = async () => {
      await fetchSensorsDataFromAPI(true); // Initial full fetch
    };

    if (isAuthenticated) {
      initialFetch();

      // Fetch sensorsData every 10 seconds (time interval set by the user)
      intervalId = setInterval(() => {
        fetchSensorsDataFromAPI();
      }, 10000); // 10 seconds interval 

      //TEST ¿updating analiticas page every 5 minutes? 
      /* // Full update every 5 minutes
      fullUpdateIntervalId = setInterval(() => {
          fetchSensorsDataFromAPI(true); // Full update
      }, 300000); // Every 5 minutes */
    }

    return () => {
      clearInterval(intervalId);
      clearInterval(fullUpdateIntervalId);
    }
  };
  
  useEffect(() => {
    const cleanup = startDataFetching(isAuthenticated, fetchSensorsDataFromAPI);
    return cleanup;
  }, [isAuthenticated]);
//   useEffect(() => {

//     // Usage:
// }, [isAuthenticated]);


  //TEST. Trigger a full update Based on Data Volume, when the appended data exceeds a certain volume
  /* useEffect(() => {
    if (unifiedData.length > 100) { //Threshold for full update
      fetchSensorsDataFromAPI(true); //Full update
    }
  }, [unifiedData]); */

  // TODO SEba temporary disable
  // useEffect(() => {
  //   if (!isLoading && isLoadingUserPermissions) {
  //     const widgetDataUnified = widgetData(unifiedData);
  //     console.log("ContextProvider UserProfile widgetDataUnified: ", widgetDataUnified);
  //     setUserProfile(prevProfile => ({
  //       ...prevProfile,
  //       userSettings: {
  //         ...prevProfile.userSettings,
  //         widgets: widgetDataUnified,
  //       }
  //     }));
  //   }
  // }, [isLoading, isLoadingUserPermissions]);
  // !end

  /* useEffect(() => {
    const data  = getDynamicData();
    setUnifiedData(data );
    console.log("ContextProvider unifiedData: ", unifiedData);
  }, [dataLoaded]); */

  //console.log("ContextProvider unifiedData outside useEffect: ", unifiedData);

  /* const contextValue = {
    unifiedData,
    widgetData: widgetData(unifiedData),
    // ... other context values
  }; */

  const entityId = "LEVC:DAS001";
  let startDate, endDate;  

  useEffect(() => {
    if (accessToken && refreshToken) {
      fetchPressure({
        entityId, //"LEVC:DAS001",
        startDate, //: "2024-05-08T12:02:40Z",
        endDate, //: "2024-05-09T12:02:50Z",
        //min_pressure: 45,
        max_pressure: 50,
        maxResults: 5,
        category: "pressure",
        accessToken,
        refreshToken,
      });
      fetchTemperature({
        entityId, //"LEVC:DAS001",
        startDate, //: "2024-05-08T12:02:40Z",
        endDate, //: "2024-05-09T12:02:50Z",
        //min_temperature: 20, //Not use it, comment the line, if no results retrieved. In postman not using it showed results
        //max_temperature: 50, //Not use it, comment the line, if no results retrieved. In postman not using it showed results
        maxResults: 5,
        category: "temperature",
        accessToken,
        refreshToken,
      });
      fetchFlow({
        entityId, //"LEVC:DAS001",
        startDate, //: "2024-05-08T12:02:40Z",
        endDate, //: "2024-05-09T12:02:50Z",
        //min_flow: 1,
        //max_flow: 50,
        maxResults: 5,
        category: "flow",
        accessToken,
        refreshToken,
      });
    } else {
      console.log("ContextProvider fibTokens not available. Waiting for tokens...");
    }
  }, [accessToken, refreshToken]);
     
  //------------END MANAGE DATA RETRIEVED FROM SENSORS---------


  //-----WIDGETS AND LAYOUTS----------
  const handleUpdateWidgetType = (widgetId, newType) => {
    updateWidgetType(widgetId, newType, userProfile, setUserProfile);
  };

  const handleUpdateWidgetOptions = (widgetId, newOptions) => {
    updateWidgetOptions(widgetId, newOptions, userProfile, setUserProfile);
  };
  const handleResetFrontLayout = () => {
    resetFrontLayout(frontLayouts, userProfile, setUserProfile);
  };

  // Put to helper
  const handleSetWidgetDefaultOptions = (widgetId, newType) => {
    setWidgetDefaultOptions(
      widgetId,
      newType,
      availableWidgets,
      userProfile,
      setUserProfile
    );
  };

  const handleAddWidget = (widgetType) =>
    addWidget(
      widgetType,
      availableWidgets,
      unifiedData,
      userProfile,
      setUserProfile
    );

  const handleRemoveWidget = (widgetId) =>
    { if (widgetId !== "mapGis") {
      removeWidget(widgetId, userProfile, setUserProfile)
    } else {
      console.log("ContextProvider Cannot remove mapGis widget");
    }
    };
  const handleStoreWidget = (widgetId) => {
    storeWidget(widgetId, userProfile, setUserProfile);
    removeWidget(widgetId, userProfile, setUserProfile);
  };
  const handleRestoreStoredWidget = (widgetId) =>
    restoreStoredWidget(widgetId, userProfile, setUserProfile);


  const setDashboardLayoutByName = (layoutName, navigateTo) => {
    try {
      console.log("ContextProvider setDashboardLayoutByName layoutName:", layoutName);
      console.log("ContextProvider setDashboardLayoutByName navigateTo:", navigateTo);
      
      if (layoutName) {
        setUserProfile((prevProfile) => {
          if (!prevProfile || !prevProfile.userSettings) {
            console.error("Invalid profile structure", prevProfile);
            return prevProfile; // Early return if structure is invalid
          }
  
          const updatedProfile = {
            ...prevProfile,
            userSettings: {
              ...prevProfile.userSettings,
              layoutMode: layoutName,
              // dashboardLayout: frontLayouts[navigateTo ? navigateTo : layoutName],
            },
          };

          localStorage.setItem("userProfile", JSON.stringify(updatedProfile));
          return updatedProfile;
        });
        // setTimeout(() => {
        //   handleSetLayout(navigateTo);
        // }
        // , 500);
      } else {
        console.error("Layout not found:", layoutName);
      }
    } catch (error) {
      console.error("Error in setDashboardLayoutByName:", error);
    }
  };
  

  const toggleDarkMode = () => {
    setUserProfile((prevState) => ({
      ...prevState,
      userSettings: {
        ...prevState.userSettings,
        darkMode:
          prevState.userSettings.darkMode === "light"
            ? "dark"
            : prevState.userSettings.darkMode === "dark"
            ? "light"
            : "dark",
      },
    }));
  };

  //-------END WIDGETS AND LAYOUTS-----------------


  return (
    <StateContext.Provider
      value={{
        // handleClick,
        updateUser,
        toggleDarkMode,
        // activeMenu,
        // setActiveMenu,
        // isClicked, setIsClicked,
        // screenSize,
        // setScreenSize,
        // showIconsOnly,
        // setShowIconsOnly,
        // activeAppbar, setActiveAppbar,
        activeLoginForm,
        setActiveLoginForm,
        userProfile,
        setUserProfile,
        selectedEvent,
        setSelectedEvent,
        selectedEventId,
        setSelectedEventId,
        handleAddWidget,
        handleRemoveWidget,
        handleStoreWidget,
        handleRestoreStoredWidget,
        addWidget,
        removeWidget,
        storeWidget,
        restoreStoredWidget,
        handleSetLayout,
        latestUpdateTime,
        // setLatestUpdateTime,
        handleResetFrontLayout,
        setDashboardLayoutByName,
        isEditingDashboardLayout, 
        setIsEditingDashboardLayout,
        handleUpdateWidgetOptions,
        handleUpdateWidgetType,
        handleSetWidgetDefaultOptions,
        accessToken,
        setAccessToken,
        refreshToken,
        setRefreshToken,
        fetchPressureData,
        fetchPressure,
        fetchTemperatureData,
        fetchFlowData,
        fetchDevicesData,
        fetchSensorsDataFromAPI, // test to add refresh button to page
        // handleSetDashboardLayoutByName,
        activeLinkName,
        setActiveLinkName,
        activeComponent,
        setActiveComponent,
        //
        logoutRemoveProfile,
        sensorsDataLatest,
        setSensorsDataLatest,
        sensorsDataLast5,
        setSensorsDataLast5,
        isLoading,
        setIsLoading,
        isLoadingUserPermissions,
        setIsLoadingUserPermissions,
        unifiedData,
        userData,
        setUserData,
        //contextValue,
        //widgetData: widgetData(unifiedData),
      }}>
      {children}
    </StateContext.Provider>
  );
};

/**We use the activeMenu inside the components of the app by exporting the useStateContext.
 * It's a function that returns the calls to the useContext but we pass which context will we wanna use.
 * Nemo tip: "Give me the data from the context (useStateContext) by using the context (useContext) and we specify which one (StateContext)"
 * In this app we'll have one context but in bigger applications we can have multiple context providers.
 */
export const useStateContext = () => useContext(StateContext);
