import React, { useState, useEffect } from "react";
import { Button } from "components/ui/button";
import { ButtonGroup } from "@mui/material";
import { useStateContext } from "contexts/ContextProvider";
import BackgroundMap from "./background-map.svg"

const SetupFrontlayout = ({ userProfile, updateUserProfile }) => {
  const { setDashboardLayoutByName } = useStateContext();
  const storedUserProfile = JSON.parse(localStorage.getItem("userProfile"));

  const [settings, setSettings] = useState({
    layoutMode: userProfile.userSettings.layoutMode || "simple",
  });

  const allLayouts = [
    {
      label: "Basico",
      value: "basico",
      grid: [1, 1],
      gridItems: [[1, 1]],
      mobileGrid: [1, 1],
      mobileGridItems: [[1, 1]],
    },
    {
      label: "Simple",
      value: "simple",
      grid: [1, 2],
      gridItems: [
        [1, 1],
        [1, 1],
      ],
      mobileGrid: [2, 1],
      mobileGridItems: [
        [1, 1],
        [1, 1],
      ],
    },
    {
      label: "Sorteado",
      value: "sorted",
      grid: [2, 2],
      gridItems: [
        [1, 1],
        [1, 2],
        [1, 1],
      ],
      mobileGrid: [2, 2],
      mobileGridItems: [
        [2, 1],
        [1, 1],
        [1, 1],
      ],
    },
    {
      label: "Complejo",
      value: "complex",
      grid: [2, 3],
      gridItems: [
        [1, 2],
        [2, 1],
        [1, 1],
        [1, 1],
      ],
      mobileGrid: [3, 2],
      mobileGridItems: [
        [2, 1],
        [2, 1],
        [1, 1],
        [1, 1],
      ],
    },
    {
      value: "custom",
      label: "Personalizado",
      grid: [4, 4],
      gridItems: [
        [2, 1],
        [2, 2],
        [2, 3],
        [1, 1],
        [1, 1],
        [2, 1],
      ],
      mobileGrid: [6, 2],
      mobileGridItems: [
        [2, 1],
        [2, 2],
        [2, 1],
        [1, 1],
        [1, 1],
        [2, 1],
      ],
    },
  ];

  let layoutOptions = [];
  if (storedUserProfile.role === "regante") {
    layoutOptions = allLayouts.filter(
      (layout) => layout.value === "basico" || layout.value === "simple"
    );
  } else if (storedUserProfile.role === "tecnico") {
    layoutOptions = allLayouts.filter(
      (layout) =>
        layout.value === "simple" ||
        layout.value === "sorted" ||
        layout.value === "complex"
    );
  } else if (storedUserProfile.role === "admin") {
    layoutOptions = allLayouts.filter(
      (layout) =>
        layout.value === "simple" ||
        layout.value === "sorted" ||
        layout.value === "complex" ||
        layout.value === "custom"
    );
  }

  const handleChange = (layoutMode) => {
    setSettings({ ...settings, layoutMode });
    setDashboardLayoutByName(layoutMode);
  };

  const renderGrid = (gridItems, rows, columns) => {
    return (
      <div
        style={{
          gridTemplateColumns: `repeat(${columns}, 1fr)`,
          gridTemplateRows: `repeat(${rows}, 1fr)`,
        }}
        className="grid w-full h-full *:bg-gray-200 dark:*:bg-gray-400 *:rounded-sm gap-1"
      >
        {gridItems.map((cell, index) => (
          <div
            key={index}
            style={{
              gridColumn: `span ${cell[0]}`,
              gridRow: `span ${cell[1]}`,
            }}
            className="rounded-md"
          ></div>
        ))}
      </div>
    );
  };

  return (
    <div className="max-w-md md:max-w-2xl mx-auto dark:text-white w-full flex flex-col gap-y-4 md:gap-y-8">
      <div className="md:text-center">
        <h2 className="font-medium leading-7 dark:text-white">
          Layout de inicio
        </h2>
        <span className="text-sm dark:text-gray-200">
          Seleccione su tipo de layout para la pagina de inicio.
        </span>
      </div>
      <div className="flex flex-col gap-2 md:gap-4">
        <ButtonGroup
          className={`!grid  mx-auto
        ${
          storedUserProfile.role === "regante" &&
          "grid-cols-2 sm:grid-cols-2 w-full md:w-1/2"
        }
        ${
          storedUserProfile.role === "tecnico" &&
          "grid-cols-3 sm:grid-cols-3 w-full"
        }
        ${
          storedUserProfile.role === "admin" &&
          "grid-cols-2 sm:grid-cols-4 w-full"
        }
           justify-center gap-3`}
        >
          {layoutOptions.map((option) => (
            <Button
              key={option.value}
              className="border border-gray-300 rounded-md h-fit flex flex-col gap-2"
              variant={
                settings.layoutMode === option.value ? "default" : "outline"
              }
              onClick={() => handleChange(option.value)}
            >
              <div className="w-20 h-14">
                {renderGrid(option.gridItems, option.grid[0], option.grid[1])}
              </div>
              {option.label}
            </Button>
          ))}
        </ButtonGroup>
        <h3 className="text-center">Prevista de layout</h3>
        <span className="text-center">
          Esta es una previsualización de cómo se verá el layout de inicio que
          ha seleccionado. Puedes ver cómo se organizarán los widgets en
          computadores y celulares.
        </span>

        <div className="flex justify-center h-full max-w-full overflow-visible gap-4">
          <div className="grid w-56 sm:w-64 md:w-96 rounded-lg border border-gray-500 grid-cols-[2.5rem_1fr] grid-rows-[auto_1fr]">
            <div className="row-span-2 border-r flex flex-col gap-1 px-1 py-1.5">
              <div className="bg-gray-200 rounded-full w-full h-2"></div>
              <div className="bg-gray-200 rounded-full w-full h-2"></div>
              <div className="bg-gray-200 rounded-full w-full h-2"></div>
              <div className="bg-gray-200 rounded-full w-full h-2"></div>
              <div className="bg-gray-200 rounded-full w-full h-2"></div>
              <div className="bg-gray-200 rounded-full w-full mt-auto h-2"></div>
            </div>
            <div className="flex w-full gap-1 px-3 pt-2 items-center justify-end">
              <div className="bg-gray-200 rounded-full w-16 h-3 mr-auto"></div>
              <div className="bg-gray-200 rounded-full size-3"></div>
              <div className="bg-gray-200 rounded-full size-3"></div>
            </div>

            <div className="flex w-full h-32 sm:h-40 md:h-56 lg:h-64 pb-3 pt-2 px-3">
              {layoutOptions.find((l) => l.value === settings.layoutMode)
                ?.gridItems &&
                renderGrid(
                  layoutOptions.find((l) => l.value === settings.layoutMode)
                    ?.gridItems,
                  layoutOptions.find((l) => l.value === settings.layoutMode)
                    ?.grid[0],
                  layoutOptions.find((l) => l.value === settings.layoutMode)
                    ?.grid[1]
                )}
            </div>
          </div>
          {/* <div className="grid h-full w-16 sm:w-20 md:w-24 lg:w-32 mt-auto rounded-lg border border-gray-500 grid-cols-[1fr] grid-rows-[auto_minmax(2rem,1fr)]">
            <div className="flex w-full gap-1 px-2 pt-2 items-center justify-end">
              <div className="bg-gray-200 rounded-full w-full max-w-16 h-2 md:h-3 mr-auto"></div>
              <div className="bg-gray-200 rounded-full aspect-square size-2 md:size-3"></div>
              <div className="bg-gray-200 rounded-full aspect-square size-2 md:size-3"></div>
            </div>
            <div className="flex *:*:min-h-4 w-full h-24 sm:h-28 md:h-40 lg:h-48 pb-2 pt-2 px-2">
              {layoutOptions.find((l) => l.value === settings.layoutMode)
                ?.mobileGridItems &&
                renderGrid(
                  layoutOptions.find((l) => l.value === settings.layoutMode)
                    ?.mobileGridItems,
                  layoutOptions.find((l) => l.value === settings.layoutMode)
                    ?.mobileGrid[0],
                  layoutOptions.find((l) => l.value === settings.layoutMode)
                    ?.mobileGrid[1]
                )}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SetupFrontlayout;
