import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//Contexts
import { ContextProvider } from './contexts/ContextProvider';
import { AuthProvider } from './contexts/AuthContext';
import { MapProvider } from './contexts/MapContext';
import { PageTitleProvider } from 'contexts/PageTitleContext';
import { BrandProvider } from 'contexts/BrandContext';
import { TooltipProvider } from 'components/ui/tooltip';
import { EventsProvider } from 'contexts/EventHandlers';

import reportWebVitals from './reportWebVitals';
import { Toaster } from 'components/ui/sonner';
const root = ReactDOM.createRoot(document.getElementById('root'));



const brandConfigs = [
  {
    brandName: 'HortaTech',
    typography: {
      fontFamily: 'Outfit, sans-serif',
    },
    colors: {
      primary: {
        DEFAULT: '#1D91E0',
        50: '#E2F1FB',
        100: '#D0E8F9',
        200: '#ACD7F4',
        300: '#88C6F0',
        400: '#64B4EB',
        500: '#40A3E6',
        600: '#1D91E0',
        700: '#1771AE',
        800: '#10517D',
        900: '#0A314B',
        950: '#062032',
      },
      secondary: {
        DEFAULT: '#53C165',
        50: '#F8FCF9',
        100: '#E9F7EB',
        200: '#CBECD0',
        300: '#ADE1B6',
        400: '#8FD79B',
        500: '#71CC80',
        600: '#53C165',
        700: '#3AA24B',
        800: '#2B7838',
        900: '#1D4F25',
        950: '#153B1B',
      }
    },
    url: 'hortatech.es',
    folder: 'hortatech',
    logo: '/hortatech/logo-large.svg',
    manifest: 'hortatech/manifest.json',
  }, {
    brandName: 'Cidaqua',
    typography: {
      fontFamily: 'Quicksand, sans-serif',
    },
    colors: {
      primary: {
        DEFAULT: '#1D91E0',
        50: '#eef9ff',
        100: '#dcf3ff',
        200: '#b2e9ff',
        300: '#6dd9ff',
        400: '#20c5ff',
        500: '#00aeff',
        600: '#008bdf',
        700: '#006fb4',
        800: '#005e94',
        900: '#004b77',
        950: '#003151',
      },
      secondary: {
        DEFAULT: '#53C165',
        50: '#F8FCF9',
        100: '#E9F7EB',
        200: '#CBECD0',
        300: '#ADE1B6',
        400: '#8FD79B',
        500: '#71CC80',
        600: '#53C165',
        700: '#3AA24B',
        800: '#2B7838',
        900: '#1D4F25',
        950: '#153B1B',
      }
    },
    url: 'cidaqua.com',
    folder: 'cidaqua',
    logo: '/cidaqua/logo-large.svg',
    manifest: 'cidaqua/manifest.json',
  },

];



root.render(
  <React.StrictMode>
    <AuthProvider>
      <PageTitleProvider>
        <ContextProvider>
          <EventsProvider>
            <MapProvider>
              <TooltipProvider>
                <BrandProvider brandConfigs={brandConfigs}>

                  <App />
                  <Toaster richColors />
                </BrandProvider>

              </TooltipProvider>

            </MapProvider>
          </EventsProvider>
        </ContextProvider>
      </PageTitleProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
