import React, { useEffect } from "react";
import { usePageTitle } from 'contexts/PageTitleContext';
import ForgotPasswordFrame from "components/authentication/ForgotPasswordFrame";
import { useBrand } from 'contexts/BrandContext';
const ForgotPassword = () => {
  const { brand } = useBrand();

  const { setPageTitle } = usePageTitle();
  useEffect(() => {
    setPageTitle("Recuperar Contraseña");
  }, [setPageTitle]);

  return (
    <div className="flex  w-full flex-col justify-center  gap-y-4 items-center">
        <img
          src={brand.logo}
          alt={brand.brandName}
          className="pointer-events-none object-contain h-12"
        />
      <ForgotPasswordFrame />
    </div>
  );
};

export default ForgotPassword;
