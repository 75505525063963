import React from "react";
import { useBrand } from 'contexts/BrandContext';

import { SignupFrame } from "../components";

import { Button } from "components/ui/button";
import { ReactComponent as ArrowLeftIcon } from "icons/arrow/left.svg";
import { Link } from "react-router-dom";
const Register = () => {
  const { brand } = useBrand();

  return (
    <div className="flex  w-full flex-col justify-center  gap-y-4 items-center">
      <Button variant="link" asChild className="text-sm text-gray-500 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-100">
      <a href={`https://${brand.url}`}>
          <ArrowLeftIcon className="h-4 w-4 mr-1 inline-block" />
          Volver a la web
        </a>
      </Button>

      <img
        src={brand.logo}
        alt={brand.brandName}
        className="pointer-events-none object-contain h-12"
      />
      <SignupFrame />
    </div>
  );
};

export default Register;
