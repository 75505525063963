const QueueItem = ({ user, className, onRowClick, user_id }) => {
  if (!user) {
    return <div>User not available</div>;
  }

  return (
    <li
      className={`${user_id === user.user_id ? "border-green-500 dark:border-green-500 hover:bg-green-200" : "border-gray-200 dark:border-gray-600"} transition-colors border text-gray-700 dark:text-gray-100 gap-x-2 items-center grid grid-cols-[auto_1fr_auto]  rounded-md px-3 py-1 ${className}`}
      onClick={() => onRowClick(user)}
      key={user.user_id}
    >
      <span className="font-medium text-right min-w-11">{user.time}</span>
      <span className={`${user_id === user.user_id ? "text-gray-500 dark:text-gray-100" : "text-gray-300"} text-sm`}>Parcela {user.parcel}</span>
      <span>
        {new Date(
          0,
          0,
          0,
          user.duration / 60,
          user.duration % 60
        ).toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit",
        })}{" "}
        hs
      </span>
    </li>
  );
};

export default QueueItem;